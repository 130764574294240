/*eslint-disable*/
import { parseIdFromProjectId } from "@/utils/helpers/functions";

export const getEvaluationList = (
  traineeArray: any[],receivedFunding?:boolean
): Partial<{ gjsp_id: string,received_funding:boolean }>[] => {
  let batchList: Partial<{ gjsp_id: string,received_funding:boolean }>[] = [];
  traineeArray.forEach((value) => {
 
        batchList.push({ gjsp_id: value[0], ...getMonitoringValues(value,receivedFunding) });
      
  });
  return batchList;
};

export const getMonitoringValues = (values:any[],received_funding:boolean) => {

  if (received_funding == true){
  return {
        "name": values[1],
        "used_funds_for_intended_purpose": values[2] == "1"?true:false,
        "started_business": values[3] == "1"?true:false,
        "employees_hired": values[4],
        "female_employees_hired": values[5],
        "youth_employees_hired": values[6],
        "pwd_employees_hired": values[7]
    }
  }
  if (received_funding == false) {
      return {
        "name": values[1],
        "started_business": values[2] == "1"?true:false,
        "employees_hired": values[3],
        "female_employees_hired": values[4],
        "youth_employees_hired": values[5],
        "pwd_employees_hired": values[6],
        "used_funds_for_intended_purpose":false
    }
  }
  
}

export interface IMonitoringParams {
    id_list: Partial<{gjsp_id:string}>[],
    received_funding:boolean
}