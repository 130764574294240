/*eslint-disable*/
import React from 'react';
import { PostDisbursementContext } from '@/utils/context/DisbursementContext';
import DisbursementBaseLayout from './components/DisbursementBaseLayout';
import DisbursementTable from './components/DisbursementTable';
import { useGetPostdisbursement } from './services/Postdisbursement.service';
import { AppContext } from '@/utils/context/AppContext';
import DisbursementFileUpload from './components/DisbursementFileUpload';
import { Box, Grid, Button } from '@mui/material';
import { defaultActiveColor } from '@/utils/constants';
import ConfirmModalBase from '../Modal/ConfirmModalBase';
import InfoText from '../Text/InfoText';

const Postdisbursement = () => {
  const {
    params,
    tabValue,
    tableData,
    postdisbursementTableOptions,
    postdisbursementTableColumns,
    previewTableOptions,
    previewTableColumns,
    previewTableData,
    regions,
    districts,
    isLoading,
    isRefetching,
    selectedItems,
    uploadCSV,
    downloadCSVTemplate,
    downloadCSV,
    templateFile,
    handleFileChange,
    setParams,
    setTabValue,
    filter,
  } = useGetPostdisbursement();

  const { currentViewState } = React.useContext(AppContext);

  return (
    <PostDisbursementContext.Provider
      value={{
        params: params,
        tabValue: tabValue,
        regions: regions,
        districts: districts,
        setParams: setParams,
        setTabValue: setTabValue,
      }}
    >
      <DisbursementBaseLayout
        title="Post-Disbursement"
        currentViewState={currentViewState}
        tabValue={tabValue}
        onFilter={filter}
        setTabValue={setTabValue}
        tabs={['Post-disbursement Applicants', 'File Upload']}
        showFilter={tabValue == 0}
        type="post"
      >
        {tabValue == 0 && (
          <>
            <DisbursementTable
              title={'Post-disbursement Applicant Table'}
              data={tableData}
              tableOptions={postdisbursementTableOptions}
              columns={postdisbursementTableColumns}
              isLoading={isLoading || isRefetching}
              actionDisabled={isLoading || selectedItems.length == 0}
              onActionButtonPress={downloadCSV}
              onCSVTemplateDownload={downloadCSVTemplate}
              showDownloadCSVButton={true}
              showDownloadTemplateButton={true}
            />
          </>
        )}
        {tabValue == 1 && (
          <>
            <DisbursementFileUpload
              templateFile={templateFile}
              handleFileChange={handleFileChange}
              prompt={'Upload a file of applicants who received disbursement'}
            />

            <Grid item xs={12} md={12} style={{ marginTop: '10px' }}>
              <Box>
                <ConfirmModalBase
                  onConfirm={() => {
                    if (templateFile !== null) uploadCSV();
                  }}
                >
                  <Button
                    sx={{
                      '&:hover': { backgroundColor: '#2a3b8f', color: 'white', border: '2px solid #2a3b8f' },
                      fontWeight: '600',
                      fontFamily: 'inherit !important',
                      fontSize: '1rem',
                      color: defaultActiveColor,
                      border: `2px dashed ${defaultActiveColor}`,
                      textTransform: 'initial !important',
                      width: '100%',
                      paddingTop: '8px',
                      paddingBottom: '8px',
                    }}
                    disabled={templateFile == null}
                    onClick={() => {}}
                  >
                    Upload File
                  </Button>
                </ConfirmModalBase>
              </Box>
              <InfoText
                wrapperStyle={{
                  marginTop: '20px',
                  marginBottom: '10px',
                  fontStyle: 'italic',

                  color: defaultActiveColor,
                }}
              >
                {
                  'Make sure dates are formatted in the YYYY-MM-DD format, and are within between the years 2023 and 2024 (2023 and 2024 included). Also, ensure that bank account numbers and disbursed amounts are valid numerical values (bank account numbers should be 10 digits long). Rows that are not formatted properly will be skipped and not added to the final upload list.'
                }
              </InfoText>
            </Grid>
            <DisbursementTable
              title={'File Upload Preview'}
              data={previewTableData}
              tableOptions={previewTableOptions}
              columns={previewTableColumns}
              isLoading={isLoading || isRefetching}
              showDownloadTemplateButton={true}
            />
          </>
        )}
      </DisbursementBaseLayout>
    </PostDisbursementContext.Provider>
  );
};

export default Postdisbursement;
