import React from "react";
import { Box, Modal, Button, FormLabel } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import DateRangeIcon from "@mui/icons-material/DateRange";
import moment from "moment";
import propTypes from "prop-types";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const AuditFilter = ({
  changeDateParams,
  fetchTrail,
  dateState,
  roles,
  changeParams,
  auditParams,
}) => {
  const buttonStyle = {
    "&:hover": { color: "#2a3b8f", border: "1px solid #2a3b8f" },
    fontWeight: "400",
    fontFamily: "inherit !important",
    fontSize: "0.85rem",
    color: "white",
    backgroundColor: "#2a3b8f",
    textTransform: "initial !important",
    padding: "0.7rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
  };
  const dateRangeStyle = {
    padding: "0.7rem",
    color: "black",
    backgroundColor: "white",
    border: "1px solid #e1e1e1",
    borderRadius: "0.8rem",
    fontSize: "0.9rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [dateModalOpen, setDateModalOpen] = React.useState(false);
  return (
    <>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <FormLabel
            sx={{
              fontFamily: "inherit",
              marginBottom: "0.1rem",
              fontWeight: "600",
            }}
          >
            Roles
          </FormLabel>
          <select
            className=" filter-select"
            style={{ width: "300px" }}
            onChange={(e) => {
              changeParams({ ...auditParams, role: e.target.value });
            }}
          >
            <option value={""}>All</option>
            {roles.map((role, index) => {
              return (
                <option key={`${role?.name}-${index}`} value={role.id}>
                  {role.name}
                </option>
              );
            })}
          </select>
        </Box>
        <Box
          style={{
            padding: "2rem",
            paddingLeft: "unset",
            paddingRight: "unset",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            marginLeft: "2rem",
          }}
        >
          <Box sx={{ marginRight: "1rem" }}>
            <button
              style={dateRangeStyle}
              onClick={() => {
                setDateModalOpen(true);
              }}
            >
              <Box>
                <DateRangeIcon style={{ color: "black" }} />
              </Box>
              <Box
                style={{
                  color: "black",
                  fontSize: "0.9rem",
                  fontWeight: "600",
                }}
              >
                {`${moment(dateState[0].startDate).format("MMMM Do YYYY")}
                            - ${moment(dateState[0].endDate).format(
                              "MMMM Do YYYY"
                            )}`}
              </Box>
            </button>
          </Box>
          <Box>
            <Button
              type="submit"
              sx={buttonStyle}
              onClick={() => {
                fetchTrail();
              }}
            >
              Filter
            </Button>
          </Box>
        </Box>

        {/* <Grid item xs={4} sx={{display:'flex,',flexDirection:'column'}}>
                <FormLabel sx={{fontFamily:'inherit',marginBottom:'0.1rem',fontWeight:'600'}}>Roles</FormLabel>
                    <select className="filter-select" name="disability" onChange={()=>{}} >
                        <option value={''}>All</option>
                        <option value={'yes'}>Show disabled Applicants</option>
                        <option value={'no'}>Hide disabled Applicants</option>
                    </select>
                </Grid> */}
      </Box>

      <Modal
        open={dateModalOpen}
        onClose={() => {
          setDateModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <DateRangePicker
            onChange={(item) => changeDateParams([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            ranges={dateState}
            direction="horizontal"
          />
          ;
          <div
            className="confirm-row w-100"
            style={{ textAlign: "center", borderTop: "1px solid #e7e7e7" }}
          >
            <button
              style={dateRangeStyle}
              onClick={() => {
                setDateModalOpen(false);
              }}
            >
              Confirm Time Period
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

AuditFilter.propTypes = {
  changeDateParams: propTypes.func,
  changeParams: propTypes.func,
  fetchTrail: propTypes.func,
  dateState: propTypes.object,
  roles: propTypes.array,
  auditParams: propTypes.object,
};

export default AuditFilter;
