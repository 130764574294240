/*eslint-disable*/
import React from 'react';
import { Modal, Box, Button, styled } from '@mui/material';
import { ReactNode } from 'react';
import styles from './modal.module.css';
import CloseIcon from '@material-ui/icons/Close';
import { defaultActiveColor } from '@/utils/constants';
import { ModalTitle } from '../styled/modal.component';

const ConfirmButton = styled(Button)({
  //@ts-ignore
  backgroundColor: `${defaultActiveColor}`,
  color: 'white!important',
  padding: '10px!important',
  textTransform: 'initial!important',
  minWidth: '20%',
  fontWeight: '500!important',
  fontSize: '15px',
  '&:hover': {
    backgroundColor: 'white!important',
    border: `1px solid ${defaultActiveColor}!important`,
    color: `${defaultActiveColor}!important`,
  },
  fontFamily: 'inherit!important',
});

const CancelButton = styled(Button)({
  //@ts-ignore
  backgroundColor: 'white',
  minWidth: '20%',
  color: `${defaultActiveColor}`,
  textTransform: 'initial!important',
  fontWeight: '500!important',
  fontSize: '15px',
  border: `1px solid ${defaultActiveColor}`,
  marginRight: '10px',
  fontFamily: 'inherit!important',
});

export interface IModalBaseLayout {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCloseable?: boolean;
  children?: ReactNode;
  showFooter?: boolean;
  title?: string;
  onConfirm?: (params?: any) => void;
  onCancel?: () => void;
  confirmParams?: any;
  cancelParams?: any;
}

const ModalBaseLayout = (props: IModalBaseLayout) => {
  const handleClose = () => {
    if (props?.isCloseable !== false) {
      props.setOpen(false);
    }
  };

  const handle = () => {
    props.setOpen(true);
  };

  return (
    <Modal open={props.open} onClose={handleClose}>
      <Box className={`${styles['modal-wrapper']}`}>
        <Box className={`${styles['modal-title']}`}>
          <ModalTitle>{props.title || ''}</ModalTitle>
          <Button onClick={handleClose}>
            <CloseIcon style={{ color: 'black' }} />
          </Button>
        </Box>
        <Box marginY={'20px'}>{props?.children}</Box>
        {props?.showFooter == true && (
          <div className={`${styles['modal-footer']}`}>
            <CancelButton
              onClick={() => {
                props?.onCancel || handleClose();
              }}
            >
              Cancel
            </CancelButton>
            <ConfirmButton
              onClick={() => {
                if (props.onConfirm) {
                  props?.onConfirm(props?.confirmParams);
                }
              }}
            >
              Submit
            </ConfirmButton>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default ModalBaseLayout;
