/*eslint-disable*/
import React from "react";
import ScoreCard from "./ScoreCard";
import propTypes from "prop-types";
import { Box } from "@mui/material";
import noscore from "../assets/noscore.png";

export default function Scores(scores) {
  const [metadata, setMetadata] = React.useState([]);

  const shouldScoresShow = (scoreObject) => {
    if (scoreObject !== null && scoreObject !== undefined) {
      if (
        scoreObject.business_acumen_score !== null &&
        scoreObject.educational_level_score !== null &&
        scoreObject.financial_literacy_score !== null &&
        scoreObject.profile_score !== null &&
        scoreObject.total_application_score !== null &&
        scoreObject.training_score !== null
      ) {
        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    try {
      let { score_metadata } = scores?.percentage;
      let keys = Object.keys(score_metadata);
      let metadata_array = [];

      keys.forEach((key) => {
        let metadataObject = { key: `${key}`, metadata: score_metadata[key] };
        metadata_array.push(metadataObject);
      });

      setMetadata(metadata_array);
    } catch (e) {

      setMetadata([]);
    }
  }, [scores]);

  return (
    <div style={{ padding: "1rem", marginBottom: "3rem" }}>
      {shouldScoresShow(scores.percentage) === true ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "start",
            flexBasis: "auto",
            gap: "48px",
            marginBottom: "3rem",
          }}
        >
          <ScoreCard
            color={"#0459AD"}
            score={scores?.percentage?.total_application_score}
            title="Total Score"
            index={0}
            metadata_array={metadata}
            metadata_key={""}
          />

          <ScoreCard
            color={"#00C9BA"}
            score={scores?.percentage?.business_acumen_score}
            title="Entrepreneurial/Business Acumen"
            index={1}
            metadata_array={metadata}
            metadata_key={"business_acumen_score_metadata"}
          />
          <ScoreCard
            color="#289BFF"
            score={scores?.percentage?.financial_literacy_score}
            title="Financial Literacy"
            index={2}
            metadata_array={metadata}
            metadata_key={"financial_literacy_score_metadata"}
          />
          <ScoreCard
            color="#7A71D1"
            score={scores?.percentage?.training_score}
            title="Training Information"
            index={3}
            metadata_array={metadata}
            metadata_key={"training_score_metadata"}
          />

          <ScoreCard
            color="#FFA000"
            score={scores?.percentage?.educational_level_score}
            title="Educational Information"
            index={4}
            metadata_array={metadata}
            metadata_key={"educational_level_score_metadata"}
          />
          <ScoreCard
            color="#FF725E"
            score={scores?.percentage?.profile_score}
            title="Personal Information"
            index={5}
            metadata_array={metadata}
            metadata_key={"client_procfile_score_metadata"}
          />
        </div>
      ) : (
        <Box
          sx={{
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box>
              <img src={noscore} />
            </Box>
            <Box mt={"1rem"} sx={{ fontSize: "1.2rem" }}>
              Applicant has no scores available
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
}
Scores.propTypes = {
  scores: propTypes.object,
};
