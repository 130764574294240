import React from 'react';
import ApplicantSidebar from '../ApplicantSidebar/ApplicantSidebar';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { AssessmentBottom, AssessmentWrapper, GrantAssessmentRoot } from './components/GrantAssessment.components';
import ApplicantNavbar from '../ApplicantNavbar/ApplicantNavbar';
import GrantList from './GrantList';
import GrantDetails from './GrantDetails';
import { useGetGrantAssessment } from './services/GrantAssessment.service';
import { GrantAssessmentContext } from '@/utils/context/GrantAssementContext';

const GrantAssessment = () => {
  const {
    currentPage,
    totalPages,
    selectedApplicantId,
    selectedApplicantResponseObject,
    isLoading,
    isSubmitting,
    expenditureList,
    isPrinting,
    setIsPrinting,
    refetch,
    setIsSubmitting,
    setSelectedApplicantResponseObject,
    setSelectedApplicantId,
    setTotalPages,
    setCurrentPage,
  } = useGetGrantAssessment();
  const theme = useTheme();
  const isSmallViewport = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <GrantAssessmentContext.Provider
      value={{
        currentPage: currentPage,
        totalPages: totalPages,
        selectedApplicantId: selectedApplicantId,
        selectedApplicantResponseObject: selectedApplicantResponseObject,
        isLoading: isLoading,
        isSubmitting: isSubmitting,
        expenditureList: expenditureList?.data,
        isPrinting: isPrinting,
        setIsPrinting: setIsPrinting,
        refetch: refetch,
        setIsSubmitting: setIsSubmitting,
        setSelectedApplicantResponseObject: setSelectedApplicantResponseObject,
        setSelectedApplicantId: setSelectedApplicantId,
        setTotalPages: setTotalPages,
        setCurrentPage: setCurrentPage,
      }}
    >
      <GrantAssessmentRoot>
        <ApplicantSidebar selectedView={6} />
        <AssessmentWrapper>
          <Grid item xs={12}>
            <ApplicantNavbar />
          </Grid>
          <AssessmentBottom container>
            {isSmallViewport ? null : (
              <Grid item md={3} style={{ height: '100%' }}>
                <GrantList />
              </Grid>
            )}
            <Grid item md={9} xs={12} sm={12}>
              <GrantDetails />
            </Grid>
          </AssessmentBottom>
        </AssessmentWrapper>
      </GrantAssessmentRoot>
    </GrantAssessmentContext.Provider>
  );
};

export default GrantAssessment;
