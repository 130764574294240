import React from 'react';

/*eslint-disable*/
import { Box, Button, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Input = styled('input')({
  display: 'none',
});

interface IDisbursementFileUpload {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  prompt?: string;
  templateFile?: File | null | undefined;
}

const DisbursementFileUpload = (props: IDisbursementFileUpload) => {
  return (
    <Box sx={{ marginTop: '30px', marginBottom: '10px' }}>
      <span style={{ fontSize: '1rem' }}>
        {props?.prompt || 'Upload excel file of applicants who have completed training'}
      </span>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box my={'1rem'}>
          <label htmlFor={`cert-upload`}>
            <Input
              accept=".csv"
              id={`cert-upload`}
              multiple
              type="file"
              onChange={(e) => {
                props.handleFileChange(e);
              }}
            />
            <Button
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100px',
                height: '100px',
                backgroundColor: '#0459AD15',
                border: '2px dashed #0459AD',
                borderRadius: '1rem',
              }}
              component="span"
            >
              <AddIcon sx={{ color: '#0459AD', fontSize: '1.8rem' }} />
            </Button>
          </label>
        </Box>
        <Box mr={'1rem'} sx={{ fontSize: '1rem', fontStyle: 'italic' }}>
          {props?.templateFile?.name ? props?.templateFile?.name : 'No Document Selected Yet'}
        </Box>
      </Box>
    </Box>
  );
};

export default DisbursementFileUpload;
