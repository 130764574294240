/*eslint-disable*/
import { Grid, Theme, styled } from '@mui/material';
export const GrantAssessmentRoot = styled('section')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  overflowY: 'hidden',
  [theme.breakpoints.up('sm')]: {},
  display: 'flex',
}));

export const AssessmentWrapper = styled(Grid)(({ theme }) => ({
  width: '98%',
  height: '100%',
}));

export const AssessmentBottom = styled(Grid)(({ theme }) => ({
  height: 'calc(100% - 70px)',
}));
