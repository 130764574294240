/*eslint-disable*/
import ModalBaseLayout, { IModalBaseLayout } from '../Modal/ModalBaseLayout.tsx';
import React from 'react';
import { Box, Button, styled, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { getNextStageText, parseStage } from './services/AdvancedTraining.service.ts';
import { ConfirmRadioGroup, CustomFormControlLabel, CustomRadioComponent } from '../styled/form.component.ts';
import { ModalSubtitle } from '../styled/modal.component.ts';

interface IConfirmModal extends Partial<IModalBaseLayout> {
  stage: 'basic' | 'intermediate' | 'advanced';
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmModal = ({ stage, open, setOpen, onConfirm }: IConfirmModal) => {
  const getDefaultState = (stageValue: typeof stage) => {
    switch (stageValue) {
      case 'basic':
        return 'intermediate';
      case 'intermediate':
        return 'advanced';
      case 'advanced':
        return 'grants';
    }
  };
  const getFinalDestinations = (value: 'intermediate' | 'advanced' | 'grants') => {
    switch (value) {
      case 'intermediate':
        return 'basic';
      case 'advanced':
        return 'intermediate';
      case 'grants':
        return 'advanced';
    }
  };
  const [destination, setDestination] = React.useState<'intermediate' | 'advanced' | 'grants'>(getDefaultState(stage));

  const handleRadioButtonChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setDestination(value as 'intermediate' | 'advanced' | 'grants');
  };

  return (
    <ModalBaseLayout
      open={open}
      setOpen={setOpen}
      title={'Submit Uploaded File'}
      onConfirm={onConfirm}
      showFooter={true}
      confirmParams={getFinalDestinations(destination)}
    >
      <Box style={{ width: '100%' }}>
        <ModalSubtitle>
          Move passed applicants to
          <span style={{ color: 'black', fontWeight: '600' }}> {parseStage(destination)}</span>
        </ModalSubtitle>
        <Box>
          <ConfirmRadioGroup value={destination} onChange={handleRadioButtonChange}>
            <CustomFormControlLabel
              value="intermediate"
              control={<CustomRadioComponent />}
              label="Intermediate Training Approvals"
              defaultChecked={stage == 'basic'}
              disabled={stage == 'intermediate' || stage == 'advanced'}
            />
            <CustomFormControlLabel
              value="advanced"
              control={<CustomRadioComponent />}
              label="Advanced Training Approvals"
              defaultChecked={stage == 'intermediate' && destination !== 'intermediate'}
              disabled={stage == 'advanced' || stage == 'basic'}
            />
            <CustomFormControlLabel
              value="grants"
              control={<CustomRadioComponent />}
              label="Grant Approvals"
              defaultChecked={stage == 'advanced'}
              disabled={stage == 'basic'}
            />
          </ConfirmRadioGroup>
        </Box>
      </Box>
    </ModalBaseLayout>
  );
};

export default ConfirmModal;
