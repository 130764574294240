/*eslint-disable*/
import React from 'react';
import { DashboardContext } from '../../DashboardContent';
import { DashboardModel, Genders, Regions } from '../interfaces/dashboard.model';
import { IChart, IChartDataObject, IMapComponentChart } from '../interfaces/chart.interfaces';
import { RegionKey } from '../types/chart.types';
import { educationalLevelLabelObj } from '../../Cards/EducationalLevelChart';
import {
  getAgeGroupChartColorMapping,
  getDisbursementGenderValueChartColorMapping,
  getTrainingGenderChartColorMapping,
} from '../utils/colorMappings';
import { handleErrorMessage, messageAlert } from '@/utils/helpers/functions';

export const DefaultData: IChartDataObject[] = [
  {
    x: 'Men',
    label: 'Men',
    y: 20,
    color: 'red',
  },
  {
    x: 'Women',
    label: 'Women',
    y: 40,
    color: 'green',
  },
];

export const useGetDisbursementDashboard = () => {
  const [districtData, setDistrictData] = React.useState({});
  const [genderChartData, setGenderChartData] = React.useState<IChart<IChartDataObject[]>>({
    data: [],
    title: 'Disbursed Applicants By Gender',
  });
  const [disabilityChartData, setDisabilityChartData] = React.useState<IChart<IChartDataObject[]>>({
    data: [],
    title: 'Disbursed Applicants By Disability Status',
  });
  const [ageDistributionChartData, setAgeDistributionChartData] = React.useState<IChart<IChartDataObject[]>>({
    data: [],
    title: 'Disbursed Applicants By Age Distribution',
  });
  const [regionalDistributionData, setRegionalDistributionData] = React.useState<IMapComponentChart>({
    data: {
      Oti: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Bono: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Ahafo: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Volta: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Ashanti: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Central: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Eastern: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Savanna: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Western: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Northern: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      'Bono East': {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      'North East': {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      'Upper West': {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      'Upper East': {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      'Greater Accra': {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      'Western North': {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
    },
    title: 'Disbursed Applicants By Region',
  });
  const [educationalLevelChartData, setEducationalLevelChartData] = React.useState<IChart<IChartDataObject[]>>({
    title: 'Disbursed Applicants By Education Level',
    data: [],
  });

  const [sectorChartData, setSectorChartData] = React.useState<IChart<IChartDataObject[]>>({
    title: 'Disbursed Applicants By Business Sector',
    data: [],
  });

  const [disbursementValueGender, setDisbursementValueGender] = React.useState<IChart<IChartDataObject[]>>({
    data: [],
    title: 'Disbursed Value by Gender (GHS)',
  });

  const [disbursementValueDisability, setDisbursementValueDisability] = React.useState<IChart<IChartDataObject[]>>({
    data: [],
    title: 'Disbursed Value by Disability Status (GHS)',
  });

  const [disbursementValueRegional, setDisbursementValueRegional] = React.useState<IChart<IChartDataObject[]>>({
    data: [],
    title: 'Disbursed Value By Region (GHS)',
  });

  const [disbursementRegional, setDisbursementRegional] = React.useState<IChart<IChartDataObject[]>>({
    data: [],
    title: 'Disbursements By Region',
  });

  const { userData, disbursementStage, setDisbursementStage } = React.useContext(DashboardContext);
  const _userData = userData as unknown as DashboardModel;

  React.useEffect(() => {
    if (_userData) {
      try {
        const _genderChartData: IChart<IChartDataObject[]> = { ...genderChartData, data: [] };
        const _disabilityChartData: IChart<IChartDataObject[]> = { ...disabilityChartData, data: [] };
        const _disabilityDisbursementValueChartData: IChart<IChartDataObject[]> = {
          ...disbursementValueGender,
          data: [],
        };
        const _genderDisbursementValueChartData: IChart<IChartDataObject[]> = {
          ...disbursementValueDisability,
          data: [],
        };
        const _ageDistributionChartData: IChart<IChartDataObject[]> = { ...ageDistributionChartData, data: [] };
        const _educationalLevelChartData: IChart<IChartDataObject[]> = { ...educationalLevelChartData, data: [] };
        const _sectorChartData: IChart<IChartDataObject[]> = { ...sectorChartData, data: [] };
        const _disbursementValueRegional: IChart<IChartDataObject[]> = { ...disbursementValueRegional, data: [] };
        const _disbursementRegional: IChart<IChartDataObject[]> = { ...disbursementRegional, data: [] };
        const _regionalDistributionData = regionalDistributionData;
        Object.keys(_userData?.age_groups).forEach((key) => {
          let { short_name, total_male_applicants, total_female_applicants } = _userData?.age_groups[key];
          _ageDistributionChartData.data.push({
            color: getAgeGroupChartColorMapping(short_name),
            label: short_name,
            x: short_name,
            y: total_male_applicants + total_female_applicants,
          });
        });
        Object.keys(_userData?.genders).forEach((value) => {
          if (value !== 'S') {
            let { gender, total_applicants, total_male_disbursed_amount, total_female_disbursed_amount } =
              _userData?.genders?.[value as keyof Genders];
            _genderChartData.data.push({
              color: getTrainingGenderChartColorMapping(gender),
              label: gender,
              x: value == 'M' ? 'Male' : 'Female',
              y: total_applicants,
            });

            _genderDisbursementValueChartData.data.push({
              color: getDisbursementGenderValueChartColorMapping(gender),
              label: gender,
              x: value == 'M' ? 'Male' : 'Female',
              y: value == 'M' ? total_male_disbursed_amount : total_female_disbursed_amount,
            });
          }
        });

        Object.keys(_userData.regions).forEach((value) => {
          let {
            districts,
            region,
            total_male_applicants,
            total_female_applicants,
            total_female_disbursed_amount,
            total_female_disbursed_applicants,
            total_male_disbursed_amount,
            total_male_disbursed_applicants,
          } = _userData.regions[value as keyof Regions];
          _disbursementValueRegional?.data?.push?.({
            color: '#FF725E',
            label: region,
            x: region,
            y: total_male_disbursed_amount + total_female_disbursed_amount,
          });

          _disbursementRegional?.data?.push?.({
            color: 'rgb(255, 160, 0)',
            label: region,
            x: region,
            y: total_male_disbursed_applicants + total_female_disbursed_applicants,
          });

          // setDistrictData(districts);
        });

        Object.keys(_userData?.educational_levels).forEach((value) => {
          let { educational_level, total_male_applicants, total_female_applicants } =
            _userData.educational_levels[value];
          _educationalLevelChartData.data.push({
            color: '#407BFF',
            label: educationalLevelLabelObj?.[educational_level]?.label,
            x: educationalLevelLabelObj?.[educational_level as string]?.label,
            y: total_male_applicants + total_female_applicants,
          });
        });

        Object.keys(_userData?.sectors).forEach((value) => {
          let { sector, total_female_applicants, total_male_applicants } = _userData?.sectors?.[value];
          _sectorChartData.data.push({
            color: '#FEC34E',
            label: value,
            x: value,
            y: total_female_applicants + total_male_applicants,
          });
        });

        ['Disabled', 'Not Disabled'].forEach((value) => {
          _disabilityChartData?.data?.push({
            color: value == 'Not Disabled' ? '#7A71D1' : '#2A3B8F',
            label: value,
            x: value,
            y:
              value == 'Disabled'
                ? _userData?.disabled?.total_male_applicants + _userData?.disabled?.total_female_applicants
                : _userData.total_completed_applications -
                  (_userData?.disabled?.total_male_applicants + _userData?.disabled?.total_female_applicants),
          });

          _disabilityDisbursementValueChartData?.data?.push({
            color: value == 'Not Disabled' ? '#7A71D1' : '#2A3B8F',
            label: value,
            x: value,
            y:
              value == 'Disabled'
                ? _userData?.disabled?.total_male_disbursed_amount + _userData?.disabled?.total_female_disbursed_amount
                : _userData?.not_disabled?.total_male_disbursed_amount +
                  _userData?.not_disabled?.total_female_disbursed_amount,
          });
        });

        setGenderChartData(_genderChartData);
        setDisabilityChartData(_disabilityChartData);
        setAgeDistributionChartData(_ageDistributionChartData);
        setEducationalLevelChartData(_educationalLevelChartData);
        setDisbursementValueDisability(_disabilityDisbursementValueChartData);
        setDisbursementValueGender(_genderDisbursementValueChartData);
        setSectorChartData(_sectorChartData);
        setDisbursementRegional(_disbursementRegional);
        setDisbursementValueRegional(_disbursementValueRegional);
        setRegionalDistributionData({ title: 'Regional Distributions', data: _userData?.regions });
      } catch (e) {
        let message = handleErrorMessage(e);
        messageAlert('error', message);
      }
    }
  }, [userData]);

  return {
    genderChartData,
    disabilityChartData,
    ageDistributionChartData,
    regionalDistributionData,
    educationalLevelChartData,
    sectorChartData,
    disbursementValueDisability,
    disbursementValueGender,
    disbursementRegional,
    disbursementValueRegional,
    _userData,
    disbursementStage,
    setDisbursementStage,
  };
};
