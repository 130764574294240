import React from "react";
import { Box } from "@mui/material";
import propTypes from "prop-types";

const Title = ({ text }) => {
  const style = {
    margin: "2rem",
    // borderBottom:'1px solid #ebeff2',
    fontWeight: "600",
    fontSize: "1.2rem",
    paddingBottom: "1rem",
    marginBottom: "unset",
    // width:'100%'
  };
  return <Box sx={style}>{text}</Box>;
};

Title.propTypes = {
  text: propTypes.string,
};

export default Title;
