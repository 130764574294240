/*eslint-disable */
import ApplicantNavbar from "@components/ApplicantNavbar/ApplicantNavbar";
//  import ApplicantList from '@components/DashboardContent/Cards/ApplicantList';
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AdminDetails from "./AdminDetails";
import AdminUserList from "./UserList";
import useGetUserData from "@utils/hooks/useGetUserData";
import StaffNavbar from "@components/Staff/StaffNavbar";
import propTypes from "prop-types";

const UsersMain = ({ id }) => {
  const params = useParams();
  const [selectedApplicant, setSelectedApplicant] = useState(params.id);
  const { userData, isLoading } = useGetUserData();

  const style = {
    display: "flex",
    width: "100%",
    height: "100vh",
  };
  return (
    <div className="flex flex-c" style={style}>
      <div className="top">
        <StaffNavbar user={userData?.user} />
      </div>
      <div className="bottom flex" style={{ height: "100%", width: "100%" }}>
        <div className="left" style={{ width: "28%" }}>
          <AdminUserList
            setSelectedApplicant={setSelectedApplicant}
            selectedApplicant={id}
          />
        </div>
        <div className="right" style={{ width: "72%" }}>
          <AdminDetails selectedApplicant={id} />
        </div>
      </div>
    </div>
  );
};

UsersMain.propTypes = {
  id: propTypes.string,
};

export default UsersMain;
