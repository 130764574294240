/*eslint-disable*/
import React from 'react';
import {
  ManualScoringRoot,
  ManualScoringSectionTitle,
  TotalScoresSection,
  ManualScoringSection,
  TotalScoreText,
} from './GrantDetails.components';
import { useGetManualScoring } from '../../services/GrantDetails/ManualScoring.service';
import ManualScoreComponent from './ManualScoreComponent';
import { IApplicantDataObject } from '../../interfaces/applicantDetails.model';

const ManualScoring = () => {
  const { ManualScoringFields, control, selectedApplicantResponseObject, onSubmit, handleSubmit } =
    useGetManualScoring();
  return (
    <ManualScoringRoot key={1}>
      <TotalScoresSection>
        <ManualScoringSectionTitle>Total Score</ManualScoringSectionTitle>
        <TotalScoreText>
          {selectedApplicantResponseObject?.client_details?.[0]?.grant_score?.total_application_score || 0} Points
        </TotalScoreText>
      </TotalScoresSection>
      <ManualScoringSectionTitle>Manual Scores</ManualScoringSectionTitle>
      <form onSubmit={handleSubmit(onSubmit)} key={Math.random()}>
        <ManualScoringSection container rowSpacing={1}>
          {ManualScoringFields.map((field, index) => {
            if (field.shouldShow!== false){
                   return (
              <ManualScoreComponent
                scoreFieldObject={field}
                control={control}
                name={field?.key}
                defaultValue={parseInt(
                  selectedApplicantResponseObject?.client_details?.[0]?.grant_score?.[
                    field.key as keyof IApplicantDataObject['client_details'][0]['grant_score']
                  ]?.toString() || '0',
                )}
              />
            );
            }
       
          })}
        </ManualScoringSection>
      </form>
    </ManualScoringRoot>
  );
};

export default ManualScoring;
