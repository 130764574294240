/*eslint-disable*/
import React from 'react';
import propTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchInput({ setSearchQuery, placeholder = 'Search Here' }) {
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #EEF1F3',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div style={{ width: '80%' }}>
        <input
          onBlur={(e) => {
            setSearchQuery(e.target.value);
          }}
          placeholder={placeholder}
          style={{
            width: '100%',
            padding: '0.65rem',
            border: '0px',
            fontFamily: 'inherit',
          }}
        ></input>
      </div>
      <div
        style={{
          order: '1',
          marginLeft: 'auto',
          textAlign: 'right',
          paddingRight: '1rem',
        }}
      >
        <SearchIcon />
      </div>
    </div>
  );
}

SearchInput.propTypes = {
  setSearchQuery: propTypes.func,
  placeholder: propTypes.string,
};
