/*eslint-disable*/
import React, { Fragment } from 'react';
import { IChart, IMapComponentChart } from './interfaces/chart.interfaces';
import {
  ChartTitle,
  ChartWrapper,
  MapComponentBody,
  MapComponentBodyLeft,
  MapComponentFooter,
} from './styled/chart.styledcomponents';
import { Button } from '@mui/material';
import { VectorMap, VectorMapProps, VectorMapLayer } from '@south-paw/react-vector-maps';
import { useGetMapComponent } from './services/MapComponent.service';
import DistrictBreakdownModal from './DistrictBreakdownModal';

//how will this map component communicate with parent components?
/**
 * the values for each region and district... need to create a TS interface for that.
 *
 * @returns
 *
 *
 */

const MapComponent = ({ title, data }: IMapComponentChart) => {
  const { layerProps, ghanaJson, selectedRegion, districtModal, districtBreakdown, onReset, setDistrictModal, getId } =
    useGetMapComponent(data);

  return (
    <Fragment>
      <DistrictBreakdownModal open={districtModal} setOpen={setDistrictModal} data={districtBreakdown} />
      <ChartWrapper className="map-component">
        <ChartTitle>{title}</ChartTitle>
        <MapComponentBody>
          <MapComponentBodyLeft>
            <VectorMap layerProps={layerProps} {...ghanaJson} currentLayers={getId(selectedRegion)} id="ghana-map" />
          </MapComponentBodyLeft>
        </MapComponentBody>
        <MapComponentFooter>
          <Button
            style={{ textTransform: 'initial', fontFamily: 'inherit' }}
            onClick={() => {
              onReset();
            }}
          >
            Reset Map
          </Button>
        </MapComponentFooter>
      </ChartWrapper>
    </Fragment>
  );
};

export default MapComponent;
