import UserProfile from '@service/UserProfile';
import { handleErrorMessage } from '@utils/helpers/functions';
import { useQuery } from 'react-query';
import { getDocuments } from '@service/CommonApi';

export default function useGetUserData() {
  const { error, data, isLoading } = useQuery('user-data', UserProfile.getSelf, {
    // staleTime: Infinity,
    refetchOnMount: true,
    // refetchOnWindowFocus: false,
  });
  const userData = data?.data;
  handleErrorMessage(error?.response);
  return { userData, error, isLoading };
}

export function useGetDocuments() {
  const { error, data, isLoading } = useQuery('documents', getDocuments);

  const documents = data?.data;

  return { documents, error, isLoading };
}

// export function useMakeEditable(id)
// {
//   const {error,data,isLoading} = useQuery('make-editable',makeEditable(id));
//   return {data,error,isLoading};
// }
