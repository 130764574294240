/*eslint-disable*/
import {
  styled,
  ButtonGroup,
  Button,
  Grid,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

export const TrainingDashboardRoot = styled(Grid)(({ theme }) => ({}));
export const DashboardComponent = styled(Grid)(({ theme }) => ({}));
export const TrainingStageSwitcherRoot = styled(ButtonGroup)(({ theme }) => ({}));
export const LeftTrainingStageButton = styled(Button)(({ theme }) => ({
  borderTopLeftRadius: '20px',
  borderBottomLeftRadius: '20px',
  textTransform: 'initial',
  fontSize: '16px',
  backgroundColor: '#E4EBFD',
  borderColor: '#E4EBFD',
  ':hover': {
    borderColor: '#E4EBFD',
  },
  fontFamily: 'inherit',
}));
export const TrainingStageButton = styled(Button)(({ theme }) => ({
  textTransform: 'initial',
  fontSize: '16px',
  backgroundColor: '#E4EBFD',
  borderColor: '#E4EBFD',
  ':hover': {
    borderColor: '#E4EBFD',
  },
  fontFamily: 'inherit',
}));
export const RightTrainingStageButton = styled(Button)(({ theme }) => ({
  borderTopRightRadius: '20px',
  borderBottomRightRadius: '20px',
  textTransform: 'initial',
  fontSize: '16px',
  backgroundColor: '#E4EBFD',
  borderColor: '#E4EBFD',
  ':hover': {
    borderColor: '#E4EBFD',
  },
  fontFamily: 'inherit',
}));

export const BreakdownAccordion = styled(Accordion)(({ theme }) => ({}));
export const BreakdownAccordionActions = styled(AccordionActions)(({ theme }) => ({}));
export const BreakdownAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  fontSize: '30px',
}));

export const BreakdownAccordionDetails = styled(AccordionDetails)(({ theme }) => ({}));
