/*eslint-disable*/
import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import InfoIcon from '@mui/icons-material/Info';

interface IInfoText {
  text?: string;
  children?: ReactNode;
  wrapperStyle?: React.CSSProperties;
}

const InfoText = ({ text, children, wrapperStyle }: IInfoText) => {
  return (
    <Box sx={{ display: 'flex', rowGap: '10px', fontSize: '16px', ...wrapperStyle }} columnGap={'10px'}>
      <InfoIcon />
      {text || children}
    </Box>
  );
};

export default InfoText;
