/*eslint-disable*/
import { messageAlert, parseIdFromProjectId } from '@/utils/helpers/functions';
import { GrantAssessmentBatchContext } from './GrantAssessmentBatchContext';
import React from 'react';
import apiConfig from '@/service/apiConfig';
import { IGrantAssementBatchParams } from './types';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { BATCH_ASSESSMENT_PASS_THRESHOLD } from './constants';

export const getTemplateHeadersWithStatus = (skipped?: boolean): MUIDataTableColumnDef[] => {
  if (skipped == undefined)
  
  {
      return [
    'Application ID',
    `Enterprise Overview Score`,
    `Challenges Score `,
    `Technical Implementation Score`,
    `Technical Capacity Score`,
    `Has Benefited From Grant Programme Score`,
    `Fiscal Expenditure Score`,
    `Grant Expenditure Score`,
    `Performance Indicator Score`,
    `Sustainability Plan Score`,
    `Operational Risk Score`,

  ];
  }
  return [
    'Application ID',
    `Enterprise Overview Score (${!skipped ? 15 : 18})`,
    `Challenges Score (${!skipped ? 6 : 10})`,
    `Technical Implementation Score (${!skipped ? 15 : 15})`,
    `Technical Capacity Score (${8})`,
    `Has Benefited From Grant Programme Score(${!skipped ? 1 : 2})`,
    `Fiscal Expenditure Score (${4})`,
    `Grant Expenditure Score (${!skipped ? 10 : 30})`,
    `Performance Indicator Score (${!skipped ? 20 : 30})`,
    `Sustainability Plan Score (${8})`,
    `Operational Risk Score (${7})`,
  
  ];
};

export const getTemplateHeaders = (skipped?: boolean) => {
  return [
    'Application ID',
    `Enterprise Overview Score (${!skipped ? 15 : 18})`,
    `Challenges Score (${!skipped ? 6 : 10})`,
    `Technical Implementation Score (${!skipped ? 15 : 15})`,
    `Technical Capacity Score (${8})`,
    `Has Benefited From Grant Programme Score(${!skipped ? 1 : 2})`,
    `Fiscal Expenditure Score (${4})`,
    `Grant Expenditure Score (${!skipped ? 10 : 30})`,
    `Performance Indicator Score (${!skipped ? 20 : 30})`,
    `Sustainability Plan Score (${8})`,
    `Operational Risk Score (${7})`,
  ];
};

export const validateScores = (traineeArray: any[], skipped?: boolean): boolean => {
  let isValid = true;
  const keyValues = [
    { key: 'enterprise_overview_score', max: !skipped ? 15 : 18 },
    { key: 'challenges_score', max: !skipped ? 6 : 10 },
    { key: 'technical_implementation_score', max: !skipped ? 15 : 15 },
    { key: 'technical_capacity_score', max: 8 },
    { key: 'has_benefited_from_grant_programme_score', max: !skipped ? 1 : 2 },
    { key: 'fiscal_expenditure_score', max: 4 },
    { key: 'grant_expenditure_score', max: !skipped ? 10 : 30 },
    { key: 'performance_indicator_score', max: !skipped ? 20 : 30 },
    { key: 'sustainability_plan_score', max: 8 },
    { key: 'operational_risk_score', max: 7 },
  ];

  keyValues.forEach((value, index) => {
    if (!isNaN(parseInt(traineeArray[index]))) {
      if (parseInt(traineeArray[index]) > value.max) {
        isValid = false;
      }
    }
  });
  return isValid;
};

export const getScores = (traineeArray: any[]) => {
  return {
    enterprise_overview_score: parseInt(traineeArray[1]),
    challenges_score: parseInt(traineeArray[2]),
    technical_implementation_score: parseInt(traineeArray[3]),
    technical_capacity_score: parseInt(traineeArray[4]),
    has_benefited_from_grant_programme_score: parseInt(traineeArray[5]),
    fiscal_expenditure_score: parseInt(traineeArray[6]),
    grant_expenditure_score: parseInt(traineeArray[7]),
    performance_indicator_score: parseInt(traineeArray[8]),
    sustainability_plan_score: parseInt(traineeArray[9]),
    operational_risk_score: parseInt(traineeArray[10]),
  };
};

export const sumArray = (array: any[]) => {
  let sum = array.reduce((prev, current) => {
    return prev + (typeof current === 'number' ? current : parseInt(current));
  }, 0);

  // console.log(sum, 95555);
  return sum;
};

export const getBatchList = (
  traineeArray: any[],
  passed?: boolean,
  passThreshold?: number,
): Partial<{ id: number }>[] => {
  let batchList: Partial<{ id: number }>[] = [];
  traineeArray.forEach((value) => {
    let totalScore = sumArray(value.slice(1, value.length - 1));
    if (passed == true) {
      if (totalScore >= passThreshold) {
        batchList.push({ id: parseIdFromProjectId(value[0]), ...getScores(value) });
      }
    } else if (passed == false) {
      if (totalScore < passThreshold) {
        batchList.push({ id: parseIdFromProjectId(value[0]), ...getScores(value) });
      }
    }
  });
  return batchList;
};

export const downloadCSVTemplate = (skipped?: boolean) => {
  try {
    let templateHeader = [getTemplateHeaders(skipped)];

    let csvContent = 'data:text/csv;charset=utf-8,';

    templateHeader.forEach(function (rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });
    const uri = encodeURI(csvContent);
    var element = document.createElement('a');
    element.href = uri;
    element.target = '_blank';
    element.download = 'template.csv';
    element.click();
  } catch (e) {
    console.log(e);
    messageAlert('error', 'CSV Download failed');
  }
};

export const getGetGranttAssessmentlist = async (props: IGrantAssementBatchParams) => {
  const keys: Array<keyof IGrantAssementBatchParams> = Object.keys({
    page: 0,
    region: undefined,
    district: undefined,
    q: undefined,
  } as IGrantAssementBatchParams) as Array<keyof IGrantAssementBatchParams>;
  let queryString = '';
  keys.forEach((key) => {
    if (props[key] && key !== 'page') {
      queryString = queryString + `&${key}=${props[key]}`;
    }
  });
  return apiConfig.getRequest(
    `staff/approvals/grant_assessment_list?page=${props?.page || 0 + 1}&per_page=100${queryString}`,
  );
};
