/*eslint-disable*/
import React from "react";
import { Box, Slider } from "@mui/material";
import {
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  LabelSeries,
  FlexibleWidthXYPlot,
} from "react-vis";
import propTypes from "prop-types";
import { set } from "date-fns";
import NumberWrapper from "../NumberWrapper";
import { DashboardContext } from "../DashboardContent";

const returnDefaultValue = (value) => {
  if (value == undefined || value == null) {
    return 0;
  }
  return value;
};

const ApplicantChart = ({ data }) => {
  const [useCanvas] = React.useState(false);
  const BarSeries = VerticalBarSeries;
  const [_chartData, setChartData] = React.useState([
    { x: "degree", label: "Degree", y: 0 },
    { x: "diploma", label: "Diploma", y: 0 },
    { x: "junior_high_schoo", label: "Junior High School", y: 0 },
    { x: "masters", label: "Masters Degree", y: 0 },
    { x: "no_formal_education", label: "No Formal Education", y: 0 },
    { x: "senior_high_school", label: "Senior High School", y: 0 },
    { x: "vocational", label: "Vocational", y: 0 },
  ]);
  const [labelArray, setLabelArray] = React.useState([]);

  const labelObj = {
    degree: { label: "Degree" },
    diploma: { label: "Diploma" },
    junior_high_schoo: { label: "Junior High School" },
    masters: { label: "Masters Dgree" },
    no_formal_education: { label: "No Formal Education" },
    senior_high_school: { label: "Senior High School" },
    vocational: { label: "Vocational" },
  };

  React.useEffect(() => {
    if (data) {
      let { skills } = data;
      let keys = Object.keys(skills);
      let chartDataArray = [];
      keys.forEach((key) => {
        let values = skills[key];
        let chartObj = {
          x: values?.vocational_skill,
          y: values?.total_male_applicants + values?.total_female_applicants,
        };
        chartDataArray.push(chartObj);
      });
      let _chartLabel = chartDataArray.map((_, idx) => {
        return { y: chartDataArray[idx].label };
      });
      setChartData(chartDataArray);
      setLabelArray(_chartLabel);
    }
  }, [data]);

  const chartData = [
    { x: "No Schooling", y: 20 },
    { x: "Junior High School", y: 30 },
    { x: "Senior High School", y: 10 },
    { x: "Diploma", y: 10 },
    { x: "Bachelors Degree", y: 20 },
    { x: "Masters", y: 30 },
  ];

  // const _labelData = chartData.map((_, idx) => ({
  //   y: chartData[idx].y
  // }));

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 50px)",
        overflowY: "auto",
      }}
    >
      {_chartData.map((item, index) => {
        return (
          <Box
            style={{ display: "flex", alignItems: "center" }}
            key={`${index}-${item?.label}`}
          >
            <Box style={{ width: "30%", fontWeight: "600", fontSize: "14px" }}>
              {item?.x}
            </Box>
            <Box className="value-slider-wrapper" style={{ width: "70%" }}>
              <Slider
                className=" value-slider"
                max={returnDefaultValue(data?.total_completed_applications)}
                min={0}
                defaultValue={item?.y}
                disabled={true}
              />
            </Box>
            <Box
              sx={{
                width: "10%",
                fontWeight: "600",
                textAlign: "right",
                paddingRight: "1rem",
                fontSize: "1.2rem",
              }}
            >
              <NumberWrapper value={item?.y} size={"1rem"} />
              <span
                style={{
                  width: "100px",
                  display: "inline",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                (%
                {((item?.y / data?.total_completed_applications) * 100).toFixed(
                  1
                )}
                )
              </span>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

ApplicantChart.propTypes = {
  totalApplications: propTypes.number,
  data: propTypes.object,
};

const VocationalChart = () => {
  const { userData } = React.useContext(DashboardContext);
  const style = {
    display: "flex",
    padding: "1.2rem",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "1rem",
    marginTop: "1.5rem",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    width: "100%",
    height: "400px",
  };
  return (
    <div className="applicants-over-time" style={style}>
      <div className="top card-title">Applications by Vocational Skills</div>
      <div className="bottom" style={{ height: "100%" }}>
        <ApplicantChart
          // type = {params?.type}
          data={userData}
          // data={userData}
        />
      </div>
    </div>
  );
};

VocationalChart.propTypes = {
  params: propTypes.object,
  totalApplications: propTypes.object,
  userData: propTypes.object,
};
export default VocationalChart;
