import React from 'react';
import ApplicantNavbar from '@components/ApplicantNavbar/ApplicantNavbar';
import ApplicantSidebar from '@components/ApplicantSidebar/ApplicantSidebar';
import { Outlet } from 'react-router-dom';
// import ApprovalsNav from './ApprovalsNav';

const ApprovalsMainLayout = () => {
  const style = {
    display: 'flex',
    width: '98%',
    height: '100vh',
  };
  const style1 = {
    width: '100%',
    height: '100vh',
    overflowY: 'hidden',
  };
  return (
    <div className="flex" style={style1}>
      <ApplicantSidebar selectedView={2} />
      <div className="flex flex-c" style={style}>
        <div className="top">
          <ApplicantNavbar type={''} navBack="/applicants" />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default ApprovalsMainLayout;
