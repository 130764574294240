import React from "react";
import { Box } from "@mui/material";
import ApplicantNavbar from "@components/ApplicantNavbar/ApplicantNavbar";
import ApplicantSidebar from "@components/ApplicantSidebar/ApplicantSidebar";
import ReportsNav from "./ReportsNav";
import propTypes from "prop-types";

const ReportsMain = ({ selectedView, children }) => {
  const style = {
    display: "flex",
    width: "calc(100% - 35px)",
    height: "100vh",
    overflowX:'hidden',
    overflowY:'hidden'
  };
  const style1 = {
    width: "100%",
    height: "100vh",
    overflowY: "hidden",
  };
  document.body.style.cssText = "overflow-y:hidden!important";
  return (
    <Box className="flex" style={style1}>
      <ApplicantSidebar selectedView={4} />
      <Box className="flex flex-c" style={style}>
        <Box className="top">
          <ApplicantNavbar type={""} navBack="/applicants" />
        </Box>
        <Box sx={{ display: "flex", height: "100vh", width: "100%" }}>
          <ReportsNav selectedView={selectedView} />
          {children}
        </Box>
      </Box>
    </Box>
  );
};

ReportsMain.propTypes = {
  selectedView: propTypes.number,
  children: propTypes.node,
};

export default ReportsMain;
