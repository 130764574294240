/*eslint-disable*/
import React from 'react';
import Menu from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import { ButtonProps, styled } from '@mui/material';
import { Button } from '@mui/material';
import { AppContext } from '@/utils/context/AppContext';

const CustomButtonWrapper = styled(Button)({
  color: '#4F4F4F',
});

const ExpandButton = (props: ButtonProps) => {
  const { currentViewState, setCurrentViewState } = React.useContext(AppContext);
  return (
    <CustomButtonWrapper
      {...props}
      onClick={() => {
        setCurrentViewState?.(currentViewState == 'expanded' ? 'closed' : 'expanded');
      }}
    >
      {currentViewState == 'expanded' && <Close style={{ paddingLeft: 'unset' }} />}
      {currentViewState == 'closed' && <Menu style={{ paddingLeft: 'unset' }} />}
    </CustomButtonWrapper>
  );
};

export default ExpandButton;
