/*eslint-disable*/
import React from "react";
import ApplicantSidebar from "@components/ApplicantSidebar/ApplicantSidebar";
import ApplicantNavbar from "@components/ApplicantNavbar/ApplicantNavbar";
import AdminDetails from "@components/Users/AdminDetails";
import useGetUserData from "@utils/hooks/useGetUserData";

const Profile = () => {
  const { userData, isLoading: _isLoading } = useGetUserData();

  const style = {
    display: "flex",
    width: "98%",
    height: "100vh",
  };
  const style1 = {
    width: "100%",
    height: "100vh",
    overflowY: "hidden",
  };
  return (
    <div className="flex" style={style1}>
      <ApplicantSidebar selectedView={5} />
      <div className="flex flex-c" style={style}>
        <div className="top">
          <ApplicantNavbar type={"User Profile"} navBack="/applicants" />
          {_isLoading == false && (
            <AdminDetails selectedApplicant={userData?.user?.id} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
