/*eslint-disable*/
import TableLoaders from '@components/Loaders/TableLoaders';
import React from 'react';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnOptions } from 'mui-datatables';
import { AvatarInitial } from '../../utils/Logo';
import { Button, Box } from '@mui/material';
import { IFilterParams } from '../../utils/interfaces/approvals.interface';
import { useGetTableData, viewUser } from './services/AdvancedTraining.service.ts';
import { useNavigate } from 'react-router-dom';

const tableBodyHeight = `${window.innerHeight * 0.95}px`;
const tableBodyMaxHeight = '';
const responsive = 'vertical';

interface IPreviewTableProps {
  data: {} | [];
  loadingState?: boolean;
  setSelectedItems?: (items) => void;
  type: 'preview' | 'list';
  title?: string;
  onActionPress?: () => {};
  onPageChange?: (page: number) => void;
  setBaseLayoutParams?: React.Dispatch<React.SetStateAction<IFilterParams>>;
  params?: IFilterParams;
  stage?: string;
}

const PreviewTable = (props: IPreviewTableProps) => {
  const [count, setCount] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();

  const getTableData = (data: any) => {
    data.stage = props.stage;
    let { tableData, count } = useGetTableData(data);
    setCount(count);
    setData(tableData);
    // props?.setBaseLayoutParams({ ...params, tableData: tableData });
  };

  const getSelectedRows = (rowArray: any[]) => {
    try {
      let rowDataArray: any = [];
      rowArray.forEach((rowIndex: string | number | any) => {
        let _applicantData = data[rowIndex];
        rowDataArray.push(_applicantData[0]); //this index is where the id is stored
      });
      return rowDataArray;
    } catch (e) {
      return [];
    }
  };

  const previewTableOptions: MUIDataTableOptions = {
    print: false,
    search: false,
    viewColumns: false,
    download: false,
    filter: false,
    filterType: 'dropdown',
    responsive,
    rowsPerPage: 10,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'Role',
      direction: 'asc',
    },
  };

  const trainingListTableOptions: MUIDataTableOptions = {
    print: false,
    search: false,
    viewColumns: false,
    download: true,
    filter: false,
    filterType: 'dropdown',
    responsive,
    serverSide: true,
    page: page,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    count: count,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'Role',
      direction: 'asc',
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setPage(tableState?.page);
      }
    },

    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const dataToState = rowsSelected?.map((item) => {
        return item;
      });
      props?.setSelectedItems?.(getSelectedRows(dataToState as any));
    },

    customSearchRender: () => null,
    customToolbarSelect: () => null,
  };

  const advancedTrainingListColumns = [
    {
      name: ' Application ID',
      options: {
        filter: false,
        customBodyRender: (value: any) => <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{value}</div>,
      } as MUIDataTableColumnOptions,
    },
    {
      name: 'Full Name',
      options: {
        filter: false,
        customBodyRender: (value: any) => <AvatarInitial text={value} />,
      } as MUIDataTableColumnOptions,
    },
    'Attendance (30)',
    'Participation (15)',
    'Class Exercise (15)',
    props?.stage == 'basic' ? 'Business Model Canvas (BMC)' : 'Business Plan Presentation(20)',
    'Pitch Practice (20)',
    {
      name: 'Status ',
      options: {
        customBodyRender: (value: any) => <span style={{ color: 'red' }}>Failed</span>,
      } as MUIDataTableColumnOptions,
    },
    {
      name: 'Action',
      options: {
        filter: false,
        customBodyRender: (id: string) => (
          <Button
            sx={{ textTransform: 'initial' }}
            onClick={() => {
              navigate(`/applicants/${id}`);
            }}
          >
            View
          </Button>
        ),
      },
    },
    'Total Score',
  ];

  const trainingListColumns = [
    {
      name: ' Application ID',
      options: {
        filter: false,
        customBodyRender: (value: any) => <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{value}</div>,
      } as MUIDataTableColumnOptions,
    },
    {
      name: 'Full Name',
      options: {
        filter: false,
        customBodyRender: (value: any) => <AvatarInitial text={value} />,
      } as MUIDataTableColumnOptions,
    },
    `Attendance(${props.stage == 'basic' ? '30' : '20'})`,
    `Participation(${props.stage == 'basic' ? '20' : '20'})`,
    `Class Exercise(${props.stage == 'basic' ? '20' : '20'})`,
    props?.stage == 'basic' ? 'Business Model Canvas (BMC)(30)' : 'Business Plan(40)',
    {
      name: 'Status ',
      options: {
        customBodyRender: (value: any) => <span style={{ color: 'red' }}>Failed</span>,
      } as MUIDataTableColumnOptions,
    },
    {
      name: 'Action',
      options: {
        filter: false,
        customBodyRender: (id: string) => (
          <Button
            sx={{ textTransform: 'initial' }}
            onClick={() => {
              navigate(`/applicants/${id}`);
            }}
          >
            View
          </Button>
        ),
      },
    },
    'Total Score',
  ];

  const advancedPreviewColumns = [
    {
      name: 'Application ID',
      options: {
        filter: false,
        customBodyRender: (value: any) => <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{value}</div>,
      } as MUIDataTableColumnOptions,
    },
    {
      name: 'Full Name',
      options: {
        filter: false,
        customBodyRender: (value: any) => <AvatarInitial text={value} />,
      } as MUIDataTableColumnOptions,
    },
    'Attendance (30)',
    'Participation (15)',
    'Class Exercise (15)',
    props?.stage == 'basic' ? 'Business Model Canvas (BMC)' : 'Business Plan Presentation(20)',
    'Pitch Practice (20)',
    'Total Score',
    {
      name: 'Status',
      options: {
        customBodyRender: (value: any) => {
          if (value == true) {
            return <span style={{ color: 'green' }}>Passed</span>;
          } else {
            return <span style={{ color: 'red' }}>Failed</span>;
          }
        },
      } as MUIDataTableColumnOptions,
    },
  ];

  const previewColumns = [
    {
      name: 'Application ID',
      options: {
        filter: false,
        customBodyRender: (value: any) => <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{value}</div>,
      } as MUIDataTableColumnOptions,
    },
    {
      name: 'Full Name',
      options: {
        filter: false,
        customBodyRender: (value: any) => <AvatarInitial text={value} />,
      } as MUIDataTableColumnOptions,
    },
    `Attendance(${props.stage == 'basic' ? '30' : '20'})`,
    `Participation(${props.stage == 'basic' ? '20' : '20'})`,
    `Class Exercise(${props.stage == 'basic' ? '20' : '20'})`,
    props?.stage == 'basic' ? 'Business Model Canvas (BMC)(30)' : 'Business Plan(40)',
    'Total Score',
    {
      name: 'Status',
      options: {
        customBodyRender: (value: any) => {
          if (value == true) {
            return <span style={{ color: 'green' }}>Passed</span>;
          } else {
            return <span style={{ color: 'red' }}>Failed</span>;
          }
        },
      } as MUIDataTableColumnOptions,
    },
  ];

  React.useEffect(() => {
    if (props.type == 'list') {
      if (typeof props?.data === 'object' && 'data' in props?.data) {
        getTableData(props?.data.data as any);
      }
    } else {
      setData(props?.data as unknown as any[]);
    }
  }, [props.data]);

  React.useEffect(() => {
    props?.onPageChange?.(page);
  }, [page]);

  return (
    <Box
      className="custom-data-table "
      my={'1rem'}
      sx={{
        width: '100%',
        paddingTop: '1rem',
        marginTop: '2rem',
      }}
    >
      {props.loadingState == true && <TableLoaders />}
      {props.loadingState == false && (
        <MUIDataTable
          title={props.title || 'Table'}
          options={props?.type == 'list' ? trainingListTableOptions : previewTableOptions}
          columns={
            props?.type == 'list'
              ? props?.stage == 'advanced'
                ? advancedTrainingListColumns
                : trainingListColumns
              : props?.stage == 'advanced'
              ? advancedPreviewColumns
              : previewColumns
          }
          data={data}
        />
      )}
    </Box>
  );
};

export default PreviewTable;
