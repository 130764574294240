import ApplicantList from "@components/ApplicantMain/ApplicantList";
import ApplicantDetails from "@components/ApplicantMain/ApplicantDetails";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const TrainingScore = () => {
  const { id } = useParams();

  const [selectedApplicant, setSelectedApplicant] = useState(id);
  const style = {
    display: "flex",
    width: "98%",
    height: "100vh",
  };
  return (
    <div className="flex flex-c" style={style}>
      <div className="bottom flex" style={{ height: "91vh" }}>
        <div className="left" style={{ width: "25%" }}>
          <ApplicantList
            type={"training"}
            category={"training"}
            setSelectedApplicant={setSelectedApplicant}
          />
        </div>
        <div className="right" style={{ width: "75%" }}>
          <ApplicantDetails
            type={"training"}
            category={"training"}
            selectedApplicant={selectedApplicant}
          />
        </div>
      </div>
    </div>
  );
};

export default TrainingScore;
