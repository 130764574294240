/*eslint-disable */
/* eslint-disable react/prop-types */
import React from "react";
import propTypes from "prop-types";
import { Box } from "@mui/material";
import SemiCircleProgressBar from "./SemiCircleProgressBar";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "1rem",
  p: 4,
};

const Bull = ({ color }) => (
  <Box
    component="span"
    color={color}
    sx={{ display: "inline-block", mb: "16px", transform: "scale(1)" }}
  >
    •
  </Box>
);

// eslint-disable-next-line react/display-name
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function ScoreCard({
  color,
  score,
  title,
  index,
  metadata_array,
  metadata_key,
}) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleClose = () => {
    setModalOpen(false);
  };
  const [metaData, setmetaData] = React.useState({});
  const showMetadata = () => {
    try {
      let metadata = metadata_array?.filter((item) => {
        return item.key == metadata_key;
      })[0];
      metadata.key_array = Object.keys(metadata?.metadata)
        ? Object.keys(metadata?.metadata)
        : [];
      setmetaData(metadata);
      setModalOpen(true);
    } catch (e) {

    }
  };

  const formatValue = (value) => {
    try {
      let stringArray = value.split("_");
      let finalArray = [];
      stringArray.forEach((value) => {
        if (value != "metadata") {
          let finalString = value.charAt(0).toUpperCase() + value.slice(1);
          finalArray.push(finalString);
        }
      });
      return finalArray.join(" ");
    } catch (e) {
      return "";
    }
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            sx={{ fontFamily: "inherit", fontSize: "1rem" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {formatValue(metaData?.key)}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ marginBottom: "1rem" }}>
                {metaData?.key_array?.map((key, index) => {
                  return (
                    <>
                      <Box>{`${formatValue(key)}`}</Box>
                      <Box
                        sx={{
                          color: "#1D9649",
                          marginTop: "1rem",
                          fontSize: "1rem",
                          marginBottom: "1rem",
                        }}
                      >{`${metaData?.metadata[key]}`}</Box>
                    </>
                  );
                })}
              </Box>
            </Box>
          </Typography>
        </Box>
      </Modal>
      <div
        className="score-card"
        onClick={() => {
          if (metadata_key !== "") {
            showMetadata();
          }
        }}
        style={{
          width: "220px",
          border: "0.5px solid rgb(234 234 234)",
          borderRadius: "11.0986px",
          padding: "16px 12px 8px",
          boxShadow: "0px 0px 0.693662px rgba(12, 26, 75, 0.24)",
        }}
      >
        <span style={{ fontWeight: "600" }}>{title}</span>
        <div style={{ marginTop: "8px" }}>
          <SemiCircleProgressBar color={color} percentage={score} />
        </div>
        <div style={{ textAlign: "center", marginTop: "-56px" }}>
          <Bull color={color} />{" "}
          <span style={{ fontSize: "10px" }}>{title}</span>
        </div>
      </div>
    </>
  );
}

ScoreCard.propTypes = {
  color: propTypes.string,
  score: propTypes.number,
  title: propTypes.string,
  index: propTypes.number,
  metadata_array: propTypes.array,
  metadata_key: propTypes.string,
};

LightTooltip.propTypes = {
  className: propTypes.string,
};

Bull.propTypes = {
  color: propTypes.string,
};
