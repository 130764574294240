import { checkPermissions } from '@/service/RequireAuth';
import React from 'react';
import { Navigate } from 'react-router-dom';

export const PermissionsWrapper = ({ children,permission }: any) => {
  const userData =  JSON.parse(window.localStorage.getItem('user'))
  const shouldShow = checkPermissions(permission,userData)
  console.log('function called');
  return <>{shouldShow == true ? <>{children}</> : <Navigate to="*" />}</>;
};
