import { IStageScoringTemplate } from '@/utils/interfaces/approvals.interface';

export const ScoringTemplate = {
  basic: {
    attendance: 30,
    business_model_canvas: 30,
    class_excercise: 20,
    participation: 20,
  } as IStageScoringTemplate,
  intermediate: {
    attendance: 20,
    class_excercise: 20,
    participation: 20,
    business_plan: 40,
  } as IStageScoringTemplate,
  advanced: {
    attendance: 30,
    business_plan: 20,
    pitch_presentation: 20,
    class_excercise: 15,
    participation: 15,
  } as IStageScoringTemplate,
};

export const ScoreRowMapping = {
  basic: {
    attendance: 2,
    participation: 3,
    class_excercise: 4,
    business_model_canvas: 5,
  } as IStageScoringTemplate,
  intermediate: {
    attendance: 2,
    participation: 3,
    class_excercise: 4,
    business_plan: 5,
  } as IStageScoringTemplate,
  advanced: {
    attendance: 2,
    participation: 3,
    class_excercise: 4,
    business_plan: 5,
    pitch_presentation: 6,
  } as IStageScoringTemplate,
};
