/*eslint-disable*/
import React from "react";
import {
  Grid,
  Box,
  Container,
  Button,
  Modal,
  Typography,
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import StaffNavbar from "../Staff/StaffNavbar";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import SettingsNav from "./SettingsNav";
import useGetUserData from "@utils/hooks/useGetUserData";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteSvg from "../../assets/Delete.svg";
import ApplicantSidebar from "@components/ApplicantSidebar/ApplicantSidebar";
import { checkPermissions } from "@service/RequireAuth";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AuditTable from "./AuditTable";
import moment from "moment";
import addDays from "date-fns/addDays";
import AuditFilter from "./AuditFilter";
import UserSignUp from "@service/UserSignUp";
import { useQuery } from "react-query";
import {
  handleErrorMessage,
  messageAlert,
  storeLocalStorage,
} from "@utils/helpers/functions";
import TableLoaders from "@components/Loaders/TableLoaders";

const AuditTrail = () => {
  const [auditParams, setAuditParams] = React.useState({
    page: 0,
    count: 1,
    data: [],
    start_date: "",
    end_date: "",
    audit_type: "administrative",
    role: null,
    user: null,
  });
  const {
    data,
    error,
    isLoading: _isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["auditTrail"],
    queryFn: () => {
      return UserSignUp?.getTrail(auditParams);
    },
    enabled: false,
    refetchOnWindowFocus: false,
  });
  const [tabValue, setTabValue] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchString, setSearchString] = React.useState("");
  const [roles, setRoles] = React.useState([]);
  const userData = JSON.parse(window?.localStorage.getItem("user"));
  const handleTabChange = (event, value) => {
    setTabValue(value);
  };
  const [dateState, setDateState] = React.useState([
    {
      startDate: new Date(moment().format("YYYY-MM-DDThh:mm:ssZ")),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const changeDateParams = (dateValues) => {
    setDateState(dateValues);
    let _time_upper_limit = moment(dateValues[0].startDate).format(
      "YYYY-MM-DD"
    );
    let _time_lower_limit = moment(dateValues[0].endDate).format("YYYY-MM-DD");
    setAuditParams({
      ...auditParams,
      page: 1,
      start_date: _time_lower_limit,
      end_date: _time_upper_limit,
    });
  };

  const getRoles = async () => {
    setIsLoading(true);
    try {
      let { data } = await UserSignUp.getRoles();
      setRoles(data);
    } catch (e) {
      handleErrorMessage(e);

    }
    setIsLoading(false);
  };

  const fetchTrail = async () => {
    setIsLoading(true);
    try {
      let { data } = await UserSignUp.getTrail(auditParams);
      let tableData = [];
      data?.data?.reverse()?.forEach((row) => {
        tableData.push([
          row?.audit_type,
          `${row?.created_by?.user?.first_name} ${row?.created_by?.user?.last_name}`,
          moment(row?.created).format("MMMM Do YYYY, h:mm:ss a"),
          row?.description,
        ]);
      });

      setAuditParams({ ...auditParams, data: tableData, count: data?.count });
    } catch (e) {
      handleErrorMessage(e);

    }
    setIsLoading(false);
  };
  const returnDefaultValue = (value) => {
    if (value != null && value != undefined) {
      return value;
    }
    return "None";
  };

  React.useEffect(() => {
    getRoles();
  }, []);

  React.useEffect(() => {
    fetchTrail();
  }, [auditParams?.page]);

  React.useEffect(() => {
    fetchTrail();
  }, [auditParams?.audit_type]);

  React.useEffect(() => {
    fetchTrail();
  }, [auditParams?.role]);

  React.useEffect(() => {
    if (tabValue == 0) {
      setAuditParams({ ...auditParams, audit_type: "administrative", page: 0 });
    } else if (tabValue == 1) {
      setAuditParams({ ...auditParams, audit_type: "approval", page: 0 });
    } else if (tabValue == 2) {
      setAuditParams({ ...auditParams, audit_type: "auth", page: 0 });
    }
  }, [tabValue]);

  return (
    <Box
      className="staff-main"
      sx={{ width: "100%", height: "100vh", overflowY: "hidden" }}
    >
      <Grid sx={{ width: "100%", height: "100%", display: "flex" }}>
        <ApplicantSidebar selectedView={3} />
        <Grid sx={{ display: "flex", flexDirection: "column", width: "98%" }}>
          <StaffNavbar user={userData?.user} />
          <Grid container sx={{ overflowY: "auto", height: "100vh" }}>
            <SettingsNav selectedView={3} />
            <Grid
              item
              xs={9}
              sx={{ display: "flex", flexDirection: "column", padding: "2rem" }}
            >
              <Box sx={{ marginBottom: "1rem" }}>
                <Tabs
                  className="custom-tabs"
                  value={tabValue}
                  onChange={handleTabChange}
                  style={{
                    borderBottom: "0.5px solid #EBEFF2",
                    borderTop: "0.5px solid #EBEFF2",
                  }}
                  aria-label="basic tabs example"
                >
                  <Tab label="Administrative Trail" value={0} />
                  <Tab label="Approval Trail" value={1} />
                  <Tab label="Authentication" value={2} />
                </Tabs>
              </Box>
              <AuditFilter
                changeDateParams={changeDateParams}
                fetchTrail={fetchTrail}
                dateState={dateState}
                roles={roles}
                changeParams={setAuditParams}
                auditParams={auditParams}
              />
              {isLoading ? (
                <TableLoaders />
              ) : (
                <AuditTable
                  auditParams={auditParams}
                  setAuditParams={setAuditParams}
                  loading={isLoading}
                  searchString={searchString}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuditTrail;
