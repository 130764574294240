/*eslint-disable*/
import React from 'react';
import { Grid, FormLabel, Button, Box, Stack, Typography } from '@mui/material';
import { EDUCATIONAL_LEVEL, VOCATIONAL_SKILLS, defaultActiveColor } from '@utils/constants';

const FilterComponent = ({
  setParams,
  params,
  userData,
  regions,
  districts,
  fetchApplicants,
  checkSelected,
  setRegion,
  selectedRegion,
}) => {
  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          paddingTop: '2rem',
          borderTop: '1px solid #e6e6e6',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={2} sx={{ display: 'flex,', flexDirection: 'column' }}>
          <FormLabel
            sx={{
              fontFamily: 'inherit',
              marginBottom: '0.1rem',
              fontWeight: '600',
            }}
          >
            Gender
          </FormLabel>
          <select
            className=" filter-select"
            onChange={(e) => {
              setParams({ ...params, gender: e.target.value });
            }}
          >
            <option value={''}>All</option>
            <option value={'M'}>Male</option>
            <option value={'F'}>Female</option>
          </select>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex,', flexDirection: 'column' }}>
          <FormLabel
            sx={{
              fontFamily: 'inherit',
              marginBottom: '0.1rem',
              fontWeight: '600',
            }}
          >
            Disability
          </FormLabel>
          <select
            className="filter-select"
            name="disability"
            onChange={(e) => {
              setParams({ ...params, disability: e.target.value });
            }}
          >
            <option value={''}>All</option>
            <option value={'yes'}>Show disabled Applicants</option>
            <option value={'no'}>Hide disabled Applicants</option>
          </select>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex,', flexDirection: 'column' }}>
          <FormLabel
            sx={{
              fontFamily: 'inherit',
              marginBottom: '0.1rem',
              fontWeight: '600',
            }}
          >
            Region
          </FormLabel>
          <select
            name="region"
            className="filter-select"
            onChange={setRegion}
            disabled={userData?.staff_type == 'bac' || userData?.staff_type == 'bac_regional_head'}
          >
            <option value={''}>All</option>
            {regions?.map((region, idx) => (
              <option selected={region.id === selectedRegion} key={`${idx} - ${region.name}`} value={region.id}>
                {region.name}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex,', flexDirection: 'column' }}>
          <FormLabel
            sx={{
              fontFamily: 'inherit',
              marginBottom: '0.1rem',
              fontWeight: '600',
            }}
          >
            District
          </FormLabel>
          <select
            name="district"
            className="filter-select"
            onChange={(e) => {
              setParams({ ...params, district: e.target.value });
            }}
            disabled={userData?.staff_type == 'bac'}
          >
            <option value={''}>All</option>
            {districts?.map((district, idx) => (
              <option selected={checkSelected(district?.id)} key={`${idx} - ${district.name}`} value={district.id}>
                {district.name}
              </option>
            ))}
          </select>
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ paddingTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        <Grid item xs={3} sx={{ display: 'flex,', flexDirection: 'column' }}>
          <FormLabel
            sx={{
              fontFamily: 'inherit',
              marginBottom: '0.1rem',
              fontWeight: '600',
            }}
          >
            Vocational Skill
          </FormLabel>
          <select
            className=" filter-select"
            onChange={(e) => {
              setParams({ ...params, vocational_skill: e.target.value });
            }}
          >
            <option value={''}>All</option>
            {VOCATIONAL_SKILLS?.map((vocation, idx) => (
              <option
                // selected = {checkSelected(district?.id)}
                key={`${idx} - ${vocation?.value}`}
                value={vocation?.value}
              >
                {vocation?.label}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex,', flexDirection: 'column' }}>
          <FormLabel
            sx={{
              fontFamily: 'inherit',
              marginBottom: '0.1rem',
              fontWeight: '600',
            }}
          >
            Educational Level
          </FormLabel>
          <select
            className="filter-select"
            name="disability"
            onChange={(e) => {
              setParams({ ...params, educational_level: e.target.value });
            }}
          >
            <option value={''}>All</option>
            {EDUCATIONAL_LEVEL?.map((educational_level, idx) => (
              <option
                // selected = {checkSelected(district?.id)}
                key={`${idx} - ${educational_level?.value}`}
                value={educational_level?.value}
              >
                {educational_level?.label}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item xs={4} justifyContent="flex-end" alignItems={'flex-end'}>
          <Box style={{ marginTop: '0.5rem' }}>
            <Stack direction="column  ">
              <Box fontSize={15}>
                <Typography sx={{ fontFamily: 'inherit' }}>Age Range:</Typography>
              </Box>
              <Box style={{ display: 'flex' }}>
                <input
                  placeholder={'Lower Age'}
                  min={0}
                  type="number"
                  className="range-input"
                  style={{
                    padding: '0.8rem',
                    border: '1px solid #EEF1F3',
                    width: '100%',
                  }}
                  onChange={(e) => {
                    setParams({ ...params, age_lower_limit: e.target.value });
                  }}
                />
                <Box mt={2}>
                  {' '}
                  <hr
                    style={{
                      width: '1rem',
                      marginLeft: '0.5rem',
                      marginRight: '0.5rem',
                    }}
                  />
                </Box>
                <input
                  placeholder={'Upper Age'}
                  min={0}
                  type="number"
                  className="range-input"
                  style={{
                    padding: '0.8rem',
                    border: '1px solid #EEF1F3',
                    width: '100%',
                  }}
                  onChange={(e) => {
                    setParams({ ...params, age_upper_limit: e.target.value });
                  }}
                />
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} style={{ marginTop: '30px' }}>
        <Box>
          <Button
            sx={{
              '&:hover': { backgroundColor: '#2a3b8f', color: 'white', border: '2px solid #2a3b8f' },
              fontWeight: '600',
              fontFamily: 'inherit !important',
              fontSize: '1rem',
              color: defaultActiveColor,
              border: `2px dashed ${defaultActiveColor}`,
              textTransform: 'initial !important',
              width: '100%',
              paddingTop: '8px',
              paddingBottom: '8px',
            }}
            onClick={fetchApplicants}
          >
            Filter
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default FilterComponent;
