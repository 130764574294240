import moment from 'moment';

export const validateDate = (date: string) => {
  try {
    let parts = date.split('/');
    if (Number.parseInt(parts[2]) > 2024 || Number.parseInt(parts[2]) < 2023) {
      throw new Error('Invalid date');
    }
    const dateMoment = moment(date).format('YYYY-MM-DD');
    if (dateMoment == 'Invalid Date') {
      throw new Error('Invalid date');
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const validateBankAccountNumber = (accountNumber: string) => {
  try {
    const bankAccountRegex = /^\d{10,20}$/;
    if (bankAccountRegex.test(accountNumber) == false) {
      throw new Error('Invalid Account Number');
    }
    return true;
    //stuff
  } catch (e) {
    return false;
  }
};

export const validateDisbursedAmount = (disbursedAmount: string) => {
  try {
    const disbursedAmountRegex = /^\d{0,9}$/;
    if (disbursedAmountRegex.test(disbursedAmount) == false) {
      throw new Error('Invalid Disbursed Amount');
    }
    return true;
  } catch (e) {
    return false;
  }
};
