/*eslint-disable */
import React, { useState } from "react";
import {
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  LabelSeries,
  FlexibleWidthXYPlot,
  Hint,
} from "react-vis";
import propTypes from "prop-types";
import moment from "moment";
import { Box } from "@mui/material";
import NumberWrapper from "../NumberWrapper";
import { DashboardContext } from "../DashboardContent";

const ApplicantChart = ({ totalApplications, type, data }) => {
  const [chartData, setChartData] = useState([
    { x: moment().subtract("7", "days").format("yyyy-mm-dd"), y: 0 },
    { x: moment().subtract("6", "days").format("yyyy-mm-dd"), y: 0 },
    { x: moment().subtract("5", "days").format("yyyy-mm-dd"), y: 0 },
    { x: moment().subtract("4", "days").format("yyyy-mm-dd"), y: 0 },
    { x: moment().subtract("3", "days").format("yyyy-mm-dd"), y: 0 },
    { x: moment().subtract("2", "days").format("yyyy-mm-dd"), y: 0 },
    { x: moment().format("yyyy-mm-dd"), y: 0 },
  ]);
  const [hintValue, setHintValue] = React.useState({});
  const [labelData, setLabelData] = useState([]);
  const BarSeries = VerticalBarSeries;
  const toolTipStyle = {
    backgroundColor: "white",
    color: "black",
    fontSize: "15px",
    fontWeight: "600",
    padding: "10px",
    borderRadius: "10px",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  };

  React.useEffect(() => {
    try {
      if (data) {
        const { last_7_days } = data;
        const keys = Object.keys(last_7_days).reverse();
        let _chartData = [];
        let _labelData = [];
        keys.forEach((key, index) => {
          let obj = {
            x: moment(key.split("-").join(""), "YYYYMMDD").fromNow(),
            y:
              last_7_days?.[key]?.total_male_applicants +
              last_7_days?.[key]?.total_female_applicants,
          };
          _chartData.push(obj);
        });

        _labelData = _chartData.map((val, index) => {
          let timeString = _chartData[index].x.split("-").join("");
          timeString = moment(timeString, "YYYYMMDD").fromNow();
          return { y: timeString };
        });

        setChartData(_chartData);
        setLabelData(_labelData);
      }
    } catch (e) {
      console.error(e, "error 31");
    }
  }, [data]);

  return (
    <div className="custom-line-series">
      <FlexibleWidthXYPlot xType="ordinal" height={300} xDistance={100}>
        <Hint value={hintValue} align={{ vertical: "top", horizontal: "left" }}>
          <Box style={toolTipStyle}>
            {hintValue?.x}:{" "}
            {<NumberWrapper value={hintValue?.y} size={"0.9rem"} />}
            <span
              style={{
                fontSize: "0.8rem",
                display: "inline",
                marginLeft: "0.4rem",
                color: "gray",
              }}
            >
              (%
              {(
                (hintValue?.y / data?.total_completed_applications) *
                100
              )?.toFixed(1)}
              )
            </span>
          </Box>
        </Hint>
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        <BarSeries
          className="vertical-bar-series-example"
          data={chartData}
          color={"#403DA0"}
          barWidth={0.8}
          animation={true}
          onNearestX={(value, { event, innerX, index }) => {
            setHintValue(value);
          }}
        />
        <LabelSeries data={labelData} getLabel={(d) => d.x} />
      </FlexibleWidthXYPlot>
    </div>
  );
};

const ApplicantsOverTime = () => {
  const { params, totalApplications, userData } =
    React.useContext(DashboardContext);
  const style = {
    display: "flex",
    padding: "1.2rem",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "1rem",
    marginTop: "1.5rem",
    marginRight:
      params.type == "" || params.type == "Applications" ? "0.5rem" : "unset",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  };
  return (
    <div className="applicants-over-time" style={style}>
      <div className="top card-title">
        {params?.type === "Applications" || params?.type === ""
          ? "Applicants Over Time"
          : "Registrations Over Time"}
      </div>
      <div className="bottom">
        <ApplicantChart
          type={params?.type}
          totalApplications={totalApplications}
          data={userData}
        />
      </div>
    </div>
  );
};

ApplicantChart.propTypes = {
  totalApplication: propTypes.string,
};

export default ApplicantsOverTime;
