/*eslint-disable*/
import React from 'react';
import {
  BusinessPlanSection,
  FormSwitcher,
  FormTypeButton,
  GrantFormSection,
  GrantOverviewRoot,
  GrantOverviewSectionTitle,
} from './GrantDetails.components';
import { useGetGrantFormFields } from '../../hooks/useGetGrantFormFields';
import { GrantAssessmentContext } from '@/utils/context/GrantAssementContext';
import { useGetGrantOverview } from '../../services/GrantDetails/GrantOverview.service';
import { Box, Grid } from '@mui/material';
import { defaultActiveColor, light_gray } from '@/utils/colors';
import { useGetBusinessFormFields } from '../../hooks/useGetBusinessFormFields';
import { useGetRegions } from '@/utils/hooks/getRegionDistricts';

const GrantOverview = () => {
  const { selectedApplicantResponseObject, isPrinting } = React.useContext(GrantAssessmentContext);
  const { currentFormStage, getSelectedStyles, onStageChange, returnFieldComponent } = useGetGrantOverview();
  const props = useGetGrantFormFields(selectedApplicantResponseObject || {});
  const { regions } = useGetRegions();
  const businessFormFieldProps = useGetBusinessFormFields({
    response: selectedApplicantResponseObject || {},
    regions: regions,
  });
  const isSkipped = selectedApplicantResponseObject?.client_details?.[0]?.skipped_advanced_training == true;

  return (
    <GrantOverviewRoot>
      {/* <FormSwitcher> */}
      {/* <FormTypeButton onClick={onStageChange} sx={getSelectedStyles(currentFormStage == 'grant_form')}>
          Grant Form
        </FormTypeButton> */}
      {/* <FormTypeButton onClick={onStageChange} sx={getSelectedStyles(currentFormStage == 'business_plan_form')}>
          Business Plan Form
        </FormTypeButton> */}
      {/* </FormSwitcher> */}
      {currentFormStage == 'grant_form' && (
        <GrantFormSection container>
          <GrantOverviewSectionTitle>Personal Information</GrantOverviewSectionTitle>
          <Grid container columnGap={2}>
            {returnFieldComponent(props.personal_information_fields)}
          </Grid>

          <GrantOverviewSectionTitle>Personal Information II</GrantOverviewSectionTitle>
          <Grid container columnGap={2}>
            {returnFieldComponent(props.personal_information_fields_2)}
          </Grid>

          <GrantOverviewSectionTitle>Business Information</GrantOverviewSectionTitle>
          <Grid container columnGap={2}>
            {returnFieldComponent(props.business_information_fields)}
          </Grid>

          <GrantOverviewSectionTitle>Business Information II</GrantOverviewSectionTitle>
          <Grid container columnGap={2}>
            {returnFieldComponent(props.business_information_fields_2)}
          </Grid>

          <GrantOverviewSectionTitle>Product Information</GrantOverviewSectionTitle>
          <Grid container columnGap={2}>
            {returnFieldComponent(props.product_information_fields)}
          </Grid>

          <GrantOverviewSectionTitle>Product Information II</GrantOverviewSectionTitle>
          <Grid container columnGap={2}>
            {returnFieldComponent(props.product_information_2_fields)}
          </Grid>

          <GrantOverviewSectionTitle>Technical Capacity</GrantOverviewSectionTitle>
          <Grid container columnGap={2}>
            {returnFieldComponent(props.technical_capability_fields)}
          </Grid>

          <GrantOverviewSectionTitle>Revenue Generation</GrantOverviewSectionTitle>
          <Grid container columnGap={2}>
            {returnFieldComponent(props.revenue_generation_fields)}
          </Grid>

          <GrantOverviewSectionTitle>Revenue Generation II</GrantOverviewSectionTitle>
          <Grid container columnGap={2}>
            {returnFieldComponent(props.revenue_generation_fields_2)}
          </Grid>

          <GrantOverviewSectionTitle>Funding Requirements</GrantOverviewSectionTitle>
          <Grid container columnGap={2}>
            {returnFieldComponent(props.funding_requirements_fields)}
          </Grid>

          <GrantOverviewSectionTitle>Impact</GrantOverviewSectionTitle>
          <Grid container>{returnFieldComponent(props.impact_fields)}</Grid>

          <GrantOverviewSectionTitle>Impact II</GrantOverviewSectionTitle>
          <Grid container columnGap={2}>
            {returnFieldComponent(props.impact_fields_2)}
          </Grid>
        </GrantFormSection>
      )}

      {currentFormStage == 'business_plan_form' && (
        <BusinessPlanSection container>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            {isPrinting && (
              <Box sx={{ textAlign: 'center', width: '100%', marginBottom: '20px', marginTop: '15px' }}>
                <Box sx={{ fontSize: '18px', marginBottom: '10px', fontWeight: '700', color: defaultActiveColor }}>
                  Ghana Jobs and Skills Project
                </Box>
                <Box sx={{ fontSize: '15px', marginBottom: '10px' }}>
                  <span style={{ fontWeight: '700' }}>Name</span>:{' '}
                  {`${selectedApplicantResponseObject?.overview?.first_name || 'No Applicant Selected'} ${
                    selectedApplicantResponseObject?.overview?.last_name || ''
                  }`}
                </Box>
                <Box sx={{ fontSize: '15px' }}>
                  <span style={{ fontWeight: '700' }}>GJSP Application Code</span>:{' '}
                  {`${selectedApplicantResponseObject?.client_details?.[0]?.project_application_code}`}
                </Box>
              </Box>
            )}
            <GrantOverviewSectionTitle as="h1">Application Summary</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.application_summary_fields)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Applicant Information</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.applicant_information_fields)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Applicant Information II</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.applicant_information_2_fields)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Applicant Information III</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.applicant_information_3_fields)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Applicant Information IV</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.applicant_information_4_fields)}
            </Grid>
          </Box>
          {
            !isSkipped && 
             <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Project Information</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.project_information_fields)}
            </Grid>
          </Box>
          }
         
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Project Information II</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.project_information_2_fields)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Project Information III</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.project_information_3_fields)}
            </Grid>
          </Box>
          {
            !isSkipped &&  <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Project Information IV</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.project_information_4_fields)}
            </Grid>
          </Box>
          }
         
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Grant Request</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.grant_request_fields)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Grant Request II</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.grant_request_fields_2)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Expected Results</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.expected_results)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>{!isSkipped?'Sustainability':'Other Issues'}</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.sustainability_fields)}
            </Grid>
          </Box>
        </BusinessPlanSection>
      )}
    </GrantOverviewRoot>
  );
};

export default GrantOverview;
