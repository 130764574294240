/*eslint-disable*/
import React from 'react';
import { AxiosResponse } from 'axios';
import { IApplicantDataObject } from '@/components/GrantAssessment/interfaces/applicantDetails.model';
interface IGrantAssementContext {
  currentPage: number;
  totalPages: number;
  selectedApplicantId: number | string;
  selectedApplicantResponseObject?: Partial<IApplicantDataObject>;
  isLoading?: boolean;
  isSubmitting?: boolean;
  refetch?: any;
  expenditureList?: any;
  isPrinting?: boolean;
  setIsPrinting?: React.Dispatch<React.SetStateAction<boolean>>;
  setExpenditureList?: React.Dispatch<React.SetStateAction<any>>;
  setIsSubmitting?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedApplicantResponseObject?: React.Dispatch<React.SetStateAction<Partial<IApplicantDataObject>>>;
  setSelectedApplicantId: React.Dispatch<React.SetStateAction<number | string>>;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const GrantAssessmentContext = React.createContext<IGrantAssementContext>({
  currentPage: 0,
  totalPages: 0,
  selectedApplicantId: 0,
  selectedApplicantResponseObject: undefined,
  isLoading: true,
  isSubmitting: false,
  isPrinting: false,
  setIsPrinting: () => {},
  setIsSubmitting: () => {},
  setSelectedApplicantResponseObject: () => {},
  setSelectedApplicantId: () => {},
  setTotalPages: () => {},
  setCurrentPage: () => {},
});
