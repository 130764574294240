import React from "react";
import { Box } from "@mui/material";
import ApplicationAgeDistribution from "./Cards/ApplicationAgeDistribution";
import DisabilityChart from "./Cards/DisabilityChart";

const ChartRow6 = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <ApplicationAgeDistribution />
      <DisabilityChart />
    </Box>
  );
};

export default ChartRow6;
