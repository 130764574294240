/*eslint-disable */
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { useQuery } from "react-query";
import { getPaginatedApplicants, searchApplicants } from "@service/Applicants";
import Pagination from "@mui/material/Pagination";
import SearchInput from "@components/SearchInput";
import ApplicantCard from "@components/ApplicantMain/Cards/ApplicantCard";
import { getPaginatedStaff } from "@service/Staff";
import StaffCard from "./StaffCard";
import { useNavigate } from "react-router-dom";
import { handleErrorMessage, messageAlert } from "@utils/helpers/functions";

const AdminUserList = ({ setSelectedApplicant, selectedApplicant }) => {
  // const [selected, setSelected] = useState(undefined)

  const { data, isLoading } = useQuery(["page", 1], () => getPaginatedStaff(1));
  const [loader, setLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  let [applicantData, setApplicantData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading == false) {
      setApplicantData(data?.data?.data);
      setTotalPages(data?.data?.total_pages);
    }
  }, [data]);

  // const selectApplicant  = (applicant) => {
  //   setSelectedApplicant(applicant.id)
  // }

  const style = {
    display: "flex",
    flexDirection: "column",
    background: "#F9FAFD",
    height: "100%",
  };

  const handlePagination = async (event, value) => {
    try {
      let { data } = await getPaginatedStaff(value);
      setApplicantData(data.data);
      setTotalPages(data.total_pages);
    } catch (e) {
      let message = handleErrorMessage(e);
      messageAlert("error", message);

    }
  };

  if (isLoading === true) return "";
  return (
    <div style={style} className="applicant-list">
      <div className="top" style={{ padding: "2rem", paddingBottom: "0.8rem" }}>
        <div
          className="title flex"
          style={{ marginBottom: "1rem", alignItems: "center" }}
        >
          <div className="left" style={{ fontSize: "1rem", fontWeight: "600" }}>
            Staff
          </div>
          <div className="right" style={{ order: "1", marginLeft: "auto" }}>
            <select
              style={{
                fontSize: "0.9rem",
                padding: "0.3rem",
                backgroundColor: "#EEF1F3",
                border: "0px",
                color: "#4F4F4F",
              }}
            >
              <option>All</option>
              <option>Pending</option>
              <option>Completed</option>
            </select>
          </div>
        </div>
        <div className="options flex" style={{ width: "100%" }}>
          <SearchInput setSearchQuery={setSearchQuery} />
        </div>
      </div>
      <div
        className="bottom bottom-list flex flex-c"
        style={{ marginLeft: "2rem", height: "calc(100%-50px)" }}
      >
        {applicantData
          ?.filter((applicant) => {
            return `${applicant?.user?.first_name} ${applicant?.user?.last_name} `
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          })
          .map((applicant, idx) => (
            <div
              key={`${applicant?.user?.id}-${idx}`}
              onClick={() => {
                // selectApplicant(applicant?.user)
                navigate(`/admin/details/${applicant?.user?.id}`);
              }}
            >
              <StaffCard
                selected={applicant?.user?.id === selectedApplicant}
                name={`${applicant?.user?.first_name} ${applicant?.user?.last_name}`}
                role={applicant?.role?.name}
              />
            </div>
          ))}
      </div>
      <div
        style={{
          order: "1",
          marginTop: "auto",
          borderTop: "1px solid #dbdbdb",
          marginRight: "1.5rem",
          paddingTop: "1.5rem",
          borderBottom: "1px solid #dbdbdb",
          paddingBottom: "1.5rem",
          marginBottom: "4rem",
          paddingLeft: "2rem",
        }}
      >
        <Pagination
          className="custom-pagination"
          size={"small"}
          onChange={handlePagination}
          count={totalPages}
          color="primary"
        />
      </div>
    </div>
  );
};

AdminUserList.propTypes = {
  setSelectedApplicant: propTypes.func,
  selectedApplicant: propTypes.number,
};
export default AdminUserList;
