/*eslint-disable*/
import React from 'react';
import {
  ActionAreaWrapper,
  ActionButton,
  GrantDetailsHeader,
  GrantDetailsHeaderBottom,
  GrantDetailsHeaderTop,
  PercentageText,
  RatingsWrapper,
  SecondaryActionButton,
  UserInfoRight,
  UserInfoWrapper,
  UserInitialsWrapper,
  UsernameWrapper,
} from './GrantDetails.components';
import { Rating, Tabs, Tab, CircularProgress, Box } from '@mui/material';
import { GrantDetailsContext } from '@/utils/context/GrantDetailsContext';
import { GrantAssessmentContext } from '@/utils/context/GrantAssementContext';
import GrantDetailsTopLoader from '../Loaders/GrantDetailsTopLoader';
import { defaultActiveColor } from '@/utils/constants';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { makeApplicantEditable } from '@/service/Applicants';
import { useNavigate } from 'react-router-dom';
import { messageAlert } from '@/utils/helpers/functions';
import { IApprovalsData } from '@/utils/interfaces/api.approvals.interface';
import apiConfig from '@/service/apiConfig';
import useGetUserData from '@/utils/hooks/useGetUserData';
import ConfirmModalBase from '@/components/Modal/ConfirmModalBase';

const GrantDetailsTop = () => {
  const { userData, isLoading: userLoading } = useGetUserData();
  const { tabValue, setTabValue } = React.useContext(GrantDetailsContext);
  const [isEditing, setIsEditing] = React.useState(false);
  const navigate = useNavigate();
  const { selectedApplicantResponseObject, isLoading, isSubmitting, isPrinting, setIsPrinting, setIsSubmitting } =
    React.useContext(GrantAssessmentContext);
  const statusComponent = (_status: string) => {
    let status = '';
    let style = {
      fontSize: '0.7rem',
      backgroundColor: '#ED1C2420',
      color: '#ED1C24',
      padding: '0.3rem',
      borderRadius: '0.3rem',
    };

    switch (_status) {
      case 'pending_application_approval':
        status = 'Pending Approval';
        style.backgroundColor = '#f9ffb8';
        style.color = '#838c01';
        break;
      case 'approved_application_approval':
        status = 'Approved for Interview';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_training':
        status = 'Approved for Training';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_grant':
        status = 'Approved for Grant Application';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      default:
        status = 'Pending';
    }

    return (
      <div className="application-status" style={style}>
        {status}
      </div>
    );
  };

  const makeEditable = async () => {
    setIsEditing(true);
    try {
      let userId = selectedApplicantResponseObject?.overview?.id;
      await makeApplicantEditable(userId);
      messageAlert('success', 'Applicant Successfully Backtracked');
      navigate('/grantassessment');
    } catch (e) {
      console.log(e);
    }
    setIsEditing(false);
  };

  const approveForDisbursement = async () => {
    setIsEditing(true);
    try {
      let userId = selectedApplicantResponseObject?.overview?.id;
      const _params: IApprovalsData = {
        approval_type: 'pre_disbursement_approval',
        id_list: [{id:userId}],
        user: userData,
        approval_status: 'approved',
      };
      await apiConfig.postRequest(`staff/approvals/`, _params);
      messageAlert('success', 'Approval Successful');
      navigate('/grantassessment');
    } catch (e) {
      messageAlert('error', 'Approval Failed');
    }
    setIsEditing(false);
  };

  return (
    <>
      {isLoading == true && <GrantDetailsTopLoader />}
      {isLoading == false && (
        <GrantDetailsHeader>
          <GrantDetailsHeaderTop>
            <UserInfoWrapper>
              <UserInitialsWrapper>
                {selectedApplicantResponseObject?.overview?.first_name?.split('')?.[0] || ''}
              </UserInitialsWrapper>
              <UserInfoRight>
                <UsernameWrapper>{`${
                  selectedApplicantResponseObject?.overview?.first_name || 'No Applicant Selected'
                } ${selectedApplicantResponseObject?.overview?.last_name || ''}`}</UsernameWrapper>
                <RatingsWrapper>
                  <Rating
                    name="user-rating"
                    value={selectedApplicantResponseObject?.client_details?.[0]?.application_score?.score_rating || 0}
                    readOnly={true}
                    sx={{ fontSize: '18px' }}
                  />
                  <PercentageText>
                    (
                    {`${
                      selectedApplicantResponseObject?.client_details?.[0]?.application_score?.percentage_score || 0
                    }%`}
                    )
                  </PercentageText>
                </RatingsWrapper>
              </UserInfoRight>
              <div>
                {statusComponent(
                  selectedApplicantResponseObject?.client_details?.[0]?.application_process_status || '',
                )}
              </div>
              {selectedApplicantResponseObject?.client_details?.[0]?.skipped_advanced_training == true && (
                <Box
                  sx={{
                    // marginTop: '5px',
                    backgroundColor: `${defaultActiveColor}20`,
                    color: defaultActiveColor,
                    padding: '5px',
                    borderRadius: '8px',
                    marginLeft: '8px',
                  }}
                >
                  Skipped Advanced Training
                </Box>
              )}
              {selectedApplicantResponseObject?.client_details?.[0]?.skipped_advanced_training == false && (
                <Box
                  sx={{
                    // marginTop: '5px',
                    backgroundColor: `${defaultActiveColor}20`,
                    color: defaultActiveColor,
                    padding: '5px',
                    borderRadius: '8px',
                    marginLeft: '8px',
                  }}
                >
                  Advanced Training to Grant
                </Box>
              )}
            </UserInfoWrapper>
            {tabValue == 0 && (
              <ActionAreaWrapper style={{ display: 'flex', flexDirection: 'row' }}>
                <ActionButton
                  onClick={() => {
                    setIsPrinting(true);
                  }}
                  disabled={isPrinting}
                >
                  {isPrinting && (
                    <CircularProgress style={{ color: defaultActiveColor, marginRight: '8px', fontSize: '12px' }} />
                  )}
                  Download Form PDF
                </ActionButton>
                <ConfirmModalBase
                  onConfirm={() => {
                    makeEditable();
                  }}
                >
                  <SecondaryActionButton
                    disabled={
                      selectedApplicantResponseObject?.client_details?.[0]?.profile
                        ?.is_business_plan_application_complete !== true || isSubmitting
                    }
                    sx={{ marginLeft: '10px' }}
                  >
                    {isEditing && (
                      <CircularProgress style={{ color: defaultActiveColor, marginRight: '8px', fontSize: '12px' }} />
                    )}
                    Backtrack Applicant
                  </SecondaryActionButton>
                </ConfirmModalBase>
              </ActionAreaWrapper>
            )}
            {tabValue == 1 && (
              <ActionAreaWrapper style={{ flexDirection: 'column', display: 'flex', rowGap: '10px' }}>
                <ConfirmModalBase
                  onConfirm={() => {
                    setIsSubmitting?.(true);
                  }}
                >
                  <ActionButton
                    disabled={
                      selectedApplicantResponseObject?.client_details?.[0]?.profile
                        ?.is_business_plan_application_complete !== true || isSubmitting
                    }
                  >
                    {isSubmitting && (
                      <CircularProgress style={{ color: defaultActiveColor, marginRight: '8px', fontSize: '12px' }} />
                    )}
                    Manually Score Application
                  </ActionButton>
                </ConfirmModalBase>

                <ConfirmModalBase onConfirm={approveForDisbursement}>
                  <SecondaryActionButton
                    disabled={
                      selectedApplicantResponseObject?.client_details?.[0]?.profile
                        ?.is_business_plan_application_complete !== true || isSubmitting
                    }
                    // onClick={approveForDisbursement}
                  >
                    {isEditing && (
                      <CircularProgress style={{ color: defaultActiveColor, marginRight: '8px', fontSize: '12px' }} />
                    )}
                    Approve For Disbursement
                  </SecondaryActionButton>
                </ConfirmModalBase>
              </ActionAreaWrapper>
            )}
          </GrantDetailsHeaderTop>
          <GrantDetailsHeaderBottom>
            <Tabs
              className="custom-tabs"
              value={tabValue}
              onChange={(event, value) => {
                if (setTabValue) {
                  setTabValue(value);
                }
              }}
              style={{ borderBottom: '0.5px solid #EBEFF2' }}
              aria-label="basic tabs example"
            >
              <Tab label="Application Overview" value={0} />
              <Tab label="Manual Scoring" value={1} />
            </Tabs>
          </GrantDetailsHeaderBottom>
        </GrantDetailsHeader>
      )}
    </>
  );
};

export default GrantDetailsTop;
