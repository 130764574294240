/*eslint-disable*/
import React from "react";
import { Box } from "@mui/material";
import Slider from "@mui/material/Slider";
import propTypes from "prop-types";
import NumberWrapper from "../NumberWrapper";
import { DashboardContext } from "../DashboardContent";

const returnDefaultValue = (value) => {
  if (value == undefined || value == null) {
    return 0;
  }
  return value;
};

const ApplicationsBySector = () => {
  const { userData } = React.useContext(DashboardContext);
  const [dataArray, setDataArray] = React.useState([]);

  React.useEffect(() => {
    try {
      if (userData != null) {
        let keyList = Object.keys(userData?.sectors);
        let dataList = [];
        keyList?.forEach((key) => {
          let object = userData?.sectors[key];
          dataList.push({
            label: key,
            value:
              object?.total_female_applicants + object?.total_male_applicants,
          });
        });
        let sorted_array = dataList.sort((a, b) => b.value - a.value);
        setDataArray(sorted_array);
      }
    } catch (e) {
      console.error(e);
    }
  }, [userData]);

  // display: flex;
  //     padding: 1.2rem;
  //     flex-direction: column;
  //     background-color: white;
  //     border-radius: 1rem;
  //     margin-top: 1.5rem;
  //     border: 1px solid rgb(229, 229, 229);
  //     height: 20rem;

  const style = {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "1rem",
    padding: "1.2rem",
    marginTop: "1.5rem",
    marginLeft: "1rem",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  };
  return (
    <Box style={style}>
      <Box
        className="title"
        style={{ fontSize: "1rem", marginBottom: "1.5rem", fontWeight: "600" }}
      >
        Number of Applications By Sector
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "300px",
          overflowY: "auto",
        }}
      >
        {dataArray.map((item, index) => {
          return (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              key={`${index}-${item?.label}`}
            >
              <Box
                style={{ width: "20%", fontSize: "14px", fontWeight: "600" }}
              >
                {item.label}
              </Box>
              <Box className="custom-slider-wrapper" style={{ width: "70%" }}>
                <Slider
                  className="custom-slider sector-slider"
                  max={userData?.total_completed_applications}
                  min={0}
                  defaultValue={item?.value}
                  disabled={true}
                />
              </Box>
              <Box
                sx={{
                  width: "10%",
                  fontWeight: "500",
                  textAlign: "right",
                  paddingRight: "1rem",
                  fontSize: "18px",
                }}
              >
                <NumberWrapper value={item?.value} size={"1rem"} />
                <span
                  style={{
                    width: "100px",
                    display: "inline",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  (%
                  {(
                    (item?.value / userData?.total_completed_applications) *
                    100
                  ).toFixed(1)}
                  )
                </span>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ApplicationsBySector;
