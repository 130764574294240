/*eslint-disable*/
import React from 'react';
import {
  TrainingStageSwitcherRoot,
  LeftTrainingStageButton,
  RightTrainingStageButton,
  TrainingStageButton,
} from './styled/trainingdashboard.styledcomponents';
import { ITrainingStageSwitcher } from './interfaces/chart.interfaces';
import { defaultActiveColor } from '@/utils/constants';

/**
 * 
 * 
 * approved_application_for_basic_training
approved_application_for_intermediate_training
approved_application_for_advanced_training
 * @param param0 
 * intermediate_training_approval
1:04
advanced_training_approval
1:04
basic_training_approval
 * @returns 
 */

const TrainingStageSwitcher = ({ currentStage, setStage }: ITrainingStageSwitcher) => {
  const getStyles = (isActive: boolean): React.CSSProperties => {
    return {
      backgroundColor: isActive ? defaultActiveColor : '#E4EBFD',
      color: isActive ? 'white' : 'black',
    };
  };
  return (
    <TrainingStageSwitcherRoot>
      <LeftTrainingStageButton
        onClick={() => {
          setStage?.('approved_application_for_basic_training');
        }}
        sx={getStyles(currentStage == 'approved_application_for_basic_training')}
      >
        Basic
      </LeftTrainingStageButton>
      <TrainingStageButton
        onClick={() => {
          setStage?.('approved_application_for_intermediate_training');
        }}
        sx={getStyles(currentStage == 'approved_application_for_intermediate_training')}
      >
        Intermediate
      </TrainingStageButton>
      <RightTrainingStageButton
        onClick={() => {
          setStage?.('approved_application_for_advanced_training');
        }}
        sx={getStyles(currentStage == 'approved_application_for_advanced_training')}
      >
        Advanced
      </RightTrainingStageButton>
    </TrainingStageSwitcherRoot>
  );
};

export default TrainingStageSwitcher;
