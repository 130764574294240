import { Typography } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import propTypes from "prop-types";

// const businessInfo = [
//     {title:"Stage of Operation", value:"Idea Stage"},
//     {title:"Industry", value:"Fashion"},
//     {title:"Association/Faith Based Organization", value:"N/A"},
// ]

const returnDefault = (value) => {
  if (value == undefined || value == null) {
    return "None";
  }
  return value;
};
function AdminInfo({ data, title, type, profile }) {
  const generalInfo = {
    overview: [
      { title: "First Name", value: data?.first_name },
      { title: "Surname", value: data?.last_name },
      { title: "Gender", value: data?.gender },
      // {title:"Phone Number", value:data?.phone_number},
      { title: "Email Address", value: data?.email },
      { title: "Region", value: returnDefault(profile?.region?.name) },
      { title: "District", value: returnDefault(profile?.district?.name) },
    ],
  };

  if (!data) return null;

  return (
    <div style={{ marginTop: "2rem" }}>
      <Grid container direction="row" style={{ marginBottom: "1.5rem" }}>
        <div
          className="card-title text-default-color"
          style={{ borderLeft: "0.2em solid #2A3B8F", paddingLeft: "0.5em" }}
        >
          {title}
        </div>
        <Grid container spacing={3}>
          {generalInfo[type]?.map((info, index) => (
            <Grid item xs={4} key={index} style={{ marginBottom: "1.5rem" }}>
              <div className="top" style={{ marginBottom: "0.5rem" }}>
                {snakeToCamelCase(info?.title)}
              </div>
              <div className="bottom">
                <Typography style={{ fontFamily: "unset", fontSize: "0.9rem" }}>
                  {info?.value}{" "}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}
AdminInfo.propTypes = {
  data: propTypes.object,
  profile: propTypes.object,
  title: propTypes.string,
  type: propTypes.string,
};

// const ApplicantOverview = ({user}) => {
//
//   return (
//     <ApplicantInfo data={user?.data?.overview} title="Personal Information"/>

//   )
// }

// ApplicantOverview.propTypes = {
//     user : propTypes.object
// }

export default AdminInfo;

const snakeToCamelCase = (sentence) =>
  sentence
    .split("_")
    .map((word) => {
      return firstUppercase(word);
    })
    .join(" ");
const firstUppercase = (word) =>
  word && word.charAt(0).toUpperCase() + word.slice(1);

// function mapResponseValueToYesOrNo(val) {
//   if(val === true){
//     return "Yes"
//   } else if (val === false) {
//     return "No"
//   } else return 'N/A'
// }
