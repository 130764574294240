/*eslint-disable*/
import React from "react";
import {
  Grid,
  Box,
  Container,
  Button,
  Modal,
  Typography,
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DashboardSidebar from "@components/DashboardSidebar/DashboardSidebar";
import StaffNavbar from "../Staff/StaffNavbar";
import {
  handleErrorMessage,
  messageAlert,
  storeLocalStorage,
} from "@utils/helpers/functions";
import SettingsNav from "./SettingsNav";
import useGetUserData from "@utils/hooks/useGetUserData";
import ApplicantSidebar from "@components/ApplicantSidebar/ApplicantSidebar";

const Pass = () => {
  const { userData, isLoading } = useGetUserData();
  return (
    <Box
      className="staff-main"
      sx={{ width: "100%", height: "100vh", overflowY: "hidden" }}
    >
      <Grid sx={{ width: "100%", height: "100vh", display: "flex" }}>
        <ApplicantSidebar selectedView={3} />
        <Grid sx={{ display: "flex", flexDirection: "column", width: "98%" }}>
          <StaffNavbar user={userData?.user} />
          <Grid container sx={{ height: "90vh" }}>
            <SettingsNav selectedView={2} />
            <Grid
              item
              xs={9}
              sx={{ display: "flex", flexDirection: "column", padding: "2rem" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ fontSize: "1.2rem", fontWeight: "600" }}>
                  Pass Mark
                </Box>
                <Box sx={{ fontSize: "0.8rem" }} my={"1rem"}>
                  The pass mark is used for evaluating and approving applicants
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "1rem",
                }}
              >
                <Box
                  sx={{ fontSize: "0.8rem", fontWeight: "600", color: "gray" }}
                  mb={"1rem"}
                >
                  Pass Mark
                </Box>
                <Box
                  sx={{ display: "flex", width: "100%", alignItems: "center" }}
                >
                  <Box mr={"1rem"} sx={{ width: "60%" }}>
                    <input
                      type="number"
                      style={{ width: "calc(100%-1.6rem)" }}
                      placeholder="Pass Mark"
                      className="add-staff-form-input"
                    ></input>
                  </Box>
                  <Box>
                    <Button
                      type="submit"
                      sx={{
                        "&:hover": {
                          color: "#2a3b8f",
                          border: "1px solid #2a3b8f",
                        },
                        fontWeight: "400",
                        fontFamily: "inherit !important",
                        fontSize: "0.85rem",
                        color: "white",
                        backgroundColor: "#2a3b8f",
                        textTransform: "initial !important",
                        padding: "0.7rem",
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                      }}
                    >
                      {" "}
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pass;
