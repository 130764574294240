/*eslint-disable*/
import { getGrantApprovals } from '@/service/Applicants';
import { GrantAssessmentContext } from '@/utils/context/GrantAssementContext';
import React from 'react';
import { useQuery } from 'react-query';
import { IGrantListParams } from '../interfaces/GrantList.interfaces';
import apiConfig from '@/service/apiConfig';
import { handleErrorMessage, messageAlert } from '@/utils/helpers/functions';

const getGrantAssessmentList = async (props: IGrantListParams) => {
  const keys: Array<keyof IGrantListParams> = Object.keys({
    page: 0,
    region: undefined,
    district: undefined,
    q: undefined,
    skipped_advanced_training:undefined
  } as IGrantListParams) as Array<keyof IGrantListParams>;
  let queryString = '';
  keys.forEach((key) => {
    if (props[key]) {
      queryString = queryString + `&${key}=${props[key]}`;
    }
  });
  return apiConfig.getRequest(
    `staff/approvals/grant_assessment_list?page=${props?.page || 0 + 1}&per_page=5${queryString}`,
  );
};

export const useGetGrantList = () => {
  const [listParams, setListParams] = React.useState<IGrantListParams>({
    page: 0,
    region: undefined,
    district: undefined,
    q: undefined,
    skipped_advanced_training:false
  });
  const { data, isLoading, error, refetch } = useQuery(
    ['fetch-grants', listParams],
    () => {
      return getGrantAssessmentList(listParams);
    },
    { staleTime: Infinity, refetchOnWindowFocus: false },
  );

  const searchQuery = (query: string) => {
    setListParams({ ...listParams, q: query, page: 0 });
  };

  const debounce = (func: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (...args: any[]) {
      const context: any = this;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // Debounce the searchQuery function to be called after 300ms of inactivity
  const debouncedSearchQuery = debounce(searchQuery, 700);

  const onFilter = () => {
    setListParams({ ...listParams, page: 0 });
  };

  React.useEffect(() => {
    refetch();
  }, [listParams.page, listParams.q]);

  React.useEffect(() => {
    if (error) {
      const message = handleErrorMessage(error);
      messageAlert('error', message);
    }
  }, [error]);

  return { data, isLoading, error, listParams, onFilter, searchQuery: debouncedSearchQuery, setListParams, refetch };
};
