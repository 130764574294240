/*eslint-disable */
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import propTypes from 'prop-types';
import AdminPermissions from '@components/Users/AdminPermissions';
import AdminActivityLog from '@components/Users/AdminActivityLog';
import { getStaff } from '@service/Staff';
import AdminInfo from './AdminInfo';
import { Box, Button, Menu, MenuItem, Grid, Modal } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSvg from '../../assets/Delete.svg';
import UserSignUp from '@service/UserSignUp';
import CloseIcon from '@mui/icons-material/Close';
import { messageAlert, handleErrorMessage } from '@utils/helpers/functions';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from 'react-hook-form';
import useGetUserData from '@utils/hooks/useGetUserData';
import { useNavigate } from 'react-router-dom';
import { checkPermissions } from '@service/RequireAuth';
import { getRegions } from '@service/CommonApi';
import { getDistricts } from '@service/CommonApi';
import CreatePasswordModal from './CreatePasswordModal';
import { useMutation } from 'react-query';
import PasswordIcon from '@mui/icons-material/Password';

const style = {
  height: '100%',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '3rem',
  paddingRight: '3rem',
};
const iconStyle = {
  borderRadius: '50%',
  backgroundColor: ' #ABB3BF',
  alignItems: 'center',
  justifyContent: 'center',
  width: '5rem',
  height: '5rem',
  marginRight: '2rem',
  fontWeight: '600',
  display: 'flex',
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
};

const loaderStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 200,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const AdminDetails = ({ selectedApplicant }) => {
  const { userData, isLoading: loadingUser } = useGetUserData();
  const [tabValue, setTabValue] = React.useState(0);
  const [user, setUser] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  const [role, setRole] = React.useState([]);
  const [editModal, setEditModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [resetModal, setResetModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [roles, setRoles] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [regions, setRegions] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [createPasswordModal, setCreatePasswordModal] = React.useState(false);
  const [createPasswordLoading, setCreatePasswordLoading] = React.useState(false);
  const [staffType, setStaffType] = React.useState('');
  const navigate = useNavigate();
  const menuOpen = Boolean(anchorEl);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    formState: { errors: errors2 },
  } = useForm();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const mutation = useMutation({
    mutationFn: (data) => {
      return UserSignUp.createPassword(data);
    },
  });

  React.useEffect(() => {
    if (selectedApplicant) fetchApplicant();
  }, [selectedApplicant]);

  const fetchApplicant = async () => {
    try {
      const response = await getStaff(selectedApplicant);
      setStaffType(response?.data?.staff_type);
      setUser(response?.data?.user);
      setProfile(response?.data?.profile);
      setRole(response?.data?.role);
    } catch (error) {
      handleErrorMessage(error);

    }
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const getRoles = async () => {
    try {
      let { data } = await UserSignUp.getRoles();
      setRoles(data);
    } catch (e) {
      handleErrorMessage(e);

    }
  };

  const onCreatePasswordSubmit = async (data) => {
    if (data?.retyped_password == data?.new_password) {
      setCreatePasswordLoading(true);
      mutation.mutate(data, {
        onError: (error) => {
          setCreatePasswordLoading(false);
          // setCreatePasswordModal(false);
          let message = handleErrorMessage(error);
          messageAlert('error', message);
        },
        onSuccess: () => {
          setCreatePasswordLoading(false);
          setCreatePasswordModal(false);
          messageAlert('success', 'Password Successfully Changed');
        },
      });
    } else {
      messageAlert('error', 'Both Passwords have to match.');
      setCreatePasswordLoading(false);
      // setCreatePasswordModal(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      let params = { role: parseInt(data?.role), id: user?.id };
      if (staffType === 'bac' || staffType === 'bac_regional_head') {
        params.region = data?.region;
      }
      if (staffType === 'bac') {
        params.district = data?.district;
      }
      setEditModal(false);
      setIsLoading(true);
      await UserSignUp.editStaff(params);
      setIsLoading(false);
      messageAlert('success', 'Role Successfully Edited');
      await fetchApplicant();
    } catch (e) {
      setEditModal(false);
      setIsLoading(false);
      console.group(e);
      messageAlert('error', 'Request failed, please try again.');
    }
  };

  const deleteStaff = async () => {
    try {
      let userId = userData?.user?.id;
      if (userId == user?.id) {
        messageAlert('error', 'Sorry, you cannot delete your own user');
        return;
      }
      let params = { id: user?.id };
      setDeleteModal(false);
      setIsLoading(true);
      await UserSignUp.deleteStaff(params);
      setIsLoading(false);
      messageAlert('success', 'Account successfully deleted');
      navigate(`/staff/management`);
    } catch (e) {
      let message = handleErrorMessage(e);
      setDeleteModal(false);
      setIsLoading(false);
      messageAlert('error', message);
    }
  };

  const resetPassword = async () => {
    try {
      let params = { id: user?.id };
      setResetModal(false);
      setIsLoading(true);
      await UserSignUp.resetPassword(params);
      setIsLoading(false);
      messageAlert('success', 'Password Reset, please check your email');
    } catch (e) {

      setResetModal(false);
      setIsLoading(false);
      let message = handleErrorMessage(e);
      messageAlert('error', message);
    }
  };

  const get_regions = async () => {
    try {
      let { data } = await getRegions();
      setRegions(data);
    } catch (e) {
      handleErrorMessage(e);

    }
  };

  const get_districts = async (region) => {
    try {
      let { data } = await getDistricts(region);
      setDistricts(data);
    } catch (e) {
      handleErrorMessage(e);

    }
  };

  React.useEffect(() => {
    get_districts(profile?.region?.id);
  }, [profile]);

  React.useEffect(() => {
    getRoles();
    get_regions();
    fetchApplicant(selectedApplicant);
  }, []);

  if (loadingUser === true) return '';
  return (
    <>
      <Modal open={isLoading} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="modal-content" sx={loaderStyle}>
          <CircularProgress />
        </Box>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content" sx={modalStyle} height={'60vh'}>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ fontWeight: '700', fontSize: '1.2rem', display: 'flex' }}>
              <Box sx={{ fontWeight: '700', fontSize: '0.9rem' }}>Deactivate Staff</Box>
            </Box>
            <Box sx={{ order: '1', marginLeft: 'auto' }}>
              <button
                style={{
                  backgroundColor: 'none',
                  border: 'none',
                  borderRadius: '50%',
                }}
                onClick={() => {
                  setDeleteModal(false);
                }}
              >
                <CloseIcon sx={{ paddingTop: '0.1rem' }} />
              </button>
            </Box>
          </Box>
          <Box
            className="message-area"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
            }}
          >
            <Box my={'1rem'}>
              <img src={DeleteSvg} />
            </Box>
            <Box mt={'2rem'} mb={'2rem'} sx={{ fontSize: '1rem', fontWeight: '700' }}>
              Are you sure you want to deactivate this staff's account?
            </Box>
          </Box>
          <Box
            mt={'3rem'}
            sx={{
              width: '100%',
              display: 'flex',
              order: '2',
              marginTop: 'auto',
            }}
          >
            <Box sx={{ display: 'flex', order: '0', marginLeft: 'auto' }}>
              <Box>
                <Button
                  sx={{
                    fontFamily: 'inherit !important',
                    fontSize: '0.85rem',
                    textTransform: 'initial !important',
                    color: '#2a3b8f',
                    padding: '0.7rem',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    border: '1px solid #2a3b8f',
                    marginRight: '2rem',
                  }}
                  onClick={() => {
                    setDeleteModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{
                    '&:hover': { color: 'red', border: '1px solid red' },
                    fontWeight: '400',
                    fontFamily: 'inherit !important',
                    fontSize: '0.85rem',
                    color: 'white',
                    backgroundColor: 'red',
                    textTransform: 'initial !important',
                    padding: '0.7rem',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                  }}
                  onClick={() => {
                    deleteStaff();
                  }}
                >
                  Deactivate Staff
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={resetModal}
        onClose={() => {
          setResetModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content" sx={modalStyle} height={'60vh'}>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ fontWeight: '700', fontSize: '1.2rem', display: 'flex' }}>
              <Box sx={{ fontWeight: '700', fontSize: '0.9rem' }}>Reset Staff Password</Box>
            </Box>
            <Box sx={{ order: '1', marginLeft: 'auto' }}>
              <button
                style={{
                  backgroundColor: 'none',
                  border: 'none',
                  borderRadius: '50%',
                }}
                onClick={() => {
                  setResetModal(false);
                }}
              >
                <CloseIcon sx={{ paddingTop: '0.1rem' }} />
              </button>
            </Box>
          </Box>
          <Box
            className="message-area"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
            }}
          >
            <Box my={'1rem'}>
              <img src={DeleteSvg} />
            </Box>
            <Box mt={'2rem'} mb={'2rem'} sx={{ fontSize: '1rem', fontWeight: '700' }}>
              This cannot be reversed.
            </Box>
            <Box sx={{ fontSize: '1rem', fontWeight: '700' }}>
              Are you sure you want to reset this staff account's password?
            </Box>
          </Box>

          <Box
            mt={'3rem'}
            sx={{
              width: '100%',
              display: 'flex',
              order: '2',
              marginTop: 'auto',
            }}
          >
            <Box sx={{ display: 'flex', order: '0', marginLeft: 'auto' }}>
              <Box>
                <Button
                  sx={{
                    fontFamily: 'inherit !important',
                    fontSize: '0.85rem',
                    textTransform: 'initial !important',
                    color: '#2a3b8f',
                    padding: '0.7rem',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    border: '1px solid #2a3b8f',
                    marginRight: '2rem',
                  }}
                  onClick={() => {
                    setResetModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{
                    '&:hover': { color: 'red', border: '1px solid red' },
                    fontWeight: '400',
                    fontFamily: 'inherit !important',
                    fontSize: '0.85rem',
                    color: 'white',
                    backgroundColor: 'red',
                    textTransform: 'initial !important',
                    padding: '0.7rem',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                  }}
                  onClick={() => {
                    resetPassword();
                  }}
                >
                  Reset Password
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={editModal}
        onClose={() => {
          setEditModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content" sx={modalStyle}>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ fontWeight: '700', fontSize: '1rem', display: 'flex' }}>
              <Box>Edit Staff</Box>
            </Box>
            <Box sx={{ order: '1', marginLeft: 'auto' }}>
              <button
                style={{
                  backgroundColor: 'none',
                  border: 'none',
                  borderRadius: '50%',
                }}
                onClick={() => {
                  setEditModal(false);
                }}
              >
                <CloseIcon sx={{ paddingTop: '0.1rem' }} />
              </button>
            </Box>
          </Box>
          <form id="create-account" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: '1rem' }}>
              <Grid container sx={{ width: '100%' }} rowSpacing={'1rem'} columnSpacing={'1rem'}>
                {(staffType === 'bac' || staffType === 'bac_regional_head') && (
                  <>
                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box>Region</Box>
                      <Box mt={'1rem'}>
                        <select
                          {...register('region', { required: true })}
                          type="text"
                          style={{ width: '100%' }}
                          className="add-staff-form-input"
                          onChange={async (e) => {
                            await get_districts(e.target.value);
                          }}
                        >
                          <option value={''}>{''}</option>
                          {regions.map((region, index) => {
                            return (
                              <option
                                value={region?.id}
                                key={`${region?.id}-${index}`}
                                selected={region.id == profile?.region?.id}
                              >
                                {region?.name}
                              </option>
                            );
                          })}
                        </select>
                      </Box>
                      {errors.region && <span>This field is required</span>}
                    </Grid>
                  </>
                )}
                {staffType === 'bac' && (
                  <>
                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box>District</Box>
                      <Box mt={'1rem'}>
                        <select
                          {...register('district', { required: true })}
                          type="text"
                          style={{ width: '100%' }}
                          className="add-staff-form-input"
                        >
                          <option value={''}>{''}</option>
                          {districts.map((district, index) => {
                            return (
                              <option
                                value={district?.id}
                                key={`${district?.id}-${index}`}
                                selected={district?.id == profile?.district?.id}
                              >
                                {district?.name}
                              </option>
                            );
                          })}
                        </select>
                      </Box>
                      {errors.district && <span>This field is required</span>}
                    </Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <Box>User Role</Box>
                  <Box mt={'1rem'}>
                    <select
                      {...register('role', { required: true })}
                      type="text"
                      style={{ width: '100%' }}
                      className="add-staff-form-input"
                    >
                      {roles?.map((role) => {
                        return <option value={role.id}>{role.name}</option>;
                      })}
                    </select>
                  </Box>
                  {errors.role && <span>This field is required</span>}
                </Grid>
              </Grid>
            </Box>
          </form>
          <Box mt={'1rem'} sx={{ width: '100%', display: 'flex' }}>
            <Box sx={{ display: 'flex', order: '0', marginLeft: 'auto' }}>
              <Box>
                <Button
                  sx={{
                    fontFamily: 'inherit !important',
                    fontSize: '0.85rem',
                    textTransform: 'initial !important',
                    color: '#2a3b8f',
                    padding: '0.7rem',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    border: '1px solid #2a3b8f',
                    marginRight: '2rem',
                  }}
                  onClick={() => {
                    setEditModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  form="create-account"
                  type="submit"
                  sx={{
                    '&:hover': {
                      color: '#2a3b8f',
                      border: '1px solid #2a3b8f',
                    },
                    fontWeight: '400',
                    fontFamily: 'inherit !important',
                    fontSize: '0.85rem',
                    color: 'white',
                    backgroundColor: '#2a3b8f',
                    textTransform: 'initial !important',
                    padding: '0.7rem',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                  }}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <CreatePasswordModal
        errors={errors2}
        handleSubmit={handleSubmit2}
        register={register2}
        modalShowing={createPasswordModal}
        setModalState={setCreatePasswordModal}
        onSubmit={onCreatePasswordSubmit}
        isLoading={createPasswordLoading}
      />

      <div style={style}>
        <div className="top flex flex-c">
          <div
            className="top flex"
            style={{
              borderBottom: '1px solid #e4e4e4',
              paddingBottom: '2rem',
              alignItems: 'center',
            }}
          >
            <div className="left flex">
              <div className="left" style={iconStyle}>
                <div style={{ color: 'white', fontSize: '2rem' }}>{user?.first_name?.split('')[0]}</div>
              </div>
              <div className="right flex flex-c" style={{ justifyContent: 'center' }}>
                <div className="top flex" style={{ marginBottom: '0.5rem', fontWeight: '600' }}>
                  <div
                    style={{
                      fontSize: '1.3rem',
                      marginRight: '0.6rem',
                      marginTop: '0.2rem',
                    }}
                  >
                    {user?.first_name} {user?.data?.overview?.last_name}
                  </div>
                </div>
                <div className="bottom flex">
                  <div
                    style={{
                      color: 'gray',
                      fontSize: '0.8rem',
                      marginRight: '0.5rem',
                      marginTop: '0.6rem',
                    }}
                  >
                    {role.name ? role.name : 'No Role'}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="right" style={{ order: '1', marginLeft: 'auto', alignItems: 'center' }}>
              <Box>
                <Button
                  onClick={handleClick}
                  sx={{
                    '&:hover': {
                      color: '#2a3b8f',
                      border: '1px solid #2a3b8f',
                    },
                    fontWeight: '400',
                    fontFamily: 'inherit !important',
                    fontSize: '0.85rem',
                    color: 'white',
                    backgroundColor: '#2a3b8f',
                    textTransform: 'initial !important',
                    padding: '0.4rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                  }}
                >
                  More Options
                  <KeyboardArrowDownIcon />
                </Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorEl}
                  anchorOrigin={{ horizontal: 'middle', vertical: 'bottom' }}
                  open={menuOpen}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      width: '25ch',
                    },
                  }}
                >
                  {checkPermissions('edit user', userData) == true && (
                    <MenuItem
                      key={1}
                      onClick={() => {
                        setEditModal(true);
                      }}
                      sx={{
                        fontFamily: 'inherit !important',
                        padding: '0.5rem',
                        fontSize: '0.8rem',
                      }}
                    >
                      <EditIcon sx={{ fontSize: '1.2rem', marginRight: '1rem' }} />
                      Edit Staff
                    </MenuItem>
                  )}
                  {checkPermissions('suspend user', userData) == true && (
                    <MenuItem
                      key={3}
                      onClick={() => {
                        setDeleteModal(true);
                      }}
                      sx={{
                        fontFamily: 'inherit !important',
                        padding: '0.5rem',
                        fontSize: '0.8rem',
                      }}
                    >
                      <DeleteOutlineIcon sx={{ fontSize: '1.2rem', marginRight: '1rem' }} />
                      Deactivate Profile
                    </MenuItem>
                  )}
                  {checkPermissions('reset password', userData) == true && (
                    <MenuItem
                      key={3}
                      onClick={() => {
                        setResetModal(true);
                      }}
                      sx={{
                        fontFamily: 'inherit !important',
                        padding: '0.5rem',
                        fontSize: '0.8rem',
                      }}
                    >
                      <RestartAltIcon sx={{ fontSize: '1.2rem', marginRight: '1rem' }} />
                      Reset Password
                    </MenuItem>
                  )}
                  {
                    <MenuItem
                      key={3}
                      onClick={() => {
                        setCreatePasswordModal(true);
                      }}
                      sx={{
                        fontFamily: 'inherit !important',
                        padding: '0.5rem',
                        fontSize: '0.8rem',
                      }}
                    >
                      <PasswordIcon sx={{ fontSize: '1.2rem', marginRight: '1rem' }} />
                      Change Password
                    </MenuItem>
                  }
                </Menu>
              </Box>
            </div>
          </div>
          <div className="bottom">
            <Tabs
              className="custom-tabs"
              value={tabValue}
              onChange={handleTabChange}
              style={{ borderBottom: '0.5px solid #EBEFF2' }}
              aria-label="basic tabs example"
            >
              <Tab label="Overview" value={0} />
              <Tab label="Permissions" value={1} />
              <Tab label="Activity Log" value={2} />
            </Tabs>
          </div>
        </div>
        <div className="bottom" style={{ overflowY: 'auto', height: '60vh', overflowX: 'hidden' }}>
          {tabValue === 0 && (
            <div className="overview-area" style={{ height: '100%' }}>
              <AdminInfo profile={profile} data={user} title="Personal Information" type="overview" />
            </div>
          )}
          {tabValue === 1 && (
            <div className="scores-area" style={{ height: '100%', marginTop: '24px' }}>
              <AdminPermissions permissions={role.permissions} />
            </div>
          )}
          {tabValue === 2 && (
            <div className="scores-area" style={{ height: '100%', marginTop: '24px' }}>
              <AdminActivityLog />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

AdminDetails.propTypes = {
  selectedApplicant: propTypes.number,
};

export default AdminDetails;
