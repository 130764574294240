/*eslint-disable*/
import React, { useContext } from 'react';
import { DashboardComponent, TrainingDashboardRoot } from './components/styled/trainingdashboard.styledcomponents';
import PieChart from './components/PieChart';
import MapComponent from './components/MapComponent';
import { useGetTrainingDashboard } from './components/services/TrainingDashboard.service';
import HorizontalBarChart from './components/HorizontalBarChart';
import TrainingStageSwitcher from './components/TrainingStageSwitcher';

const TrainingDashboard = () => {
  const {
    ageDistributionChartData,
    disabilityChartData,
    genderChartData,
    educationalLevelChartData,
    sectorChartData,
    trainingStage,
    setTrainingStage,
    regionalDistributionData,
  } = useGetTrainingDashboard();

  return (
    <TrainingDashboardRoot container columnSpacing={2}>
      <DashboardComponent item xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <TrainingStageSwitcher setStage={setTrainingStage} currentStage={trainingStage} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={4}>
        <PieChart title={genderChartData.title} data={genderChartData?.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={4}>
        <MapComponent data={regionalDistributionData.data} title="Training Regional Distribution" />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={4}>
        <PieChart title={disabilityChartData.title} data={disabilityChartData?.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={12}>
        <HorizontalBarChart title={educationalLevelChartData.title} data={educationalLevelChartData.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={4}>
        <PieChart title={ageDistributionChartData.title} data={ageDistributionChartData?.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={8}>
        <HorizontalBarChart title={'Trainings By Business Sector'} data={sectorChartData?.data} />
      </DashboardComponent>
    </TrainingDashboardRoot>
  );
};

export default TrainingDashboard;
