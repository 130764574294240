/*eslint-disable*/
import React from 'react';
import SearchInput from '@components/SearchInput';
import { Box, Button } from '@mui/material';

interface ISearchComponent {
  onTextChange: (text: string) => void;
  onActionButtonPress: () => void;
  handleTemplateDownload?: () => void;
  type: 'list' | 'preview';
  actionDisabled?: boolean;
  buttonDescription?: string;
}

const SearchComponent = (props: ISearchComponent) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid rgb(228, 228, 228);',
        marginTop: '20px',
        paddingTop: '20px',
      }}
    >
      <Box sx={{ width: '400px' }}>
        <SearchInput setSearchQuery={props.onTextChange} />
      </Box>
      <Box></Box>

      <Box my={'0.5rem'} sx={{ order: '1', marginLeft: 'auto', display: 'flex' }}>
        <Button
          sx={{
            '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
            fontWeight: '400',
            fontFamily: 'inherit !important',
            fontSize: '0.85rem',
            color: 'white',
            backgroundColor: !props.actionDisabled ? '#2a3b8f' : '#b6b6b6',
            textTransform: 'initial !important',
            padding: '0.7rem',
            paddingLeft: '2rem',
            paddingRight: '2rem',
          }}
          disabled={props.actionDisabled}
          onClick={() => {
            props.onActionButtonPress();
          }}
        >
          {props.buttonDescription}
        </Button>
        <Button
          onClick={() => {
            props?.handleTemplateDownload();
          }}
          style={{ marginLeft: '15px', textTransform: 'initial', fontFamily: 'inherit' }}
        >
          Download Template
        </Button>
      </Box>
    </Box>
  );
};

export default SearchComponent;
