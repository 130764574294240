/*eslint-disable*/
import React from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const MenuComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonStyle = {
    "&:hover": { color: "#2a3b8f", border: "1px solid #2a3b8f" },
    fontWeight: "400",
    fontFamily: "inherit !important",
    fontSize: "0.85rem",
    color: "white",
    backgroundColor: "#2a3b8f",
    textTransform: "initial !important",
    padding: "0.4rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  };

  const menuItemStyle = {
    fontFamily: "inherit !important",
    padding: "0.5rem",
    fontSize: "0.8rem",
  };
  return (
    <Box>
      <Button sx={buttonStyle} onClick={handleClick}>
        More Options
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "middle", vertical: "bottom" }}
        open={menuOpen}
        onClose={handleClose}
        PaperProps={{
          style: { width: "25ch" },
        }}
      >
        <MenuItem key={1} sx={menuItemStyle}>
          Edit Applicant
        </MenuItem>
        <MenuItem key={2} sx={menuItemStyle}>
          Delete Applicant
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default MenuComponent;
