/*eslint-disable*/
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { IApplicantDataObject } from '../interfaces/applicantDetails.model';
import {
  IDefaultPerformanceIndicator,
  IGetDefaultIndicatorState,
  IGetOtherIndicators,
} from '../interfaces/ManualScoring.interfaces';
import React from 'react';
import { Box } from '@mui/material';

const tableBodyHeight = `500px`;
const tableBodyMaxHeight = '';

//** exports */
export const ActivityTableColumns = [
  'Activity',
  'Detailed Output and Activity Description',
  'Start Month',
  'End Month',
  'Year',
];

export const IndicatorTableColumns = ['Name of Indicator', 'Pre-Grant State', 'Post-Grant State'];

export const GrantExpenditureTableColumns: MUIDataTableColumnDef[] = [
  'Item',
  'Quantity',
  'Unit Cost',
  'Amount',
  {
    name: 'Invoices',
    options: {
      customBodyRender: (value: any) => {
        return (
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            {value?.map((value: any, index: number) => {
              return (
                <a href={value} target="_blank">
                  {`Invoice `}
                  {index + 1}
                </a>
              );
            })}
          </Box>
        );
        // return <>{value?.[0]}</>;
      },
    },
  },
];
export const ExpectedResultColumns: string[] = [
  'Performance Indicators',
  'Before grant support',
  'After grant support',
];
export const FiscalProjectionTableColumns = ['Projection', 'Year 1', 'Year 2', 'Year 3'];
export const FormTableInputOptions: MUIDataTableOptions = {
  print: false,
  selectableRowsHideCheckboxes: true,
  search: true,
  viewColumns: false,
  download: false,
  filter: false,
  filterType: 'dropdown',
  responsive: 'vertical',
  rowsPerPage: 10,
  tableBodyHeight,
  tableBodyMaxHeight,
  sortOrder: {
    name: 'Role',
    direction: 'asc',
  },
};

export const DEFAULT_KEYS = [
  'sales_default',
  'employees_default',
  'production_default',
  'exports_default',
  'volume_default',
  'environmental_impact_default',
  'social_impact_default',
];

export const DefaultPerformanceIndicatorsFieldState: Partial<IDefaultPerformanceIndicator>[] = [
  {
    title: 'Sales',
    name_of_indicator: 'sales_default',
    pre_grant_state: 'sales_default_pre',
    post_grant_state: 'sales_default_post',
    details_of_benefits: 'sales_default_explanation',
    type: 'number',
    placeholder: 'Sales (GHC)',
  },
  {
    title: 'Employees',
    name_of_indicator: 'employees_default',
    pre_grant_state: 'employees_default_pre',
    post_grant_state: 'employees_default_post',
    details_of_benefits: 'employees_default_explanation',
    type: 'number',
    placeholder: 'Number of Employees',
  },
  // {
  //   title: 'Production',
  //   name_of_indicator: 'production_default',
  //   pre_grant_state: 'production_default_pre',
  //   post_grant_state: 'production_default_post',
  //   details_of_benefits: 'production_default_explanation',
  // },
  {
    title: 'Exports (if applicable)',
    name_of_indicator: 'exports_default',
    pre_grant_state: 'exports_default_pre',
    post_grant_state: 'exports_default_post',
    details_of_benefits: 'exports_default_explanation',
    type: 'number',
    placeholder: 'Number of Exports',
  },
  {
    title: 'Volume of Production',
    name_of_indicator: 'volume_default',
    pre_grant_state: 'volume_default_pre',
    post_grant_state: 'volume_default_post',
    details_of_benefits: 'volume_default_explanation',
    type: 'number',
    placeholder: 'Volume of Production',
  },
  {
    title: 'Environmental Impact',
    name_of_indicator: 'environmental_impact_default',
    pre_grant_state: 'environmental_impact_default_pre',
    post_grant_state: 'environmental_impact_default_post',
    details_of_benefits: 'environmental_impact_default_explanation',
    type: 'alphanumeric',
    placeholder: 'Environmental Impact',
  },
  {
    title: 'Social Impact',
    name_of_indicator: 'social_impact_default',
    pre_grant_state: 'social_impact_default_pre',
    post_grant_state: 'social_impact_default_post',
    details_of_benefits: 'social_impact_default_explanation',
    type: 'alphanumeric',
    placeholder: 'Social Impact',
  },
];

export const getDefaultIndicatorState = ({ keyname, indicatorArray }: IGetDefaultIndicatorState) => {
  let defaultIndicatorState = indicatorArray?.filter((indicator) => {
    return indicator.name_of_indicator == keyname;
  })[0];

  return defaultIndicatorState;
};

export const getOtherIndicators = ({
  indicatorArray,
}: IGetOtherIndicators): IApplicantDataObject['profile'][0]['grant_information']['performance_indicators'] => {
  return indicatorArray?.filter((indicator) => {
    return !DEFAULT_KEYS.includes(indicator?.name_of_indicator || '');
  });
};

//** exports */
