/*eslint-disable*/
import React from 'react';
import propTypes from 'prop-types';
import ApplicantSidebarNavitem from './ApplicantSidebarNavitem';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import {
  MdDashboard,
  MdPeopleAlt,
  MdPhone,
  MdMail,
  MdSettings,
  MdLogout,
  MdClose,
  MdSupervisedUserCircle,
  MdMenu,
  MdCreditScore,
  MdAttachMoney,
} from 'react-icons/md';
import Logo from '@utils/Logo';
import { useNavigate } from 'react-router-dom';
import ApplicantDrawer from './ApplicantDrawer';
import useGetUserData from '@utils/hooks/useGetUserData';
import { checkPermissions, logout } from '@service/RequireAuth';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import DisbursalMenu from './Submenus/DisbursalMenu';
import ShowPermissions from '../Users/ShowPermissions';
import AssessmentMenu from './Submenus/AssessmentMenu';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';

const ApplicantSidebar = ({ selectedView = 1 }) => {
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const userData = JSON.parse(window.localStorage.getItem('user'));
  const style = {
    background: '#2A3B8F',
    padding: '1rem',
    // minHeight: "100%",
    zIndex: '10000000000',
    cursor: 'pointer',
    width: '35px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
  const [reportsAnchor, setReportsAnchor] = React.useState(null);
  const [anchor, setAnchor] = React.useState(null);
  const [prefMenuAnchor, setPrefMenuAnchor] = React.useState(null);
  const [disbursalMenuAnchor, setDisbursalMenuAnchor] = React.useState(null);
  const [assessmentMenuAnchor, setAssessmentMenuAnchor] = React.useState(null);
  const prefMenuOpen = Boolean(prefMenuAnchor);
  const open = Boolean(anchor);
  const reportsOpen = Boolean(reportsAnchor);

  const handleReportsMenuSelect = (event) => {
    if (reportsOpen) {
      setReportsAnchor(null);
    } else setReportsAnchor(event.currentTarget);
  };

  const handleMenuSelect = (event) => {
    if (open) {
      handleClose();
    } else setAnchor(event.currentTarget);
  };
  const handlePrefMenuSelect = (event) => {
    if (prefMenuOpen) {
      setPrefMenuAnchor(null);
    } else {
      setPrefMenuAnchor(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <>
      <DisbursalMenu menuAnchor={disbursalMenuAnchor} setMenuAnchor={setDisbursalMenuAnchor} />
      <AssessmentMenu menuAnchor={assessmentMenuAnchor} setMenuAnchor={setAssessmentMenuAnchor} />

      <Menu
        id="basic-menu"
        anchorEl={reportsAnchor}
        open={reportsOpen}
        onClose={() => {
          setReportsAnchor(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            color: '#2A3B8F',
            backgroundColor: 'white',
            paddingLeft: '0.5rem',
            boxShadow: 1,
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          sx={{ fontFamily: 'inherit!important' }}
          onClick={() => {
            navigate('/reports/dataAnalysis');
          }}
        >
          Data Analysis
        </MenuItem>
        <MenuItem
          sx={{ fontFamily: 'inherit!important' }}
          onClick={() => {
            navigate('/reports/detailedReports');
          }}
        >
          Detailed Reports
        </MenuItem>
        <MenuItem
          sx={{
            fontFamily: 'inherit!important',
            color: 'red',
            display: 'flex',
          }}
          onClick={() => {
            setReportsAnchor(null);
          }}
        >
          <Box
            sx={{
              fontFamily: 'inherit!important',
              color: 'red',
              display: 'flex',
              fontSize: '1rem',
            }}
          >
            Close Menu
          </Box>
          <Box sx={{ order: '1', marginLeft: 'auto' }}>
            <CloseIcon sx={{ color: 'red', marginLeft: '1rem' }} />
          </Box>
        </MenuItem>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            color: '#2A3B8F',
            backgroundColor: 'white',
            paddingLeft: '0.5rem',
            boxShadow: 1,
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {checkPermissions('applicant approvals', userData) == true && (
          <MenuItem
            sx={{ fontFamily: 'inherit!important' }}
            onClick={() => {
              navigate('/applicant/approvals');
            }}
          >
            Application Approvals
          </MenuItem>
        )}
        {checkPermissions('interview approvals', userData) == true && (
          <MenuItem
            sx={{ fontFamily: 'inherit!important' }}
            onClick={() => {
              navigate('/interview/approvals');
            }}
          >
            Interview/Screening Approvals
          </MenuItem>
        )}

        {checkPermissions('training approvals', userData) == true && (
          <MenuItem
            sx={{ fontFamily: 'inherit!important' }}
            onClick={() => {
              navigate('/training/advancedTraining/basic');
            }}
          >
            Training Approvals
          </MenuItem>
        )}

        <ShowPermissions shouldShow={checkPermissions('grant approvals', userData)}>
          <MenuItem
            sx={{ fontFamily: 'inherit!important' }}
            onClick={() => {
              navigate('/grant/approvals/regular');
            }}
          >
            Grant Approvals
          </MenuItem>
        </ShowPermissions>

        <MenuItem
          sx={{
            fontFamily: 'inherit!important',
            color: 'red',
            display: 'flex',
          }}
          onClick={() => {
            handleClose();
          }}
        >
          <Box
            sx={{
              fontFamily: 'inherit!important',
              color: 'red',
              display: 'flex',
              fontSize: '1rem',
            }}
          >
            Close Menu
          </Box>
          <Box sx={{ order: '1', marginLeft: 'auto' }}>
            <CloseIcon sx={{ color: 'red', marginLeft: '1rem' }} />
          </Box>
        </MenuItem>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={prefMenuAnchor}
        open={prefMenuOpen}
        onClose={() => {
          setPrefMenuAnchor(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            color: '#2A3B8F',
            backgroundColor: 'white',
            paddingLeft: '0.5rem',
            boxShadow: 1,
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          sx={{ fontFamily: 'inherit!important' }}
          onClick={() => {
            navigate('/settings/roles');
          }}
        >
          User Management
        </MenuItem>
        <MenuItem
          sx={{ fontFamily: 'inherit!important' }}
          onClick={() => {
            navigate('/settings/automaticScoring');
          }}
        >
          Settings
        </MenuItem>
        <MenuItem
          sx={{ fontFamily: 'inherit!important' }}
          onClick={() => {
            navigate('/settings/auditTrail');
          }}
        >
          Audit Trail
        </MenuItem>
        <MenuItem
          sx={{
            fontFamily: 'inherit!important',
            color: 'red',
            display: 'flex',
          }}
          onClick={() => {
            setPrefMenuAnchor(null);
          }}
        >
          <Box
            sx={{
              fontFamily: 'inherit!important',
              color: 'red',
              display: 'flex',
              fontSize: '1rem',
            }}
          >
            Close Menu
          </Box>
          <Box sx={{ order: '1', marginLeft: 'auto' }}>
            <CloseIcon sx={{ color: 'red', marginLeft: '1rem' }} />
          </Box>
        </MenuItem>
      </Menu>
      <div style={style}>
        <div>
          <div className="applicant-logo top" style={{ marginLeft: '-0.7rem' }}>
            <Logo size="small" />
          </div>
          <div className="applicant-sidebar bottom">
            <div className="mt-5 flex flex-c" style={{ alignItems: 'center', marginTop: '2rem' }}>
              {userData !== undefined && (
                <>
                  <ApplicantSidebarNavitem
                    onClick={() => {
                      setDrawerOpen(!drawerOpen);
                    }}
                    selectedView={selectedView}
                    view={100}
                    icon={drawerOpen ? <MdClose /> : <MdMenu />}
                    title={drawerOpen ? 'Close Sidebar' : 'Expand Sidebar'}
                  />
                  {(checkPermissions('registrations', userData) == true ||
                    checkPermissions('applications', userData) == true) && (
                    <>
                      <ApplicantSidebarNavitem
                        onClick={() => {
                          navigate('/dashboard');
                        }}
                        selectedView={selectedView}
                        view={0}
                        icon={<MdDashboard />}
                        title={'Dashboard'}
                      />
                    </>
                  )}

                  <ApplicantSidebarNavitem
                    onClick={() => {
                      navigate('/applicants');
                    }}
                    selectedView={selectedView}
                    view={1}
                    icon={<MdPeopleAlt />}
                    title={'Applicants'}
                  />

                  {(checkPermissions('applicant approvals', userData) == true ||
                    checkPermissions('training approvals', userData) == true ||
                    checkPermissions('interview approvals', userData) == true) && (
                    <>
                      <ApplicantSidebarNavitem
                        onClick={handleMenuSelect}
                        selectedView={selectedView}
                        view={2}
                        icon={<AssignmentTurnedInIcon />}
                        title={'Approvals'}
                      />
                    </>
                  )}

                  <ShowPermissions shouldShow={checkPermissions('view grant assessment', userData)}>
                    <ApplicantSidebarNavitem
                      onClick={(e) => {
                        setAssessmentMenuAnchor(e.target);
                      }}
                      title={'Grant Assessment'}
                      view={6}
                      icon={<MdCreditScore />}
                      selectedView={selectedView}
                    />
                  </ShowPermissions>

                  <ShowPermissions
                    shouldShow={
                      checkPermissions('pre disbursement', userData) && checkPermissions('post disbursement', userData)
                    }
                  >
                    <ApplicantSidebarNavitem
                      onClick={(event) => {
                        setDisbursalMenuAnchor(event.target);
                      }}
                      selectedView={selectedView}
                      view={7}
                      icon={<MdAttachMoney />}
                      title={'Disbursement'}
                    />
                  </ShowPermissions>

                    <ApplicantSidebarNavitem
                        onClick={() => {
                          navigate('/monitoring');
                        }}
                        selectedView={selectedView}
                        view={8}
                        icon={<MonitorHeartIcon/>}
                        title={'Monitoring'}
                      />

                    <ShowPermissions
                    shouldShow={
                      userData?.role?.name == 'super_admin' || userData?.user_type == "super_admin" || userData?.role?.name == 'super admin' 
                    }
                  >
                     <ApplicantSidebarNavitem
                        onClick={handlePrefMenuSelect}
                        selectedView={selectedView}
                        view={3}
                        icon={<MdSettings />}
                        title={'Preferences'}
                      />
                  </ShowPermissions>

                  {/* {userData?.staff_type == 'super_admin' && (
                    <>
                      <ApplicantSidebarNavitem
                        onClick={handlePrefMenuSelect}
                        selectedView={selectedView}
                        view={3}
                        icon={<MdSettings />}
                        title={'Preferences'}
                      />
                    </>
                  )} */}
                  <ShowPermissions
                    shouldShow={
                      checkPermissions('data analysis', userData) || checkPermissions('detailed report', userData)
                    }
                  >
                    <ApplicantSidebarNavitem
                      onClick={handleReportsMenuSelect}
                      selectedView={selectedView}
                      view={4}
                      icon={<ShowChartIcon />}
                      title={'Reports'}
                    />
                  </ShowPermissions>
                </>
              )}
            </div>
          </div>
        </div>

        <div>
          <div
            className="mt-5 flex flex-c"
            style={{
              alignItems: 'center',
              marginTop: '2rem',
              marginBottom: '2rem',
            }}
          >
            <ApplicantSidebarNavitem
              onClick={() => {
                navigate('/profile');
              }}
              selectedView={selectedView}
              view={5}
              icon={<MdSupervisedUserCircle />}
              title={'Settings'}
            />
            <ApplicantSidebarNavitem selectedView={selectedView} view={undefined} icon={<MdPhone />} title={'Phone'} />
            <ApplicantSidebarNavitem selectedView={selectedView} view={undefined} icon={<MdMail />} title={'Email'} />
            <hr style={{ width: '100%', margin: 0 }} />

            <ApplicantSidebarNavitem
              onClick={() => {
                logout();
                navigate('/');
              }}
              selectedView={selectedView}
              view={undefined}
              icon={<MdLogout />}
              title={'Logout'}
            />
          </div>
        </div>
      </div>
      <ApplicantDrawer userData={userData} open={drawerOpen} onclose={setDrawerOpen} />
    </>
  );
};

ApplicantSidebar.propTypes = {
  selectedView: propTypes.number,
};
export default ApplicantSidebar;
