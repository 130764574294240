/*eslint-disable*/
import React from "react";
import GenderDistribution from "./Cards/GenderDistribution";
import propTypes from "prop-types";
import ApplicationValue from "./Cards/ApplicationValue";
import ApplicantRegionalDistribution from "./Cards/ApplicantRegionalDistribution";
import ApplicationsBySector from "./Cards/ApplicationsBySector";
import { DashboardContext } from "./DashboardContent";

const ChartRow2 = () => {
  const style = {
    display: "flex",
    // width:"100%",
  };
  const { tabValue, params } = React.useContext(DashboardContext);
  return (
    <div className="chart-row" style={style}>
      {tabValue == 1 && (
        <div style={{ display: "flex", width: "100%" }}>
          <ApplicantRegionalDistribution />
          <ApplicationsBySector />
        </div>
      )}
      {tabValue == 0 && (
        <div className="left" style={{ width: "100%", display: "flex" }}>
          {params?.type === "Registrations" && (
            <>
              <ApplicationValue />
              <ApplicantRegionalDistribution />
            </>
          )}
          {(params?.type === "Applications" || params?.type === "") && (
            <GenderDistribution />
          )}
        </div>
      )}
    </div>
  );
};

ChartRow2.propTypes = {
  userData: propTypes.object,
  params: propTypes.object,
  selectedRegion: propTypes.string,
  regions: propTypes.array,
  setSelectedRegion: propTypes.func,
  setParams: propTypes.func,
  "": propTypes.array,
  hasRegionVal: propTypes.bool,
  infoType: propTypes.string,
};

export default ChartRow2;
