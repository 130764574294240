/*eslint-disable*/
import axios from 'axios';
import apiConfig from './apiConfig';

export interface IGetDashboardData {
  training_level?: string;
  skipped_advanced_training?: boolean;
  disbursement_stage?: string;
}

export async function getDashboardData(data: IGetDashboardData) {

    try{
  const keys: Array<keyof IGetDashboardData> = Object.keys({
    training_level: undefined,
    skipped_advanced_training: undefined,
    disbursement_stage: undefined,
  } as IGetDashboardData) as Array<keyof IGetDashboardData>;
  let queryString = '';

  keys.forEach((key, index) => {
    if (data[key] !== undefined) {
      queryString = queryString + `&${key}=${data[key]}`;
    }
  });

  queryString = queryString.slice(1);

  return apiConfig.getRequest(`staff/dashboard/dashboard_metrics?${queryString}`);
    }
    catch(e){
        console.log(e)
          return apiConfig.getRequest(`staff/dashboard/dashboard_metrics`);

    }

}
