import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import propTypes from "prop-types";
import SemiCircle from "./SemiCircle";

export default function SemiCircleProgressBar({ color, percentage }) {
  const value = percentage || 0;

  return (
    <div
      style={{
        position: "relative",
        width: "80%",
        marginTop: "24px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <SemiCircle color={color} />
      <CircularProgressbar
        value={value}
        strokeWidth={12}
        background={false}
        circleRatio={0.5}
        dx={10}
        text={
          <tspan style={{ fontWeight: "bold" }} dy={-10} dx={-7}>{`${
            percentage || 0
          }%`}</tspan>
        }
        styles={{
          // Customize the root svg element
          root: {},
          // Customize the path, i.e. the "completed progress"
          path: {
            // Path color
            stroke: `${color}`,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",
            // Customize transition animation
            transition: "stroke-dashoffset 0.5s ease 0s",
            // Rotate the path
            transform: "rotate(0.75turn)",
            transformOrigin: "center center",
          },
          // Customize the circle behind the path, i.e. the "total progress"
          trail: {
            // Trail color
            stroke: `${color}5E`,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",
            // Rotate the trail
            transform: "rotate(0.75turn)",
            transformOrigin: "center center",
          },
          // Customize the text
          text: {
            // Text color
            fill: "#000",
            // Text size
            fontSize: "8px",
          },
          // Customize background - only used when the `background` prop is true
          background: {
            fill: `${color}0D`,
          },
        }}
      />
    </div>
  );
}

SemiCircleProgressBar.propTypes = {
  color: propTypes.string,
  percentage: propTypes.string,
};
