/*eslint-disable*/
import React from 'react';
import {
  GrantDetailsRoot,
  GrantDetailsHeader,
  GrantDetailsHeaderContainer,
} from './components/GrantDetails/GrantDetails.components';
import GrantDetailsTop from './components/GrantDetails/GrantDetailsTop';
import { GrantDetailsContext } from '@/utils/context/GrantDetailsContext';
import { useGetGrantDetails } from './services/GrantDetails/GrantDetails.service';
import GrantDetailsBottom from './components/GrantDetails/GrantDetailsBottom';

const GrantDetails = () => {
  const { tabValue, setTabValue } = useGetGrantDetails();
  return (
    <GrantDetailsContext.Provider value={{ tabValue, setTabValue }}>
      <GrantDetailsRoot>
        <GrantDetailsTop />
        <GrantDetailsBottom />
      </GrantDetailsRoot>
    </GrantDetailsContext.Provider>
  );
};

export default GrantDetails;
