/*eslint-disable*/
import MultiSelectButton from '@/components/Buttons/MultiSelectButton';
import SwitchComponent from '@/components/Switch/SwitchComponent';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { IDetailedReportsParams } from '@/utils/interfaces/reports.interfaces';
import { Box, Tabs, Tab } from '@mui/material';
import React from 'react';
import moment from 'moment';
import { defaultActiveColor } from '@/utils/constants';

interface ITabNavigation {
  disabled: boolean;
  tabValue: number;
  multiSelectValue: string;
  showDisqualified: boolean;
  dateState: any;
  dateRangeStyle: any;
  params: Partial<IDetailedReportsParams>;
  setDateModalOpen: any;
  text:string;
  setParams: (e: Partial<IDetailedReportsParams>) => void;
  handleDisqualifiedToggle: (e: boolean) => void;
  handleMultiSelectChange: (e: string) => void;
  handleTabChange: (e: any, value: number) => void;
}

const TabNavigation = ({
  disabled,
  tabValue,
  multiSelectValue,
  showDisqualified,
  params,
  dateState,
  dateRangeStyle,
  setDateModalOpen,
  text,
  setParams,
  handleDisqualifiedToggle,
  handleMultiSelectChange,
  handleTabChange,
}: ITabNavigation) => {
  const [active, setActive] = React.useState(false);
  const labels = [
    { label: 'Basic', value: 'approved_application_for_basic_training' },
    { label: 'Intermediate', value: 'approved_application_for_intermediate_training' },
    { label: 'Advanced', value: 'approved_application_for_advanced_training' },
  ];

  const grantLabels = [
    { label: 'Skipped Advanced Training', value: true },
    { label: 'Advanced Training To Grant', value: false },
  ];

  const disbursementLabels = [
    { label: 'Pre Disbursement', value: 'approved_application_grant_pre_disbursement' },
    { label: 'Post Disbursement', value: 'approved_application_grant_post_disbursement' },
  ];

  const monitoringEvaluationLabels = [
    {label:'Grant Beneficiaries',value:'received_grant'},
    {label:'Non-Grant Beneficiaries',value:'received_training'}
  ]
  return (
    <>
      <Box style={{ padding: '2rem' }}>
        <Box
          marginBottom={'2.5rem'}
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={2}
          flex={1}
        >
          {[
            'application',
            'approved_application_for_basic_training',
            'approved_application_for_intermediate_training',
            'approved_application_for_advanced_training',
            'approved_application_grant_pre_disbursement',
          ].indexOf(params.application_process_status) > -1 && text !== 'Total Applicants' && tabValue!==11 && (
            <>
              <Box fontWeight={'600'} fontSize={'14px'}>
                {showDisqualified ? 'Hide' : 'Show'} Disqualified Applicants
              </Box>
              <SwitchComponent disabled={disabled} active={showDisqualified==true} setActive={handleDisqualifiedToggle} />
            </>
          )}
        </Box>

        <Tabs className="custom-tabs" value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Registration" value={0} disabled={disabled} />
          <Tab label="Applications" value={1} disabled={disabled} />
          <Tab label="Rejected Applications" value={2} disabled={disabled} />
          <Tab label="Training" value={3} disabled={disabled} />
          <Tab label="Grants" value={4} disabled={disabled} />
          <Tab label="Grant Disbursement" value={5} disabled={disabled} />
          <Tab label="Grant Asessment Scores" value={10} disabled={disabled} />
          <Tab label="Monitoring and Evaluation" value={11} disabled={disabled} />
        </Tabs>
      </Box>
      {[
        'approved_application_for_basic_training',
        'approved_application_for_intermediate_training',
        'approved_application_for_advanced_training',
        'approved_application_grant_pre_disbursement',
      ].indexOf(params.application_process_status) > -1 && (
        <>
          {' '}
          {showDisqualified ? (
            <Box color={'red'} mb={4} paddingLeft={'2rem'} paddingRight={'2rem'} fontSize={'16px'} fontWeight={'600'}>
              Disqualified Applicants
            </Box>
          ) : (
            <Box
              color={defaultActiveColor}
              mb={4}
              paddingLeft={'2rem'}
              paddingRight={'2rem'}
              fontSize={'16px'}
              fontWeight={'600'}
            >
              Passed Applicants
            </Box>
          )}
        </>
      )}

      <Box
        display={'flex'}
        flex={1}
        justifyContent={'space-between'}
        alignItems={'space-between'}
        paddingLeft={'2rem'}
        paddingRight={'2rem'}
      >
        {[3, 6, 7].includes(tabValue) && (
          <Box paddingTop={'0rem'}>
            <MultiSelectButton
              onChange={(e) => {
                handleMultiSelectChange(e as string);
              }}
              selected={multiSelectValue}
              labels={labels}
              disabled={disabled}
            />
          </Box>
        )}

        {[4, 8].includes(tabValue) && (
          <Box paddingTop={'0rem'}>
            <MultiSelectButton
              onChange={(e) => {
                setParams({
                  ...params,
                  application_process_status: 'approved_application_grant',
                  skipped_advanced_training: e as boolean,
                });
              }}
              selected={params.skipped_advanced_training}
              labels={grantLabels}
              disabled={disabled}
            />
          </Box>
        )}

        {[5, 9].includes(tabValue) && (
          <Box padding={'0rem'}>
            <MultiSelectButton
              onChange={(e) => {
                setParams({
                  ...params,
                  application_process_status: e as string,
                  skipped_advanced_training: false,
                  show_disqualified: false,
                });
              }}
              selected={params.application_process_status}
              labels={disbursementLabels}
              disabled={disabled}
            />
          </Box>
        )}
        {
          [11,12].includes(tabValue) && (
            <Box padding={'0rem'}>
              <MultiSelectButton
                onChange={(e) => {
                  if (e === 'received_grant') {
                    setParams({
                      ...params,
                      application_process_status: 'application',
                      receivedGrant:true,
                      receivedTraining:false,
                      show_disqualified: false,
                    });
                    return;
                  }
                  if (e==='received_training') { 
                    setParams({
                      ...params,
                      application_process_status: 'application',
                      receivedGrant:false,
                      receivedTraining:true,
                      show_disqualified: false,
                    });
                    return;
                  }
                 
                }}
                selected={params.receivedGrant ? 'received_grant' : 'received_training'}
                labels={monitoringEvaluationLabels}
                disabled={disabled}
              />
            </Box>
          )
        }

        <Box sx={{ marginRight: '1rem' }}>
          <button
            style={dateRangeStyle}
            onClick={() => {
              setDateModalOpen(true);
            }}
          >
            <Box>
              <DateRangeIcon style={{ color: 'black' }} />
            </Box>
            <Box
              style={{
                color: 'black',
                fontSize: '0.9rem',
                fontWeight: '600',
              }}
            >
              {`${moment(dateState[0].startDate).format('MMMM Do YYYY')}
                        - ${moment(dateState[0].endDate).format('MMMM Do YYYY')}`}
            </Box>
          </button>
        </Box>
      </Box>
    </>
  );
};

export default TabNavigation;
