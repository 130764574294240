import { GrantDetailsContext } from '@/utils/context/GrantDetailsContext';
import React from 'react';
import { GrantDetailsBottomWrapper } from './GrantDetails.components';
import GrantOverview from './GrantOverview';
import ManualScoring from './ManualScoring';

const GrantDetailsBottom = () => {
  const { tabValue } = React.useContext(GrantDetailsContext);
  return (
    <GrantDetailsBottomWrapper>
      {tabValue == 0 && <GrantOverview />}
      {tabValue == 1 && <ManualScoring />}
    </GrantDetailsBottomWrapper>
  );
};

export default GrantDetailsBottom;
