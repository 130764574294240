/*eslint-disable*/
import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const GrantDetailsTopLoader = (props: IContentLoaderProps) => (
  <ContentLoader viewBox="0 0 1000 160" height={300} width={1000} {...props}>
    <rect x="110" y="21" rx="4" ry="4" width="1000" height="20" />
    <rect x="111" y="41" rx="3" ry="3" width="1000" height="20" />
    <rect x="304" y="-46" rx="3" ry="3" width="1000" height="20" />
    <rect x="371" y="-45" rx="3" ry="3" width="1000" height="20" />
    <rect x="484" y="-45" rx="3" ry="3" width="1000" height="20" />
    <circle cx="48" cy="48" r="48" />
  </ContentLoader>
);

GrantDetailsTopLoader.metadata = {
  name: 'Akash Bambhaniya',
  github: 'Akashnb', // Github username
  description: 'Avatar With Text (circle)',
  filename: 'AvatarWithText', // filename of your loader
};

export default GrantDetailsTopLoader;
