/*eslint-disable*/
import React from "react";
import { Box } from "@mui/material";
// import ApplicationValueBySector from './Cards/ApplicationValueBySector';
import ApplicationAgeDistribution from "./Cards/ApplicationAgeDistribution";
import VocationalChart from "./Cards/VocationalChart";
import propTypes from "prop-types";

const ChartRow3 = () => {
  const style = {
    display: "flex",
    // width:"100%",
  };
  return (
    <Box style={style}>
      {/* <ApplicationValueBySector userData={userData}/> */}
      <VocationalChart />
    </Box>
  );
};

ChartRow3.propTypes = {
  userData: propTypes.object,
};

export default ChartRow3;
