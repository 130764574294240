/*eslint-disable*/
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { IGenerateCSV } from '../interfaces/DisbursementTable.interface';
import { PostDisbursementContext, PredisbursementContext } from '@/utils/context/DisbursementContext';
import { parseIdFromProjectId } from '@/utils/helpers/functions';

export const useGetPredisbursementTableDataPrint = (data: AxiosResponse<IApprovalsList, any>) => {
  const tableData: any[][] = [];
  let count = 0;

  data?.data?.data?.forEach((applicant) => {
    let { project_application_code, user, profile, approvals } = applicant;
    let finalApproval = approvals[approvals.length - 1];
    let { bank_name, account_number, branch_name, date_of_payment, disbursed_amount } = finalApproval;

    let tableRow = [
      project_application_code,
      account_number || profile?.grant_information?.bank?.bank_account_number || 'N/A',
      bank_name || profile?.grant_information?.bank?.bank_name || 'N/A',
      branch_name || profile?.grant_information?.bank?.bank_branch || 'N/A',
      (date_of_payment ? moment(date_of_payment).format('YYYY-MM-DD') : null) || moment().format('YYYY-MM-DD'),
      0,
      project_application_code,
    ];
    tableData.push(tableRow);
  });
  count = data?.data?.count;
  return { tableData, count };
};

export const useGetPredisbursementTableData = (data: AxiosResponse<IApprovalsList, any>) => {
  const tableData: any[][] = [];
  let count = 0;

  data?.data?.data?.forEach((applicant) => {
    let { project_application_code, user, profile, grant_score } = applicant as Partial<IApprovalsListData>;
    let tableRow = [
      project_application_code || 'N/A',
      `${user?.first_name} ${user.last_name}` || 'N/A',
      profile?.grant_information?.enterprise_name || 'N/A',
      // profile?.grant_information?.industry?.name || 'N/A',
      // '' || 'N/A',
      profile?.region?.name || 'N/A',
      profile?.district?.name || 'N/A',
      user?.phone_number || 'N/A',
      profile?.grant_information?.business_plan?.total_funding_requested || 'N/A',
      grant_score.total_application_score || 'N/A',
      project_application_code || 'N/A',
    ];
    tableData.push(tableRow);
  });
  count = data?.data?.count;
  return { tableData, count };
};

export const useGetPostdisbursementTableData = (data: AxiosResponse<IApprovalsList, any>) => {
  const tableData: any[][] = [];
  let count = 0;

  data?.data?.data?.forEach((applicant) => {
    let { project_application_code, user, profile, approvals } = applicant;
    let finalApproval = approvals[approvals.length - 1];
    let { bank_name, account_number, branch_name, date_of_payment, disbursed_amount } = finalApproval;

    let tableRow = [
      project_application_code,
      account_number || profile?.grant_information?.bank?.bank_account_number || 'N/A',
      bank_name || profile?.grant_information?.bank?.bank_name || 'N/A',
      branch_name || profile?.grant_information?.bank?.bank_branch || 'N/A',
      (date_of_payment ? moment(date_of_payment).format('YYYY-MM-DD') : null) || moment().format('YYYY-MM-DD'),
      disbursed_amount||0,
      project_application_code,
    ];
    tableData.push(tableRow);
  });
  count = data?.data?.count;
  return { tableData, count };
};

export const useGeneratePreDisbursementCSVPrint = ({ rows, columns, type, data }: IGenerateCSV) => {
  try {
    let header = columns;
    let csvArray = [];
    csvArray.push(header);
    rows.forEach((id) => {
      const { tableData } = useGetPredisbursementTableDataPrint(data);
      const selectedApplicant = tableData.filter((applicant) => {
        return parseIdFromProjectId(applicant[0]) == parseIdFromProjectId(id);
      })[0];
      csvArray.push(selectedApplicant.splice(0, selectedApplicant.length - 1));
    });
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvArray.forEach((row: any[]) => {
      let rowString = row.join(',');
      csvContent += rowString + '\r\n';
    });
    const uri = encodeURI(csvContent);
    var element = document.createElement('a');
    element.href = uri;
    element.target = '_blank';
    element.download = 'export.csv';
    element.click();
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};

export const useGenerateDisbursementCSV = ({ rows, columns, type, data }: IGenerateCSV) => {
  try {
    let header = columns;
    let csvArray = [];
    csvArray.push(header);
    rows.forEach((id) => {
      const { tableData } =
        type == 'Pre' ? useGetPredisbursementTableData(data) : useGetPostdisbursementTableData(data);
      const selectedApplicant = tableData.filter((applicant) => {
        return parseIdFromProjectId(applicant[0]) == parseIdFromProjectId(id);
      })[0];
      csvArray.push(selectedApplicant.splice(0, selectedApplicant.length - 1));
    });
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvArray.forEach((row: any[]) => {
      let rowString = row.join(',');
      csvContent += rowString + '\r\n';
    });
    const uri = encodeURI(csvContent);
    var element = document.createElement('a');
    element.href = uri;
    element.target = '_blank';
    element.download = 'export.csv';
    element.click();
    return { success: true };
  } catch (e) {
    return { success: false };
  }
};
