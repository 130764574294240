/*eslint-disable */
import React, { useEffect, useState } from 'react';
import ApplicantCard from './Cards/ApplicantCard';
import propTypes from 'prop-types';
import { useQuery } from 'react-query';
import { getPaginatedApplicants, searchApplicants } from '@service/Applicants';
import Pagination from '@mui/material/Pagination';
import SearchInput from '@components/SearchInput';
import { CircularProgress } from '@mui/material';
import { messageAlert } from '@utils/helpers/functions';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getApplicantApprovals, getInterviewApprovals } from '@service/Applicants';
import SidebarLoader from '@components/Loaders/SidebarLoader';
import { handleErrorMessage } from '@utils/helpers/functions';

const ApplicantList = ({ type, category, id }) => {
  const userData = JSON.parse(window.localStorage.getItem('user'));
  const [currentPage, setCurrentPage] = React.useState(1);
  const { data, isLoading } = useQuery(['page', 1], () => {
    // if (_isLoading == false)
    // {

    if (type == undefined) {
      return getPaginatedApplicants(currentPage, 10, undefined, searchQuery);
    } else if (type == 'applicants') {
      return getApplicantApprovals(currentPage, 10, userData?.profile?.region?.id, userData?.profile?.district?.id);
    } else if (type == 'interview') {
      return getInterviewApprovals(currentPage, 10, userData?.profile?.region?.id, userData?.profile?.district?.id);
    }

    // }
  });
  const [totalPages, setTotalPages] = useState(1);
  const [applicantData, setApplicantData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (id == null || id == undefined) {
      if (type == undefined) {

        // redirectUser(data?.data?.data[0]?.id,type)
      }
    }
    if (data?.data?.data) {
      setApplicantData(data?.data?.data);
      setTotalPages(data?.data?.total_pages);
    }
  }, [data]);

  // useEffect(()=>{
  //   if (_isLoading == false)
  //   {
  //     fetchApplicants('');
  //   }
  // },[_isLoading])

  const fetchApplicants = async (query) => {
    try {
      setCurrentPage(1);
      setLoader(true);

      const response = await searchApplicants(
        query,
        type,
        userData?.profile?.region?.id,
        userData?.profile?.district?.id,
        1,
      );
      if (response?.data?.data) {
        setApplicantData(response?.data?.data);
        setTotalPages(response?.data?.total_pages);
      }
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const style = {
    display: 'flex',
    flexDirection: 'column',
    background: '#F9FAFD',
    height: `100%`,
  };

  const handleSearch = () => {
    fetchApplicants(searchQuery);
  };

  const handlePagination = async (event, value) => {
    setLoader(true);
    try {
      setCurrentPage(value);
      let _type = type ? type : '';
      let { data } = await getPaginatedApplicants(
        value,
        10,
        _type,
        searchQuery,
        userData?.profile?.region?.id,
        userData?.profile?.district?.id,
      );
      setApplicantData(data.data);
      setTotalPages(data.total_pages);
    } catch (e) {
      handleErrorMessage(e);
      messageAlert('error', 'Error loading Data.');
    }
    setLoader(false);
  };

  const redirectUser = (id, type) => {
    if (type == undefined) {


      if (id !== undefined && id !== null) {
        navigate(`/applicants/${id}`);
      } else {
        navigate(`/applicants`);
      }
    } else if (type == 'applicants') {
      navigate(`/applicants/${id}/applicants`);
    } else if (type == 'interview') {
      navigate(`/applicants/${id}/interview`);
    }
  };

  const returnTitleString = (type, category) => {
    if (type == undefined && category == undefined) {
      return 'Applicants';
    }
    if (type == 'training') {
      return 'Training Approvals';
    }
    if (type == 'interview') {
      return 'Interview Approvals';
    }
    return 'Applicant Approvals';
  };

  // React.useEffect(()=>{
  //   if (type == undefined)
  //   {
  //     getPaginatedApplicants(1,5);
  //   }
  // },[type])

  if (isLoading === true) return <SidebarLoader />;
  return (
    <div style={style} className="applicant-list">
      <div className="top" style={{ padding: '2rem', paddingBottom: '0.2rem' }}>
        <div className="title flex" style={{ marginBottom: '1.5rem', flexDirection: 'column' }}>
          <div className="left" style={{ fontSize: '1rem', fontWeight: '600' }}>
            {returnTitleString(type, category)}
          </div>
        </div>
        <div className="options flex" style={{ width: '100%' }}>
          <SearchInput placeholder="Enter Name, Phone, or ID Number" setSearchQuery={setSearchQuery} />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <Button
            sx={{
              '&:hover': { color: '#2a3b8f' },
              fontWeight: '400',
              fontFamily: 'inherit !important',
              fontSize: '0.95rem',
              color: 'white',
              backgroundColor: '#2a3b8f',
              textTransform: 'initial !important',
              width: '100%',
            }}
            onClick={handleSearch}
          >
            Filter
          </Button>
        </div>
      </div>
      <div className="bottom bottom-list flex flex-c" style={{ marginLeft: '2rem', height: 'calc(100%-50px)' }}>
        {loader == true && (
          <Box sx={{ textAlign: 'center', marginTop: '5rem' }}>
            <CircularProgress />
            <Box sx={{ marginTop: '1.5rem' }}>Loading Data Please Wait...</Box>
          </Box>
        )}

        {loader == false && type == undefined && (
          <>
            {applicantData?.map((applicant, idx) => {

              return (
                <div
                  key={`${applicant.id}-${idx}`}
                  onClick={() => {

                    if (applicant?.id !== undefined && applicant?.id !== null) {
                      redirectUser(applicant.id, type);

                    } else {
                      redirectUser(applicant?.user?.id, type);
                    }
                  }}
                >
                  {applicant?.id && (
                    <ApplicantCard
                      selected={id == applicant?.id}
                      name={`${applicant?.first_name} ${applicant?.last_name}`}
                      percentage={applicant?.percentage_score}
                      rating={applicant?.score_rating}
                    />
                  )}
                  {!applicant?.id && (
                    <ApplicantCard
                      selected={id == applicant?.user?.id}
                      name={`${applicant?.user?.first_name} ${applicant?.user?.last_name}`}
                      percentage={applicant?.percentage_score}
                      rating={applicant?.score_rating}
                    />
                  )}
                </div>
              );
            })}
          </>
        )}

        {loader == false && (type == 'applicants' || type == 'interview') && (
          <>
            {applicantData?.map((applicant, idx) => {
              return (
                <div
                  key={`${applicant?.user?.id}-${idx}`}
                  onClick={() => {
                    redirectUser(applicant?.user?.id, type);
                  }}
                >
                  <ApplicantCard
                    selected={id == applicant?.user?.id}
                    name={`${applicant?.user?.first_name} ${applicant?.user?.last_name}`}
                    percentage={applicant?.applicant_score}
                    rating={applicant?.score_rating}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
      <div
        style={{
          order: '1',
          marginTop: 'auto',
          borderTop: '1px solid #dbdbdb',
          marginRight: '1.5rem',
          paddingTop: '1.5rem',
          borderBottom: '1px solid #dbdbdb',
          paddingBottom: '1.5rem',
        }}
      >
        <Pagination
          page={currentPage}
          className="custom-pagination"
          size={'small'}
          onChange={handlePagination}
          count={totalPages}
          color="primary"
        />
      </div>
    </div>
  );
};

ApplicantList.propTypes = {
  type: propTypes.string,
  category: propTypes.string,
  id: propTypes.string,
};
export default ApplicantList;
