/*eslint-disable*/
import { useMutation } from 'react-query';
import apiConfig from '../../../service/apiConfig';
import { PASS_THRESHOLD } from '../../../utils/constants.js';
import { messageAlert, parseIdFromProjectId } from '../../../utils/helpers/functions';
import { useNavigate } from 'react-router-dom';
import { ScoreRowMapping, ScoringTemplate } from '../constants.ts';

export const TEXT_CONSTANTS = {
  action_text_list: 'Download Selected Applicants',
  action_text_preview: 'Submit Uploaded File',
  basic_stage_title: 'Basic Level',
  intermediate_stage_title: 'Intermediate Level',
  advanced_stage_title: 'Advanced Level',
};

export const useRunApprovals = () => {
  const approvalsMutation = useMutation({
    mutationFn: () => {
      return apiConfig.postRequest(`staff/approvals/`);
    },
  });
  return { mutateFn: approvalsMutation };
};

export const getBackendTrainingStage = (type: 'basic' | 'intermediate' | 'advanced') => {
  switch (type) {
    case 'basic':
      return 'basic_training_approval';
    case 'intermediate':
      return 'intermediate_training_approval';
    case 'advanced':
      return 'advanced_training_approval';
  }
};

export const getNextStageText = (type: 'basic' | 'intermediate' | 'advanced') => {
  switch (type) {
    case 'basic':
      return 'Intermediate Approvals';
    case 'intermediate':
      return 'Advanced Approvals';
    case 'advanced':
      return 'Grant Approvals';
    default:
      return 'the next stage';
  }
};

export const getScoreKeys = (stage: string, data: string[]) => {
  switch (stage) {
    case 'basic':
      return {
        basic_attendance_score: data[2],
        basic_participation_score: data[3],
        basic_class_exercise_score: data[4],
        basic_business_model_canvas_score: data[5],
      };
    case 'intermediate':
      return {
        intermediate_attendance_score: data[2],
        intermediate_participation_score: data[3],
        intermediate_class_exercise_score: data[4],
        intermediate_business_plan_score: data[5],
      };
    case 'advanced':
      return {
        advanced_attendance_score: data[2],
        advanced_participation_score: data[3],
        advanced_class_exercise_score: data[4],
        advanced_business_plan_presentation_score: data[5],
        advanced_pitch_practice_score: data[6],
      };
    default:
      return {};
  }
};

export const getPassedTrainees = (
  traineeArray: any[],
  stage: string,
  pass_threshold?: number,
): Partial<{ id: number }>[] => {
  let passedStudents: Partial<{ id: number }>[] = [];
  traineeArray.forEach((traineeRow: string[]) => {
    let totalScore = parseInt(traineeRow[stage == 'advanced' ? 7 : 6]);
    if (totalScore >= (pass_threshold || PASS_THRESHOLD)) {
      passedStudents.push({ id: parseIdFromProjectId(traineeRow[0]), ...getScoreKeys(stage, traineeRow) });
    }
  });
  return passedStudents;
};

export const getBacktrackedTrainees = (traineeArray:any[]) => {
   let passedStudents: Partial<{ id: number }>[] = [];
  traineeArray.forEach((traineeRow: string[]) => {
    passedStudents.push({id:parseIdFromProjectId(traineeRow[0])})
  })
  return passedStudents
}

export const getFailedTrainees = (
  traineeArray: any[],
  stage: string,
  pass_threshold?: number,
): Partial<{ id: number }>[] => {
  console.log('this triggered 9222');
  let failedApplicants: Partial<{ id: number }>[] = [];
  traineeArray.forEach((traineeRow: string[]) => {
    let totalScore = parseInt(traineeRow[stage == 'advanced' ? 7 : 6]);
    if (totalScore < (pass_threshold || PASS_THRESHOLD)) {
      failedApplicants.push({ id: parseIdFromProjectId(traineeRow[0]), ...getScoreKeys(stage, traineeRow) });
    }
  });
  return failedApplicants;
};

export const useGetTableData = (data: { data: any[]; count: number; stage: string }) => {
  let tableData: any[][] = [];
  let count = 0;
  data?.data?.forEach((applicant: { applicant_score: any; user: any; project_application_code: any }) => {
    let { applicant_score, user, project_application_code } = applicant;
    let tableRow =
      data.stage == 'advanced'
        ? [project_application_code, `${user?.first_name} ${user?.last_name}`, 0, 0, 0, 0, 0, false, user?.id, 0]
        : [project_application_code, `${user?.first_name} ${user?.last_name}`, 0, 0, 0, 0, false, user?.id, 0];
    tableData.push(tableRow);
  });
  count = data?.count;
  return { tableData, count };
};

export const getTitle = (state: 'basic' | 'intermediate' | 'advanced') => {
  switch (state) {
    case 'basic':
      return `Training Approvals - ${TEXT_CONSTANTS.basic_stage_title}`;
    case 'intermediate':
      return `Training Approvals - ${TEXT_CONSTANTS.intermediate_stage_title}`;
    case 'advanced':
      return `Training Approvals - ${TEXT_CONSTANTS.advanced_stage_title}`;
    default:
      return 'Basic Training';
  }
};

export const checkPassed = (day: number, pass_threshold?: number): boolean => {
  //
  if (day >= (pass_threshold || PASS_THRESHOLD)) {
    return true;
  }
  return false;
};

export const checkSkipped = (stage: string, destination: string) => {
  if (stage == 'intermediate' && destination == 'advanced_training_approval') {
    return true;
  }
  return false;
};

export const validateScores = (stage: 'basic' | 'intermediate' | 'advanced', row: string[]) => {
  if (row.length > 1) {
    let validScore: boolean = true;
    let scoringTemplate = ScoringTemplate[stage];
    let scoringMapping = ScoreRowMapping[stage];

    Object.keys(scoringTemplate).forEach((key) => {
      let index = scoringMapping[key];
      if (parseInt(row[index]) > scoringTemplate[key]) {
        validScore = false;
      }
    });
    return validScore;
  }

  //run some kind of comparison using a score - index position mapping....
};

export const viewUser = (id: string) => {
  const navigate = useNavigate();
  navigate(`/applicants/${id}`);
};

export const parseStage = (stage: 'intermediate' | 'advanced' | 'grants') => {
  switch (stage) {
    case 'intermediate':
      return 'Intermediate Approvals';
    case 'advanced':
      return 'Advanced Approvals';
    case 'grants':
      return 'Grant Approvals';
  }
};
