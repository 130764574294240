import React from "react";
// import { Image } from "react-bootstrap";
import gealogo from "@images/logo/gealogo.png";
import propTypes from "prop-types";
import { Avatar, Box } from "@mui/material";

const logoVariants = {
  small: {
    width: "50px",
  },
  medium: {
    width: "74.5px",
  },
  normal: {
    width: "80px",
  },
  large: {
    width: "100px",
  },
};

const Logo = ({ type, size }) => {
  const logoStyle = size ? logoVariants[size] : logoVariants.normal;

  return <img src={type === "dark" ? gealogo : gealogo} style={logoStyle} />;
};

Logo.propTypes = {
  type: propTypes.string,
  size: propTypes.string,
};
export default Logo;

const PRIMARY_COLOR = "#2a3b8f";
const returnCapitalizedInitial = (name) => {
  let initial = "";
  if (name !== undefined && name !== null) {
    initial = name.charAt(0).toUpperCase();
  }
  return initial;
};

export const AvatarInitial = ({ text }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Avatar
        sx={{
          backgroundColor: PRIMARY_COLOR,
          color: "white",
          fontSize: "0.7rem",
          width: "2rem",
          height: "2rem",
        }}
      >
        {returnCapitalizedInitial(text?.[0])}
        {returnCapitalizedInitial(text?.split(" ")?.[1])}
      </Avatar>
      <Box sx={{ marginLeft: "0.6rem" }}>{text}</Box>
    </Box>
  );
};
AvatarInitial.propTypes = {
  text: propTypes.string,
};
