/*eslint-disable */
import React from "react";
import propTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

const ApplicantSidebarNavitem = ({
  selectedView,
  view,
  icon,
  title,
  ...rootDOMAttributes
}) => {
  const style = {
    color: selectedView == view ? "#2A3B8F" : "#FFF",
    backgroundColor: selectedView == view ? "#F2F2F2" : "",
    borderRadius: "0.3rem",
    padding: "0.6rem",
    fontSize: "1.2rem",
  };

  return (
    <Tooltip placement="right" title={title}>
      <div className="applicant-nav-item" style={style} {...rootDOMAttributes}>
        <i>{icon}</i>
      </div>
    </Tooltip>
  );
};

ApplicantSidebarNavitem.propTypes = {
  selectedView: propTypes.number,
  view: propTypes.number,
  icon: propTypes.object,
  title: propTypes.string,
};

export default ApplicantSidebarNavitem;
