import { getRegions, getDistricts } from '@service/CommonApi';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

export function useGetRegions() {
  const { error, data, isLoading,refetch } = useQuery('regions', getRegions, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const regions = data?.data;

  return { regions, error, isLoading,refetch };
}

export function useGetDistricts(regions, selectedRegion) {
  const [state, setState] = useState({
    isLoading: false,
    error: null,
    districts: null,
  });

  const selectedRegionPKValue = getSelectedRegionPKValueFromRegion(regions, selectedRegion);

  useEffect(() => {
    if (selectedRegionPKValue) {
      getDistrictValues();
    }
  }, [regions, selectedRegion]);

  const error = state?.error || null;
  const isLoading = state?.isLoading || false;

  return { districts: state?.districts?.data, error, isLoading };

  async function getDistrictValues() {
    setState({ ...state, isLoading: true });
    try {
      const results = await getDistricts(selectedRegionPKValue);
      setState({ ...state, districts: results, isLoading: false });
    } catch (error) {
      setState({ ...state, error });
    }
  }
}

function getSelectedRegionPKValueFromRegion(regions, selectedRegion) {
  if (regions) {
    const region = regions.find((region) => region.id === selectedRegion);

    return region?.id || null;
  }

  return null;
}
