/*eslint-disable*/
import React from "react";
import ApplicantSidebar from "@components/ApplicantSidebar/ApplicantSidebar";
import { Box, Grid } from "@mui/material";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { useNavigate } from "react-router-dom";
import { checkPermissions } from "@service/RequireAuth";
import useGetUserData from "@utils/hooks/useGetUserData";
import { DefaultButton } from "@/components/Buttons/ButtonFullWidth";

const NotFound = () => {
  let { userData, isLoading } = useGetUserData();
  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (isLoading == false) {
  //     let status =
  //       checkPermissions("registrations", userData) == true ||
  //       checkPermissions("applications", userData) == true;

  //     if (status == true) {
  //       navigate("/dashboard");
  //     }
  //   }
  // }, [userData]);

  return (
    <Box sx={{ width: "100%", height: "100vh", display: "flex" }}>
      <ApplicantSidebar selectedView={-1} />
      <Box
        sx={{
          width: "98%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <DangerousIcon sx={{ fontSize: "10rem", color: "#2A3B8F" }} />
        </Box>
        <Box my={"2rem"} sx={{ fontSize: "4rem", fontWeight: "600" }}>
          404
        </Box>
        <Box
        mb={3}
          sx={{
            fontSize: "1.2rem",
            marginTop: "1rem",
            textAlign: "center",
            lineHeight: "2rem",
            
          }}
        >
          Sorry, the page you are trying to access cannot be found. <br></br>{" "}
          You may also not have the right security privileges to access this
          page.
          <br></br>Please contact the administrator.
        </Box>
         <DefaultButton onClick={()=>{navigate(-1)}} text="Go Back"/>
      </Box>
    </Box>
  );
};

export default NotFound;
