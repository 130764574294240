import ApplicantNavbar from '@components/ApplicantNavbar/ApplicantNavbar';
import ApplicantList from './ApplicantList';
import ApplicantDetails from './ApplicantDetails';
import React from 'react';
import propTypes from 'prop-types';

const ApplicantMain = ({ id, type, category }) => {
  const style = {
    display: 'flex',
    height: '100vh',
    width: '100%',
  };

  return (
    <div className="flex flex-c" style={style}>
      <div className="top">
        <ApplicantNavbar />
      </div>
      <div className="bottom flex" style={{ height: '91%' }}>
        <div className="left" style={{ width: '25%' }}>
          <ApplicantList id={id} type={type} category={category} />
        </div>
        <div className="right" style={{ width: '75%' }}>
          <ApplicantDetails type={type} category={category} selectedApplicant={id} />
        </div>
      </div>
    </div>
  );
};

ApplicantMain.propTypes = {
  id: propTypes.string,
  type: propTypes.string,
  category: propTypes.string,
};

export default ApplicantMain;
