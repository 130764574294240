/*eslint-disable*/
import React from "react";
import { Grid, Box, Button } from "@mui/material";
import DashboardSidebar from "@components/DashboardSidebar/DashboardSidebar";
// import StaffNavbar from './StaffNavbar';
import SearchInput from "@components/SearchInput";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";

const responsive = "vertical";
const tableBodyHeight = "auto";
const tableBodyMaxHeight = "100%";

const dummyData = [];

export default function BasicTable() {
  const [searchString, setSearchString] = React.useState("");
  const columns = [
    "Name",
    "Phone Number",
    "Email Address",
    "Role",
    {
      name: "action",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <Button
            onClick={() => {
              alert(value);
            }}
          >
            View
          </Button>
        ),
      },
    },
  ];

  const tableOptions = {
    print: false,
    search: false,
    viewColumns: false,
    download: false,
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    searchText: searchString,
    onTableChange: (action, state) => {
      //
      //   console.dir(state);
    },
    customSearchRender: () => null,
  };

  return (
    <div>
      <Box className="staff-main" sx={{ width: "100%", height: "100%" }}>
        <Grid container sx={{ width: "100%", height: "100vh" }}>
          <Box
            className="custom-data-table"
            my={"2rem"}
            sx={{ width: "100%", height: "100%" }}
          >
            <MUIDataTable
              options={tableOptions}
              columns={columns}
              data={dummyData}
            />
          </Box>
        </Grid>
      </Box>
    </div>
  );
}
