/*eslint-disable*/
import { getDetailedAnalysis, getMonitoringAndEvaluationGrant, getMonitoringAndEvaluationTraining } from '@/service/CommonApi';
import { AvatarInitial } from '@/utils/Logo';
import { basic_score_keys, intermediate_score_keys, advanced_score_keys, grant_score_keys } from '@/utils/constants';
import { handleErrorMessage, messageAlert } from '@/utils/helpers/functions';
import { IDetailedReportsParams } from '@/utils/interfaces/reports.interfaces';
import { basicColumns, intermediateColumns, advancedColumns } from '@/utils/tableColumns.tsx';
import { Button } from '@mui/material';
import { addDays } from 'date-fns';
import moment from 'moment';
import { MUIDataTableOptions } from 'mui-datatables';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { getTemplateHeadersWithStatus } from '@/components/GrantAssessmentBatch/helpers';
import { BATCH_ASSESSMENT_PASS_THRESHOLD } from '@/components/GrantAssessmentBatch/constants';
import apiConfig from '@/service/apiConfig';
import { grantBeneficiariesColumns } from '@/components/MonitoringEvaluation/constants';

export const getDisqualifiedStage = (stage: string) => {
  switch (stage) {
    case 'applicant':
      return 'applicant_approval';
    case 'approved_application_for_basic_training':
      return 'basic_training_approval';
    case 'approved_application_for_intermediate_training':
      return 'intermediate_training_approval';
    case 'approved_application_for_advanced_training':
      return 'advanced_training_approval';
    case 'approved_application_grant_pre_disbursement':
      return 'pre_disbursement_approval';
  }
};

export const useGetDetailedReports = () => {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    overflowY: 'auto',
  };
  const boxStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    border: '2px solid #000',
    padding: '16px',
    backgroundColor: 'white',
  };

  const buttonStyle: React.CSSProperties = {
    //@ts-ignore
    '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
    fontWeight: '400',
    fontFamily: 'inherit !important',
    fontSize: '0.85rem',
    color: 'white',
    backgroundColor: '#2a3b8f',
    textTransform: 'none',
    padding: '0.7rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  };
  const dateRangeStyle: React.CSSProperties = {
    padding: '0.7rem',
    color: 'black',
    backgroundColor: 'white',
    border: '1px solid #e1e1e1',
    borderRadius: '0.8rem',
    fontSize: '0.9rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };
  const [reportsLoading, setReportsLoading] = React.useState(false);
  const [text, setText] = React.useState('Total Registrants');
  const [tabValue, setTabValue] = React.useState(0);
  const [dateModalOpen, setDateModalOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [params, setParams] = React.useState<Partial<IDetailedReportsParams>>({
    application_process_status: 'registration',
    date_upper_limit: '',
    date_lower_limit: '',
    q: '',
    show_disqualified: false,
    page: 1,
    per_page: 100,
  });
  const [reportsData, setReportsData] = React.useState([]);
  //@ts-ignore
  const { isLoading: batchReportsLoading, mutateAsync,mutate } = useMutation(
    'get-batch',

    (params: Partial<IDetailedReportsParams>) => {
      if(params.receivedGrant){
        getMonitoringAndEvaluationGrant({q:searchQuery})
      }
      if (params.receivedTraining){
        getMonitoringAndEvaluationTraining({q:searchQuery})
      }

      if (!params.receivedGrant && !params.receivedTraining){
      getDetailedAnalysis({ ...params, q: searchQuery });

      }


    },
  );
  const navigate = useNavigate();
  const responsive = 'vertical';
  const tableBodyHeight = `${window.innerHeight}px`;
  const tableBodyMaxHeight = '';
  const exportPdf = () => {
    const element = document.querySelector('[aria-label="Print"]') as HTMLElement;
    element?.click();
  };
  const tableOptions: Partial<MUIDataTableOptions> = {
    print: true,
    search: false,
    viewColumns: false,
    download: true,
    filter: false,
    filterType: 'dropdown',
    responsive,
    serverSide: true,
    page: page,
    rowsPerPage: 100,
    rowsPerPageOptions: [100],
    count: pageCount,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'Role',
      direction: 'asc',
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setPage(tableState?.page);
        setParams({ ...params, page: tableState?.page + 1 });
      }
    },
    customSearchRender: () => null,
    customToolbarSelect: () => null,
  };

  const grantApplicationsColumns = [
    'Registration Code',
    'Application Code',
    'Applicant Name',
    'Gender',
    'Age',
    'Id Type',
    'Id Number',
    'Region',
    'District',
    'Digital Address',
    'Community',
    'Address Line 1',
    'Educational Level',
    'Vocational Skills',
    'Business Sector',
    'Disability Status',
    'Phone Number',
    'Application Status',
    'Grant Amount Requested',
    'Date Grant Submitted',
  ];

  const columns = [
    {
      name: 'Applicant',
      options: {
        filter: true,
        customBodyRender: (value) => <AvatarInitial text={value || ''} />,
      },
    },
    'Client ID',
    'Application ID',
    'Registration Date',
    'Applicant Name',
    'Phone Number',
    'ID Number',
    {
      name: 'Action',
      options: {
        filter: true,
        customBodyRender: (value) => (
          <Button
            sx={{ textTransform: 'none', fontFamily: 'inherit' }}
            onClick={() => {
              navigate(`/applicants/${value}`);
            }}
          >
            View
          </Button>
        ),
      },
    },
  ];

  const changeDateParams = (dateValues) => {
    setDateState(dateValues);
    let _time_upper_limit = moment(dateValues[0].startDate).format('YYYY-MM-DD');
    let _time_lower_limit = moment(dateValues[0].endDate).format('YYYY-MM-DD');
    setParams({
      ...params,
      page: 1,
      date_upper_limit: _time_lower_limit,
      date_lower_limit: _time_upper_limit,
    });
  };
  const [dateState, setDateState] = React.useState([
    {
      startDate: new Date(moment().format('YYYY-MM-DDThh:mm:ssZ')),
      endDate: addDays(new Date(), 5),
      key: 'selection',
    },
  ]);
  const handleTabChange = (event, value) => {
    const {has_grant_score,...newParams} = params
    setTabValue(value);
    setPage(0);
    // registration, application, rejection, training, grant
    switch (value) {
      case 0:
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'registration',
          show_disqualified: false,
          receivedGrant:false,
          receivedTraining:false

          
        });
        setText('Total Registrants');
        break;
      case 1:
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'application',
          show_disqualified: false,
          receivedGrant:false,
          receivedTraining:false

          
        });
        setText('Total Applicants');
        break;
      case 2:
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'rejection',
          show_disqualified: false,
          receivedGrant:false,
          receivedTraining:false

          
        });
        setText('Total Rejected');
        break;
      case 3:
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'approved_application_for_basic_training',
          show_disqualified: false,
          receivedGrant:false,
          receivedTraining:false

          
        });
        setText('Total Trainees');
        break;
      case 4:
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'approved_application_grant',
          show_disqualified: false,
          skipped_advanced_training: true,
          receivedGrant:false,
          receivedTraining:false

          
        });
        setText('Total Grants');
        break;
      case 5:
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'approved_application_grant_pre_disbursement',
          show_disqualified: false,
          receivedGrant:false,
          receivedTraining:false

          
        });
        setText('Disbursement');
        break;
      case 6:
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'approved_application_for_intermediate_training',
          show_disqualified: false,
          receivedGrant:false,
          receivedTraining:false

          
        });
        setText('Intermediate Training');
        break;
      case 7:
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'approved_application_for_advanced_training',
          show_disqualified: false,
          skipped_advanced_training: false,
          receivedGrant:false,
          receivedTraining:false

          
        });
        setText('Advanced Training');
        break;
      case 8:
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'approved_application_grant',
          show_disqualified: false,
          skipped_advanced_training: false,
          receivedGrant:false,
          receivedTraining:false

          
        });
        setText('Advanced To Grants');
        break;
      case 9:
        const {has_grant_score,..._newParams} = params
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'approved_application_grant_post_disbursement',
          show_disqualified: false,
          receivedGrant:false,
          receivedTraining:false

          
        });
        setText('Post Disbursement Training');
        break;
      case 10:
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'application',
          show_disqualified: false,
          has_grant_score: true,
          receivedGrant:false,
          receivedTraining:false
        });
        setText('Grant Applicants');
        break;
      case 11:
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'application',
          show_disqualified: false,
          receivedGrant:true,
          receivedTraining:false
        });
        setText('Grant Beneficiaries');
        break;
      case 12:
        setParams({
          ...newParams,
          page: 1,
          application_process_status: 'application',
          show_disqualified: false,
          receivedGrant:false,
          receivedTraining:true
        });
        setText('Non-Grant Beneficiaries');
        break;
    }
  };

  const returnDefaultValue = (value) => {
    if (value == undefined || value == null || value == '') {
      return `N/A`;
    }
    return value;
  };

  const getTotalGrantAssessmentScore = (scoreObject: any) => {
    let totalScore = 0;
    grant_score_keys.forEach((key) => {
      try {
        totalScore =
          totalScore + (scoreObject[key] === undefined || scoreObject[key] === null ? 0 : parseInt(scoreObject[key]));
      } catch (e) {
        return totalScore + 0;
      }
    });
    console.log(totalScore, 33000);
    return totalScore;
  };

  const loadScores = (scoreObject: any, stage: 'basic' | 'intermediate' | 'advanced') => {
    let scores = [];
    switch (stage) {
      case 'basic':
        basic_score_keys.forEach((key) => {
          scores.push(scoreObject[key] || 'N/A');
        });
        break;
      case 'intermediate':
        intermediate_score_keys.forEach((key) => {
          console.log(key, 330);
          scores.push(scoreObject[key] || 'N/A');
        });
        break;
      case 'advanced':
        advanced_score_keys.forEach((key) => {
          scores.push(scoreObject[key] || 'N/A');
        });
        break;
    }
    return scores;
  };

  const loadGrantAssessmentScores = (scoreObject: any, skipped_advanced_training: boolean) => {
    try {
        let scores = []
        grant_score_keys.forEach((key)=>{
        scores.push(scoreObject[key]||'0')
        })
        return scores
    } catch(e){
      return Array.from(Array(8),(_x,index)=>{
        return '0'
      })
    }  }

  const returnTableColumns = (_tabValue: number) => {
    switch (params.application_process_status) {
      case 'approved_application_for_basic_training': {
        return basicColumns;
      }
      case 'approved_application_for_intermediate_training': {
        return intermediateColumns;
      }
      case 'approved_application_for_advanced_training': {
        if (_tabValue !== 10) {
          return advancedColumns;
        }
      }

    }
    switch (_tabValue) {
      case 10:
        return getTemplateHeadersWithStatus(undefined);
      case 11:
        return grantBeneficiariesColumns;
      default:
        return columns;
    }
    //6,7,3
  };

  const returnTableRow = (tabValue: number, data: any) => {
    let {
      profile,
      user,
      project_application_code,
      application_process_status,
      business_profile,
      training_score,
      grant_score,
    gjsp_id,
    name,
    client,
    received_funding,
    used_funds_for_intended_purpose,
    started_business,
    employees_hired,
    female_employees_hired,
    youth_employees_hired,
    pwd_employees_hired,

    } = data;
    switch (params.application_process_status) {
      case 'approved_application_for_basic_training': {
        return [
          `${returnDefaultValue(project_application_code)}`,
          `${returnDefaultValue(user?.first_name)} ${returnDefaultValue(user?.last_name)}`,
          ...loadScores(training_score, 'basic'),
          user?.id,
        ];
      }
      case 'approved_application_for_intermediate_training': {
        return [
          `${returnDefaultValue(project_application_code)}`,
          `${returnDefaultValue(user?.first_name)} ${returnDefaultValue(user?.last_name)}`,
          ...loadScores(training_score, 'intermediate'),
          user?.id,
        ];
      }
      case 'approved_application_for_advanced_training':
        if (tabValue !== 10) {
          {
            return [
              `${returnDefaultValue(project_application_code)}`,
              `${returnDefaultValue(user?.first_name)} ${returnDefaultValue(user?.last_name)}`,
              ...loadScores(training_score, 'advanced'),
              user?.id,
            ];
          }
        }
    }
    switch (tabValue) {
      case 10:
        return [
          `${returnDefaultValue(project_application_code)}`,
          ...loadGrantAssessmentScores(grant_score,false)
        ];
      
      case 11:
        return [
           `${gjsp_id}`,
           `${name}`,
           `${received_funding}`,
           `${used_funds_for_intended_purpose}`,
           `${started_business}`,
           `${employees_hired}`,
           `${female_employees_hired}`,
           `${youth_employees_hired}`,
           `${pwd_employees_hired}`
        ]

      default:
        return [
          `${returnDefaultValue(project_application_code)}`,
          `${user?.id}`,
          `${project_application_code || 'N/A'}`,
          moment(user?.created).format('YYYY-MM-DD'),
          `${user?.first_name} ${user?.last_name}`,
          user?.phone_number,
          profile?.id_number,
          user?.id,
        ];
    }
    //6,7,3
  };

  const fetchReports = async (page = null, isFiltering = false) => {
    setReportsLoading(true);
    try {
      let _params = params;
      _params.page = page == null || !isFiltering ? _params?.page : 1;
      let response = await getDetailedAnalysis({ ..._params, q: searchQuery });
      let { data, total_pages, count } = response?.data;
      let finalData = [];
      ((params.receivedGrant || params.receivedTraining)?response.data: data)?.forEach((data) => {

        let row = returnTableRow(tabValue, data);
        finalData.push(row);
      });
      console.log(finalData, 456);
      setReportsData(finalData);
      setPageCount(count);
      if (params.receivedGrant == true){

      }

      if (isFiltering == true) {
        setPage(1);
      }
    } catch (e) {
      console.log(e);
      let message = handleErrorMessage(e);
      messageAlert('error', message);
    }
    setReportsLoading(false);
  };

  const onQueryChange = (value) => {
    setParams({ ...params, q: value });
  };

  const fetchBatch = async () => {
    try {
      //@ts-ignore
      await getDetailedAnalysis({...params,page:0})
      messageAlert('success', 'Your request has been made. Please check your email in about 10 minutes. Please do not make subsequent requests until you have received the email.');
      // mutate(
      // //@ts-ignore
      // { ...params, page: 0 },
      // {
      //   onSuccess: (data) => {
      //     console.log(data, 356);
      //     messageAlert('success', 'Your request has been made. Please check your email in about 10 minutes. Please do not make subsequent requests until you have received the email.');
      //   },
      //   onError: (error) => {
      //     console.log(error,517777);
      //     const message = handleErrorMessage(error);
      //     messageAlert('success', message);
      //   },
      // },

    } catch(e){
      // const message = handleErrorMessage(e);
      messageAlert('error', e.response.data[0]);
    }
    
  };

  const handleMultiSelectChange = (e: string) => {
    setParams({ ...params, skipped_advanced_training: false, application_process_status: e, show_disqualified: false });
  };

  const handleDisqualifiedToggle = (e: boolean) => {
    setParams({ ...params, show_disqualified: e });
  };

  React.useEffect(() => {
    if (reportsLoading == false) {
      fetchReports(1);
    }
  }, [params]);

  return {
    reportsLoading,
    containerStyle,
    tabValue,
    dateRangeStyle,
    dateModalOpen,
    tableOptions,
    reportsData,
    text,
    dateState,
    buttonStyle,
    boxStyle,
    pageCount,
    batchReportsLoading,
    params,
    showDisqualified: params.show_disqualified,
    multiSelectValue: params.application_process_status,
    searchQuery,
    setSearchQuery,
    setParams,
    handleDisqualifiedToggle,
    handleMultiSelectChange,
    fetchBatch,
    onQueryChange,
    changeDateParams,
    setDateModalOpen,
    setDateState,
    fetchReports,
    exportPdf,
    returnTableColumns,
    handleTabChange,
  };
};
