/*eslint-disable*/
import React from 'react';
import {
  GrantListWrapper,
  GrantListHeaderWrapper,
  GrantListHeaderTitle,
  GrantListSearchWrapper,
  GrantListRoot,
  GrantListFilterButton,
  PaginationWrapper,
} from './components/GrantList.components';
import SearchInput from '../SearchInput';
import { GrantAssessmentContext } from '@/utils/context/GrantAssementContext';
import { useGetGrantList } from './services/GrantList.service';
import Loader from './components/Loader';
import { AxiosResponse } from 'axios';
import { Pagination } from '@mui/material';
import ApplicantCard from '../ApplicantMain/Cards/ApplicantCard';
import { useNavigate } from 'react-router-dom';

const GrantList = () => {
  const { selectedApplicantId, setTotalPages, setCurrentPage } = React.useContext(GrantAssessmentContext);
  const { data, error, isLoading, listParams, refetch, onFilter, setListParams, searchQuery } = useGetGrantList();
  const navigate = useNavigate();
  const _data = data as unknown as AxiosResponse<IApprovalsList>;

  // React.useEffect(() => {
  //
  //   if (selectedApplicantId == undefined) {
  //     if (_data?.data?.data?.[0]) {
  //
  //       navigate(`/grantassessment/${_data?.data?.data?.[0]?.user?.id}`);
  //     }
  //   }
  // }, []);

  return (
    <GrantListRoot>
      <GrantListHeaderWrapper>
        <GrantListHeaderTitle>Grant Assessment</GrantListHeaderTitle>
        <GrantListSearchWrapper>
          <SearchInput placeholder="Enter Name, Phone, or ID Number" setSearchQuery={searchQuery} />
          <div style={{ marginTop: '1rem' }}>
            <GrantListFilterButton onClick={onFilter}>Filter</GrantListFilterButton>
          </div>
        </GrantListSearchWrapper>
      </GrantListHeaderWrapper>
      <GrantListWrapper>
        {isLoading && <Loader text="Loading Data Please wait" />}
        {_data?.data?.data?.map((data, index) => {
          return (
            <div
              onClick={() => {
                navigate(`/grantassessment/${data?.user?.id}`);
              }}
            >
              <ApplicantCard
                selected={data?.user?.id == selectedApplicantId}
                name={`${data?.user?.first_name} ${data?.user?.last_name}`}
                percentage={parseInt(data?.applicant_score)}
                rating={data?.score_rating}
              />
            </div>
          );
        })}
      </GrantListWrapper>
      {!isLoading && (
        <PaginationWrapper>
          <Pagination
            count={_data?.data?.total_pages}
            page={listParams?.page || 1}
            className="custom-pagination"
            size={'small'}
            onChange={(event, value) => {
              setListParams({ ...listParams, page: value });
            }}
          />
        </PaginationWrapper>
      )}
    </GrantListRoot>
  );
};

export default GrantList;
