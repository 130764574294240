/*eslint-disable */
import React from "react";
import propTypes from "prop-types";

const StaffCard = ({ name, selected, role }) => {
  const iconStyle = {
    borderRadius: "50%",
    fontSize: "0.5rem",
    backgroundColor: " #2A3B8F",
    alignItems: "center",
    justifyContent: "center",
    width: "2rem",
    height: "2rem",
    marginRight: "0.8rem",
    fontWeight: "600",
  };

  const applicantCardStyle = {
    marginTop: "1rem",
    backgroundColor: selected === true ? "white" : "unset",
    borderLeft: selected === true ? "3px solid rgb(42, 59, 143)" : "0px",
    padding: "1.5rem",
  };

  const returnCapitalizedInitial = (name) => {
    let initial = "";
    if (name !== undefined && name !== null) {
      initial = name.charAt(0).toUpperCase();
    }
    return initial;
  };

  //   const yetToBeRated =  ( rating == 0) && ( percentage == 0)

  return (
    <div className="applicant-card flex" style={applicantCardStyle}>
      <div className="applicant-icon left flex" style={iconStyle}>
        <div style={{ color: "white" }}>{returnCapitalizedInitial(name)}</div>
      </div>
      <div
        className="applicant-card-content flex flex-c"
        style={{ justifyContent: "center" }}
      >
        <div
          className="top"
          style={{
            fontSize: "0.95rem",
            fontWeight: "500",
            marginBottom: "0.5rem",
          }}
        >
          {name}
        </div>
        <div className="bottom flex">
          <div>{role}</div>
        </div>
      </div>
    </div>
  );
};

StaffCard.propTypes = {
  name: propTypes.string,
  role: propTypes.string,
};

export default StaffCard;
