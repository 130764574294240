import { Typography } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import propTypes from "prop-types";

// const businessInfo = [
//     {title:"Stage of Operation", value:"Idea Stage"},
//     {title:"Industry", value:"Fashion"},
//     {title:"Association/Faith Based Organization", value:"N/A"},
// ]
function ApplicantInfo({ data, title, type }) {


  const returnDefaultGender = (value) => {
    if (value == null || value == undefined) {
      return "Not Available";
    } else if (value == "M") {
      return "Male";
    } else {
      return "Female";
    }
  };

  const generalInfo = {
    overview: [
      {
        title: "Application ID",
        value:
          data?.data?.client_details?.[0]?.project_application_code ||
          "Application Not Complete",
      },
      { title: "First Name", value: data?.data?.overview?.first_name },
      { title: "Middle Name", value: data?.data?.overview?.middle_name },
      { title: "Surname", value: data?.data?.overview?.last_name },
      {
        title: "Gender",
        value: returnDefaultGender(data?.data?.overview?.gender),
      },
      { title: "Date of Birth", value: data?.data?.overview?.date_of_birth },
      { title: "Phone Number", value: data?.data?.overview?.phone_number },
      { title: "Email Address", value: data?.data?.overview?.email },
      { title: "Nationality", value: data?.data?.profile?.[0]?.country },
      {
        title: "Region of Residence",
        value: data?.data?.profile[0]?.region?.name,
      },
      {
        title: "Ghana Card ID Number",
        value: data?.data?.profile[0]?.id_number,
      },
      {
        title: "Marital Status",
        value: data?.data?.profile[0]?.marital_status,
      },
      { title: "Number of Dependents", value: "2" },
    ],
    business: [
      // {title:"Desires training to start business", value: mapResponseValueToYesOrNo(data?.data?.training_request?.[0]?.[0]?.desires_training_to_start_business)},
      {
        title: "Can evaluate business idea",
        value: mapResponseValueToYesOrNo(
          data?.data?.training_request?.[0]?.[0]?.can_evaluate_business_idea
        ),
      },
      // {title:"Has Operated own business", value: mapResponseValueToYesOrNo(data?.data?.training_request?.[0]?.[0]?.operated_own_business)},
      // {title:"Has Operated other business", value: mapResponseValueToYesOrNo(data?.data?.training_request?.[0]?.[0]?.operated_other_business)},
      {
        title: "Has previous entrepreneurial training",
        value: mapResponseValueToYesOrNo(
          data?.data?.training_request?.[0]?.[0]
            ?.has_previous_entrepreneurial_training
        ),
      },
      {
        title: "Able to receive entrepreneurial training",
        value: mapResponseValueToYesOrNo(
          data?.data?.training_request?.[0]?.[0]
            ?.able_to_receive_entrepreneurial_training
        ),
      },
      {
        title: "Has business plan",
        value: mapResponseValueToYesOrNo(
          data?.data?.training_request?.[0]?.[0]?.has_business_plan
        ),
      },
    ],
    scores: [{ title: "First Name", value: "Bruce" }],
  };

  if (!data) return null;

  return (
    <div style={{ marginTop: type === "overview" ? "2rem" : "" }}>
      <Grid container direction="row">
        <div
          className="card-title text-default-color"
          style={{ borderLeft: "0.2em solid #2A3B8F", paddingLeft: "0.5em" }}
        >
          {title}
        </div>
        <Grid container spacing={3} style={{ marginBottom: "2rem" }}>
          {generalInfo[type]?.map((info, index) => (
            <Grid item xs={4} key={index} style={{ marginBottom: "1.5rem" }}>
              <div className="top" style={{ marginBottom: "0.5rem" }}>
                {snakeToCamelCase(info?.title)}
              </div>
              <div className="bottom">
                <Typography variant="subtitle2">{info?.value} </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}
ApplicantInfo.propTypes = {
  data: propTypes.object,
  title: propTypes.string,
  type: propTypes.string,
};

// const ApplicantOverview = ({user}) => {
//
//   return (
//     <ApplicantInfo data={user?.data?.overview} title="Personal Information"/>

//   )
// }

// ApplicantOverview.propTypes = {
//     user : propTypes.object
// }

export default ApplicantInfo;

const snakeToCamelCase = (sentence) =>
  sentence
    .split("_")
    .map((word) => {
      return firstUppercase(word);
    })
    .join(" ");
const firstUppercase = (word) =>
  word && word.charAt(0).toUpperCase() + word.slice(1);

function mapResponseValueToYesOrNo(val) {
  if (val === true) {
    return "Yes";
  } else if (val === false) {
    return "No";
  } else return "N/A";
}
