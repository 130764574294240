import React from "react";
import ApplicantSidebar from "@components/ApplicantSidebar/ApplicantSidebar";
import UsersMain from "@components/Users/UsersMain";
import { useParams } from "react-router-dom";

const AdminUsers = () => {
  const params = useParams();
  const { id } = params;

  const style = {
    width: "100%",
    height: "100vh",
    overflowY: "hidden",
  };
  return (
    <div className="flex" style={style}>
      <ApplicantSidebar />
      <UsersMain id={id} />
    </div>
  );
};

export default AdminUsers;
