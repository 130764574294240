/*eslint-disable */
import React from 'react';
import { VectorMap } from '@south-paw/react-vector-maps';
import ghanaJson from '../../../assets/ghanaJson.json';
import propTypes from 'prop-types';
import { Modal, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import SearchInput from '@components/SearchInput';
import NumberWrapper from '../NumberWrapper';
import { DashboardContext } from '../DashboardContent';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
};

const RegionalInformation = ({ regionName }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <div className="regional-information w-100" style={style}>
      <div className="top">
        {`${regionName === 'None' || regionName == '' ? 'No region selected' : regionName + ' districts'}`}
      </div>
      <div className="bottom w-100">
        {/* {''?.map((district, index)=>{
                    return (
                        <div className="district-row flex w-100" key = {index}> 
                            <div className="left">
                                {district.district.name}
                            </div>
                            <div className="right">
                                {district.count}
                            </div>
                        </div>
                    )
                })} */}
      </div>
    </div>
  );
};

const getDistrictInfo = (dataObject, name) => {
  try {
    let { districts } = dataObject?.regions[name];
    let districtNames = Object.keys(districts);
    let districtInfoArray = [];
    districtNames.forEach((district) => {
      districtInfoArray.push(districts[district]);
    });
    return districtInfoArray;
  } catch (e) {
    return [];
  }
};

const ApplicantRegionalDistribution = () => {
  const { params, selectedRegion, regions, userData, setSelectedRegion } = React.useContext(DashboardContext);
  const [detailsModal, setDetailsModal] = React.useState(false);
  const [regionDetails, setRegionDetails] = React.useState([]);
  const [regionName, setRegionName] = React.useState('');
  const [totalvalues, setTotalValues] = React.useState({
    male: 0,
    female: 0,
    total: 0,
  });
  const [districtSearch, setDistrictSearch] = React.useState('');
  const handleMapClick = (regionName) => {
    if (regions !== null && regions !== undefined) {
      let region = regions?.filter((region) => {
        return region.name === regionName;
      })[0];

      setSelectedRegion(region?.id);
      let detailedInfo = getDistrictInfo(userData, region?.name);

      setRegionDetails(detailedInfo);
      setDetailsModal(true);
      setDistrictSearch('');
      setRegionName(region?.name);

      //    setParams(prevState => ({...prevState,region:foundId,district:''}));
      return;
    }

    setDetailsModal(true);
    setSelectedRegion('');
    // setParams(prevState => ({...prevState,region:'',district:''}));
  };

  const layerProps = {
    onClick: ({ target }) => {
      handleMapClick?.(target?.attributes?.name?.value);
    },
  };
  const style = {
    display: 'flex',
    padding: '1.2rem',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '1rem',
    marginTop: '1.5rem',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    width: params?.type == 'Registrations' ? '50%' : 'unset',
  };

  const breakDownText = {
    fontSize: '14px',
    fontWeight: '600',
    marginBottom: '0.5rem',
  };

  const getId = (index) => {
    if (index === '') {
      let id_array = [];
      ghanaJson.layers.map((layer) => {
        id_array.push(layer.id);
      });

      return id_array;
    } else if (regions?.[index - 1] !== undefined) {
      let { name } = regions[index - 1];
      let { id } = ghanaJson.layers.filter((layer) => {
        return layer.name === name;
      })[0];
      return [id];
    }
    return [];
  };

  // const getRegionName = (index) =>
  // {
  //     if (index!==''&&index!==undefined && regions?.length > 0)
  //     {
  //         let {name} = regions[index-1];
  //         let {name:regionName} = ghanaJson.layers.filter((layer)=>{
  //             return layer.name === name;
  //         })[0];
  //         return regionName;

  //     }
  //     return '';
  // }

  React.useEffect(() => {
    if (regionName != '') {
      let regionObj = userData?.regions[regionName];
      if (params?.type == '' || params?.type == 'Applications') {
        setTotalValues({
          male: regionObj?.total_male_applicants,
          female: regionObj?.total_female_applicants,
          total: regionObj?.total_male_applicants + regionObj?.total_female_applicants,
        });
      } else {
        setTotalValues({
          male: regionObj?.total_male_registrants,
          female: regionObj?.total_female_registrants,
          total: regionObj?.total_male_registrants + regionObj?.total_female_registrants,
        });
      }
    } else {
      if (params?.type == '' || params?.type == 'Applications') {
        setTotalValues({
          male: userData?.genders?.M?.total_applicants,
          female: userData?.genders?.F?.total_applicants,
          total: userData?.genders?.M?.total_applicants + userData?.genders?.F?.total_applicants,
        });
      } else {
        setTotalValues({
          male: userData?.genders?.M?.total_registrants,
          female: userData?.genders?.F?.total_registrants,
          total: userData?.genders?.M?.total_registrants + userData?.genders?.F?.total_registrants,
        });
      }
    }
  }, [regionName, userData]);

  const resetMap = () => {
    setSelectedRegion('');
    setRegionName('');
  };

  return (
    <div style={style} className="regional-distribution-card">
      <Modal
        open={detailsModal}
        onClose={() => {
          setDetailsModal(false);
        }}
      >
        <Box sx={modalStyle}>
          <Box sx={{ width: '100%', display: 'flex' }}>
            <Box sx={{ fontWeight: '700', fontSize: '1.2rem', display: 'flex' }}>
              <Box sx={{ fontWeight: '700', fontSize: '0.9rem' }}>{regionName} Region District Breakdown</Box>
            </Box>
            <Box sx={{ order: '1', marginLeft: 'auto' }}>
              <button
                style={{
                  backgroundColor: 'white',
                  border: 'none',
                  borderRadius: '50%',
                }}
                onClick={() => {
                  setDetailsModal(false);
                }}
              >
                <CloseIcon sx={{ paddingTop: '0.1rem' }} />
              </button>
            </Box>
          </Box>
          <Box my={'0.8rem'}>
            <SearchInput setSearchQuery={setDistrictSearch} placeholder="Enter District Name" />
          </Box>
          <Box sx={{ height: '300px', overflowY: 'auto' }}>
            {regionDetails
              ?.filter((district) => {
                return district?.district?.toLowerCase()?.includes(districtSearch?.toLowerCase());
              })
              .map((district, index) => {
                return (
                  <Accordion sx={{ boxShadow: 'unset', marginTop: '0.5rem' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <Typography sx={{ fontFamily: 'inherit' }}>{district?.district}</Typography>
                      <Typography
                        sx={{
                          fontFamily: 'inherit',
                          order: '1',
                          marginLeft: 'auto',
                        }}
                      >
                        <NumberWrapper
                          value={
                            params.type == '' || params.type == 'Applications'
                              ? district?.total_male_applicants + district?.total_female_applicants
                              : district?.total_male_registrants + district?.total_female_registrants
                          }
                          size={'1.5rem'}
                        />
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          marginTop: '1rem',
                          justifyCenter: 'center',
                        }}
                      >
                        <Box sx={{ fontSize: '1rem' }}>Total Amount Requested</Box>
                        <Box
                          sx={{
                            marginLeft: 'auto',
                            order: '1',
                            fontSize: '1.5rem',
                          }}
                        >
                          {district?.total_amount_requested}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          marginTop: '1rem',
                          justifyCenter: 'center',
                        }}
                      >
                        <Box sx={{ fontSize: '1rem' }}>
                          Total Male {params.type == '' || params.type == 'Applications' ? 'Applicants' : 'Registrants'}
                        </Box>
                        <Box
                          sx={{
                            marginLeft: 'auto',
                            order: '1',
                            fontSize: '1.5rem',
                          }}
                        >
                          <NumberWrapper
                            size={'1.5rem'}
                            value={
                              params.type == '' || params.type == 'Applications'
                                ? district?.total_male_applicants
                                : district?.total_male_registrants
                            }
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          marginTop: '1rem',
                          justifyCenter: 'center',
                        }}
                      >
                        <Box sx={{ fontSize: '1rem' }}>
                          Total Female{' '}
                          {params.type == '' || params.type == 'Applications' ? 'Applicants' : 'Registrants'}
                        </Box>
                        <Box
                          sx={{
                            marginLeft: 'auto',
                            order: '1',
                            fontSize: '1.5rem',
                          }}
                        >
                          <NumberWrapper
                            size={'1.5rem'}
                            value={
                              params.type == '' || params.type == 'Applications'
                                ? district?.total_female_applicants
                                : district?.total_female_registrants
                            }
                          />
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </Box>
        </Box>
      </Modal>
      <div className="top flex flex-c">
        <div style={{ fontSize: '1rem', marginBottom: '0.3rem' }}>
          {params?.type === 'Applications' || params?.type === ''
            ? 'Applicant Regional Distribution'
            : 'Registrant Regional Distribution'}
        </div>
        {/* <div style={{fontWeight:'400',height:'0.9rem'}}>
                {hoveredRegion==='None'?'':hoveredRegion}
            </div>      */}
      </div>
      <div className="bottom flex" style={{ with: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <div className="left" style={{ marginRight: '2.5rem' }}>
          {/* <VectorMap {...ghanaJson} currentLayers = {[clickedId]} id="ghana-map" layerProps={layerProps}/> */}
          <VectorMap layerProps={layerProps} {...ghanaJson} currentLayers={getId(selectedRegion)} id="ghana-map" />
        </div>
        <div
          style={{
            textAlign: 'left',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              fontWeight: '700',
              marginBottom: '1rem',
              fontSize: '0.95rem',
            }}
          >
            {regionName == '' ? 'All Regions' : regionName + ' Region'}
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div style={breakDownText}>
              Total {params.type == 'Applications' || params.type == '' ? 'Applicants' : 'Registrants'}
              : <NumberWrapper size={'0.9rem'} value={totalvalues?.total} />
            </div>
            <div style={breakDownText}>
              Male {params.type == 'Applications' || params.type == '' ? 'Applicants' : 'Registrants'}
              : <NumberWrapper size={'0.9rem'} value={totalvalues?.male} />{' '}
            </div>
            <div style={breakDownText}>
              Female {params.type == 'Applications' || params.type == '' ? 'Applicants' : 'Registrants'}
              : <NumberWrapper size={'0.9rem'} value={totalvalues?.female} />{' '}
            </div>
          </div>
        </div>

        {/* <div className="right">
                <RegionalInformation regionName = {selectedRegion!==''?getRegionName(selectedRegion):''} />
            </div> */}
      </div>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button
          onClick={() => {
            resetMap();
          }}
          sx={{ textTransform: 'inherit', fontFamily: 'inherit' }}
        >
          Reset Map
        </Button>
      </div>
    </div>
  );
};

RegionalInformation.propTypes = {
  regionName: propTypes.string,
  selectedRegion: propTypes.string,
  setSelectedRegion: propTypes.func,
  setParams: propTypes.func,
  '': propTypes.array,
};

export default ApplicantRegionalDistribution;
