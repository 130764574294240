import React from 'react';
import { PredisbursementContext } from '@/utils/context/DisbursementContext';
import DisbursementBaseLayout from './components/DisbursementBaseLayout';
import { useGetPredisbursement } from './services/Predisbursement.service';
import { AppContext } from '@/utils/context/AppContext';
import DisbursementTable from './components/DisbursementTable';

const Predisbursement = () => {
  const {
    params,
    tabValue,
    tableData,
    predisbursementTableOptions,
    predisbursementTableColumns,
    regions,
    districts,
    isLoading,
    isRefetching,
    selectedItems,
    downloadCSV,
    setParams,
    setTabValue,
    filter,
  } = useGetPredisbursement();
  const { currentViewState } = React.useContext(AppContext);
  return (
    <PredisbursementContext.Provider
      value={{
        params: params,
        tabValue: tabValue,
        regions: regions,
        districts: districts,
        setParams: setParams,
        setTabValue: setTabValue,
      }}
    >
      <DisbursementBaseLayout
        title="Pre-Disbursement"
        currentViewState={currentViewState}
        tabValue={tabValue}
        onFilter={filter}
        setTabValue={setTabValue}
        type="pre"
      >
        {tabValue == 0 && (
          <>
            <DisbursementTable
              title={'Predisbursement Preview Table'}
              data={tableData}
              tableOptions={predisbursementTableOptions}
              columns={predisbursementTableColumns}
              isLoading={isLoading || isRefetching}
              actionDisabled={isLoading || selectedItems.length == 0}
              onActionButtonPress={downloadCSV}
              showDownloadCSVButton={true}
            />
          </>
        )}
        {tabValue == 1 && <></>}
      </DisbursementBaseLayout>
    </PredisbursementContext.Provider>
  );
};

export default Predisbursement;
