/*eslint-disable*/
import React from 'react';
import { Grid, Box, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useQuery, useQueryClient } from 'react-query';
import ApprovalsNav from '../Approvals/ApprovalsNav';
import FilterComponent2 from './FilterComponent2.tsx';
import PreviewTable from './PreviewTable.tsx';
import TraineeFileUpload from './TraineeFileUpload.tsx';
import ConfirmModal from './ConfirmModal.tsx';
import SearchComponent from './SearchComponent.tsx';

import {
  useRunApprovals,
  useGetTableData,
  getTitle,
  TEXT_CONSTANTS,
  checkPassed,
  getBackendTrainingStage,
  getPassedTrainees,
  checkSkipped,
  validateScores,
  getFailedTrainees,
  getBacktrackedTrainees,
} from './services/AdvancedTraining.service.ts';
import { getTrainingApprovals } from '../../service/Applicants';
import { useGetRegions, useGetDistricts } from '../../utils/hooks/getRegionDistricts';
import { messageAlert, handleErrorMessage } from '../../utils/helpers/functions';
import { PASS_THRESHOLD } from '../../utils/constants.js';
import apiConfig from '../../service/apiConfig';
import { IAdvancedTrainingBaseLayout, IFilterParams } from '@/utils/interfaces/approvals.interface.ts';
import useGetUserData from '@/utils/hooks/useGetUserData.jsx';
import { IApprovalsData, ApprovalTypeProps } from '@/utils/interfaces/api.approvals.interface.ts';
import ExpandButton from '../Buttons/ExpandButton.tsx';
import { AppContext } from '@/utils/context/AppContext.ts';

const AdvancedTrainingBaseLayout = (props: IAdvancedTrainingBaseLayout) => {
  const { currentViewState } = React.useContext(AppContext);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const { mutateFn: approvalsMutation } = useRunApprovals();
  const [selectedTrainees, setSelectedTrainees] = React.useState<any>([]);
  const navigate = useNavigate();
  const { userData, isLoading: userLoading } = useGetUserData();
  const [tabValue, setTabValue] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [isUploading, setIsUploading] = React.useState(false);
  const [params, setParams] = React.useState<IFilterParams>({
    tableData: [],
    age_lower_limit: '',
    age_upper_limit: '',
    disability: '',
    district: '',
    educational_level: '',
    gender: '',
    q: '',
    region: '',
    score_lower_limit: '',
    score_upper_limit: '',
    vocational_skill: '',
    pass_threshold: PASS_THRESHOLD,
  });
  const [traineeFile, setTraineeFile] = React.useState<File | null>();
  const [previewList, setPreviewList] = React.useState<any>([]);
  const { regions, error: regionsError } = useGetRegions();
  const { districts, error: districtsError } = useGetDistricts(regions, parseInt(params?.region));
  const {
    data: traineeData,
    isLoading,
    isRefetching,
    error,
    refetch,
  } = useQuery({
    queryKey: ['trainingList'],
    queryFn: () => {
      return getTrainingApprovals(
        currentPage + 1,
        10,
        params?.region == '' ? userData?.profile?.region?.id : params?.region,
        params?.district == '' ? userData?.profile?.district?.id : params?.district,
        params?.gender,
        params?.disability,
        params?.score_lower_limit,
        params?.score_upper_limit,
        params?.educational_level,
        params?.vocational_skill,
        params?.q,
        props?.stage,
      );
    },
    staleTime: Infinity,
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const onTableSelectionChange = (list: any[]) => {
    setSelectedTrainees(list);
  };

  const onSearchTextChange = (text: string) => {
    setParams({ ...params, q: text });
  };

  const onActionButtonPress = (tabValue:number)=>{
    switch(tabValue){
      case 0:
        downloadCSV()
        break
      case 1:
        openModal()
        break
      case 2:
        backtrackApplicant()
        break
      default:
        return
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj: File | undefined = e?.target?.files?.[0];
    if (fileObj) {
      setTraineeFile(fileObj);
      previewTable(fileObj);
    }
  };

  const previewTable = (file: File) => {
    setPreviewList([]);

    try {
      let reader = new FileReader();
      let isError = false;

      reader.addEventListener('loadend', () => {
        //
        let temp = typeof reader?.result === 'string' ? reader?.result.split('\r\n') : [];
        let tableArray = [];
        //start counting from the first row after the header row...
        // if (temp.length > 500) {
        //   messageAlert('error', 'Preview Failed, make sure the CSV has fewer than 500 rows and try again');
        //   return;
        // }
        for (let i = 1; i < temp.length; i++) {
          //
          let row = temp[i]
            .replace(/['"]+/g, '')
            .split(',')
            .splice(0, props?.stage == 'advanced' ? 7 : 6) as Array<any>;

          if (tabValue == 2){
            if (row[0]){
                tableArray.push(row);
            }
             
          }
          else {
  if (validateScores(props?.stage, row) == true) {
            let passedIndex1 = props?.stage == 'advanced' ? 8 : 7;
            let passedIndex2 = props?.stage == 'advanced' ? 7 : 6;
            let totalScore =
              props?.stage == 'advanced'
                ? parseInt(row[2]) + parseInt(row[3]) + parseInt(row[4]) + parseInt(row[5]) + parseInt(row[6])
                : parseInt(row[2]) + parseInt(row[3]) + parseInt(row[4]) + parseInt(row[5]);
            row[passedIndex1] = checkPassed(totalScore, params?.pass_threshold) || false ? true : false;
            row[passedIndex2] = totalScore.toString();
            tableArray.push(row);
          } else if (validateScores(props?.stage, row) == false) {
            isError = true;
          }
          }

        
          //create some kind of stage based mapping for the array,... pass and fail based on that
        }
        if (isError == true) {
          messageAlert('error', 'Some rows did not pass score validation, as a result the fields have been skipped');
        }
        setPreviewList(tableArray);
      });

      reader.readAsText(file);
    } catch (e) {
      messageAlert('error', 'Preview Failed, make sure file is in the right format');
    }
  };

  const downloadCSV = () => {
    if (selectedTrainees.length > 0) {
      try {
        let selectedApplicantList =
          props?.stage == 'advanced'
            ? [
                [
                  'Application ID',
                  'Full Name',
                  'Attendance (30)',
                  'Participation (15)',
                  'Class Exercise (15)',
                  'Business Plan Presentation(20)',
                  'Pitch Practice (20)',
                ],
              ]
            : [
                [
                  'Application ID',
                  'Full Name',
                  `Attendance(${props.stage == 'basic' ? '30' : '20'})`,
                  `Participation(${props.stage == 'basic' ? '20' : '20'})`,
                  `Class Exercise(${props.stage == 'basic' ? '20' : '20'})`,
                  props?.stage == 'basic' ? 'Business Model Canvas (BMC)(30)' : 'Business Plan(40)',
                ],
              ];

        selectedTrainees.forEach((id) => {
          const { tableData } = useGetTableData(traineeData?.data);

          let applicantObj = tableData.filter((applicant) => {
            return applicant[0] == id;
          })[0];

          applicantObj?.splice(props?.stage == 'advanced' ? 7 : 6); // this code removes the 'passed' column from the data before creating the CSV file

          // // applicantObj[applicantObj?.length - 1] = 0;
          // applicantObj?.splice(applicantObj?.length - 1, 1); //removes the action column from the row before pushing..
          selectedApplicantList.push(applicantObj);
        });
        let csvContent = 'data:text/csv;charset=utf-8,';

        selectedApplicantList.forEach(function (rowArray) {
          let row = rowArray.join(',');
          csvContent += row + '\r\n';
        });
        const uri = encodeURI(csvContent);
        var element = document.createElement('a');
        element.href = uri;
        element.target = '_blank';
        element.download = 'export.csv';
        element.click();

        setSelectedTrainees([]);
      } catch (e) {}
    }
  };

  const getActionDisabledStatus = (): boolean => {
    switch (tabValue) {
      case 0:
        return selectedTrainees.length == 0;
      case 1:
        return traineeFile == undefined;
      case 2:
        return traineeFile == undefined;
      default:
        return true;
    }
  };

  const clearData = () => {
    setTraineeFile(null);
    setPreviewList([]);
    setSelectedTrainees([]);
    refetch();
  };

  const backtrackApplicant = async() => {
        setConfirmOpen(false);
    setIsUploading(true);
      console.log(previewList)
    const _params: Partial<IApprovalsData> = {
    
      approval_type: 'basic_training_approval',
      id_list: getBacktrackedTrainees(previewList),
      
    };

    if (_params?.id_list.length > 0) {
      try {
        await apiConfig.postRequest(`staff/approvals/backtrack`, _params);
          messageAlert('success', 'Backtrack Successful');
      } catch (e) {
        let message = handleErrorMessage(error);
        messageAlert('error', `Make Sure All applicants are actually in Basic Training Approvals `);
      }
    }

    //submit failed applicants as well...

  
    setIsUploading(false);
    clearData();
  }

  const uploadCSV = async (destination?: 'basic' | 'intermediate' | 'advanced') => {
    setConfirmOpen(false);
    setIsUploading(true);
    const _params: IApprovalsData = {
      approval_type: getBackendTrainingStage(destination || props.stage) as unknown as ApprovalTypeProps,
      id_list: getPassedTrainees(previewList, props.stage, params.pass_threshold),
      user: userData,
      approval_status: 'approved',
      skipped_advanced_training: checkSkipped(props?.stage, getBackendTrainingStage(destination || props.stage)),
    };

    if (_params?.id_list.length > 0) {
      try {
        await apiConfig.postRequest(`staff/approvals/`, _params);
      } catch (e) {
        let message = handleErrorMessage(error);
        messageAlert('error', message);
      }
    }

    //submit failed applicants as well...

    const _failedParams: IApprovalsData = {
      approval_type: getBackendTrainingStage(destination || props.stage) as unknown as ApprovalTypeProps,
      id_list: getFailedTrainees(previewList, props.stage, params.pass_threshold),
      user: userData,
      approval_status: 'disqualified',
      skipped_advanced_training: checkSkipped(props?.stage, getBackendTrainingStage(destination || props.stage)),
    };

    if (_failedParams?.id_list.length > 0) {
      try {
        await apiConfig.postRequest(`staff/approvals/`, _failedParams);
      } catch (e) {
        let message = handleErrorMessage(error);
        messageAlert('error', message);
      }
    }
    messageAlert('success', 'Approval Successful');
    setIsUploading(false);
    clearData();
  };

  const downloadCSVTemplate = () => {
    let templateHeader =
      props?.stage == 'advanced'
        ? [
            [
              'Application ID',
              'Full Name',
              'Attendance (30)',
              'Participation (15)',
              'Class Exercise (15)',
              'Business Plan Presentation(20)',
              'Pitch Practice (20)',
            ],
          ]
        : [
            [
              'Application ID',
              'Full Name',
              `Attendance(${props.stage == 'basic' ? '30' : '20'})`,
              `Participation(${props.stage == 'basic' ? '20' : '20'})`,
              `Class Exercise(${props.stage == 'basic' ? '20' : '20'})`,
              props?.stage == 'basic' ? 'Business Model Canvas (BMC)(30)' : 'Business Plan(40)',
            ],
          ];
    let csvContent = 'data:text/csv;charset=utf-8,';

    templateHeader.forEach(function (rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });
    const uri = encodeURI(csvContent);
    var element = document.createElement('a');
    element.href = uri;
    element.target = '_blank';
    element.download = 'template.csv';
    element.click();
  };

  const openModal = () => {
    setConfirmOpen(true);
  };

  const checkSelected = (id: number | string) => {
    if (params?.district == '') {
      return id == userData?.profile?.district?.id;
    } else {
      return id == params?.district;
    }
  };

  const filter = () => {
    if (currentPage > 0) {
      setCurrentPage(0);
      return;
    }
    refetch();
  };

  React.useEffect(() => {
    if (traineeFile) {
      previewTable(traineeFile);
    }
  }, [params?.pass_threshold]);

  React.useEffect(() => {
    handleErrorMessage(error);
    handleErrorMessage(regionsError);
    handleErrorMessage(districtsError);
  }, [error, regionsError, districtsError]);

  React.useEffect(() => {
    refetch();
  }, [currentPage]);

  React.useEffect(() => {
    refetch();
  }, []);

  document.body.style.cssText = 'overflow-y:hidden!important';

  if (error) {
    let message = handleErrorMessage(error);
    messageAlert({ messageType: 'error', msg: message });
  }

  if (isLoading == true) return 'Loading';

  return (
    <Grid container sx={{ height: '100%', overflowY: 'auto' }}>
      <ConfirmModal open={confirmOpen} setOpen={setConfirmOpen} stage={props.stage} onConfirm={(params)=>{
        console.log(tabValue)
        if(tabValue == 2){
          backtrackApplicant()
        }
        else {
          uploadCSV(params)
        }     
       }} />
      {currentViewState == 'expanded' && <ApprovalsNav selectedView={props.currentPage} />}

      <Grid
        item
        md={currentViewState == 'expanded' ? 9 : 12}
        xs={12}
        sm={12}
        lg={currentViewState == 'expanded' ? 10 : 12}
        xl={currentViewState == 'expanded' ? 10 : 12}
        sx={{ display: 'flex', flexDirection: 'column', padding: '2.5rem' }}
      >
        <Box sx={{ fontSize: '1.2rem', fontWeight: '600', marginBottom: '0.1rem' }}>
          <ExpandButton />
          {getTitle(props.stage)}
        </Box>
        <Box className="bottom" style={{ borderTop: '1px solid #e4e4e4', marginTop: '30px', marginBottom: '10px' }}>
          <Tabs
            className="custom-tabs"
            value={tabValue}
            onChange={(e, value) => {
              setTabValue(value);
            }}
            style={{}}
            aria-label="basic tabs example"
          >
            <Tab label="Applicants In Training" value={0} />
            <Tab label="Trained Applicants" value={1} />
            {props.stage == 'basic' &&<Tab label="Backtrack Applicant" value={2}/>}
          </Tabs>
        </Box>
        {tabValue == 0 && (
          <FilterComponent2
            params={params}
            setParams={setParams}
            districts={districts}
            regions={regions}
            checkSelected={checkSelected}
            onFilter={filter}
          />
        )}
        {(tabValue == 1|| tabValue == 2) && <TraineeFileUpload handleFileChange={handleFileChange} trainingListFile={traineeFile} prompt={tabValue == 2?'Upload Excel sheet of applicants you want backtracked':null} />}
        <Box marginY={'15px'}>
          <SearchComponent
            onTextChange={onSearchTextChange}
            onActionButtonPress={()=>{onActionButtonPress(tabValue)}}
            type={tabValue == 0 ? 'list' : 'preview'}
            buttonDescription={tabValue == 0 ? TEXT_CONSTANTS.action_text_list : TEXT_CONSTANTS.action_text_preview}
            actionDisabled={getActionDisabledStatus()}
            handleTemplateDownload={downloadCSVTemplate}
          />
        </Box>
        {tabValue == 0 && (
          <PreviewTable
            data={tabValue == 0 ? traineeData : previewList}
            type={tabValue == 0 ? 'list' : 'preview'}
            loadingState={isLoading || isRefetching || approvalsMutation.isLoading || isUploading}
            onPageChange={handlePageChange}
            setSelectedItems={onTableSelectionChange}
            setBaseLayoutParams={setParams}
            params={params}
            stage={props?.stage}
          />
        )}
        {tabValue == 1 && (
          <PreviewTable
            data={previewList}
            type={'preview'}
            loadingState={isLoading || isRefetching || approvalsMutation.isLoading || isUploading}
            onPageChange={handlePageChange}
            setSelectedItems={onTableSelectionChange}
            setBaseLayoutParams={setParams}
            params={params}
            stage={props?.stage}
          />
        )}
         {tabValue == 2 && (
          <PreviewTable
            data={previewList}
            type={'preview'}
            loadingState={isLoading || isRefetching || approvalsMutation.isLoading || isUploading}
            onPageChange={handlePageChange}
            setSelectedItems={onTableSelectionChange}
            setBaseLayoutParams={setParams}
            params={params}
            stage={props?.stage}
          />
        )}

        {props?.children}
      </Grid>
    </Grid>
  );
};

export default AdvancedTrainingBaseLayout;
