/*eslint-disable*/
import React from 'react';
import {
  DisbursementContainer,
  DisbursementMainLayout,
  DisbursementNavigationWrapper,
  DisbursementRootComponent,
  DisbursementRootTop,
} from './styledcomponents/DisbursementBaseLayout.components';
import { IDisbursementBaseLayout } from '../interfaces/DisbursementBaseLayout.interface';
import ExpandButton from '@/components/Buttons/ExpandButton';
import { Tabs, Tab } from '@mui/material';
import DisbursementFilter from './DisbursementFilter';
import { DisbursementMainRight } from './styledcomponents/DisbursementBaseLayout.components';
import ApplicantNavbar from '@/components/ApplicantNavbar/ApplicantNavbar';
import ApplicantSidebar from '@/components/ApplicantSidebar/ApplicantSidebar';
import { PostDisbursementContext, PredisbursementContext } from '@/utils/context/DisbursementContext';

const DisbursementBaseLayout = ({
  currentViewState,
  title,
  tabs,
  tabValue,
  showFilter,
  type,
  setTabValue,
  onFilter,
  ...props
}: IDisbursementBaseLayout) => {
  const { regions, params, setParams, districts } = React.useContext(
    type == 'pre' ? PredisbursementContext : PostDisbursementContext,
  );
  return (
    <DisbursementMainLayout>
      <ApplicantSidebar selectedView={7} />
      <DisbursementMainRight>
        <ApplicantNavbar type={''} navBack="/applicants" />
        <DisbursementContainer container as="section">
          <DisbursementRootComponent
            item
            md={currentViewState == 'expanded' ? 9 : 12}
            xs={12}
            sm={12}
            lg={currentViewState == 'expanded' ? 10 : 12}
            xl={currentViewState == 'expanded' ? 10 : 12}
          >
            <DisbursementRootTop>
              {/* <ExpandButton /> */}
              {title}
            </DisbursementRootTop>

            {tabs && tabs?.length > 0 !== false && (
              <DisbursementNavigationWrapper>
                <Tabs
                  className="custom-tabs"
                  value={tabValue}
                  onChange={(e, value) => {
                    setTabValue(value);
                  }}
                >
                  {tabs?.map((tabTitle, index) => (
                    <Tab label={tabTitle} value={index} key={`${tabTitle}-${index}`} />
                  ))}
                </Tabs>
              </DisbursementNavigationWrapper>
            )}
            {showFilter !== false && (
              <DisbursementFilter
                regions={regions}
                districts={districts}
                onFilter={onFilter}
                setParams={setParams}
                params={params}
              />
            )}
            {props?.children}
          </DisbursementRootComponent>
        </DisbursementContainer>
      </DisbursementMainRight>
    </DisbursementMainLayout>
  );
};

export default DisbursementBaseLayout;
