/*eslint-disable*/
import React from 'react';
import {
  ChartWrapper,
  ChartTitle,
  PieChartWrapper,
  PieChartFooter,
  PieChartLegend,
  PieChartLegendValue,
  PieChartDescription,
  PieChartDescriptionText,
} from './styled/chart.styledcomponents';
import { IChart, IChartDataObject } from './interfaces/chart.interfaces';
import { RadialChartPoint, RadialChart } from 'react-vis';
import NumberWrapper from '../NumberWrapper';

interface IPieChart extends IChart<IChartDataObject[]> {
  item_description?: string;
}

const PieChart = ({ title, data, item_description }: IPieChart) => {
  const [chartData, setChartData] = React.useState<RadialChartPoint[]>([]);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    const sum = data?.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.y || 0);
    }, 0);
    const _chartData: RadialChartPoint[] = [];
    data?.forEach((data) => {
      _chartData.push({
        angle: ((data.y || 0) / (sum || 0)) * 360,
        label: data.x,
        color: data.color,
        style: { stroke: data.color, fill: data.color },
      });
    });
    setChartData(_chartData);
    setTotal(sum);
  }, [data]);

  return (
    <ChartWrapper>
      <ChartTitle>{title}</ChartTitle>
      <PieChartWrapper>
        <PieChartDescription>
          <PieChartDescriptionText>Total {item_description || 'Trainees'}</PieChartDescriptionText>
          <NumberWrapper value={total} align={'center'} />
        </PieChartDescription>
        <RadialChart data={chartData} width={300} height={300} innerRadius={130} radius={80} />
      </PieChartWrapper>
      <PieChartFooter container>
        {data?.map((value, index) => {
          return (
            <PieChartLegend key={`${index}-${value.label}`} md={3} xs={12}>
              <span className="key-color" style={{ backgroundColor: value?.color }}></span>
              <PieChartLegendValue>
                <div>{value.x}</div>
                <NumberWrapper value={value.y} size={'0.8rem'} />
              </PieChartLegendValue>
            </PieChartLegend>
          );
        })}
      </PieChartFooter>
    </ChartWrapper>
  );
};

export default PieChart;
