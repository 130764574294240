/*eslint-disable*/
import { Button } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

export const basicColumns: MUIDataTableColumnDef[] = [
  'Application ID',
  'Full Name',
  'Attendance',
  'Participation',
  'Class Exercise',
  'Business Model Canvas',
  {
    name: 'Action',
    options: {
      customBodyRender: (value: number) => {
        return (
          <Link to={`/applicants/${value}`}>
            <Button onClick={() => {}} sx={{ textTransform: 'none', fontFamily: 'inherit', fontWeight: '600' }}>
              View
            </Button>
          </Link>
        );
      },
    },
  },
];

export const intermediateColumns: MUIDataTableColumnDef[] = [
  'Application ID',
  'Full Name',
  'Attendance',
  'Participation',
  'Class Exercise',
  'Business Plan',
  {
    name: 'Action',
    options: {
      customBodyRender: (value: number) => {
        return (
          <Link to={`/applicants/${value}`}>
            <Button onClick={() => {}} sx={{ textTransform: 'none', fontFamily: 'inherit', fontWeight: '600' }}>
              View
            </Button>
          </Link>
        );
      },
    },
  },
];

export const advancedColumns: MUIDataTableColumnDef[] = [
  'Application ID',
  'Full Name',
  'Attendance',
  'Participation',
  'Class Exercise',
  'Business Plan Presentation',
  'Pitch Practice',
  {
    name: 'Action',
    options: {
      customBodyRender: (value: number) => {
        return (
          <Link to={`/applicants/${value}`}>
            <Button onClick={() => {}} sx={{ textTransform: 'none', fontFamily: 'inherit', fontWeight: '600' }}>
              View
            </Button>
          </Link>
        );
      },
    },
  },
];
