export const useLoadGrantExpenditures = (rowData: any) => {
  try {
    console.log(rowData, 3333);
    let rows: any[][] = [];
    let totalAmount = 0;
    let totalQuantity = 0;
    let totalUnitCost = 0;

    rowData?.forEach((row: any) => {
      let invoiceArray: any = [];
      row?.invoices?.forEach((invoice: any) => {
        invoiceArray.push(invoice?.invoice);
      });
      rows.push([row.item, row.quantity, row.unit_cost, row.total_amount_spent, invoiceArray]);
      totalQuantity = totalQuantity + row.quantity;
      totalUnitCost = totalUnitCost + parseInt(row.unit_cost);
      totalAmount = totalAmount + parseInt(row.total_amount_spent);
    });

    rows.push(['Total Expected Expenditure', totalQuantity, totalUnitCost, totalAmount]);
    return { rowData: rows };
  } catch (e) {
    return { rowData: [] };
  }
};
