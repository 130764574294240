/*eslint-disable*/
import { defaultActiveColor } from '@/utils/constants';
import styles from './modal.module.css';
import React, { ReactNode } from 'react';
import { Modal, Box, Button, styled } from '@mui/material';
import { ModalTitle } from '../styled/modal.component';
import CloseIcon from '@material-ui/icons/Close';

const ConfirmButton = styled(Button)({
  //@ts-ignore
  backgroundColor: `${defaultActiveColor}`,
  color: 'white!important',
  padding: '10px!important',
  textTransform: 'initial!important',
  minWidth: '20%',
  fontWeight: '500!important',
  fontSize: '15px',
  '&:hover': {
    backgroundColor: 'white!important',
    border: `1px solid ${defaultActiveColor}!important`,
    color: `${defaultActiveColor}!important`,
  },
  fontFamily: 'inherit!important',
});

const CancelButton = styled(Button)({
  //@ts-ignore
  backgroundColor: 'white',
  minWidth: '20%',
  color: `${defaultActiveColor}`,
  textTransform: 'initial!important',
  fontWeight: '500!important',
  fontSize: '15px',
  border: `1px solid ${defaultActiveColor}`,
  marginRight: '10px',
  fontFamily: 'inherit!important',
});

interface IConfirmModalBase {
  onConfirm: any;
  children: ReactNode;
  confirmText?: string;
}

const ConfirmModalBase = ({ children, onConfirm, confirmText }: IConfirmModalBase) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
      >
        <Box className={`${styles['modal-wrapper']}`}>
          <Box className={`${styles['modal-title']}`}>
            <ModalTitle>Confirm Action</ModalTitle>
            <Button
              onClick={() => {
                setOpen(!open);
              }}
            >
              <CloseIcon style={{ color: 'black' }} />
            </Button>
          </Box>
          <Box marginY={'20px'} fontSize={'16px'}>
            Do You Confirm this action?
          </Box>

          <div className={`${styles['modal-footer']}`}>
            <CancelButton
              onClick={() => {
                setOpen(!open);
              }}
            >
              Cancel
            </CancelButton>
            <ConfirmButton
              onClick={() => {
                onConfirm();
                setOpen(!open);
              }}
            >
              {confirmText || 'Submit'}
            </ConfirmButton>
          </div>
        </Box>
      </Modal>
      <Box
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default ConfirmModalBase;
