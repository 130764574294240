import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Homepage from '@views/Homepage';
import React from 'react';
import SignIn from '@views/Signin';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Applicants from '@views/Applicants';
import RequireAuth from '@service/RequireAuth';
import StaffManagement from '@components/Staff/StaffManagement';
import AdminUsers from '@views/AdminUsers';
import Roles from '@components/Settings/Roles';
import Pass from '@components/Settings/Pass';
import AutomaticScoring from '@components/Settings/AutomaticScoring';
import Interview from '@components/Approvals/Interview';
import ApprovalsMainLayout from '@components/Approvals/ApprovalsMainLayout';
// import Loan from '@components/Approvals/Loan';
import TrainingScore from '@components/Score/TrainingScore';
import ApplicatsApproval from '@components/Approvals/Applicants';
import NotFound from '@views/NotFound';
import Grants from '@components/Approvals/Grants';
import Profile from '@components/UserProfile/Profile';
import DataAnalysis from '@components/Reports/DataAnalysis/DataAnalysis';
import DetailedReports from '@components/Reports/DetailedReports/DetailedReports';
import UserDetails from '@components/Reports/UserDetails/UserDetails';
import AuditTrail from '@components/Settings/AuditTrail';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import AdvancedTrainingBaseLayout from '../src/components/AdvancedTraining/AdvancedTrainingBaseLayout.tsx';
import { AppContext } from './utils/context/AppContext.ts';
import GrantAssessment from './components/GrantAssessment/GrantAssessment.tsx';
import Predisbursement from './components/Disbursement/Predisbursement.tsx';
import { PermissionsWrapper } from './components/Users/PermissionsWrapper.tsx';
import NotFoundDashboard from './views/NotFoundDashboard.tsx';
import Postdisbursement from './components/Disbursement/Postdisbursement.tsx';
import GrantAssessmentPrint from './components/GrantAssessment/GrantAssessmentPrint.tsx';
import GrantAssessmentBatch from './components/GrantAssessmentBatch/GrantAssessmentBatch.tsx';
import MonitoringEvalutation from './components/MonitoringEvaluation/MonitoringEvalutation.tsx';

function App() {
  const queryClient = new QueryClient();
  const [currentViewState, setCurrentViewState] = React.useState('closed');

  /*eslint-disable*/

  /*eslint-enable*/

  return (
    <AppContext.Provider value={{ currentViewState: currentViewState, setCurrentViewState: setCurrentViewState }}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="noAuthDashboard" element={<NotFoundDashboard />} />
            <Route
              path="/applicants"
              element={
                <RequireAuth>
                  <Applicants />
                </RequireAuth>
              }
            />
            <Route
              path="/applicants/:id"
              element={
                <RequireAuth>
                  <Applicants />
                </RequireAuth>
              }
            />
            <Route
              path="/applicants/:id/:type"
              element={
                <RequireAuth>
                  <Applicants />
                </RequireAuth>
              }
            />
            <Route
              path="/applicants/:id/:type/:category"
              element={
                <RequireAuth>
                  <Applicants />
                </RequireAuth>
              }
            />
            <Route
              path="/grantassessment"
              element={
                <RequireAuth>
                  <GrantAssessment key={0} />
                </RequireAuth>
              }
            />
            <Route
              path="/grantassessment/:id"
              element={
                <RequireAuth>
                  <GrantAssessment key={1} />
                </RequireAuth>
              }
            />
            <Route
              path="/grantassessment/print/:id"
              element={
                <RequireAuth>
                  <GrantAssessmentPrint />
                </RequireAuth>
              }
            />
            <Route
              path="/grantassessment/batch"
              element={
                <RequireAuth>
                  <GrantAssessmentBatch />
                </RequireAuth>
              }
            />

            <Route path="/monitoring" element={
              <RequireAuth>
               <MonitoringEvalutation/>
              </RequireAuth>
            }/>

            <Route
              path="/staff/management"
              element={
                <RequireAuth>
                  <StaffManagement />
                </RequireAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />

            <Route
              path="/admin/details/:id"
              element={
                <RequireAuth>
                  <AdminUsers />
                </RequireAuth>
              }
            />
            <Route
              element={
                <RequireAuth>
                  <ApprovalsMainLayout />
                </RequireAuth>
              }
            >
              <Route path="/applicant/approvals" element={<ApplicatsApproval />} />
              <Route path="/interview/approvals" element={<Interview />} />
              {/* <Route path="/training/approvals" element={<Training/>} /> */}
              <Route
                path="/training/advancedTraining/basic"
                element={
                  <PermissionsWrapper permission={'training approvals'}>
                    <AdvancedTrainingBaseLayout stage={'basic'} currentPage={4} key={Math.random()} />
                  </PermissionsWrapper>
                }
              />
              <Route
                path="/training/advancedTraining/intermediate"
                element={
                  <PermissionsWrapper permission={'training approvals'}>
                    <AdvancedTrainingBaseLayout stage={'intermediate'} currentPage={5} key={Math.random()} />
                  </PermissionsWrapper>
                }
              />
              <Route
                path="/training/advancedTraining/advanced"
                element={
                  <PermissionsWrapper permission={'training approvals'}>
                    <AdvancedTrainingBaseLayout stage={'advanced'} currentPage={6} key={Math.random()} />
                  </PermissionsWrapper>
                }
              />
              {/* <Route
                path="/training/approvals"
                element={
                  <PermissionsWrapper
                    key={Math.random()}
                    shouldShow={checkPermissions('grant approvals', userData) == true}
                  >
                    <AdvancedTrainingBaseLayout stage={'basic'} currentPage={4} key={7} />
                  </PermissionsWrapper>
                }
              /> */}
              <Route
                path="/grant/approvals/skipped"
                element={
                  <PermissionsWrapper permission={'grant approvals'}>
                    <Grants type={'skipped'} key={Math.random()} />
                  </PermissionsWrapper>
                }
              />

              <Route
                path="/grant/approvals/regular"
                element={
                  <PermissionsWrapper key={Math.random()} permission={'grant approvals'}>
                    <Grants type="regular" key={Math.random()} />
                  </PermissionsWrapper>
                }
              />
              <Route path="/training/:id/score" element={<TrainingScore />} />
            </Route>
            <Route
              path="/predisbursement"
              element={
                //<PermissionsWrapper permission={'pre disbursement'}>
                  <Predisbursement key={Math.random()} />
               // </PermissionsWrapper>
              }
            />
            <Route
              path="/postdisbursement"
              element={
                //<PermissionsWrapper permission={'post disbursement'}>
                  <Postdisbursement key={Math.random()} />
               // </PermissionsWrapper>
              }
            />

            <Route
              path="/settings/roles"
              element={
                <RequireAuth>
                  <Roles />
                </RequireAuth>
              }
            />
            <Route
              path="/settings/pass"
              element={
                <RequireAuth>
                  <Pass />
                </RequireAuth>
              }
            />
            <Route
              path="/settings/automaticScoring"
              element={
                <RequireAuth>
                  <AutomaticScoring />
                </RequireAuth>
              }
            />
            <Route
              path="/settings/auditTrail"
              element={
                <RequireAuth>
                  <AuditTrail />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Homepage />
                </RequireAuth>
              }
            />
            <Route
              path="/reports/dataAnalysis"
              element={
                <RequireAuth>
                  <PermissionsWrapper permission={'data analysis'}>
                    <DataAnalysis />
                  </PermissionsWrapper>
                </RequireAuth>
              }
            />
            <Route
              path="/reports/userDetails"
              element={
                <RequireAuth>
                  <PermissionsWrapper permission={'detailed report'}>
                    <UserDetails />
                  </PermissionsWrapper>
                </RequireAuth>
              }
            />
            <Route
              path="/reports/detailedReports"
              element={
                <RequireAuth>
                  <PermissionsWrapper permission={'detailed report'}>
                    <DetailedReports />
                  </PermissionsWrapper>
                </RequireAuth>
              }
            />
            <Route
              path="/admins"
              element={
                <RequireAuth>
                  <AdminUsers />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </AppContext.Provider>
  );
}

export default App;
