/*eslint-disable*/
import React from 'react';
import apiConfig from '@/service/apiConfig';
import { useGetRegions, useGetDistricts } from '@/utils/hooks/getRegionDistricts';
import { IFilterParams } from '@/utils/interfaces/approvals.interface';
import { useQuery } from 'react-query';
import {
  BATCH_ASSESSMENT_PASS_THRESHOLD,
  getBatchGrantAssessmentColumns,
  getBatchGrantAssessmentPreviewColumns,
  getBatchGrantAssessmentTableOptions,
  getGrantAssessmentPreviewOptions,
} from '../constants';
import { handleErrorMessage, messageAlert } from '@/utils/helpers/functions';
import { useGenerateAssessmentCSV } from './useGenerateBatchAssementCSV';
import { AxiosResponse } from 'axios';
import { useGetGrantAssessmentTableData } from './useGetGrantAssessmentTableData';
import { IApprovalsData } from '@/utils/interfaces/api.approvals.interface';
import useGetUserData from '@/utils/hooks/useGetUserData';
import { getBatchList, getGetGranttAssessmentlist, getTemplateHeaders, sumArray, validateScores } from '../helpers';

const tableBodyHeight = `${window.innerHeight * 0.95}px`;
const tableBodyMaxHeight = '';

const useGrantAssessmentBatch = () => {
  const [skipped, setSkipped] = React.useState(false);
  const { userData, isLoading: userLoading } = useGetUserData();
  const [uploading, setUploading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [previewList, setPreviewList] = React.useState<any[][]>([]);
  const [showFilter, setShowFilter] = React.useState(true);
  const [templateFile, setTemplateFile] = React.useState<File>();
  const [params, setParams] = React.useState<IFilterParams>({
    tableData: [],
    age_lower_limit: '',
    age_upper_limit: '',
    disability: '',
    district: '',
    educational_level: '',
    gender: '',
    q: '',
    region: '',
    score_lower_limit: '',
    score_upper_limit: '',
    vocational_skill: '',
    pass_threshold: 0,
  });
  const { regions, error: regionsError } = useGetRegions();
  const { districts, error: districtsError } = useGetDistricts(regions, parseInt(params?.region || '1'));
  const { data, isLoading, isRefetching, error, refetch } = useQuery<AxiosResponse<IApprovalsList>>(
    'training-list',
    () => {
      return getGetGranttAssessmentlist({
        district: params.district,
        region: params.region,
        page: currentPage,
        q: params.q,
      });
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  React.useEffect(() => {
    const { count, tableData } = useGetGrantAssessmentTableData(data);
    setTableData(tableData);
    setCount(count);
  }, [data]);

  const getSelectedRows = (rowArray: any[]) => {
    try {
      let rowDataArray: any = [];
      rowArray.forEach((rowIndex: string | number | any) => {
        let _applicantData = tableData[rowIndex];
        console.log(_applicantData[1], 89999);
        rowDataArray.push(_applicantData[1]); //this index is where the id is stored
      });
      return rowDataArray;
    } catch (e) {
      return [];
    }
  };

  const filter = () => {
    if (currentPage > 0) {
      setCurrentPage(0);
      return;
    }
    refetch();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj: File | undefined = e?.target?.files?.[0];
    if (fileObj) {
      setTemplateFile(fileObj);
      previewTable(fileObj);
    }
  };

  const previewTable = (file: File) => {
    let hasError = false;
    try {
      let reader = new FileReader();
      reader.addEventListener('loadend', () => {
        let temp = typeof reader?.result === 'string' ? reader?.result.split('\r\n') : [];
        let tableArray = [];
        for (let i = 1; i < temp.length; i++) {
          let row = temp[i].replace(/['"]+/g, '').split(',').slice(0, temp[i].length) as Array<any>;
          if (row.length > 1) {
            if (validateScores(row.slice(1, row.length), skipped) == true) {
              tableArray.push([
                ...row,
                sumArray(row.slice(1, row.length)) >= BATCH_ASSESSMENT_PASS_THRESHOLD ? 'Passed' : 'Failed',
              ]);
            } else {
              hasError = true;
            }
          }
        }
        if (hasError) {
          messageAlert(
            'error',
            'Some rows contained invalid values, those rows have been removed from the uploaded csv.',
          );
        }

        setPreviewList(tableArray);
      });
      reader.readAsText(file);
    } catch (e: any) {
      messageAlert('error', e.message || 'Preview Failed, make sure file is in the right format');
    }
  };

  const clearData = () => {
    setPreviewList([]);
    setTabValue(0);
    refetch();
    setTemplateFile(null);
  };

  const downloadCSV = () => {
    const { success } = useGenerateAssessmentCSV({
      rows: selectedItems,
      columns: getTemplateHeaders(skipped),
      data: data,
    });

    if (success) {
      messageAlert('success', 'CSV Downloaded');
      setSelectedItems([]);
    }
  };

  const uploadCSV = async () => {
    setUploading(true);
    if (previewList.length > 0) {
      try {
        const _params: IApprovalsData = {
          approval_type: 'pre_disbursement_approval',
          id_list: getBatchList(previewList, true, BATCH_ASSESSMENT_PASS_THRESHOLD),
          user: userData,
          approval_status: 'approved',
        };
        await apiConfig.postRequest(`staff/approvals/`, _params);

        if (getBatchList(previewList, false, BATCH_ASSESSMENT_PASS_THRESHOLD).length > 0) {
          const _params: IApprovalsData = {
            approval_type: 'pre_disbursement_approval',
            id_list: getBatchList(previewList, false, BATCH_ASSESSMENT_PASS_THRESHOLD),
            user: userData,
            approval_status: 'disqualified',
          };
          await apiConfig.postRequest(`staff/approvals/`, _params);
        }
        messageAlert('success', 'Approval Successful');
        clearData();
      } catch (e) {
        console.log(e);
        let message = handleErrorMessage(error);
        messageAlert('error', message);
      }
    }
    setUploading(false);
  };

  const batchGrantAssessmentTableOptions = getBatchGrantAssessmentTableOptions({
    count: count,
    currentPage: currentPage,
    tableBodyHeight: tableBodyHeight,
    tableBodyMaxHeight: tableBodyMaxHeight,
    selectedItems: selectedItems,
    setCurrentPage: setCurrentPage,
    getSelectedRows: getSelectedRows,
    setSelectedItems: setSelectedItems,
  });
  const batchGrantAssessmentTablePreviewOptions = getGrantAssessmentPreviewOptions({
    tableBodyHeight: tableBodyHeight,
    tableBodyMaxHeight: tableBodyMaxHeight,
  });
  const batchGrantAssessmentPreviewColumns = getBatchGrantAssessmentPreviewColumns(skipped);
  const batchGrantAssessmentColumns = getBatchGrantAssessmentColumns();

  React.useEffect(() => {
    if (templateFile) {
      previewTable(templateFile);
    }
  }, [skipped]);

  React.useEffect(()=>{
    refetch()
  },[currentPage])

  return {
    regions,
    params,
    districts,
    tabValue,
    batchGrantAssessmentTableOptions,
    batchGrantAssessmentColumns,
    showFilter,
    batchGrantAssessmentTablePreviewOptions,
    batchGrantAssessmentPreviewColumns,
    previewList,
    tableData,
    isLoading: isLoading || isRefetching || uploading,
    skipped,
    templateFile,
    setSkipped,
    uploadCSV,
    downloadCSV,
    handleFileChange,
    filter,
    setShowFilter,
    setTabValue,
    setParams,
  };
};

export default useGrantAssessmentBatch;
