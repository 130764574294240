import { defaultActiveColor } from '@/utils/constants';

export const getDisbursementGenderChartColorMapping = (key: string) => {
  switch (key) {
    case 'M':
      return '#FFA000';
    case 'F':
      return '#407BFF';
    default:
      return defaultActiveColor;
  }
};

export const getDisbursementDisabilityChartColorMapping = (key: string) => {
  switch (key) {
    case 'Yes':
      return '#2A3B8F';
    case 'No':
      return '#7A71D1';
    default:
      return defaultActiveColor;
  }
};

export const getDisbursementAgeGroupChartColorMapping = (key: string) => {
  switch (key) {
    case '18-30yrs':
      return '#FFA000';
    case '31-35yrs':
      return '#FF725E';
    case '36-40yrs':
      return '#00C9BA';
    case '41 years and above':
      return '#407BFF';
    default:
      return defaultActiveColor;
  }
};

export const getGrantGenderChartColorMapping = (key: string) => {
  switch (key) {
    case 'M':
      return '#FFA000';
    case 'F':
      return '#407BFF';
    default:
      return defaultActiveColor;
  }
};

export const getGrantDisabilityChartColorMapping = (key: string) => {
  switch (key) {
    case 'Yes':
      return '#2A3B8F';
    case 'No':
      return '#7A71D1';
    default:
      return defaultActiveColor;
  }
};

export const getGrantAgeGroupChartColorMapping = (key: string) => {
  switch (key) {
    case '18-30yrs':
      return '#FFA000';
    case '31-35yrs':
      return '#FF725E';
    case '36-40yrs':
      return '#00C9BA';
    case '41 years and above':
      return '#407BFF';
    default:
      return defaultActiveColor;
  }
};

export const getTrainingGenderChartColorMapping = (key: string) => {
  switch (key) {
    case 'M':
      return '#0052B4';
    case 'F':
      return '#FF725E';
    default:
      return defaultActiveColor;
  }
};

export const getDisbursementGenderValueChartColorMapping = (key: string) => {
  switch (key) {
    case 'M':
      return 'rgb(255, 160, 0)';
    case 'F':
      return 'rgb(0, 201, 186)';
    default:
      return defaultActiveColor;
  }
};

export const getDisabilityValueChartColorMapping = (key: string) => {
  switch (key) {
    case 'M':
      return '#0052B4';
    case 'F':
      return '#FF725E';
    default:
      return defaultActiveColor;
  }
};

export const getDisabilityChartColorMapping = (key: string) => {
  switch (key) {
    case 'Yes':
      return '#2A3B8F';
    case 'No':
      return '#7A71D1';
    default:
      return defaultActiveColor;
  }
};

export const getAgeGroupChartColorMapping = (key: string) => {
  switch (key) {
    case '18-30yrs':
      return '#407BFF';
    case '31-35yrs':
      return '#00C9BA';
    case '36-40yrs':
      return '#FF725E';
    case '41 years and above':
      return '#FFA000';
    default:
      return defaultActiveColor;
  }
};
