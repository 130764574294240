/*eslint-disable*/
import React from 'react';
import { useGetGrantDashboard } from './components/services/Grantdashboard.service';
import HorizontalBarChart from './components/HorizontalBarChart';
import MapComponent from './components/MapComponent';
import TrainingStageSwitcher from './components/TrainingStageSwitcher';
import { TrainingDashboardRoot, DashboardComponent } from './components/styled/trainingdashboard.styledcomponents';
import PieChart from './components/PieChart';
import GrantStageSwitcher from './components/GrantStageSwitcher';

const GrantDashboard = () => {
  const {
    ageDistributionChartData,
    disabilityChartData,
    genderChartData,
    educationalLevelChartData,
    sectorChartData,
    regionalDistributionData,
    setSkippedAdvancedTraining,
    skippedAdvancedTraining,
  } = useGetGrantDashboard();

  return (
    <TrainingDashboardRoot container columnSpacing={2}>
      <DashboardComponent item xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <GrantStageSwitcher setStage={setSkippedAdvancedTraining} currentStage={skippedAdvancedTraining} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={4}>
        <PieChart title={genderChartData.title} data={genderChartData?.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={4}>
        <MapComponent data={regionalDistributionData.data} title={regionalDistributionData.title} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={4}>
        <PieChart title={disabilityChartData.title} data={disabilityChartData?.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={12}>
        <HorizontalBarChart title={educationalLevelChartData.title} data={educationalLevelChartData.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={4}>
        <PieChart title={ageDistributionChartData.title} data={ageDistributionChartData?.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={8}>
        <HorizontalBarChart title={sectorChartData.title} data={sectorChartData?.data} />
      </DashboardComponent>
    </TrainingDashboardRoot>
  );
};

export default GrantDashboard;
