/*eslint-disable*/
import React from 'react';
import {
  DisbursementContainer,
  DisbursementMainLayout,
  DisbursementMainRight,
  DisbursementNavigationWrapper,
  DisbursementRootComponent,
  DisbursementRootTop,
} from '../Disbursement/components/styledcomponents/DisbursementBaseLayout.components';
import { Tabs, Tab, Button, Box } from '@mui/material';
import ApplicantNavbar from '@/components/ApplicantNavbar/ApplicantNavbar';
import ApplicantSidebar from '@/components/ApplicantSidebar/ApplicantSidebar';
import { GrantAssessmentBatchContext } from './GrantAssessmentBatchContext';
import useGrantAssessmentBatch from './hooks/useGrantAssessmentBatch';
import { grantTabs } from './constants';
import GrantAssessmentBatchFilter from './components/GrantAssessmentBatchFilter';
import GrantAssessmentBatchTable from './components/GrantAssessmentBatchTable';
import GrantAssessmentBatchFileUpload from './components/GrantAssessmentBatchFileUpload';
import { downloadCSVTemplate } from './helpers';
import SwitchComponent from '../Switch/SwitchComponent';
import ConfirmModal from '../AdvancedTraining/ConfirmModal';
import ConfirmModalBase from '../Modal/ConfirmModalBase';

const GrantAssessmentBatch = ({}) => {
  const {
    regions,
    districts,
    params,
    tabValue,
    batchGrantAssessmentColumns,
    batchGrantAssessmentPreviewColumns,
    batchGrantAssessmentTableOptions,
    batchGrantAssessmentTablePreviewOptions,
    previewList,
    tableData,
    isLoading,
    skipped,
    setSkipped,
    templateFile,
    uploadCSV,
    handleFileChange,
    filter,
    downloadCSV,
    setTabValue,
    setParams,
  } = useGrantAssessmentBatch();
  return (
    <GrantAssessmentBatchContext.Provider
      value={{
        skipped: skipped,
        setSkipped: setSkipped,
      }}
    >
      <DisbursementMainLayout>
        <ApplicantSidebar selectedView={6} />
        <DisbursementMainRight>
          <ApplicantNavbar type={''} navBack="/applicants" />
          <DisbursementContainer container as="section">
            <DisbursementRootComponent item xs={12} sm={12}>
              <DisbursementRootTop display={'flex'} justifyContent={'space-between'}>
                {/* <ExpandButton /> */}
                Batch Grant Assessment
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2}>
                  <Box fontWeight={'600'} fontSize={'14px'}>
                    {skipped == true
                      ? 'Use Intermediate to Grant Scoring Guidelines'
                      : 'Use Skipped Applicant Scoring Guidelines'}
                  </Box>
                  <SwitchComponent disabled={isLoading} active={skipped} setActive={setSkipped} />
                </Box>
              </DisbursementRootTop>

              {grantTabs && grantTabs?.length > 0 !== false && (
                <DisbursementNavigationWrapper>
                  <Tabs
                    className="custom-tabs"
                    value={tabValue}
                    onChange={(e, value) => {
                      setTabValue(value);
                    }}
                  >
                    {grantTabs?.map((tabTitle, index) => (
                      <Tab label={tabTitle.label} value={index} key={`${tabTitle.label}-${index}`} />
                    ))}
                  </Tabs>
                </DisbursementNavigationWrapper>
              )}
              {tabValue == 0 && (
                <GrantAssessmentBatchFilter
                  regions={regions}
                  districts={districts}
                  onFilter={filter}
                  setParams={setParams}
                  params={params}
                />
              )}
              {tabValue == 1 && (
                <GrantAssessmentBatchFileUpload
                  templateFile={templateFile}
                  handleFileChange={handleFileChange}
                  prompt={'Upload a file of applicants who are eligible for scoring and Pre Disbursement Approval'}
                />
              )}
              {tabValue == 1 && (
                <Box sx={{ width: '100%' }}>
                  <ConfirmModalBase onConfirm={uploadCSV} confirmText="Yes">
                    <Button
                      sx={{
                        '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
                        fontWeight: '400',
                        fontFamily: 'inherit !important',
                        fontSize: '0.85rem',
                        color: 'white',
                        backgroundColor: '#2a3b8f',
                        textTransform: 'initial !important',
                        padding: '0.7rem',
                        paddingLeft: '2rem',
                        paddingRight: '2rem',
                        ':disabled': {
                          backgroundColor: '#e0e0e0',
                        },
                        width: '100%',
                      }}
                      disabled={isLoading || previewList.length == 0}
                    >
                      Upload CSV
                    </Button>
                  </ConfirmModalBase>
                </Box>
              )}

              <GrantAssessmentBatchTable
                columns={tabValue == 0 ? batchGrantAssessmentColumns : batchGrantAssessmentPreviewColumns}
                data={tabValue == 0 ? tableData : previewList}
                tableOptions={
                  tabValue == 0 ? batchGrantAssessmentTableOptions : batchGrantAssessmentTablePreviewOptions
                }
                isLoading={isLoading}
                showDownloadCSVButton={tabValue == 0}
                title={tabValue == 0 ? 'Grant Assessment List' : 'Batch Upload Preview'}
                onActionButtonPress={downloadCSV}
                downloadTemplate={() => {
                  downloadCSVTemplate(skipped);
                }}
              />
            </DisbursementRootComponent>
          </DisbursementContainer>
        </DisbursementMainRight>
      </DisbursementMainLayout>
    </GrantAssessmentBatchContext.Provider>
  );
};

export default GrantAssessmentBatch;
