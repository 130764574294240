/*eslint-disable*/
import { IApplicantDataObject } from '../interfaces/applicantDetails.model';
import {
  getDefaultIndicatorState,
  DefaultPerformanceIndicatorsFieldState,
  getOtherIndicators,
} from '../utils/constants';
export const loadIndicatorTables = (
  rowData: IApplicantDataObject['profile'][0]['grant_information']['performance_indicators'],
) => {
  try {
    let rows: any[][] = [];
    let sales_default_object = getDefaultIndicatorState({
      keyname: 'sales_default',
      indicatorArray: rowData,
    });
    let employees_default_object = getDefaultIndicatorState({
      keyname: 'employees_default',
      indicatorArray: rowData,
    });
    let production_default_object = getDefaultIndicatorState({
      keyname: 'production_default',
      indicatorArray: rowData,
    });
    let exports_default_object = getDefaultIndicatorState({
      keyname: 'exports_default',
      indicatorArray: rowData,
    });
    let volume_of_production_default = getDefaultIndicatorState({
      keyname: 'volume_default',
      indicatorArray: rowData,
    });
    let environmental_impact_default = getDefaultIndicatorState({
      keyname: 'environmental_impact_default',
      indicatorArray: rowData,
    });
    let social_impact_default = getDefaultIndicatorState({
      keyname: 'social_impact_default',
      indicatorArray: rowData,
    });

    [
      sales_default_object,
      employees_default_object,
      production_default_object,
      exports_default_object,
      volume_of_production_default,
      environmental_impact_default,
      social_impact_default,
    ].forEach((value) => {
      if (value) {
        let { pre_grant_state, post_grant_state, name_of_indicator } = value;
        let indicator_name = DefaultPerformanceIndicatorsFieldState.find((value) => {
          return value.name_of_indicator == name_of_indicator;
        })?.title;
        rows.push([indicator_name, pre_grant_state, post_grant_state]);
      }
    });

    getOtherIndicators({ indicatorArray: rowData })?.forEach((indicator) => {
      rows.push([indicator.name_of_indicator, indicator.pre_grant_state, indicator.post_grant_state]);
    });
    return {rowData: rows};
  } catch (e) {
    return {rowData:[]};
  }
};
