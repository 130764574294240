/*eslint-disable */
import React, { useEffect, useState } from 'react';
import TitleArea from './TitleArea';
import DataRow from './DataRow';
import ChartRow from './ChartRow';
import ChartRow2 from './ChartRow2';
import UserSignUp from '@service/UserSignUp';
import propTypes from 'prop-types';
import { useGetRegions, useGetDistricts } from '@utils/hooks/getRegionDistricts';
import { checkPermissions } from '@service/RequireAuth';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import ChartRow3 from './ChartRow3';
import ChartRow4 from './ChartRow4';
import ChartRow6 from './ChartRow6';
import { messageAlert } from '@utils/helpers/functions';
import { handleErrorMessage } from '@utils/helpers/functions';
import DashboardLoader from '@components/Loaders/DashboardLoader';
import { createContext } from 'react';
import TrainingDashboard from './TrainingDashboard';
import GrantDashboard from './GrantDashboard';
import DisbursementDashboard from './DisbursementDashboard';
import { IGetDashboardData, getDashboardData } from '@/service/Dashboard';

export const DashboardContext = createContext({
  userData: {},
  tabValue: 0,
  trainingStage: 'basic_training_approval',
  disbursementStage: 'post_disbursement_approval',
  skippedAdvancedTraining: true,
  params: {
    type: '',
    gender: '',
    region: '',
    district: '',
    time_upper_limit: null,
    time_lower_limit: null,
  },
  regions: null,
  totalApplications: 0,
  selectedRegionName: '',
  setSkippedAdvancedTraining: null,
  setSelectedRegionName: null,
  selectedRegion: null,
  setSelectedRegion: null,
  setTrainingStage: null,
  setDisbursementStage: null,
});

const DashboardContent = ({ profile, user, type }) => {
  const [userData, setUserData] = useState(null);
  const userObj = JSON.parse(window.localStorage.getItem('user'));
  const [loader, setLoader] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [selectedRegion, setSelectedregion] = React.useState(profile?.region ? profile.region.id : '');
  const [selectedRegionName, setSelectedregionName] = React.useState('');
  const [hasRegionVal, setHasRegionVal] = useState((profile?.district && profile?.region) !== undefined ? true : false);
  const [trainingStage, setTrainingStage] = React.useState('approved_application_for_basic_training');
  const [disbursementStage, setDisbursementStage] = React.useState('approved_application_grant_post_disbursement');
  const [skippedAdvancedTraining, setSkippedAdvancedTraining] = React.useState(true);

  const { regions,isLoading:regionsLoading } = useGetRegions();
  const [params, setParams] = useState({
    type: '',
    gender: '',
    region: '',
    district: '',
    time_upper_limit: null,
    time_lower_limit: null,
  });

  const setValue = (tabValue) => {
    switch (tabValue) {
      case 0:
        return 'Registrations';

      case 1:
        return 'Applications';
    }
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);

    // setSelectedregion('');
  };

  const getData = async (params: IGetDashboardData) => {
    try {
      setLoader(true);
      let response = await getDashboardData({ ...params });
      if (response){
        let {data} = response;
  setUserData(data);
      }
    
    } catch (e) {
      console.log(e)
    
      
    }
    setLoader(false)
  };

  useEffect(() => {
    switch (tabValue) {
      case 2:
        getData({
          training_level: trainingStage,
        });
        break;
      case 3:
        getData({
          skipped_advanced_training: skippedAdvancedTraining,
        });
        break;
      case 4:
        getData({
          disbursement_stage: disbursementStage,
        });
        break;
      default:
        getData({});
    }
  }, [tabValue, trainingStage, skippedAdvancedTraining, disbursementStage]);

  useEffect(() => {
    setParams({ ...params, type: setValue(tabValue) });
  }, [tabValue]);

  useEffect(() => {
    {
      if (checkPermissions('registrations', userObj) == true && checkPermissions('applications', userObj) == true) {
        setParams({ ...params, type: '' });
        setTabValue(0);
      } else if (
        checkPermissions('registrations', userObj) == true &&
        checkPermissions('applications', userObj) == false
      ) {
        setParams({ ...params, type: 'Registrations' });
        setTabValue(0);
      } else if (
        checkPermissions('registrations', userObj) == false &&
        checkPermissions('applications', userObj) == true
      ) {
        setParams({ ...params, type: 'Applications' });
        setTabValue(1);
      }

      switch (userObj?.staff_type) {
        case 'bac':
          setParams({
            ...params,
            region: userObj?.profile?.region?.id,
            district: userObj?.profile?.district?.id,
          });
          break;
        case 'bac_regional_head':
          setParams({ ...params, region: userObj?.profile?.region?.id });
          break;
        default:
          return;
      }
    }
  }, []);


  const style: React.CSSProperties = {
    padding: '3.5rem',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  };
  const totalApplications = userData?.data?.total_applications;
  //  if (isLoading == true) return (<h1>LOADING</h1>);
  return (
    <DashboardContext.Provider
      value={{
        userData: userData,
        trainingStage: trainingStage,
        tabValue: tabValue,
        params: params,
        regions: regions,
        selectedRegion: selectedRegion,
        selectedRegionName: selectedRegionName,
        totalApplications: totalApplications,
        disbursementStage: disbursementStage,
        skippedAdvancedTraining: skippedAdvancedTraining,
        setSkippedAdvancedTraining: setSkippedAdvancedTraining,
        setDisbursementStage: setDisbursementStage,
        setSelectedRegionName: setSelectedregionName,
        setSelectedRegion: setSelectedregion,
        setTrainingStage: setTrainingStage,
      }}
    >
      <div className="dashboard-content" style={style}>
        <TitleArea username={`${user?.first_name}`} />
        <Box my={'1rem'}>
          <Tabs
            className="custom-tabs"
            value={tabValue}
            onChange={handleTabChange}
            style={{ borderBottom: '0.5px solid #EBEFF2' }}
            aria-label="basic tabs example"
          >
            <Tab
              disabled={checkPermissions('registrations', userObj) == true ? false : true}
              label="Registrations"
              value={0}
            />
            <Tab
              disabled={checkPermissions('applications', userObj) == true ? false : true}
              label="Applications"
              value={1}
            />
            <Tab disabled={checkPermissions('trainings', userObj) == false} label="Training" value={2} />

            <Tab label="Grants" value={3} />
            <Tab label="Disbursement" value={4} />
          </Tabs>
        </Box>

        {loader || regionsLoading  && (
          <Box sx={{ width: '100%' }}>
            <DashboardLoader />
          </Box>
        )}
        {loader == false && (
          <>
            {(tabValue == 0 || tabValue == 1) && <DataRow />}
            {(tabValue == 0 || tabValue == 1) && <ChartRow />}
            {(tabValue == 0 || tabValue == 1) && <ChartRow2 />}
            {tabValue == 1 && <ChartRow6 />}
            {tabValue == 1 && <ChartRow3 userData={userData} />}
            {tabValue == 1 && (
              <>
                <ChartRow4 />
              </>
            )}
            {tabValue == 2 && <TrainingDashboard />}
            {tabValue == 3 && <GrantDashboard />}
            {tabValue == 4 && <DisbursementDashboard />}
          </>
        )}
      </div>
    </DashboardContext.Provider>
  );
};
DashboardContent.propTypes = {
  user: propTypes.object,
  profile: propTypes.object,
  type: propTypes.string,
};
export default DashboardContent;
