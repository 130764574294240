import { toast } from 'react-toastify';

export function handleErrorMessage(response) {
  try {
    let errorMessage = '';
    switch (response?.code) {
      case 'ERR_BAD_REQUEST':
        if (response?.message == 'Request failed with status code 401') {
          localStorage.clear();
          window.location.replace('/');
          return response?.data?.detail;
        }
        errorMessage = Object.values(response?.response?.data)[0][0];
        return errorMessage || Object.values(Object.values(response?.data)[0])[0][0];
      case 400:
        errorMessage = Object.values(response?.response?.data)[0][0];
        return errorMessage || Object.values(Object.values(response?.data)[0])[0][0];
      case 'token_not_valid':
        localStorage.clear();
        window.location.replace('/');
        return response?.data?.detail;
      case 404:
        return response?.data?.detail;
      default:
        return 'Error Occured';
    }
  } catch (e) {
    return 'Error Occured';
  }
}

export const messageAlert = (messageType, msg) => {
  messageType === 'success'
    ? toast.success(msg)
    : toast.error(msg, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
};

export function storeLocalStorage(title, data) {
  window.localStorage.setItem(title, JSON.stringify(data));
  return;
}

export function getLocalStorage(title) {
  return window.localStorage.getItem(title) ? JSON.parse(window.localStorage.getItem(title)) : null;
}

export const snakeToCamelCase = (sentence) =>
  !sentence
    ? null
    : sentence
        .split('_')
        .map((word) => {
          return firstUppercase(word);
        })
        .join(' ');
const firstUppercase = (word) => word && word?.charAt(0).toUpperCase() + word.slice(1);

export const formatNumbers = (value) => {
  if (isNaN(value)) {
    return 0.0;
  } else {
    return value.toLocaleString(undefined, { maximumFractionDigits: 1 });
  }
};

export const returnCapitalizedInitial = (name) => {
  let initial = '';
  if (name !== undefined && name !== null) {
    initial = name.charAt(0).toUpperCase();
  }
  return initial;
};

export const getTableData = (data) => {
  let tableData = [];
  let count = 0;
  data?.data?.forEach((applicant) => {
    let { applicant_score, user } = applicant;
    let tableRow = [
      user?.id,
      `${user?.first_name} ${user?.last_name}`,
      user?.phone_number,
      applicant_score,
      false,
      user?.id,
    ];
    tableData.push(tableRow);
  });
  count = data?.count;
  return { tableData, count };
  // props?.setBaseLayoutParams({ ...params, tableData: tableData });
};

export const parseIdFromProjectId = (projectId) => {
  let startIndex = -1;
  let endIndex = -1;

  // Find the first non-zero and non-string character in the string
  for (let i = 0; i < projectId.length; i++) {
    if (!isNaN(projectId[i]) && projectId[i] !== '0') {
      startIndex = i;
      break;
    }
  }

  // Find the last number after the startIndex
  for (let i = startIndex + 1; i < projectId.length; i++) {
    if (isNaN(projectId[i])) {
      endIndex = i - 1;
      break;
    }
  }

  // Extract the substring between the startIndex and endIndex and parse it to a number
  const numberString = projectId.substring(startIndex, endIndex + 1);
  return parseInt(numberString);
};
