/*eslint-disable*/
import React from 'react';
import { TableWrapper } from './styledcomponents/DisbursementTable.components';
import { IDisbursementTable } from '../interfaces/DisbursementTable.interface';
import MUIDataTable from 'mui-datatables';
import TableLoaders from '@/components/Loaders/TableLoaders';
import { Box, Button } from '@mui/material';

const DisbursementTable = ({
  data,
  columns,
  title,
  tableOptions,
  isLoading,
  actionDisabled,
  showDownloadCSVButton,
  showDownloadTemplateButton,
  onCSVTemplateDownload,
  onActionButtonPress,
}: IDisbursementTable) => {
  return (
    <TableWrapper className="custom-data-table ">
      {showDownloadCSVButton == true && (
        <Box my={'0.5rem'} sx={{ order: '1', marginLeft: 'auto' }}>
          <Button
            sx={{
              '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
              fontWeight: '400',
              fontFamily: 'inherit !important',
              fontSize: '0.85rem',
              color: 'white',
              backgroundColor: '#2a3b8f',
              textTransform: 'initial !important',
              padding: '0.7rem',
              paddingLeft: '2rem',
              paddingRight: '2rem',
              ':disabled': {
                backgroundColor: '#e0e0e0',
              },
            }}
            disabled={actionDisabled}
            onClick={() => {
              onActionButtonPress?.();
            }}
          >
            Download CSV
          </Button>
          {showDownloadTemplateButton == true && (
            <Button
              onClick={() => {
                onCSVTemplateDownload();
              }}
              style={{ marginLeft: '15px', textTransform: 'initial', fontFamily: 'inherit' }}
            >
              Download Template
            </Button>
          )}
        </Box>
      )}

      {isLoading == true && <TableLoaders />}
      {isLoading == false && <MUIDataTable title={title} options={tableOptions} columns={columns} data={data} />}
    </TableWrapper>
  );
};

export default DisbursementTable;
