/*eslint-disable*/
import { styled, Typography, Grid, Button } from '@mui/material';
export const GrantListRoot = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#F9FAFD',
  height: '100%',
  // overflowY: 'auto',
}));

export const GrantListHeaderWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem',
}));

export const GrantListWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem',
  paddingTop: '0rem',
  overflowY: 'auto',
}));

export const GrantListHeaderTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'inherit',
  marginBottom: '1rem',
}));

export const GrantListSearchWrapper = styled('article')(({ theme }) => ({}));

export const GrantListFilterButton = styled(Button)(({ theme }) => ({
  '&:hover': { color: '#2a3b8f' },
  fontWeight: '400',
  fontFamily: 'inherit',
  fontSize: '0.95rem',
  color: 'white',
  backgroundColor: '#2a3b8f',
  textTransform: 'initial',
  width: '100%',
}));

export const PaginationWrapper = styled('footer')(({ theme }) => ({
  order: '1',
  marginTop: 'auto',
  borderTop: '1px solid #dbdbdb',
  marginRight: '1.5rem',
  paddingTop: '1.5rem',
  borderBottom: '1px solid #dbdbdb',
  paddingBottom: '1.5rem',
}));
