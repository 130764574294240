/*eslint-disable*/
import DashboardContent from '@components/DashboardContent/DashboardContent';
import DashboardNavbar from '@components/DashboardNavbar/DashboardNavbar';
import DashboardSidebar from '@components/DashboardSidebar/DashboardSidebar';
import useGetUserData from '@utils/hooks/useGetUserData';
import React from 'react';
import '../assets/dashboardstyles.css';
import { checkPermissions, logout } from '@service/RequireAuth';
import { useNavigate } from 'react-router-dom';
import ApplicantSidebar from '@components/ApplicantSidebar/ApplicantSidebar';
import CreatePasswordModal from '@/components/Users/CreatePasswordModal';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import UserSignUp from '@/service/UserSignUp';
import ResetPasswordModal from '@/components/Users/ResetPasswordModal';
import { handleErrorMessage, messageAlert } from '@/utils/helpers/functions';
// import DashboardSidebar from '@components/DashboardSidebar';
// import DashboardNavbar from '@components/DashboardNavbar';

const Homepage = () => {
  const userData = JSON.parse(window.localStorage.getItem('user'));
  const [resetModal, setResetModal] = React.useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (data.new_password == data.retyped_password){
      mutation.mutate({
        old_password: window.localStorage.getItem('defaultPassword'),
        new_password:data.new_password
      },{
        onSuccess:(data)=>{
          messageAlert('success','Password Successfully Reset');
          logout()
          navigate('/')
          // setResetModal(false)
        },
        onError:(e)=>{
          let message = handleErrorMessage(e)
          messageAlert('error',message)
          setResetModal(false)
        }
        
      })
      return
    }
    messageAlert('error','Both Passwords should match.')

  };
  const mutation = useMutation({
    mutationFn: (data) => {
      return UserSignUp.changePassword(data);
    },
  });
  const navigate = useNavigate();
  const style = {
    height: '100vh',
    display: 'flex',
  };

  React.useEffect(() => {
    // document.body.style.cssText = "overflow-y:auto!important;";

    let status =
      checkPermissions('registrations', userData) == true ||
      checkPermissions('applications', userData) == true ||
      checkPermissions('trainings', userData) == true ||
      checkPermissions('disbursement', userData) == true ||
      checkPermissions('grants', userData) == true;
    if (status == false) {
      navigate('/noAuthDashboard');
    }

    setResetModal(userData?.user?.must_reset_password == true)
  }, [userData]);

  return (
    <div className="dashboard-main " style={style}>
      <ResetPasswordModal
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
        isLoading={mutation.isLoading}
        modalShowing={resetModal}
        setModalState={setResetModal}
      />
      <ApplicantSidebar selectedView={0} />
      <div className="dashboard-home">
        <DashboardNavbar user={userData?.user} />
        <DashboardContent profile={userData?.profile} user={userData?.user} type={userData?.staff_type} />
      </div>
    </div>
  );
};

export default Homepage;
