import React from "react";
import ContentLoader from "react-content-loader";

const DashboardLoader = ({ ...rest }) => (
  <ContentLoader height="500" width={"100%"} viewBox={`0 0 100% 500`} {...rest}>
    <rect x="15" y="15" rx="3" ry="3" width="100%" height="129" />
    {/* <rect x="426" y="15" rx="3" ry="3" width="298" height="129" />
    <rect x="1000" y="15" rx="3" ry="3" width="298" height="129" /> */}

    {/* <rect x="15" y="100" rx="4" ry="4" width="100%" height="25" /> */}
    <rect x="15" y="180" rx="2" ry="2" width="100%" height="400" />
    <rect x="15" y="300" rx="2" ry="2" width="100%" height="20" />
    <rect x="60" y="400" rx="2" ry="2" width="100%" height="20" />
  </ContentLoader>
);

export default DashboardLoader;
