/*eslint-disable*/
import React from 'react';
import { useForm } from 'react-hook-form';
import { IManualScoreField, IManualScoringParams } from '../../interfaces/ManualScoring.interfaces';
import { GrantAssessmentContext } from '@/utils/context/GrantAssementContext';
import { scoreApplicant } from '@/service/Applicants';
import { messageAlert } from '@/utils/helpers/functions';
import { getManualScoringFields } from '../../constants/manualscoring.constants';

export const useGetManualScoring = () => {
  const { control, setValue, handleSubmit } = useForm();
  const { isSubmitting, selectedApplicantId, selectedApplicantResponseObject, refetch, setIsSubmitting } =
    React.useContext(GrantAssessmentContext);
  const onSubmit = async (values: any) => {
    if (selectedApplicantId) {
      try {
        const scoringParams: Partial<IManualScoringParams> = values;
        scoringParams.client = selectedApplicantId || '';
        Object.keys(scoringParams).forEach((value) => {
          if (scoringParams[value as keyof IManualScoringParams] === null) {
            scoringParams[value as any] = 0;
          }
        });

        await scoreApplicant(scoringParams);
        messageAlert('success', 'Applicant Scoring Successfully Updated!');
        refetch?.();
      } catch (e) {
        messageAlert('error', 'Request Failed');
      }
      setIsSubmitting?.(false);
      return;
    }
    messageAlert('error', 'No Applicant ID, please try again.');
  };

  React.useEffect(() => {
    if (isSubmitting) {
      handleSubmit(onSubmit)();
    }
  }, [isSubmitting]);

  React.useEffect(() => {
    try {
      const grantScores = selectedApplicantResponseObject?.client_details?.[0]?.grant_score;
      if (grantScores) {
        Object.keys(grantScores).forEach((key) => {
          setValue(key, grantScores[key as keyof typeof grantScores]);
        });
      }
    } catch (e) {}
  }, [selectedApplicantResponseObject]);
  return {
    ManualScoringFields: getManualScoringFields({ data: selectedApplicantResponseObject?.profile }),
    control,
    selectedApplicantResponseObject,
    handleSubmit,
    onSubmit,
  };
};
