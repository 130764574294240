import React from "react";
import TotalCard from "./Cards/TotalCard";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ArticleIcon from "@mui/icons-material/Article";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
// import PersonIcon from '@mui/icons-material/Person';
// import proptypes from 'prop-types';
// import { checkPermissions } from '@service/RequireAuth';
import { DashboardContext } from "./DashboardContent";

const DataRow = () => {
  const { userData, params, tabValue } = React.useContext(DashboardContext);
  const setValue = (tabValue) => {
    switch (tabValue) {
      case 0:
        return "Registrations";

      case 1:
        return "Applications";
    }
  };

  params.type = setValue(tabValue);

  const style = {
    display: "flex",
    // width:"100%",
    marginTop: "1.5rem",
  };

  const returnDefaultValue = (value) => {
    if (value == null || value == undefined) {
      return 0;
    }
    return value;
  };

  return (
    <div className="data-row" style={style}>
      {params?.type === "Registrations" && params?.type !== "" && (
        <>
          <TotalCard
            params={params}
            title={"Total Registrations"}
            value={returnDefaultValue(userData?.total_registrations)}
            icon={<ArticleIcon style={{ color: "#0D5AE5" }} />}
            backgroundColor="#0D5AE5"
          />
          <TotalCard
            params={params}
            title={"Today's Registrations"}
            value={returnDefaultValue(userData?.total_registrations_today)}
            icon={<ArticleIcon style={{ color: "#FF4B55" }} />}
            backgroundColor="#FF4B55"
          />
        </>
      )}
      {(params?.type === "Applications" || params?.type === "") && (
        <>
          <TotalCard
            params={params}
            title={"Total Applications"}
            value={returnDefaultValue(userData?.total_completed_applications)}
            icon={<AssignmentTurnedInIcon style={{ color: "#FFC42E" }} />}
            backgroundColor="#FFC42E"
          />
          <TotalCard
            params={params}
            title={"Applications For Today"}
            value={returnDefaultValue(
              userData?.total_completed_applications_today
            )}
            icon={<PendingActionsIcon style={{ color: "#0D5AE5" }} />}
            backgroundColor="#0D5AE5"
          />
          {/* <TotalCard  params = {params} title={"Today's Value Requested"} value={returnDefaultValue(userData?.total_amount_requested_today)} icon={<AssignmentTurnedInIcon style={{color:"#7A71D1"}} />} backgroundColor="#7A71D1"/> */}
          <TotalCard
            params={params}
            title={"Rejected Applications"}
            value={returnDefaultValue(userData?.total_amount_requested)}
            icon={<PendingActionsIcon style={{ color: "#FF4B55" }} />}
            backgroundColor="#FF4B55"
          />
          {/* <TotalCard title={params?.type==='Applications'||params?.type===''?'Total Successful Applications':'Total Successful Registrations'} value={data?.total_successful_applications} icon={<PersonIcon style={{color:"#7A71D1"}} />} backgroundColor="#7A71D1"/> */}
        </>
      )}
    </div>
  );
};

// DataRow.propTypes = {
//   userData: proptypes.object,
//   params:proptypes.object,
//   tabValue:proptypes.number
// }
export default DataRow;
