import React from "react";
import { Grid, Box } from "@mui/material";
import propTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const ReportsNav = ({ selectedView }) => {
  const navigate = useNavigate();
  const getStyles = (view, selectedView) => {
    if (view === selectedView) {
      return {
        backgroundColor: "white",
        marginLeft: "2rem",
        borderLeft: " 3px solid #2a3b8f",
        fontSize: "0.95rem",
        padding: "1.5rem",
        fontWeight: "500",
      };
    } else
      return {
        fontSize: "0.95rem",
        marginLeft: "2rem",
        padding: "1rem",
        fontWeight: "500",
      };
  };

  return (
    <Grid style={{ width: "20%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F9FAFD",
          height: "100%",
        }}
      >
        <Box
          className="settings-nav-item"
          mt={"3rem"}
          mb={"1rem"}
          sx={getStyles(0, selectedView)}
          onClick={() => {
            navigate("/reports/dataAnalysis");
          }}
        >
          Data Analysis
        </Box>
        <Box
          className="settings-nav-item"
          mb={"1rem"}
          sx={getStyles(1, selectedView)}
          onClick={() => {
            navigate("/reports/detailedReports");
          }}
        >
          Detailed Reports
        </Box>
      </Box>
    </Grid>
  );
};

ReportsNav.propTypes = {
  selectedView: propTypes.number,
};

export default ReportsNav;
