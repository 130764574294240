/*eslint-disable*/
import axios from 'axios';
import apiConfig from './apiConfig';

const adminLogin = (fields) => {
  const url = `${apiConfig.BASE_URL}/staff/auth/account/login`;
  return axios.post(url, fields);
};

const createStaff = (fields) => {
  const url = `staff/auth/create-account`;
  const params = {};
  params.first_name = fields.first_name;
  params.last_name = fields.last_name;
  params.email = fields.email;
  params.phone_number = fields.phone_number;
  params.gender = fields.gender;
  params.role = parseInt(fields.role);
  params.enabled = true;
  params.region = fields.region;
  params.district = fields.district;
  params.staff_type = fields.staff_type;
  params.date_of_birth = fields.date_of_birth;

  return apiConfig.postRequest(url, params);
};

const editStaff = (fields) => {
  let { id, ...rest } = fields;
  const url = `staff/dashboard/admin-users/${id}`;
  const params = rest;
  return apiConfig.patchRequest(url, params);
};

const deleteStaff = (fields) => {
  let { id } = fields;
  const url = `staff/dashboard/admin-users/${id}`;
  return apiConfig.deleteRequest(url);
};

const resetPassword = (fields) => {
  let { id } = fields;
  const url = `staff/dashboard/admin-users/${id}/reset_password`;
  return apiConfig.postRequest(url);
};

const editRole = (fields) => {
  const url = `staff/dashboard/roles/${fields?.id}`;
  const params = {};
  params.name = fields?.name;
  params.permissions = fields?.permissions;
  return apiConfig.patchRequest(url, params);
};

const createRoles = (fields) => {
  const url = `staff/dashboard/roles`;
  const params = {};
  params.name = fields?.name;
  params.permissions = fields?.permissions;

  return apiConfig.postRequest(url, params);
};

const getRoles = () => {
  const url = `staff/dashboard/roles`;
  return apiConfig.getRequest(url);
};

const getStaff = (page, query = '') => {
  const url = `staff/dashboard/admin-users?page=${page + 1}&per_page=10&q=${query}`;
  return apiConfig.getRequest(url);
};

const getTopFive = (fields) => {
  const url = `staff/dashboard/top_five_districts`;
  const params = {};

  params.region = fields.region || null;
  params.district = fields.district || null;
  params.time_upper_limit = fields.time_upper_limit || null;
  params.time_lower_limit = fields.time_lower_limit || null;

  if (params.region === 'all regions') params.region = null;
  if (params.district === 'all districts') params.district = null;

  if (fields.gender && fields.gender !== 'All') {
    params.gender = fields.gender;
  }
  if (fields.type) params.type = fields.type;

  return apiConfig.postRequest(url, params);
};

const getPermissions = () => {
  const url = `staff/dashboard/permissions`;
  return apiConfig.getRequest(url);
};

const getDataAnalysis = (fields) => {
  const url = `staff/reports/detailed_analysis`;
  const params = {};

  params.date_upper_limit = fields.time_upper_limit || null;
  params.date_lower_limit = fields.time_lower_limit || null;

  let paramsString = `date_upper_limit=${params?.date_upper_limit}&date_lower_limit=${params?.date_lower_limit}`;
  return apiConfig.getRequestWithData(url, paramsString);
};

const getUsers = (trainingStage) => {
  const url =
    trainingStage == ''
      ? `staff/dashboard/dashboard_metrics`
      : `staff/dashboard/dashboard_metrics?training_level=${trainingStage}`;
  // const params = {};

  return apiConfig.getRequest(url);
};

const createAutomaticScore = (fields) => {
  const url = `staff/dashboard/scores`;
  fields.pass_mark = 0;
  return apiConfig.postRequest(url, fields);
};

const getAutomaticScore = () => {
  const url = `staff/dashboard/scores`;
  return apiConfig.getRequest(url);
};

const getCategories = () => {
  const url = `staff/dashboard/permission-categories`;
  return apiConfig.getRequest(url);
};

const createPassword = (data) => {
  const url = `client/auth/account/update_password`;
  return apiConfig.postRequest(url, data);
};

const changePassword = (data) => {
  const url = `staff/auth/account/change_password`
  return apiConfig.postRequest(url, data);
}
const getTrail = (params) => {
  /*eslint-disable*/
  let { count, data, page, ...otherParams } = params;
  let queryString = '';
  let keyArray = Object.keys(otherParams);
  Object.values(otherParams).forEach((item, index) => {
    if (item !== '' && item !== undefined && item !== null) {
      let keyName = keyArray[index];

      queryString = queryString + `&${keyName}=${item}`;
    }
  });
  const url = `api/common/log-entries?page=${page + 1}${queryString}`;
  return apiConfig.getRequest(url);
  /*eslint-enable*/
};

export default {
  adminLogin,
  getUsers,
  getTopFive,
  getStaff,
  createStaff,
  createRoles,
  deleteStaff,
  editStaff,
  resetPassword,
  getRoles,
  getPermissions,
  editRole,
  createAutomaticScore,
  getAutomaticScore,
  getCategories,
  createPassword,
  changePassword,
  getDataAnalysis,
  getTrail,
};
