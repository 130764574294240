/*eslint-disable*/
import React from 'react';
import { IApprovalsBaseLayout } from '@/utils/interfaces/approvals.interface';
import { Grid } from '@mui/material';
import ApprovalsNav from './ApprovalsNav';
import { AppContext } from '@/utils/context/AppContext';

const ApprovalsBaseLayout = ({ children, selectedView }: IApprovalsBaseLayout) => {
  const { currentViewState, setCurrentViewState } = React.useContext(AppContext);

  return (
    <Grid container sx={{ height: '100%', overflowY: 'auto' }}>
      {currentViewState == 'expanded' && <ApprovalsNav selectedView={selectedView || 0} />}
      <Grid
        item
        md={currentViewState == 'expanded' ? 9 : 12}
        xs={12}
        sm={12}
        lg={currentViewState == 'expanded' ? 10 : 12}
        xl={currentViewState == 'expanded' ? 10 : 12}
        sx={{ display: 'flex', flexDirection: 'column', padding: '2.5rem' }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default ApprovalsBaseLayout;
