/*eslint-disable*/
import React from 'react';
import { IFilterParams } from '../interfaces/approvals.interface';
export interface IDisbursementContext {
  tabValue?: number;
  params?: IFilterParams | undefined;
  regions?: any;
  districts?: any;
  setParams?: React.Dispatch<React.SetStateAction<IFilterParams>>;
  setTabValue?: React.Dispatch<React.SetStateAction<number>>;
}

export interface IPreDisbursementContext extends IDisbursementContext {}

export interface IPostDisbursementContext extends IDisbursementContext {}

export const PredisbursementContext = React.createContext<IPreDisbursementContext>({});
export const PostDisbursementContext = React.createContext<IPostDisbursementContext>({});
