import React from "react";
import { RadialChart } from "react-vis/dist";
import propTypes from "prop-types";
import { Box } from "@mui/material";
import { DashboardContext } from "../DashboardContent";

const returnDefaultValue = (value) => {
  if (value == undefined || value == null) {
    return 0;
  }
  return value;
};

const GenderDistributionChart = ({ genderObject, totalApplications }) => {
  const getAngle = (total, categorySize) => {
    let angle = Math.floor((categorySize / total) * 360);
    return angle;
  };

  const dummyData = [
    {
      angle: getAngle(totalApplications, genderObject.males),
      label: `Male: %${(
        (genderObject?.males / totalApplications) *
        100
      )?.toFixed(2)}`,
      style: { stroke: "#0052B4", fill: "#0052B4" },
    },
    {
      angle: getAngle(totalApplications, genderObject.females),
      label: `Female: %${(
        (genderObject?.females / totalApplications) *
        100
      )?.toFixed(2)}`,
      style: { stroke: "rgb(255 50 21)", fill: "rgb(255 50 21)" },
    },
  ];
  return (
    <Box>
      <RadialChart
        data={dummyData}
        width={400}
        height={300}
        innerRadius={130}
        radius={80}
        // showLabels={isHovering}
        labelsStyle={{
          fill: "yellow!important",
          dominantBaseline: "middle",
          textAnchor: "middle",
        }}
      ></RadialChart>
    </Box>
  );
};

const GenderDistribution = () => {
  const { userData } = React.useContext(DashboardContext);
  const [isHovering, setIsHovering] = React.useState(false);
  const style = {
    display: "flex",
    padding: "1.2rem",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "1rem",
    marginTop: "1.5rem",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  };

  const keyStyle = {
    fontSize: "13px",
    fontWeight: "600",
  };
  const handleOnEnter = () => {
    setIsHovering(true);
  };

  const handleOnLeave = () => {
    setIsHovering(false);
  };

  const percentText = {
    fontSize: "13px",
    fontWeight: "600",
  };
  return (
    <div className="gender-distribution" style={style}>
      <div className="card-title">Applications By Gender</div>
      <div
        className="bottom flex w-100"
        onMouseEnter={handleOnEnter}
        onMouseLeave={handleOnLeave}
      >
        <GenderDistributionChart
          totalApplications={returnDefaultValue(
            userData?.genders?.M?.total_applicants +
              userData?.genders?.F?.total_applicants
          )}
          genderObject={{
            males: returnDefaultValue(userData?.genders?.M?.total_applicants),
            females: returnDefaultValue(userData?.genders?.F?.total_applicants),
          }}
        />
        <div className="total-description flex flex-c">
          <div className="top" style={{ fontWeight: "600", fontSize: "15px" }}>
            Total Applicants
          </div>
          <div className="bottom">
            {returnDefaultValue(
              userData?.genders?.M?.total_applicants +
                userData?.genders?.F?.total_applicants
            )}
          </div>
        </div>
        {isHovering && (
          <div
            className="flex flex-c"
            style={{ position: "absolute", left: "10px", top: "50%" }}
          >
            <div>
              <span style={percentText}>Male: %</span>{" "}
              {(
                (userData?.genders?.M?.total_applicants /
                  (userData?.genders?.M?.total_applicants +
                    userData?.genders?.F?.total_applicants)) *
                100
              )?.toFixed(2)}
            </div>
            <div>
              <span style={percentText}>Female: %</span>{" "}
              {(
                (userData?.genders?.F?.total_applicants /
                  (userData?.genders?.M?.total_applicants +
                    userData?.genders?.F?.total_applicants)) *
                100
              )?.toFixed(2)}{" "}
            </div>
          </div>
        )}
        <div className="distribution-key flex">
          <div style={keyStyle}>
            <span
              className="key-color"
              style={{ backgroundColor: "#0052B4" }}
            ></span>
            Male Applicants (
            {returnDefaultValue(userData?.genders?.M?.total_applicants)})
          </div>
          <div style={keyStyle}>
            <span
              className="key-color"
              style={{ backgroundColor: "rgb(255 50 21)" }}
            ></span>
            Female Applicants (
            {returnDefaultValue(userData?.genders?.F?.total_applicants)})
          </div>
        </div>
      </div>
    </div>
  );
};

GenderDistributionChart.propTypes = {
  genderObject: propTypes.object,
  totalApplications: propTypes.number,
};

export default GenderDistribution;
