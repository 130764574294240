/*eslint-disable*/
import React from 'react';
import { IReturnUserFeedback, IViewUserSubmissionModal } from '../interfaces/ManualScoring.interfaces';
import { DialogTitle, DialogContent, DialogActions, styled, Grid } from '@mui/material';
import * as MUI from '@mui/material';
import {
  returnActivityDataTableComponent,
  returnExpectedResultTableComponent,
  returnFiscalProjectionTableComponent,
  returnGrantExpenditureTableComponent,
  useGetViewUserSubmission,
} from '../services/GrantDetails/ViewUserSubmission.service';
import { defaultActiveColor } from '@/utils/constants';
import { useLoadBusinessActiviites, useLoadDisplayTableRows } from '../hooks/useLoadBusinessActivities';
import { GrantAssessmentContext } from '@/utils/context/GrantAssementContext';

const Dialog = styled(MUI.Dialog)(({ theme }) => ({}));
const CloseButton = styled(MUI.Button)(({ theme }) => ({
  color: defaultActiveColor,
}));
const DialogRoot = styled(MUI.Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 'unset',
  },
}));

const returnUserFeedback = ({ data, type, expenditureList }: IReturnUserFeedback) => {
  switch (type) {
    case 'activity':
      return returnActivityDataTableComponent(data);
    case 'default':
      return <>{data}</>;
    case 'performance_indicator':
      return returnExpectedResultTableComponent(data);
    case 'fiscal_projection':
      return returnFiscalProjectionTableComponent(data);
    case 'grants_expenditure':
      return returnGrantExpenditureTableComponent(expenditureList);
  }
  return <>{'user feedback'}</>;
};

const ViewUserSubmission = ({ open, scoreObject, setOpen }: IViewUserSubmissionModal) => {
  // const { fullScreen } = useGetViewUserSubmission({ open: open, setOpen: setOpen });
  const { expenditureList } = React.useContext(GrantAssessmentContext);
  const theme = MUI.useTheme();
  const fullScreen = MUI.useMediaQuery(theme.breakpoints.down('sm'));

  const userFeedback = React.useMemo(() => {
    return returnUserFeedback({
      data: scoreObject?.submissionData,
      type: scoreObject?.type,
      expenditureList: expenditureList,
    });
  }, [scoreObject]);
  return (
    <Dialog
      open={open || false}
      onClose={() => {
        setOpen(!open);
      }}
      fullScreen={fullScreen}
    >
      <DialogRoot>
        <DialogTitle>{scoreObject?.title}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={12} sx={{ fontSize: '16px', fontWeight: '700' }} mb={'10px'}>
              Question Prompt
            </Grid>
            <Grid item xs={12} md={12} mb={'15px'} sx={{ fontSize: '14px', fontStyle: 'italic' }}>
              {scoreObject?.questionPrompt}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ fontSize: '16px', fontWeight: '700', color: defaultActiveColor }}
              mb={'10px'}
            >
              User Response
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                borderRadius: '8px',
                border: `1px solid ${defaultActiveColor}`,
                padding: '8px',
                fontSize: '14px',
              }}
            >
              {userFeedback}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CloseButton
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </CloseButton>
        </DialogActions>
      </DialogRoot>
    </Dialog>
  );
};

export default ViewUserSubmission;
