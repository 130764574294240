/*eslint-disable */
import React from 'react';
import propTypes from 'prop-types';
import { Rating } from '@mui/material';

const ApplicantCard = ({ name, selected, percentage, rating }) => {
  const iconStyle = {
    borderRadius: '50%',
    fontSize: '0.5rem',
    backgroundColor: ' #2A3B8F',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    marginRight: '0.8rem',
    fontWeight: '600',
  };

  const applicantCardStyle = {
    marginTop: '1rem',
    backgroundColor: selected === true ? 'white' : 'unset',
    borderLeft: selected === true ? '3px solid rgb(42, 59, 143)' : '0px',
    marginBottom: selected == true ? '0.5rem' : 'unset',
    padding: '1.5rem',
  };

  const returnCapitalizedInitial = (name) => {
    let initial = '';
    if (name !== undefined && name !== null) {
      initial = name.charAt(0).toUpperCase();
    }
    return initial;
  };

  const yetToBeRated = rating == 0 && percentage == 0;

  return (
    <div className="applicant-card flex" style={applicantCardStyle}>
      <div className="applicant-icon left flex" style={iconStyle}>
        <div style={{ color: 'white' }}>{returnCapitalizedInitial(name)}</div>
      </div>
      <div className="applicant-card-content flex flex-c" style={{ justifyContent: 'center' }}>
        <div
          className="top"
          style={{
            fontSize: '0.95rem',
            fontWeight: '500',
            marginBottom: '0.5rem',
          }}
        >
          {name}
        </div>
        <div className="bottom flex" style={{ alignItems: 'center' }}>
          <div>
            {yetToBeRated ? <span>Yet to be rated</span> : <Rating name="user-rating" value={rating} readOnly={true} />}
          </div>
          {yetToBeRated ? null : <div style={{ marginLeft: '0.2rem' }}>({`${Math.floor(percentage)}%`})</div>}
        </div>
      </div>
    </div>
  );
};

ApplicantCard.propTypes = {
  name: propTypes.string,
  percentage: propTypes.number,
  rating: propTypes.number,
};

export default ApplicantCard;
