import React from "react";
import { Grid, Box } from "@mui/material";
import propTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const SettingsNav = ({ selectedView }) => {
  const navigate = useNavigate();
  const getStyles = (view, selectedView) => {
    if (view === selectedView) {
      return {
        backgroundColor: "white",
        marginLeft: "2rem",
        borderLeft: " 3px solid #2a3b8f",
        fontSize: "1rem",
        padding: "1.5rem",
        fontWeight: "600",
      };
    } else
      return {
        fontSize: "1rem",
        marginLeft: "2rem",
        padding: "1rem",
        fontWeight: "600",
      };
  };

  return (
    <Grid item xs={3}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F9FAFD",
          height: "100%",
        }}
      >
        {/* <Box sx={{fontSize:'1.2rem',padding:'2rem',paddingTop:'3rem !important',fontWeight:'600'}}>Settings</Box> */}
        <Box
          className="settings-nav-item"
          mt={"3rem"}
          mb={"1rem"}
          sx={getStyles(0, selectedView)}
          onClick={() => {
            navigate("/settings/roles");
          }}
        >
          User Management
        </Box>
        <Box
          className="settings-nav-item"
          mb={"1rem"}
          sx={getStyles(1, selectedView)}
          onClick={() => {
            navigate("/settings/automaticScoring");
          }}
        >
          Settings
        </Box>
        {/* <Box  className="settings-nav-item"  mb={'1rem'} sx={getStyles(2,selectedView)} onClick={()=>{navigate('/settings/pass')}}>
                            Pass Mark
                        </Box> */}
        <Box
          className="settings-nav-item"
          mb={"1rem"}
          sx={getStyles(3, selectedView)}
          onClick={() => {
            navigate("/settings/auditTrail");
          }}
        >
          Audit Trail
        </Box>
      </Box>
    </Grid>
  );
};

SettingsNav.propTypes = {
  selectedView: propTypes.number,
};

export default SettingsNav;
