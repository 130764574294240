/*eslint-disable*/

import { IGrantFormField } from '../interfaces/GrantOverview.interface';
import { AxiosResponse } from 'axios';
import { IApplicantDataObject } from '../interfaces/applicantDetails.model';

export const GrantFormFields: IGrantFormField[] = [{}];

export const useGetGrantFormFields = (response: Partial<IApplicantDataObject>) => {
  const personal_information_fields: IGrantFormField[] = [
    { label: 'First Name', type: 'default', value: response?.overview?.first_name },
    { label: 'Last Name', type: 'default', value: response?.overview?.last_name },
    { label: 'Middle Name', type: 'default', value: response?.overview?.middle_name },
    { label: 'Date of Birth', type: 'default', value: response?.overview?.date_of_birth },
    { label: 'Nationality', type: 'default', value: response?.profile?.[0]?.country },
    { label: 'Marital Status', type: 'default', value: response?.profile?.[0]?.marital_status },
    { label: 'Digital Address', type: 'default', value: response?.profile?.[0]?.digital_address },
    { label: 'Phone Number', type: 'default', value: response?.overview?.phone_number },
    { label: 'Phone Number 2', type: 'default', value: response?.overview?.temp_phone_number },
  ];

  const personal_information_fields_2: IGrantFormField[] = [
    { label: 'Email Address', type: 'default', value: response?.overview?.email },
    {
      label: 'Are you a person with disability',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.owner_is_disabled == true ? 'Yes' : 'No',
    },
    {
      label: 'Indicate which of the GEA Entrepreneurship Training you have participated in',
      value: response?.profile?.[0]?.grant_information?.trainings_participated_in,
    },
    {
      label: 'Have you undergone any mentorship session offered under the GJSP or offered by GEA?',
      type: 'boolean',
      value: response?.profile?.[0]?.grant_information?.has_undergone_mentorship == true ? 'Yes' : 'No',
    },
    {
      label: 'Have you participated in any grant management program?',
      type: 'boolean',
      value:
        response?.profile?.[0]?.grant_information?.has_participated_in_grant_management_program == true ? 'Yes' : 'No',
    },
  ];

  const business_information_fields: IGrantFormField[] = [
    { label: 'Business Name', type: 'default', value: response?.profile?.[0]?.grant_information?.enterprise_name },
    {
      label: 'Business Phone Number',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.enterprise_name,
    },
    { label: 'Region', type: 'default', value: response?.profile?.[0]?.grant_information?.enterprise_name },
    { label: 'City', type: 'default', value: response?.profile?.[0]?.grant_information?.enterprise_name },
    { label: 'Address Line 1', type: 'default', value: response?.profile?.[0]?.grant_information?.enterprise_name },
    { label: 'Address Line 2', type: 'default', value: response?.profile?.[0]?.grant_information?.enterprise_name },
    {
      label: 'Have you registered the business?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.enterprise_name,
    },
    {
      label: 'Do you have a business plan?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.has_business_plan == true ? 'Yes' : 'No',
    },
    {
      label: 'State the Mission of your business',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.mission,
    },
    {
      label: 'State the Vision of your business',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.vision,
    },
  ];

  const business_information_fields_2: IGrantFormField[] = [
    {
      label: 'Full Time Male Employees',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.full_time_male_employees,
    },
    {
      label: 'Full Time Female Employees',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.full_time_female_employees,
    },
    {
      label: 'Part Time Male Employees',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.part_time_male_employees,
    },
    {
      label: 'Part Time Female Employees',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.part_time_female_employees,
    },
    {
      label: 'Shareholders',
      type: 'shareholder_type',
      value: response?.profile?.[0]?.grant_information?.shareholders,
    },
  ];

  const product_information_fields: IGrantFormField[] = [
    {
      label: "What are the business' products/services?",
      type: 'product',
      value: response?.profile?.[0]?.grant_information?.products,
    },
    {
      label: 'What industry sector is the business in?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.industry?.name,
    },
    {
      label: 'At what stage of development are the products /services development?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.stage_of_development,
    },
    {
      label: 'How opportunity was identified and business idea mooted',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.opportunity_identified_and_idea_mooted,
    },
  ];

  const product_information_2_fields: IGrantFormField[] = [
    {
      label: 'Do you have Ghana Standards Authority (GSA) certification?',
      type: 'boolean',
      value: response?.profile?.[0]?.grant_information?.has_gsa_certification == true ? 'Yes' : 'No',
    },
    {
      label: 'Do you have Food and Drug Authority (FDA) certification?',
      type: 'boolean',
      value: response?.profile?.[0]?.grant_information?.has_fda_certification == true ? 'Yes' : 'No',
    },
  ];

  const technical_capability_fields: IGrantFormField[] = [
    {
      label: 'Does the business have the required technical expertise to deliver the product/service?',
      type: 'boolean',
      value: response?.profile?.[0]?.grant_information?.has_required_techinical_expertise == true ? 'Yes' : 'No',
    },
    {
      label: 'Does the business have the capacity to provide additional resources to implement the plan?',
      type: 'boolean',
      value:
        response?.profile?.[0]?.grant_information?.has_capacity_to_provide_additional_resources == true ? 'Yes' : 'No',
    },
    {
      label: 'Does the business have an active financial management process/system in place?',
      type: 'boolean',
      value:
        response?.profile?.[0]?.grant_information?.has_an_active_financial_management_process == true ? 'Yes' : 'No',
    },
  ];

  const product_marketing_fields: IGrantFormField[] = [
    {
      label: 'Who are the customers of the products and services?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.who_are_the_customers,
    },
    {
      label: 'Who are your immediate target markets?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.immediate_target_market,
    },
    {
      label: 'Who are the key competitors in the market?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.key_competitors,
    },
  ];
  const revenue_generation_fields: IGrantFormField[] = [
    {
      label: 'Fiscal Year One',
      type: 'fiscal_projection',
      value: response?.profile?.[0]?.grant_information?.financial_projections,
    },
    {
      label: 'Fiscal Year Two',
      type: 'fiscal_projection',
      value: response?.profile?.[0]?.grant_information?.financial_projections,
    },
    {
      label: 'Fiscal Year Three',
      type: 'fiscal_projection',
      value: response?.profile?.[0]?.grant_information?.financial_projections,
    },
    {
      label: 'Fiscal Year Four',
      type: 'fiscal_projection',
      value: response?.profile?.[0]?.grant_information?.financial_projections,
    },
    {
      label: 'Fiscal Year Five',
      type: 'fiscal_projection',
      value: response?.profile?.[0]?.grant_information?.financial_projections,
    },
  ];
  const revenue_generation_fields_2: IGrantFormField[] = [
    {
      label: 'What are the key assumptions for the projection?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.key_assumptions_for_business,
    },
  ];

  const funding_requirements_fields: IGrantFormField[] = [
    {
      label: 'How much funding is required?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.amount_of_funding_required,
    },
    {
      label: 'How do you intend using the funds?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.intention_for_funds,
    },
  ];

  const impact_fields: IGrantFormField[] = [
    {
      label: 'What results do you expect to achieve one year after the grants facility?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.results_expected_after_a_year_of_funding,
    },
  ];

  const impact_fields_2: IGrantFormField[] = [
    {
      label: 'Is there a succession plan for the business?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.has_succession_plan == true ? 'Yes' : 'No',
    },
    {
      label: 'What plans are put in place to sustain operations after expiry of the grant support?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.succession_plan,
    },
  ];

  return {
    personal_information_fields,
    personal_information_fields_2,
    business_information_fields,
    business_information_fields_2,
    product_information_fields,
    product_information_2_fields,
    technical_capability_fields,
    product_marketing_fields,
    revenue_generation_fields,
    revenue_generation_fields_2,
    funding_requirements_fields,
    impact_fields,
    impact_fields_2,
  };
};
