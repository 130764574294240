/*eslint-disable */
import React from "react";
// import '../style/DashboardStyles.css';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import propTypes from "prop-types";
import { AvatarInitial } from "@utils/Logo";

const StaffNavbar = ({ user }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const style = {
    display: "flex",
    flexDirection: "row",
    padding: "1.1rem",
    width: "unset",
  };
  return (
    <div className="dashboard-navbar" style={style}>
      <div
        className="left flex"
        style={{
          marginTop: "0.3rem",
          fontSize: "2rem",
          justifyContent: "center",
        }}
      >
        <div onClick={() => navigate(-1)}>
          <ArrowBackIosNewIcon style={{ color: "#ABB3BF" }} />
        </div>
        <div
          style={{
            fontSize: "1rem",
            fontWeight: "700",
            marginTop: "0.3rem",
            marginLeft: "1.5rem",
          }}
        ></div>
      </div>
      <div className="right" style={{ alignItems: "center", display: "flex" }}>
        {/* <div className="notification-area mr-2">
          <div className="notification-dot"></div>
          <i className="fa fa-bell" aria-hidden="true"></i>
        </div> */}
        {/* <div className="username-area mr-2">
         {user?.first_name} {user?.last_name}
        </div> */}
        <AvatarInitial text={`${user?.first_name} ${user?.last_name}` || ""} />
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={menuOpen ? "long-menu" : undefined}
          aria-expanded={menuOpen ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "20ch",
            },
          }}
        >
          <MenuItem key={1} onClick={handleClose}>
            Account Settings
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

StaffNavbar.propTypes = {
  user: propTypes.object,
};

export default StaffNavbar;
