import apiConfig from "./apiConfig";

export function getApplicants() {
  return apiConfig.getRequest("staff/dashboard/applicants");
}

export function getApplicant(id) {
  return apiConfig.getRequest(`staff/dashboard/applicants/${id}`);
}

export function getPaginatedApplicants(page) {
  return apiConfig.getRequest(
    `staff/dashboard/applicants?page=${page}&per_page=5`
  );
}

export function searchApplicants(query) {
  if (query !== "") {
    return apiConfig.getRequest(
      `staff/dashboard/applicants_search?query=${query}`
    );
  }
}

export function getStaff(id) {
  return apiConfig.getRequest(`staff/dashboard/admin-users/${id}`);
}

export function getAllStaff() {
  return apiConfig.getRequest(`staff/dashboard/admin-users?all=true`);
}

export function createAdmin(data) {
  const { data: requestData } = data;
  return apiConfig.postRequest(`staff/auth/create-account`, requestData);
}

export function getRoles() {
  return apiConfig.getRequest("staff/dashboard/roles");
}

export function getRoleById(selectedRole) {
  const { queryKey } = selectedRole;
  const roleId = queryKey[1];

  return apiConfig.getRequest(`staff/dashboard/roles/${roleId}`);
}

export function getRoleByIdAsync(rid) {
  return apiConfig.getRequest(`staff/dashboard/roles/${rid}`);
}

export function createRole(data) {
  const { data: requestData } = data;
  return apiConfig.postRequest("staff/dashboard/roles", requestData);
}

export function getPermissionList() {
  return apiConfig.getRequest("staff/dashboard/permission_list");
}

export function getPaginatedStaff(page) {
  return apiConfig.getRequest(
    `staff/dashboard/admin-users?page=${page}&per_page=10`
  );
}
