/*eslint-disable*/
import React from 'react';
import {
  ManualScoringBottom,
  ManualScoringField,
  ManualScoringFieldRoot,
  ManualScoringLabel,
} from './GrantDetails.components';
import { Controller } from 'react-hook-form';
import { IManualScoreComponent } from '../../interfaces/ManualScoring.interfaces';
import { Tooltip } from '@material-ui/core';
import { Box, Typography, styled, tooltipClasses } from '@mui/material';
import { light_gray } from '@/utils/colors';
import { defaultActiveColor } from '@/utils/constants';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import ViewUserSubmission from '../ViewUserSubmission';
// @ts-ignore
const GuidelineTooltip = styled(({ className, ...props }) => (
  // @ts-ignore
  <Tooltip {...props} classes={{ popper: className }} placement="left-end" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#E8EEF8',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 230,
    overflowY: 'auto',
  },
}));

const GuidelineTooltipTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Nunito',
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '21px',
  letterSpacing: '0px',
  textAlign: 'left',
  marginBottom: '0.7rem',
}));

const GuidelineText = styled(Typography)(({ theme }) => ({
  color: '#5F5F5F',
  fontSize: '12px',
  marginBottom: '3px',
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid #dfdfdf`,
  paddingBottom: '8px',
}));

const GuidelineScore = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '15px',
}));

const GuidelineTotalScoreText = styled(Typography)(({ theme }) => ({
  color: `white`,
  fontSize: '20px',
  fontWeight: '400',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '12px',
  padding: '5px',
  paddingLeft: '8px',
  paddingRight: '8px',
  backgroundColor: defaultActiveColor,
  borderRadius: '8px',
}));

const GuidelineTotalScoreTextDescription = styled(Typography)(({ theme }) => ({
  color: `white`,
  marginRight: '10px',
  fontSize: '14px',
  fontWeight: '400',
}));

const PromptAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'unset',
  fontFamily: 'inherit',
  borderTop: '0px',
  '.MuiAccordion-root:before': {
    display: 'unset',
  },
}));

const PromptAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  fontFamily: 'inherit',
  padding: 'unset',
}));

const PromptAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  paddingLeft: 'unset',
}));

const ViewUserSubmissionButton = styled(Button)(({ theme }) => ({
  maxWidth: '300px',
  textTransform: 'initial',
  fontFamily: 'inherit',
  paddingLeft: '0px',
  justifyContent: 'flex-start',
  color: defaultActiveColor,
}));

const ManualScoreComponent = ({ control, scoreFieldObject, defaultValue, ...props }: IManualScoreComponent) => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <>
      {' '}
      <ViewUserSubmission open={open} setOpen={setOpen} scoreObject={scoreFieldObject} />
      {/* @ts-ignore */}
      <GuidelineTooltip
        title={
          <div className="manual-score-wrapper">
            <GuidelineTooltipTitle>Scoring Guidelines</GuidelineTooltipTitle>
            <GuidelineTotalScoreText>
              {' '}
              <GuidelineTotalScoreTextDescription>Maximum Score</GuidelineTotalScoreTextDescription>{' '}
              <Box
                sx={{
                  backgroundColor: 'white',
                  color: defaultActiveColor,
                  // padding: '8px',
                  borderRadius: '32px',
                  width: '30px',
                  height: '30px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  fontWeight: '700',
                }}
              >
                {scoreFieldObject?.range?.high || 0}
              </Box>
            </GuidelineTotalScoreText>

            {scoreFieldObject?.scoringGuidelines?.map((guideline, index) => {
              return (
                <GuidelineText key={`${index}`} as="div">
                  <GuidelineScore>{`${guideline.score}`} Points</GuidelineScore> <div>{`${guideline.description}`}</div>
                </GuidelineText>
              );
            })}
          </div>
        }
      >
        <ManualScoringField xs={12} md={6} item>
          <ManualScoringFieldRoot>
            <ManualScoringLabel>{scoreFieldObject.title}</ManualScoringLabel>
            <ManualScoringBottom>
              <ViewUserSubmissionButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <Visibility style={{ marginRight: '10px' }} width={'100px'} />
                View Applicant Submission Details
              </ViewUserSubmissionButton>

              <Controller
                name={props.name}
                control={control}
                defaultValue={defaultValue}
                render={({ field }) => (
                  <select
                    className="filter-select"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    value={field.value}
                    defaultValue={defaultValue}
                  >
                    {Array.from(
                      { length: (scoreFieldObject?.range?.high || 0 - (scoreFieldObject?.range?.low || 0)) + 1 },
                      (_, index) => (scoreFieldObject?.range?.low || 0) + index,
                    ).map((number, index) => {
                      return (
                        <option
                          key={`${number}`}
                          value={number}
                          selected={number == defaultValue || number == field.value}
                        >
                          {number}
                        </option>
                      );
                    })}
                  </select>
                )}
              />
            </ManualScoringBottom>
          </ManualScoringFieldRoot>
        </ManualScoringField>
      </GuidelineTooltip>
    </>
  );
};

export default ManualScoreComponent;
