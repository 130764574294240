/*eslint-disable */
import React from 'react';
import { Box, Tab, Tabs, Modal, Button } from '@mui/material';
import ReportsMain from '../BaseLayout/ReportsMain';
import Title from '../Title';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SearchInput from '@components/SearchInput';
import MUIDataTable from 'mui-datatables';
import TableLoaders from '@components/Loaders/TableLoaders';
import { useGetDetailedReports } from '../hooks/useGetDetailedReports';
import TabNavigation from './TabNavigation';
import { defaultActiveColor } from '@/utils/constants';

const DetailedReports = () => {
  const {
    containerStyle,
    tabValue,
    reportsLoading,
    dateRangeStyle,
    dateState,
    buttonStyle,
    dateModalOpen,
    boxStyle,
    text,
    batchReportsLoading,
    tableOptions,
    reportsData,
    pageCount,
    multiSelectValue,
    showDisqualified,
    params,
    searchQuery,
    setSearchQuery,
    setParams,
    handleDisqualifiedToggle,
    handleMultiSelectChange,
    fetchBatch,
    onQueryChange,
    exportPdf,
    changeDateParams,
    fetchReports,
    setDateModalOpen,
    handleTabChange,
    returnTableColumns,
  } = useGetDetailedReports();

  document.body.style.cssText = 'overflow-y:hidden!important';
  return (
    <ReportsMain selectedView={1}>
      <Box sx={containerStyle}>
        <Title text="Detailed Reports" />
        <TabNavigation
          params={params}
          handleMultiSelectChange={handleMultiSelectChange}
          multiSelectValue={multiSelectValue}
          disabled={reportsLoading}
          handleTabChange={handleTabChange}
          tabValue={tabValue}
          showDisqualified={showDisqualified}
          handleDisqualifiedToggle={handleDisqualifiedToggle}
          dateRangeStyle={dateRangeStyle}
          dateState={dateState}
          setDateModalOpen={setDateModalOpen}
          setParams={setParams}
          text={text}
        />
        <Box
          style={{
            padding: '2rem',
            paddingLeft: 'unset',
            paddingRight: 'unset',
            paddingTop: '10px',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #ebeff2',
            marginLeft: '2rem',
            marginRight: '2rem',
          }}
        >
          <Modal
            open={dateModalOpen}
            onClose={() => {
              setDateModalOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={boxStyle}>
              <DateRangePicker
                onChange={(item) => changeDateParams([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                ranges={dateState}
                direction="horizontal"
              />
              ;
              <div
                className="confirm-row w-100"
                style={{ textAlign: 'center', borderTop: '1px solid #e7e7e7', paddingTop: '18px' }}
              >
                <button
                  style={dateRangeStyle}
                  onClick={() => {
                    setDateModalOpen(false);
                  }}
                  disabled={reportsLoading}
                >
                  Confirm Time Period
                </button>
              </div>
            </Box>
          </Modal>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: '2rem',
            marginRight: '2rem',
            alignItems: 'center',
            marginTop: '2rem',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ fontSize: '1rem' }}>{text}:</Box>
            <Box sx={{ fontWeight: '600', fontSize: '1rem', marginLeft: '0.5rem' }}>{pageCount}</Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '600px',
                marginRight: '1rem',
              }}
            >
              <SearchInput
                setSearchQuery={setSearchQuery}
                placeholder={'Search name/registration code/phone number/ID number'}
              />
            </Box>

            <Box style={{ display: 'flex', flexDirection: 'row' }} gap={'10px'}>
              <Button
                type="submit"
                sx={buttonStyle}
                onClick={() => {
                  exportPdf();
                }}
              >
                Export As PDF
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          gap={2}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginLeft: '2rem',
            marginRight: '2rem',
            alignItems: 'center',
            marginTop: '2rem',
          }}
        >
          <Button
            type="submit"
            sx={{
              ...buttonStyle,
              backgroundColor: 'white',
              color: defaultActiveColor,
              border: '1px solid',
              borderColor: defaultActiveColor,
            }}
            onClick={() => {
              fetchBatch();
            }}
            disabled={reportsLoading||tabValue == 11}
          >
            Generate Batch Report
          </Button>
          <Button
            type="submit"
            sx={{
              ...buttonStyle,
              width: '200px',
            }}
            onClick={() => {
              fetchReports(1, true);
            }}
            disabled={reportsLoading}
          >
            Filter
          </Button>
        </Box>

        <Box
          className="custom-data-table reports-table"
          my={'2rem'}
          mb={'6rem'}
          sx={{ marginLeft: '2rem', marginRight: '2rem' }}
        >
          {(reportsLoading || batchReportsLoading) && <TableLoaders />}
          {!(reportsLoading || batchReportsLoading) && (
            <MUIDataTable title={''} options={tableOptions} columns={returnTableColumns(tabValue)} data={reportsData} />
          )}
        </Box>
      </Box>
    </ReportsMain>
  );
};

export default DetailedReports;
