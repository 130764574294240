/*eslint-disable*/
import React from "react";
import propTypes from "prop-types";
import { Box } from "@mui/material";
import EducationalLevelChart from "./Cards/EducationalLevelChart";
import DisabilityChart from "./Cards/DisabilityChart";

const ChartRow4 = () => {
  const style = {
    display: "flex",
    // width:"100%",
  };
  return (
    <Box style={style}>
      <EducationalLevelChart />
    </Box>
  );
};

ChartRow4.propTypes = {
  userData: propTypes.object,
  params: propTypes.object,
};

export default ChartRow4;
