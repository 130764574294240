import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import propTypes from "prop-types";

export default function AlertDialog({
  open,
  title,
  body,
  handleCloseModal,
  actionButton,
}) {
  //   const [open, setOpen] = React.useState(false);

  //   const handleOpenModal = () => {
  //     setOpen(true);
  //   };

  //   const handleCloseModal = () => {
  //     setOpen(false);
  //   };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{body}</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              color: "#2a3b8f",
              textTransform: "initial !important",
              border: "1px solid #2a3b8f",
            }}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>

          {actionButton}
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  open: propTypes.bool,
  title: propTypes.string,
  body: propTypes.node,
  handleCloseModal: propTypes.func,
  actionButton: propTypes.node,
};
