/*eslint-disable*/
import React from 'react';
import MUIDataTable from 'mui-datatables';
import { TableWrapper } from '@/components/Disbursement/components/styledcomponents/DisbursementTable.components';
import TableLoaders from '@/components/Loaders/TableLoaders';
import { Box, Button } from '@mui/material';
import { IGrantAssessmentBatchTable } from '../../GrantAssessmentBatch/types';

const MonitoringEvaluationTable = ({
  data,
  columns,
  title,
  tableOptions,
  isLoading,
  actionDisabled,
  showDownloadCSVButton,
  onActionButtonPress,
  downloadTemplate,
}: IGrantAssessmentBatchTable) => {
  return (
    <TableWrapper className="custom-data-table " sx={{ overflowX: 'auto' }}>
      {showDownloadCSVButton == true && (
        <Box my={'0.5rem'} sx={{ order: '1', marginLeft: 'auto' }}>
          <Button
            sx={{
              '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
              fontWeight: '400',
              fontFamily: 'inherit !important',
              fontSize: '0.85rem',
              color: 'white',
              backgroundColor: '#2a3b8f',
              textTransform: 'initial !important',
              padding: '0.7rem',
              paddingLeft: '2rem',
              paddingRight: '2rem',
              ':disabled': {
                backgroundColor: '#e0e0e0',
              },
            }}
            disabled={actionDisabled}
            onClick={() => {
              downloadTemplate()
            }}
          >
            Download Template
          </Button>
          {/* <Button
            onClick={() => {
              downloadTemplate();
            }}
            style={{ marginLeft: '15px', textTransform: 'initial', fontFamily: 'inherit' }}
          >
            Download Template
          </Button> */}
        </Box>
      )}

      {isLoading == true && <TableLoaders />}
      {isLoading == false && <MUIDataTable title={title} options={tableOptions} columns={columns} data={data} />}
    </TableWrapper>
  );
};

export default MonitoringEvaluationTable;
