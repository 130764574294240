/*eslint-disable*/
import React from "react";
import ApplicantRegionalDistribution from "./Cards/ApplicantRegionalDistribution";
import ApplicantsOverTime from "./Cards/ApplicantsOverTime";
import propTypes from "prop-types";
import GenderDistribution from "./Cards/GenderDistribution";
import { DashboardContext } from "./DashboardContent";

const ChartRow = () => {
  const { tabValue } = React.useContext(DashboardContext);
  const style = {
    display: "flex",
  };
  return (
    <div className="chart-row" style={style}>
      <div className="left" style={{ width: tabValue == 1 ? "60%" : "100%" }}>
        <ApplicantsOverTime />
      </div>
      <div className="right" style={{ width: tabValue == 1 ? "40%" : "unset" }}>
        {tabValue == 1 && <GenderDistribution />}
      </div>
    </div>
  );
};
ChartRow.propTypes = {
  tabValue: propTypes.number,
};

export default ChartRow;
