/*eslint-disable*/
import React from "react";
import {
  Grid,
  Box,
  Container,
  Button,
  Modal,
  Typography,
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import StaffNavbar from "../Staff/StaffNavbar";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import UserSignUp from "@service/UserSignUp";
import { useForm, Controller } from "react-hook-form";
import { messageAlert, handleErrorMessage } from "@utils/helpers/functions";
import SettingsNav from "./SettingsNav";
import useGetUserData from "@utils/hooks/useGetUserData";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteSvg from "../../assets/Delete.svg";
import ApplicantSidebar from "@components/ApplicantSidebar/ApplicantSidebar";
import { checkPermissions } from "@service/RequireAuth";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const responsive = "vertical";
const tableBodyMaxHeight = "";
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

const Roles = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    setValue,
    control,
    formState: { errors: errors2 },
  } = useForm();
  const { userData, isLoading } = useGetUserData();
  const navigate = useNavigate();
  const [roleModal, setRoleModal] = React.useState(false);
  const [viewModal, setViewModal] = React.useState(false);
  const [permissionsState, setPermissionsState] = React.useState({});
  const [permissionList, setPermissionList] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [currentRole, setCurrentRole] = React.useState({});
  const [editModal, setEditModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [permissionCategories, setPermissionCategories] = React.useState([]);
  const columns = [
    { name: "Role", options: { sortDescFirst: true } },
    "Permission Count",
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <Box sx={{ display: "flex" }}>
            <Box>
              <Button
                onClick={() => {
                  setCurrentRole(value);
                  setViewModal(true);
                }}
              >
                <i className="fa fa-eye"></i>
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  let _permissionState = permissionsState;
                  Object.keys(_permissionState).forEach(
                    (v) => (_permissionState[v] = false)
                  );
                  value?.permissions.forEach((permission) => {
                    _permissionState[permission.id] = true;
                  });
                  setCurrentRole(value);
                  setValue("name", value?.name);
                  setPermissionsState(_permissionState);
                  setEditModal(true);
                }}
              >
                <i className="fa fa-edit"></i>
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  setCurrentRole(value);
                  setDeleteModal(true);
                }}
              >
                <i className="fa fa-trash-o"></i>
              </Button>
            </Box>
          </Box>
        ),
      },
    },
  ];

  const permissionsSearch = watch("permissionsSearch")
    ? watch("permissionsSearch")
    : "";

  const tableOptions = {
    fixedHeader: true,
    fixedFooter: true,
    print: false,
    search: false,
    viewColumns: false,
    download: false,
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyMaxHeight: `${window.innerHeight - window.innerHeight * 0.4}px`,
    onTableChange: (action, state) => {},
    customSearchRender: () => null,
  };

  const handlePermissionsChange = (event) => {
    setPermissionsState({
      ...permissionsState,
      [event.target.name]: event.target.checked,
    });
  };

  const submitRoleForm = async (data) => {
    try {
      let checkedPermissions = [];
      let _permissionList = [...permissionList];
      _permissionList.forEach((permission) => {

        let _permission = permission;
        let { id } = _permission;

        if (permissionsState[id] && permissionsState[id] === true) {
          _permission.category = permission?.category?.id;
          checkedPermissions.push(_permission);
        }
      });
      if (checkedPermissions.length === 0) {
        messageAlert(
          "error",
          "Must add at least one permission before submitting"
        );
        return;
      }
      await UserSignUp.createRoles({
        name: data.name,
        permissions: checkedPermissions,
      });
      messageAlert("success", "Role Successfully added");
      setRoleModal(false);
      await getPermissions();
      await getRoles();
    } catch (e) {
      messageAlert("error", "Error processing request, please try again later");
      setRoleModal(false);
    }
  };

  const submitEditRoleForm = async (data) => {
    try {
      let checkedPermissions = [];

      permissionList.forEach((permission) => {
        let { id } = permission;
        if (permissionsState[id] && permissionsState[id] === true) {
          let _permission = permission;
          _permission.category = _permission?.category?.id;
          checkedPermissions.push(_permission);
        }
      });
      if (checkedPermissions.length === 0) {
        messageAlert(
          "error",
          "Must add at least one permission before submitting"
        );
        return;
      }
      await UserSignUp.editRole({
        name: data.name,
        permissions: checkedPermissions,
        id: currentRole?.id,
      });
      messageAlert("success", "Role Successfully Edited");
      setEditModal(false);
      await getPermissions();
      let _permissionState = permissionsState;
      Object.keys(_permissionState).forEach(
        (v) => (_permissionState[v] = false)
      );
      setPermissionsState(_permissionState);
      await getRoles();
    } catch (e) {
      messageAlert("error", "Error processing request, please try again later");
      setRoleModal(false);
    }
  };

  const getPermissions = async () => {
    try {
      let { data } = await UserSignUp.getPermissions();
      let permissions = [];
      let permissionState = {};
      data.forEach((permission) => {
        let permissionObj = permission;
        permissions.push(permissionObj);
        permissionState[permission.id] = false;
      });
      setPermissionList(permissions);
      setPermissionsState(permissionState);
    } catch (e) {
      let message = handleErrorMessage(e);
      messageAlert("error", message);

    }
  };

  const getCategories = async () => {
    try {
      let { data } = await UserSignUp.getCategories();
      setPermissionCategories(data);
    } catch (e) {
      handleErrorMessage(e);

    }
  };

  const filterPermission = (permission, category) => {
    return permission?.category?.name == category;
  };

  const isCategoryEmpty = (permissionArray, category) => {
    try {
      let found = false;
      permissionArray.every((permission) => {
        if (permission?.category?.name == category) {
          found = true;
          return false;
        }
        return true;
      });
      return !found;
    } catch (e) {
      return true;
    }
  };

  const getRoles = async () => {
    try {
      let { data } = await UserSignUp.getRoles();
      let permissionTableData = [];
      data.reverse().forEach((role) => {
        let rowData = [];
        let { name, permissions } = role;
        rowData.push(name, permissions.length, role);
        permissionTableData.push(rowData);
      });
      setTableData(permissionTableData);
    } catch (e) {
      handleErrorMessage(e);

    }
  };

  const handleTabChange = (event, value) => {
    if (value == 1) {
      navigate("/staff/management");
    }
  };

  React.useEffect(() => {
    document.body.style.cssText = "overflow-y:hidden!important";
    getPermissions();
    getRoles();
    getCategories();
    if (isLoading == false) {
      let status = userData?.staff_type == "super_admin" || userData?.role?.name == 'super_admin' || userData?.role?.name == 'super admin';
      if (status == false) {
        navigate("/404");
      }
    }
  }, [userData]);

  //   const {approveAndDeclineGrants,approveAndDeclineLoans,approveGrants,approveLoans} = permissionsState;

  return (
    <>
      <Modal
        open={roleModal}
        onClose={() => {
          setRoleModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content" sx={modalStyle}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{ fontWeight: "700", fontSize: "1.2rem", display: "flex" }}
            >
              <Box sx={{ fontWeight: "700", fontSize: "0.9rem" }}>
                Add New Role
              </Box>
            </Box>
            <Box sx={{ order: "1", marginLeft: "auto" }}>
              <button
                style={{
                  backgroundColor: "none",
                  border: "none",
                  borderRadius: "50%",
                }}
                onClick={() => {
                  setRoleModal(false);
                }}
              >
                <CloseIcon sx={{ paddingTop: "0.1rem" }} />
              </button>
            </Box>
          </Box>
          <Box sx={{ marginTop: "1rem" }}>
            <form onSubmit={handleSubmit(submitRoleForm)}>
              <Grid
                container
                sx={{ width: "100%" }}
                rowSpacing={"1rem"}
                columnSpacing={"1rem"}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1.2rem",
                  }}
                >
                  <Box sx={{ fontWeight: "600", fontSize: "0.85rem" }}>
                    Role Name
                  </Box>
                  <Box mt={"1rem"}>
                    <input
                      type="text"
                      style={{ width: "calc(100%-1.6rem)" }}
                      placeholder="Role Name"
                      className="add-staff-form-input"
                      {...register("name", { required: true })}
                    ></input>
                  </Box>
                  {errors.name && (
                    <span style={{ color: "red", marginTop: "0.5rem" }}>
                      *This field is required
                    </span>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box sx={{ fontWeight: "600", fontSize: "0.85rem" }}>
                    Privileges
                  </Box>
                  <Box mt={"1rem"} sx={{ backgroundColor: "#F9FAFD" }}>
                    <FormControl
                      sx={{ m: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormGroup>
                        <Grid
                          container
                          sx={{ width: "100%" }}
                          rowSpacing={"1rem"}
                          columnSpacing={"1rem"}
                        >
                          <Grid item xs={12} sx={{}}>
                            <Box sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
                              Search
                            </Box>
                            <Box mt={"1rem"}>
                              <input
                                type="text"
                                style={{}}
                                placeholder="Permission Name"
                                className="add-staff-form-input"
                                {...register("permissionsSearch")}
                              ></input>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              height: "250px",
                              overflowY: "auto",
                              paddingLeft: "1rem",
                              marginTop: "1.5rem",
                            }}
                          >
                            {permissionCategories?.map((category) => {
                              if (
                                isCategoryEmpty(
                                  permissionList,
                                  category?.name
                                ) == false
                              ) {
                                return (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sx={{ marginTop: "1rem" }}
                                      my={"0.5rem"}
                                    >
                                      <div
                                        className="card-title text-default-color"
                                        style={{
                                          borderLeft: "0.2em solid #2A3B8F",
                                          paddingLeft: "0.5em",
                                          marginBottom: "0.5rem",
                                        }}
                                      >
                                        {category?.name}
                                      </div>
                                    </Grid>
                                    {permissionList
                                      .filter((permission) => {
                                        return (
                                          filterPermission(
                                            permission,
                                            category?.name
                                          ) == true
                                        );
                                      })
                                      .filter((permission) => {
                                        return permission.name
                                          .toLowerCase()
                                          .includes(
                                            permissionsSearch.toLowerCase()
                                          );
                                      })
                                      .map((permission) => {
                                        return (
                                          <Grid item xs={6}>
                                            <FormControlLabel
                                              className="custom-checkbox"
                                              sx={{
                                                fontStyle: "inherit !important",
                                              }}
                                              control={
                                                <Checkbox
                                                  sx={{
                                                    color: "#2A3B8F",
                                                    "&.Mui-checked": {
                                                      color: "#2A3B8F",
                                                    },
                                                  }}
                                                  checked={
                                                    permissionsState[
                                                      permission.id
                                                    ]
                                                  }
                                                  onChange={
                                                    handlePermissionsChange
                                                  }
                                                  name={`${permission.id}`}
                                                />
                                              }
                                              label={`${permission.name}`}
                                            />
                                          </Grid>
                                        );
                                      })}
                                  </>
                                );
                              }
                            })}
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </FormControl>
                  </Box>
                  <Box mt={"1rem"} sx={{ width: "100%", display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        order: "0",
                        marginLeft: "auto",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Button
                          sx={{
                            fontFamily: "inherit !important",
                            fontSize: "0.85rem",
                            textTransform: "initial !important",
                            color: "#2a3b8f",
                            padding: "0.7rem",
                            paddingLeft: "2rem",
                            paddingRight: "2rem",
                            border: "1px solid #2a3b8f",
                            marginRight: "2rem",
                          }}
                          onClick={() => {
                            setRoleModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          type="submit"
                          sx={{
                            "&:hover": {
                              color: "#2a3b8f",
                              border: "1px solid #2a3b8f",
                            },
                            fontWeight: "400",
                            fontFamily: "inherit !important",
                            fontSize: "0.85rem",
                            color: "white",
                            backgroundColor: "#2a3b8f",
                            textTransform: "initial !important",
                            padding: "0.7rem",
                            paddingLeft: "2rem",
                            paddingRight: "2rem",
                          }}
                        >
                          Create Role
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content" sx={modalStyle} height={"60vh"}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{ fontWeight: "700", fontSize: "1.2rem", display: "flex" }}
            >
              <Box sx={{ fontWeight: "700", fontSize: "0.9rem" }}>
                Delete Role
              </Box>
            </Box>
            <Box sx={{ order: "1", marginLeft: "auto" }}>
              <button
                style={{
                  backgroundColor: "none",
                  border: "none",
                  borderRadius: "50%",
                }}
                onClick={() => {
                  setDeleteModal(false);
                }}
              >
                <CloseIcon sx={{ paddingTop: "0.1rem" }} />
              </button>
            </Box>
          </Box>
          <Box
            className="message-area"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Box my={"1rem"}>
              <img src={DeleteSvg} />
            </Box>
            <Box
              mt={"2rem"}
              mb={"2rem"}
              sx={{ fontSize: "1rem", fontWeight: "700" }}
            >
              This action is not reversible
            </Box>
            <Box sx={{ fontSize: "1rem", fontWeight: "700" }}>
              Are you sure you want to delete this role?
            </Box>
          </Box>
          <Box
            mt={"3rem"}
            sx={{
              width: "100%",
              display: "flex",
              order: "2",
              marginTop: "auto",
            }}
          >
            <Box sx={{ display: "flex", order: "0", marginLeft: "auto" }}>
              <Box>
                <Button
                  sx={{
                    fontFamily: "inherit !important",
                    fontSize: "0.85rem",
                    textTransform: "initial !important",
                    color: "#2a3b8f",
                    padding: "0.7rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    border: "1px solid #2a3b8f",
                    marginRight: "2rem",
                  }}
                  onClick={() => {
                    setDeleteModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{
                    "&:hover": { color: "red", border: "1px solid red" },
                    fontWeight: "400",
                    fontFamily: "inherit !important",
                    fontSize: "0.85rem",
                    color: "white",
                    backgroundColor: "red",
                    textTransform: "initial !important",
                    padding: "0.7rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                  }}
                >
                  Delete Role
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={editModal}
        onClose={() => {
          setEditModal(false);
          let _permissionState = permissionsState;
          Object.keys(_permissionState).forEach(
            (v) => (_permissionState[v] = false)
          );
          setPermissionsState(_permissionState);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content" sx={modalStyle}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{ fontWeight: "700", fontSize: "1.2rem", display: "flex" }}
            >
              <Box sx={{ fontWeight: "700", fontSize: "0.9rem" }}>
                Edit Role
              </Box>
            </Box>
            <Box sx={{ order: "1", marginLeft: "auto" }}>
              <button
                style={{
                  backgroundColor: "none",
                  border: "none",
                  borderRadius: "50%",
                }}
                onClick={() => {
                  setEditModal(false);
                  let _permissionState = permissionsState;
                  Object.keys(_permissionState).forEach(
                    (v) => (_permissionState[v] = false)
                  );
                  setPermissionsState(_permissionState);
                }}
              >
                <CloseIcon sx={{ paddingTop: "0.1rem" }} />
              </button>
            </Box>
          </Box>
          <Box sx={{ marginTop: "1rem" }}>
            <form onSubmit={handleSubmit2(submitEditRoleForm)}>
              <Grid
                container
                sx={{ width: "100%" }}
                rowSpacing={"1rem"}
                columnSpacing={"1rem"}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1.2rem",
                  }}
                >
                  <Box sx={{ fontWeight: "600", fontSize: "0.85rem" }}>
                    Role Name
                  </Box>
                  <Box mt={"1rem"}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      value={currentRole?.name}
                      defaultValue={currentRole?.name}
                      name="name"
                      render={({ field }) => (
                        <input
                          type="text"
                          style={{ width: "calc(100%-1.6rem)" }}
                          placeholder="Role Name"
                          className="add-staff-form-input"
                          {...field}
                        ></input>
                      )}
                    />
                  </Box>
                  {errors2.name && (
                    <span style={{ color: "red", marginTop: "0.5rem" }}>
                      *This field is required
                    </span>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box sx={{ fontWeight: "600", fontSize: "0.85rem" }}>
                    Privileges
                  </Box>
                  <Box mt={"1rem"} sx={{ backgroundColor: "#F9FAFD" }}>
                    <FormControl
                      sx={{ m: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormGroup>
                        <Grid
                          container
                          sx={{ width: "100%" }}
                          rowSpacing={"1rem"}
                          columnSpacing={"1rem"}
                        >
                          <Grid item xs={12} sx={{}}>
                            <Box sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
                              Search
                            </Box>
                            <Box mt={"1rem"}>
                              <input
                                type="text"
                                style={{}}
                                placeholder="Permission Name"
                                className="add-staff-form-input"
                                {...register2("permissionsSearch")}
                              ></input>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              height: "250px",
                              overflowY: "auto",
                              paddingLeft: "1rem",
                              marginTop: "1.5rem",
                            }}
                          >
                            {permissionCategories?.map((category) => {
                              if (
                                isCategoryEmpty(
                                  permissionList,
                                  category?.name
                                ) == false
                              ) {
                                return (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sx={{ marginTop: "1rem" }}
                                      my={"0.5rem"}
                                    >
                                      <div
                                        className="card-title text-default-color"
                                        style={{
                                          borderLeft: "0.2em solid #2A3B8F",
                                          paddingLeft: "0.5em",
                                          marginBottom: "0.5rem",
                                        }}
                                      >
                                        {category?.name}
                                      </div>
                                    </Grid>
                                    {permissionList
                                      .filter((permission) => {
                                        return (
                                          filterPermission(
                                            permission,
                                            category?.name
                                          ) == true
                                        );
                                      })
                                      .filter((permission) => {
                                        return permission.name
                                          .toLowerCase()
                                          .includes(
                                            permissionsSearch.toLowerCase()
                                          );
                                      })
                                      .map((permission) => {
                                        return (
                                          <Grid item xs={6}>
                                            <FormControlLabel
                                              className="custom-checkbox"
                                              sx={{
                                                fontStyle: "inherit !important",
                                              }}
                                              control={
                                                <Checkbox
                                                  sx={{
                                                    color: "#2A3B8F",
                                                    "&.Mui-checked": {
                                                      color: "#2A3B8F",
                                                    },
                                                  }}
                                                  checked={
                                                    permissionsState[
                                                      permission.id
                                                    ]
                                                  }
                                                  onChange={
                                                    handlePermissionsChange
                                                  }
                                                  name={`${permission.id}`}
                                                />
                                              }
                                              label={`${permission.name}`}
                                            />
                                          </Grid>
                                        );
                                      })}
                                  </>
                                );
                              }
                            })}
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </FormControl>
                  </Box>
                  <Box mt={"1rem"} sx={{ width: "100%", display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        order: "0",
                        marginLeft: "auto",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Button
                          sx={{
                            fontFamily: "inherit !important",
                            fontSize: "0.85rem",
                            textTransform: "initial !important",
                            color: "#2a3b8f",
                            padding: "0.7rem",
                            paddingLeft: "2rem",
                            paddingRight: "2rem",
                            border: "1px solid #2a3b8f",
                            marginRight: "2rem",
                          }}
                          onClick={() => {
                            setEditModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          type="submit"
                          sx={{
                            "&:hover": {
                              color: "#2a3b8f",
                              border: "1px solid #2a3b8f",
                            },
                            fontWeight: "400",
                            fontFamily: "inherit !important",
                            fontSize: "0.85rem",
                            color: "white",
                            backgroundColor: "#2a3b8f",
                            textTransform: "initial !important",
                            padding: "0.7rem",
                            paddingLeft: "2rem",
                            paddingRight: "2rem",
                          }}
                        >
                          {" "}
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={viewModal}
        onClose={() => {
          setViewModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content" sx={modalStyle}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{ fontWeight: "700", fontSize: "1.2rem", display: "flex" }}
            >
              <Box sx={{ fontWeight: "700", fontSize: "0.9rem" }}>
                View Role
              </Box>
            </Box>
            <Box sx={{ order: "1", marginLeft: "auto" }}>
              <button
                style={{
                  backgroundColor: "none",
                  border: "none",
                  borderRadius: "50%",
                }}
                onClick={() => {
                  setViewModal(false);
                }}
              >
                <CloseIcon sx={{ paddingTop: "0.1rem" }} />
              </button>
            </Box>
          </Box>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1.2rem",
            }}
          >
            <Box sx={{ fontWeight: "600", fontSize: "0.85rem" }}>Role Name</Box>
            <Box mt={"1rem"}>
              <input
                type="text"
                style={{ width: "calc(100%-1.6rem)" }}
                placeholder="Role Name"
                value={currentRole?.name}
                className="add-staff-form-input"
                readOnly={true}
              ></input>
            </Box>
          </Grid>
          <Box mt={"1.5rem"} sx={{ fontWeight: "600", fontSize: "0.85rem" }}>
            Privileges
          </Box>
          <Box
            sx={{
              backgroundColor: "#F9FAFD",
              marginTop: "1rem",
              height: "50vh",
              overflowY: "auto",
              padding: "1rem",
            }}
          >
            <Grid container sx={{ width: "100%" }}>
              {permissionCategories?.map((category) => {
                if (isCategoryEmpty(permissionList, category?.name) == false) {
                  return (
                    <>
                      <Grid item xs={12} mt={"0.5rem"}>
                        <div
                          className="card-title text-default-color"
                          style={{
                            borderLeft: "0.2em solid #2A3B8F",
                            paddingLeft: "0.5em",
                            marginBottom: "1rem",
                          }}
                        >
                          {category?.name}
                        </div>
                      </Grid>
                      {currentRole?.permissions
                        ?.filter((permission) => {
                          return (
                            filterPermission(permission, category?.name) == true
                          );
                        })
                        .filter((permission) => {
                          return permission.name
                            .toLowerCase()
                            .includes(permissionsSearch.toLowerCase());
                        })
                        .map((permission) => {
                          return (
                            <Grid
                              item
                              xs={6}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <Box>
                                <CheckCircleIcon
                                  sx={{ fontSize: "1rem", color: "#02B242" }}
                                />
                              </Box>
                              <Box sx={{ marginLeft: "1rem" }}>
                                {permission.name}
                              </Box>
                            </Grid>
                          );
                        })}
                    </>
                  );
                }
              })}
            </Grid>
          </Box>
        </Box>
      </Modal>

      <Box
        className="staff-main"
        sx={{ width: "100%", height: "100vh", overflowY: "hidden" }}
      >
        <Grid sx={{ width: "100%", height: "100%", display: "flex" }}>
          <ApplicantSidebar selectedView={3} />
          <Grid sx={{ display: "flex", flexDirection: "column", width: "98%" }}>
            <StaffNavbar user={userData?.user} />
            <Grid container sx={{ overflowY: "auto", height: "100vh" }}>
              <SettingsNav selectedView={0} />
              <Grid
                item
                xs={9}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "2rem",
                }}
              >
                <Box sx={{ marginBottom: "1rem" }}>
                  <Tabs
                    className="custom-tabs"
                    value={0}
                    onChange={handleTabChange}
                    style={{
                      borderBottom: "0.5px solid #EBEFF2",
                      borderTop: "0.5px solid #EBEFF2",
                    }}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Roles" value={0} />
                    <Tab label="User Profile" value={1} />
                  </Tabs>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    className="add-staff-btn"
                    sx={{
                      backgroundColor: "#2a3b8f",
                      order: "1",
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                      width: "20%",
                      borderRadius: "0.5rem",
                      justifyContent: "center",
                      padding: "0.4rem",
                    }}
                    onClick={() => {
                      setRoleModal(true);
                    }}
                  >
                    <Box>
                      <AddIcon
                        sx={{
                          color: "white",
                          marginRight: "0.4rem",
                          marginTop: "0.1rem",
                        }}
                      />
                    </Box>
                    <Box>
                      <span style={{ color: "white", fontSize: "0.9rem" }}>
                        Add New Role
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="custom-data-table settings-table"
                  my={"2rem"}
                  sx={{ width: "100%" }}
                >
                  <MUIDataTable
                    options={tableOptions}
                    columns={columns}
                    data={tableData}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Roles;
