import React from "react";
import propTypes from "prop-types";
export default function SemiCircle({ color }) {
  const semiCircleStyle = {
    position: "absolute",
    top: " 21%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "100px",
    width: "210px",
    borderRadius: "180px 180px 0 0",
    backgroundColor: `${color}0D`,
  };
  return <div style={semiCircleStyle}></div>;
}

SemiCircle.propTypes = {
  color: propTypes.string,
};
