/*eslint-disable*/
import React from 'react';
import { DashboardContext } from '../../DashboardContent';
import { DashboardModel, Genders, Regions } from '../interfaces/dashboard.model';
import { IChart, IChartDataObject, IMapComponentChart } from '../interfaces/chart.interfaces';
import { RegionKey } from '../types/chart.types';
import { educationalLevelLabelObj } from '../../Cards/EducationalLevelChart';
import {
  getAgeGroupChartColorMapping,
  getGrantAgeGroupChartColorMapping,
  getGrantGenderChartColorMapping,
  getTrainingGenderChartColorMapping,
} from '../utils/colorMappings';

export const DefaultData: IChartDataObject[] = [
  {
    x: 'Men',
    label: 'Men',
    y: 20,
    color: 'red',
  },
  {
    x: 'Women',
    label: 'Women',
    y: 40,
    color: 'green',
  },
];

export const useGetGrantDashboard = () => {
  const [districtData, setDistrictData] = React.useState({});
  const [genderChartData, setGenderChartData] = React.useState<IChart<IChartDataObject[]>>({
    data: [],
    title: 'Grant Applicants By Gender',
  });
  const [disabilityChartData, setDisabilityChartData] = React.useState<IChart<IChartDataObject[]>>({
    data: [],
    title: 'Grant Applicants By Disability Status',
  });
  const [ageDistributionChartData, setAgeDistributionChartData] = React.useState<IChart<IChartDataObject[]>>({
    data: [],
    title: 'Grant Applicants By Age Distribution',
  });
  const [regionalDistributionData, setRegionalDistributionData] = React.useState<IMapComponentChart>({
    data: {
      Oti: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Bono: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Ahafo: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Volta: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Ashanti: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Central: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Eastern: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Savanna: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Western: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      Northern: {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      'Bono East': {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      'North East': {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      'Upper West': {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      'Upper East': {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      'Greater Accra': {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
      'Western North': {
        region: '',
        districts: {},
        total_male_applicants: 0,
        total_amount_requested: 0,
        total_male_registrants: 0,
        total_female_applicants: 0,
        total_female_registrants: 0,
      },
    },
    title: 'Grant Applicants By Region',
  });
  const [educationalLevelChartData, setEducationalLevelChartData] = React.useState<IChart<IChartDataObject[]>>({
    title: 'Grant Applicants By Education Level',
    data: [],
  });

  const [sectorChartData, setSectorChartData] = React.useState<IChart<IChartDataObject[]>>({
    title: 'Grant Applicants By Business Sector',
    data: [],
  });

  const { userData, skippedAdvancedTraining, setSkippedAdvancedTraining } = React.useContext(DashboardContext);
  const _userData = userData as unknown as DashboardModel;

  React.useEffect(() => {
    if (_userData) {
      try {
        const _genderChartData: IChart<IChartDataObject[]> = { ...genderChartData, data: [] };
        const _disabilityChartData: IChart<IChartDataObject[]> = { ...disabilityChartData, data: [] };
        const _ageDistributionChartData: IChart<IChartDataObject[]> = { ...ageDistributionChartData, data: [] };
        const _educationalLevelChartData: IChart<IChartDataObject[]> = { ...educationalLevelChartData, data: [] };
        const _sectorChartData: IChart<IChartDataObject[]> = { ...sectorChartData, data: [] };
        const _regionalDistributionData = regionalDistributionData;
        Object.keys(_userData?.age_groups).forEach((key) => {
          let { short_name, total_male_applicants, total_female_applicants } = _userData?.age_groups[key];
          _ageDistributionChartData.data.push({
            color: getGrantAgeGroupChartColorMapping(short_name),
            label: short_name,
            x: short_name,
            y: total_male_applicants + total_female_applicants,
          });
        });
        Object.keys(_userData?.genders).forEach((value) => {
          if (value !== 'S') {
            let { gender, total_applicants } = _userData?.genders?.[value as keyof Genders];
            _genderChartData.data.push({
              color: getGrantGenderChartColorMapping(gender),
              label: gender,
              x: value == 'M' ? 'Male' : 'Female',
              y: total_applicants,
            });
          }
        });

        Object.keys(_userData.regions).forEach((value) => {
          let { districts, region, total_male_applicants, total_female_applicants } =
            _userData.regions[value as keyof Regions];
          // setDistrictData(districts);
        });

        Object.keys(_userData?.educational_levels).forEach((value) => {
          let { educational_level, total_male_applicants, total_female_applicants } =
            _userData.educational_levels[value];
          _educationalLevelChartData.data.push({
            color: '#00C9BA',
            label: educationalLevelLabelObj?.[educational_level]?.label,
            x: educationalLevelLabelObj?.[educational_level as string]?.label,
            y: total_male_applicants + total_female_applicants,
          });
        });

        Object.keys(_userData?.sectors).forEach((value) => {
          let { sector, total_female_applicants, total_male_applicants } = _userData?.sectors?.[value];
          _sectorChartData.data.push({
            color: '#407BFF',
            label: value,
            x: value,
            y: total_female_applicants + total_male_applicants,
          });
        });

        ['Disabled', 'Not Disabled'].forEach((value) => {
          _disabilityChartData?.data?.push({
            color: value == 'Not Disabled' ? '#2A3B8F' : '#7A71D1',
            label: value,
            x: value,
            y:
              value == 'Disabled'
                ? _userData?.disabled?.total_male_applicants + _userData?.disabled?.total_female_applicants
                : _userData.total_completed_applications -
                  (_userData?.disabled?.total_male_applicants + _userData?.disabled?.total_female_applicants),
          });
        });

        setGenderChartData(_genderChartData);
        setDisabilityChartData(_disabilityChartData);
        setAgeDistributionChartData(_ageDistributionChartData);
        setEducationalLevelChartData(_educationalLevelChartData);
        setSectorChartData(_sectorChartData);
        setRegionalDistributionData({ title: 'Regional Distributions', data: _userData?.regions });
      } catch (e) {}
    }
  }, [userData]);

  return {
    genderChartData,
    disabilityChartData,
    ageDistributionChartData,
    regionalDistributionData,
    educationalLevelChartData,
    sectorChartData,
    _userData,
    skippedAdvancedTraining,
    setSkippedAdvancedTraining,
  };
};
