/*eslint-disable*/
import { IApplicantDataObject } from '../interfaces/applicantDetails.model';

export const useLoadFiscalProjections = (
  data: IApplicantDataObject['profile'][0]['grant_information']['financial_projections'],
) => {
  try {
    let rows: any[][] = [];
    let projected_turnover_list = ['Projected Sales/Turnover'];
    let projected_operating_cost_list = ['Projected Operating Cost'];
    let projected_net_profit_list = ['Projected Net Profit'];

    data?.forEach((row) => {
      projected_turnover_list.push(row?.projected_turnover || '');
      projected_operating_cost_list.push(row?.projected_operating_cost || '');
      projected_net_profit_list.push(
        (parseInt(row?.projected_turnover || '') - parseInt(row?.projected_operating_cost || '')).toString(),
      );
    });

    rows.push(projected_turnover_list);
    rows.push(projected_operating_cost_list);
    rows.push(projected_net_profit_list);

    return { rowData: rows };
  } catch (e) {
    return { rowData: [] };
  }
};
