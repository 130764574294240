/*eslint-disable*/
import React from "react";
import { Box, Button, Menu, MenuItem, Grid, Modal } from "@mui/material";
import propTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

const CreatePasswordModal = ({
  onSubmit,
  modalShowing,
  setModalState,
  register,
  errors,
  handleSubmit,
  isLoading,
}) => {
  const inputStyle = {
    padding: "10px",
    width: "100%",
  };

  const labelStyle = {
    fontSize: "1rem",
    fontWeight: "600",
  };

  const errorTextStyle = {
    color: "red",
    display: "block",
    marginTop: "0.5rem",
  };

  return (
    <Modal
      open={modalShowing}
      onClose={() => {
        setEditModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-content" sx={modalStyle}>
        {isLoading && (
          <Box
            className="modal-content"
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <>
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box
                sx={{ fontWeight: "700", fontSize: "2rem", display: "flex" }}
              >
                <Box>Change Password</Box>
              </Box>
              <Box sx={{ order: "1", marginLeft: "auto" }}>
                <button
                  style={{
                    backgroundColor: "none",
                    border: "none",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    setModalState(false);
                  }}
                >
                  <CloseIcon sx={{ paddingTop: "0.1rem" }} />
                </button>
              </Box>
            </Box>
            <form id="create-account" onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ marginTop: "1rem" }}>
                <Grid
                  container
                  sx={{ width: "100%" }}
                  rowSpacing={"1rem"}
                  columnSpacing={"1rem"}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box style={labelStyle}>Old Password</Box>
                    <Box mt={"1rem"}>
                      <input
                        type={"password"}
                        style={inputStyle}
                        {...register("old_password", { required: true })}
                        placeholder={"Old Password"}
                      ></input>
                    </Box>
                    {errors.old_password && (
                      <span style={errorTextStyle}>This field is required</span>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box style={labelStyle}>New Password</Box>
                    <Box mt={"1rem"}>
                      <input
                        type={"password"}
                        style={inputStyle}
                        {...register("new_password", { required: true })}
                        placeholder={"New Password"}
                      ></input>
                    </Box>
                    {errors.new_password && (
                      <span style={errorTextStyle}>This field is required</span>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box style={labelStyle}>Re-type New Password</Box>
                    <Box mt={"1rem"}>
                      <input
                        type={"password"}
                        style={inputStyle}
                        {...register("retyped_password", { required: true })}
                        placeholder={"New Password"}
                      ></input>
                    </Box>
                    {errors.retyped_password && (
                      <span style={errorTextStyle}>This field is required</span>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </form>
          </>
        )}

        <Box mt={"1rem"} sx={{ width: "100%", display: "flex" }}>
          <Box sx={{ display: "flex", order: "0", marginLeft: "auto" }}>
            <Box>
              <Button
                sx={{
                  fontFamily: "inherit !important",
                  fontSize: "0.85rem",
                  textTransform: "initial !important",
                  color: "#2a3b8f",
                  padding: "0.7rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                  border: "1px solid #2a3b8f",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  setModalState(false);
                }}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                form="create-account"
                type="submit"
                sx={{
                  "&:hover": { color: "#2a3b8f", border: "1px solid #2a3b8f" },
                  fontWeight: "400",
                  fontFamily: "inherit !important",
                  fontSize: "0.85rem",
                  color: "white",
                  backgroundColor: "#2a3b8f",
                  textTransform: "initial !important",
                  padding: "0.7rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

CreatePasswordModal.propTypes = {
  onSubmit: propTypes.func,
  modalShowing: propTypes.bool,
  setModalState: propTypes.func,
  register: propTypes.func,
  errors: propTypes.object,
  handleSubmit: propTypes.func,
  isLoading: propTypes.bool,
};

export default CreatePasswordModal;
