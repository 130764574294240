import React from "react";
// import '../style/DashboardStyles.css';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import useGetUserData from "@utils/hooks/useGetUserData";
import propTypes from "prop-types";
import { AvatarInitial } from "@utils/Logo";

const ApplicantNavbar = ({ type = "Applicants List" }) => {
  const navigate = useNavigate();
  const { userData } = useGetUserData();

  const style = {
    display: "flex",
    flexDirection: "row",
    padding: "1.1rem",
  };

  return (
    <div className="dashboard-navbar" style={style}>
      <div
        className="left flex"
        style={{
          marginTop: "0.3rem",
          fontSize: "2rem",
          justifyContent: "center",
        }}
      >
        <div onClick={() => navigate(-1)}>
          <ArrowBackIosNewIcon style={{ color: "#ABB3BF" }} />
        </div>
        <div
          style={{
            fontSize: "1rem",
            fontWeight: "700",
            marginTop: "0.3rem",
            marginLeft: "1.5rem",
          }}
        >
          {type}
        </div>
      </div>
      <div className="right">
        {/* <div className="notification-area mr-2">
          <div className="notification-dot"></div>
          <i className="fa fa-bell" aria-hidden="true"></i>
        </div> */}
        <div className="username-area mr-2">
          <AvatarInitial
            text={
              `${userData?.user?.first_name} ${userData?.user?.last_name}` || ""
            }
          />
        </div>
      </div>
    </div>
  );
};
ApplicantNavbar.propTypes = {
  navBack: propTypes.string,
  type: propTypes.string,
};
export default ApplicantNavbar;
