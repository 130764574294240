import React from "react";
import { Box, Rating } from "@mui/material";
import propTypes from "prop-types";

const AvatarIcon = ({ firstName, lastName, rating, score }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const iconStyle = {
    borderRadius: "50%",
    backgroundColor: " #ABB3BF",
    alignItems: "center",
    justifyContent: "center",
    width: "5rem",
    height: "5rem",
    marginRight: "2rem",
    fontWeight: "600",
    display: "flex",
  };

  const textStyle = {
    fontSize: "2rem",
    color: "white",
  };

  const rightStyle = {
    display: "flex",
    flexDirection: "column",
  };

  return (
    <Box sx={containerStyle}>
      <Box sx={iconStyle}>
        <Box sx={textStyle}>{firstName?.split("")[0]}</Box>
      </Box>
      <Box sx={rightStyle}>
        <Box
          sx={{ fontSize: "1.2rem", fontWeight: "600", marginBottom: "0.5rem" }}
        >
          {firstName} {lastName}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <Rating name="user-rating" value={rating} readOnly={true} />
          </Box>
          <Box>({score})%</Box>
        </Box>
      </Box>
    </Box>
  );
};

AvatarIcon.propTypes = {
  firstName: propTypes.string,
  lastName: propTypes.string,
  rating: propTypes.number,
  score: propTypes.number,
};

export default AvatarIcon;
