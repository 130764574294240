/*eslint-disable*/
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getApplicant, getGrantBusinessExpenditures } from '@/service/Applicants';
import { AxiosResponse } from 'axios';
import { IApplicantDataObject } from '../interfaces/applicantDetails.model';
import html2pdf from 'html2pdf.js';
import { BASE_CLIENT, BASE_URL } from '@/service/apiConfig';

export const useGetGrantAssessment = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [selectedApplicantId, setSelectedApplicantId] = React.useState<string | number | undefined>(id);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isPrinting, setIsPrinting] = React.useState(false);
  const [selectedApplicantResponseObject, setSelectedApplicantResponseObject] = React.useState<
    Partial<IApplicantDataObject> | undefined
  >();
  const { data, isLoading, refetch, isError } = useQuery(
    [id],
    () => {
      if (id) {
        return getApplicant(id);
      }
    },
    { staleTime: Infinity, refetchOnWindowFocus: false },
  );

  const {
    data: expenditureList,
    isLoading: expenditureLoading,
    isRefetching: expenditureRefetching,
  } = useQuery(
    [selectedApplicantResponseObject],
    () => {
      if (selectedApplicantResponseObject) {
        return getGrantBusinessExpenditures(id);
      }
    },
    { refetchOnWindowFocus: false, refetchOnMount: false, refetchOnReconnect: false, staleTime: Infinity },
  );

  React.useEffect(() => {
    if (data) {
      setSelectedApplicantResponseObject(data.data.data);
    }
  }, [data]);

  React.useEffect(() => {
    setSelectedApplicantId(id ? id : 1);
  }, [id]);

  React.useEffect(() => {
    if (isPrinting) {
      try {
        if (document !== null) {
          let a = window.open('', '', 'height=500, width=500');

          a.document.write('<html>');
          a.document.write('<head>');
          a.document.write('<link rel="preconnect" href="https://fonts.googleapis.com">');
          // Include other necessary styles and scripts here
          a.document.write(`<style>
        body{
          width:100%;
          height:100vh;
          overflow-y:auto;
        }
      </style>`);
          a.document.write('</head>');

          a.document.write('<body>');

          // Create an iframe and set its source URL
          a.document.write(
            `<iframe src="${BASE_CLIENT}/grantassessment/print/${id}" id="iframe" width="100%" height="100%"></iframe>`,
          );

          a.document.write('</body></html>');

          setTimeout(function () {
            const iframe = a.document.getElementById('iframe') as HTMLIFrameElement;
            iframe?.contentWindow?.print();
            a.document.close();
          }, 5000);
          a.document.close();
        }
      } catch (e) {
        console.log(e);
      }
      setIsPrinting(false);

      ///download as pdf...
    }
  }, [isPrinting]);

  return {
    currentPage,
    totalPages,
    selectedApplicantId,
    selectedApplicantResponseObject,
    isLoading,
    isSubmitting,
    expenditureList,
    isPrinting,
    setIsPrinting,
    refetch,
    setIsSubmitting,
    setSelectedApplicantResponseObject,
    setSelectedApplicantId,
    setCurrentPage,
    setTotalPages,
  };
};

export const useGetGrantList = () => {};
