/*eslint-disable */
import React, { useEffect, useState } from 'react';
import ApprovalsNav from './ApprovalsNav';
import { Box, Button, Grid, Tab, Tabs, Button as MuiButton } from '@mui/material';
import MUIDataTable, { MUIDataTableProps } from 'mui-datatables';
import SearchInput from '@components/SearchInput';
import { useNavigate } from 'react-router-dom';
import { AvatarInitial } from '@utils/Logo';
import { getPaginatedApplicants, getGrantApprovals, getTrainingApprovals, uploadFile } from '@service/Applicants';
import { MdAdd } from 'react-icons/md';
import { styled } from '@mui/material/styles';
import AlertDialog from '@components/Modals';
import { messageAlert } from '@utils/helpers/functions';
import { useMutation, useQueryClient } from 'react-query';
import useGetUserData from '@utils/hooks/useGetUserData';
import { useGetDocuments } from '@utils/hooks/useGetUserData';
import AddIcon from '@mui/icons-material/Add';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import EllipsisText from 'react-ellipsis-text';
import { checkPermissions } from '@service/RequireAuth';
import TableLoaders from '@components/Loaders/TableLoaders';
import { handleErrorMessage } from '@utils/helpers/functions';
import ApprovalsBaseLayout from './ApprovalsBaseLayout';
import ExpandButton from '../Buttons/ExpandButton';
import { IGrantParams } from '@/utils/interfaces/approvals.interface';

const Grants = ({ type }: IGrantParams) => {
  let [applicantData, setApplicantData] = useState({
    page: 0,
    count: 1,
    data: [],
    isLoading: false,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const userData = JSON.parse(window?.localStorage?.getItem('user'));
  // const {userData,isLoading:userLoading} = useGetUserData();
  const [params, setParams] = React.useState({
    region: '',
    district: '',
    gender: '',
    disability: '',
    score_upper_limit: '',
    score_lower_limit: '',
  });

  const navigate = useNavigate();
  const responsive = 'vertical';
  const tableBodyHeight = `${window.innerHeight * 0.95}px`;
  const tableBodyMaxHeight = '';

  const tableOptions: MUIDataTableProps['options'] = {
    print: false,
    search: false,
    viewColumns: false,
    download: true,
    filter: false,
    filterType: 'dropdown',
    responsive,
    serverSide: true,
    page: applicantData?.page,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    count: applicantData?.count,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'Role',
      direction: 'asc',
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setApplicantData({ ...applicantData, page: tableState?.page });
      }
    },
    customSearchRender: () => null,
    customToolbarSelect: () => null,
  };

  const columns = [
    {
      name: 'Name',
      options: {
        filter: false,
        customBodyRender: (value) => <AvatarInitial text={value} />,
      },
    },
    'Phone Number',
    {
      name: 'Applicant Score(%)',
      options: {
        filter: false,
        customBodyRender: (value) => <div style={{ color: '#1D9649', fontWeight: 'bold' }}>{value}%</div>,
      },
    },
    {
      name: 'Action',
      options: {
        filter: false,
        customBodyRender: (id) => (
          <Button
            sx={{ textTransform: 'initial' }}
            onClick={() => {
              navigate(`/applicants/${id}`);
            }}
          >
            View
          </Button>
        ),
      },
    },
  ];

  const getTableData = (data) => {
    let tableData = [];
    data?.data?.forEach((applicant) => {
      let { applicant_score, user } = applicant;
      let tableRow = [`${user?.first_name} ${user?.last_name}`, user?.phone_number, applicant_score, user?.id];
      tableData.push(tableRow);
    });
    setApplicantData({ ...applicantData, count: data?.count, data: tableData });
  };

  const fetchApplicants = async () => {
    setIsLoading(true);
    try {
      const response = await getGrantApprovals(
        applicantData?.page + 1,
        10,
        params?.region == '' ? userData?.profile?.region?.id : params?.region,
        params?.district == '' ? userData?.profile?.district?.id : params?.district,
        params.gender,
        params.disability,
        params.score_upper_limit,
        params.score_lower_limit,
        '',
        '',
        '',
        '',
        searchQuery,
        type === 'skipped',
      );
      if (response?.data) {
        getTableData(response?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      let message = handleErrorMessage(error);
      messageAlert('error', message);
    }
  };

  useEffect(() => {
    fetchApplicants();
  }, [applicantData?.page]);

  useEffect(() => {
    // if(!applicantData?.isLoading){
    //     fetchApplicants()
    // }
    // let status = checkPermissions('training approvals', userData);
    // if (status == false) {
    //   navigate('/404');
    // }
  }, []);

  return (
    <ApprovalsBaseLayout selectedView={type == 'regular' ? 9 : 8}>
      <Box
        sx={{
          width: '100%',
          fontSize: '1.2rem',
          fontWeight: '600',
          marginBottom: '2rem',
        }}
      >
        <ExpandButton />
        Grant Approvals ({type == 'regular' ? 'Regular Applicants' : 'Skipped Applicants'})
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={8} style={{ display: 'flex' }}>
          <SearchInput setSearchQuery={setSearchQuery} />
          <Box>
            <Button
              onClick={() => {
                fetchApplicants();
              }}
              sx={{
                '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
                fontWeight: '400',
                fontFamily: 'inherit !important',
                fontSize: '0.85rem',
                color: 'white',
                backgroundColor: '#2a3b8f',
                textTransform: 'initial !important',
                padding: '0.7rem',
                paddingLeft: '2rem',
                paddingRight: '2rem',
                height: '40px',
                marginLeft: '1rem',
              }}
            >
              Filter
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box className="custom-data-table settings-table" my={'1rem'} sx={{ width: '100%' }}>
        {isLoading == true ? (
          <TableLoaders />
        ) : (
          <MUIDataTable title="" options={tableOptions} columns={columns} data={applicantData?.data} />
        )}
      </Box>
    </ApprovalsBaseLayout>
  );
};

export default Grants;
