import React from "react";
// import { NumericFormat } from 'react-number-format';
import propTypes from "prop-types";

const NumberWrapper = ({ value, size, align }) => {
  return (
    <span style={{ fontSize: size, textAlign: align ? align : "left" }}>
      {value?.toLocaleString(undefined, { maximumFractionDigits: 1 })}
    </span>
  );
};

NumberWrapper.propTypes = {
  value: propTypes.any,
  size: propTypes.string,
  align: propTypes.string,
};

export default NumberWrapper;
