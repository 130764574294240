/*eslint-disable*/
import { IMapComponentChart } from '../interfaces/chart.interfaces';
import React from 'react';
import ghanaJson from '../../../../assets/ghanaJson.json';
import { VectorMapProps, VectorMapLayer } from '@south-paw/react-vector-maps';
import { RegionKey } from '../types/chart.types';
import { DashboardContext } from '../../DashboardContent';
import { Region, Regions } from '../interfaces/dashboard.model';

export const useGetMapComponent = (data: IMapComponentChart['data']) => {
  const [districtModal, setDistrictModal] = React.useState(false);
  const [selectedRegion, setSelectedRegion] = React.useState();

  const [districtBreakdown, setDistrictBreakdown] = React.useState<Regions[keyof Regions]['districts']>({});
  const { regions, selectedRegionName, setSelectedRegionName } = React.useContext(DashboardContext);

  const handleMapClick = (regionName: string) => {
    if (regions) {
      let { id, name } = regions?.filter((region: any) => {
        return region.name == regionName;
      })[0];
      setSelectedRegion(id);
      setSelectedRegionName(name);
      setDistrictModal(true);
    }
  };
  const onReset = () => {
    setSelectedRegionName('');
    setSelectedRegion(null);
  };

  const layerProps: VectorMapProps['layerProps'] = {
    onClick: ({ target }: any) => {
      handleMapClick?.(target?.attributes?.name?.value);
    },
  };
  const getId = (key: string | number) => {
    if (key == '' || key == undefined) {
      let ghanaJsonIdArray: string[] = ghanaJson.layers.map((layer) => {
        return layer.id;
      });
      return ghanaJsonIdArray;
    } else if (regions?.[(key as number) - 1] !== undefined) {
      let { name } = regions[(key as number) - 1];
      let { id } = ghanaJson.layers.filter((layer) => {
        return layer.name === name;
      })[0];
      return [id];
    }

    // const {} = data[key as RegionKey];
    //     if (key === '') {
    //       let id_array = [];
    //       ghanaJson.layers.map((layer) => {
    //         id_array.push(layer.id);
    //       });
    //       return id_array;
    //     } else if (regions?.[key - 1] !== undefined) {
    //       let { name } = regions[key - 1];
    //       let { id } = ghanaJson.layers.filter((layer) => {
    //         return layer.name === name;
    //       })[0];
    //       return [id];
    //     }
    //     return [];
  };
  React.useEffect(() => {}, [data]);

  React.useEffect(() => {
    let districts = Object?.keys?.(data)
      ?.map?.((key) => {
        return data[key];
      })
      ?.filter((region) => {
        return region?.region == selectedRegionName;
      })?.[0] as Regions[keyof Regions];

    setDistrictBreakdown(districts?.districts);
  }, [selectedRegionName]);

  return { layerProps, ghanaJson, selectedRegion, districtModal, districtBreakdown, onReset, setDistrictModal, getId };
};
