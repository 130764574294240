export const grantBeneficiariesColumns = [
    'Applicant ID',
    'Name',
    'Have you received the funding assigned to you? (Yes=1/No=0)',
    'Did you use the funds for the grant items agreed upon?(Yes=1/No=0)',
    'Have you started your business since you completed the training and received grant?(Yes=1/No=0)',
    'How many new people have you employed since you received the grant(total)?',
    'How many new people have you employed since you received the grant(female)?',
    'How many new people have you employed since you received the grant(youth)?',
    'How many new people have you employed since you received the grant(PWDs)?'
]

export const nonGrantBeneficiariesColumns = [
    `Applicant ID`,
    `Name`,
    `Have you started a business since you completed the training?(Yes=1/No=0)`,
    `How many new people have you employed since you completed the training(total)?`,
    `How many new people have you employed since you completed the training(female)?`,
    `How many new people have you employed since you completed the training(youth)?`,
    `How many new people have you employed since you completed the training(PWDs)?`
]