/*eslint-disable*/
import { styled, Typography, Grid, Box, Slider } from '@mui/material';
import React from 'react';

export const ChartTitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '14px',
  letterSpacing: '0em',
  fontFamily: 'inherit',
}));
export const ChartWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  padding: '1.2rem',
  flexDirection: 'column',
  backgroundColor: 'white',
  borderRadius: '1rem',
  marginTop: '1.5rem',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  marginLeft: '1rem',
  minHeight: '400px',
  justifyContent: 'space-between',
}));

export const HorizontalChartWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 50px)',
  overflowY: 'auto',
}));

export const HorizontalChartRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const HorizontalRowLabel = styled(Typography)(({ theme }) => ({
  width: '20%',
  fontWeight: '600',
  fontSize: '14px',
}));

export const HorizontalSliderWrapper = styled(Box)(({ theme }) => ({
  width: '70%',
}));

export const HorizontalSliderValue = styled(Box)(({ theme }) => ({
  width: '10%',
  fontWeight: '600',
  textAlign: 'right',
  paddingRight: '1rem',
  fontSize: '1.2rem',
}));

export const HorizontalSliderPercentageText = styled(Typography)(({ theme }) => ({
  width: '100px',
  display: 'inline',
  fontSize: '0.8rem',
}));

export const MapComponentBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '20px',
  marginBottom: '20px',
}));

export const MapComponentBodyLeft = styled(Box)(({ theme }) => ({}));

export const MapComponentFooter = styled(Box)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
}));

export const PieChartWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
}));

export const PieChartFooter = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const PieChartLegend = styled(Grid)(({ theme }) => ({
  fontSize: '14px',
  alignItems: 'center',
  display: 'flex',
}));

export const PieChartLegendValue = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const PieChartDescription = styled(Box)(({ theme }) => ({
  position: 'absolute',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  top: '45%',
  fontSize: '20px',
  justifyContent: 'center',
}));

export const PieChartDescriptionText = styled(Typography)(({ theme }) => ({
  marginBottom: '5px',
  fontSize: '12px',
  color: '#4F4F4F',
}));

export const BarChartSlider = ({ color, ...props }: React.CSSProperties) =>
  styled(Slider)(({ theme }) => ({
    '.MuiSlider-track': {
      borderWidth: '4px',
      borderColor: color || '#ffa000',
    },
    '.MuiSlider-rail': {
      display: 'none',
    },
    '.MuiSlider-thumb': {
      display: 'none',
    },
    '&.Mui-disabled': {
      color: color || '#ffa000',
    },
    ...props,
  }));
