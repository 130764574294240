import React from "react";
import propTypes from "prop-types";
import { Button } from "@mui/material";

const ButtonFullWidth = ({ isLoading }) => {
  return (
    <div className="d-grid gap-2">
      <button type="submit" className="btn btn btn-general btn-block">
        {isLoading ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          " Next "
        )}
      </button>
    </div>
  );
};

ButtonFullWidth.propTypes = {
  isLoading: propTypes.bool,
};

export const DefaultButton = ({
  isLoading,
  icon = "",
  text,
  className,
  color = "#2a3b8f",
  onClick,
  disabled,
}) => {
  return (
    <Button
      startIcon={icon}
      onClick={onClick}
      className={className}
      variant="contained"
      disabled={disabled}
      sx={{
        "&:hover": {
          color: "color",
          border: `1px solid ${color}`,
          backgroundColor: color,
        },
        color: "white",
        backgroundColor: color,
        textTransform: "initial !important",
      }}
    >
      {isLoading ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        text
      )}
    </Button>
  );
};
DefaultButton.propTypes = {
  isLoading: propTypes.bool,
  icon: propTypes.element,
  text: propTypes.string,
  className: propTypes.string,
  color: propTypes.string,
  onClick: propTypes.func,
  disabled: propTypes.bool,
};

export default ButtonFullWidth;
