/*eslint-disable*/
import React from 'react';
import { DialogTitle, DialogContent, DialogActions, styled, Grid } from '@mui/material';
import * as MUI from '@mui/material';
import { defaultActiveColor } from '@/utils/constants';
import { IDistrictBreakdownModal } from './interfaces/chart.interfaces';
import {
  BreakdownAccordion,
  BreakdownAccordionDetails,
  BreakdownAccordionSummary,
} from './styled/trainingdashboard.styledcomponents';
import { ArrowDownward, Dashboard, Search } from '@mui/icons-material';
import { DashboardContext } from '../DashboardContent';

const Dialog = styled(MUI.Dialog)(({ theme }) => ({}));
const CloseButton = styled(MUI.Button)(({ theme }) => ({
  color: defaultActiveColor,
}));
const DialogRoot = styled(MUI.Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 'unset',
    width: '600px',
  },
}));

const DistrictBreakdownModal = ({ open, data, setOpen }: IDistrictBreakdownModal) => {
  const [searchString, setSearchString] = React.useState('');
  const { selectedRegionName } = React.useContext(DashboardContext);
  const theme = MUI.useTheme();
  const fullScreen = MUI.useMediaQuery(theme.breakpoints.down('sm'));
  const BreakdownComponent = React.useMemo(() => {
    if (data !== null && data !== undefined) {
      return Object?.keys?.(data)
        ?.map?.((key) => {
          return data[key];
        })
        ?.filter?.((value) => {
          return value.district.toLowerCase().includes(searchString.toLowerCase()) == true;
        })
        .sort(
          (a, b) =>
            b.total_male_registrants +
            b.total_female_registrants -
            (a.total_male_registrants + a.total_female_registrants),
        )
        ?.map?.((districtData) => {
          return (
            <BreakdownAccordion>
              <BreakdownAccordionSummary expandIcon={<ArrowDownward />}>
                <MUI.Box sx={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                  <MUI.Box> {districtData.district}</MUI.Box>
                  <MUI.Box>{districtData.total_male_registrants + districtData.total_female_registrants}</MUI.Box>
                </MUI.Box>
              </BreakdownAccordionSummary>
              <BreakdownAccordionDetails>
                <Grid container>
                  <Grid xs={12}>
                    <span>Total Male Trainees: </span> <span>{districtData.total_male_registrants}</span>
                  </Grid>
                  <Grid xs={12}>
                    <span>Total Female Trainees: </span>
                    <span>{districtData.total_female_registrants}</span>
                  </Grid>
                </Grid>
              </BreakdownAccordionDetails>
            </BreakdownAccordion>
          );
        });
    }
    return <></>;
  }, [data, searchString]);

  console.log(data, 2111);
  return (
    <Dialog
      open={open || false}
      onClose={() => {
        setOpen(!open);
        setSearchString('');
      }}
      fullScreen={fullScreen}
    >
      <DialogRoot>
        <DialogTitle>
          {`${selectedRegionName} Region`}
          <Grid item xs={12} mt={'20px'} mb={'10px'}>
            <MUI.TextField
              variant="outlined"
              onChange={({ target: { value } }) => {
                setSearchString(value);
              }}
              fullWidth
              placeholder="Search For district"
              focused={true}
            />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ maxHeight: '300px', overflowY: 'auto' }}>
            <Grid item xs={12}>
              {BreakdownComponent}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CloseButton
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </CloseButton>
        </DialogActions>
      </DialogRoot>
    </Dialog>
  );
};

export default DistrictBreakdownModal;
