export const BUSINESS_TYPES = [
  { label: 'Agribusiness', value: 'agribusiness' },
  { label: 'Agro-processing', value: 'agro_processing' },
  { label: 'Construction', value: 'construction' },
  { label: 'Commerce and Trade', value: 'commerce_and_trade' },
  { label: 'Education', value: 'education' },
  { label: 'Fashion and Beauty', value: 'fashion_and_beauty' },
  { label: 'Food and Beverage', value: 'food_and_beverage' },
  {
    label: 'Healthcare and Pharmaceuticals',
    value: 'healthcare_and_pharmaceuticals',
  },
  { label: 'Manufacturing', value: 'manufacturing' },
  { label: 'Technology (ICT)', value: 'technology' },
  {
    label: 'Real Estate and Construction',
    value: 'real_estate_and_construction',
  },
  { label: 'Renewable Energy', value: 'renewable_energy' },
  { label: 'Services', value: 'services' },
  { label: 'Tourism and Hospitality', value: 'tourism_and_hospitality' },
  { label: 'Transportation', value: 'transportation' },
  { label: 'Water and Sanitation', value: 'water_and_sanitation' },
];

export const EDUCATIONAL_LEVEL = [
  { label: 'No formal education', value: 'no_formal_education' },
  { label: 'JHS/JSS', value: 'junior_high_school' },
  { label: 'Middle', value: 'junior_high_school' },
  { label: 'SHS/SSS', value: 'senior_high_school' },
  { label: 'Vocational/Technical/Commercial', value: 'vocational' },
  { label: 'Teacher Training/Agric/Nursing Certificate', value: 'diploma' },
  { label: 'Certificate/Diploma', value: 'diploma' },
  { label: 'Tertiary - HND', value: 'degree' },
  { label: "Tertiary - Bachelor's Degree", value: 'degree' },
  { label: 'Tertiary - Postgraduate', value: 'degree' },
  { label: "Tertiary - Master's Degree", value: 'masters' },
  { label: 'Tertiary - Ph.D', value: 'masters' },
  { label: 'Others', value: 'other' },
];

export const DISABILITY_STATUS = [
  { label: 'Disabled', value: 'yes' },
  { label: 'Not Disabled', value: 'no' },
];

export const VOCATIONAL_CERTS = [
  { label: 'National Proficiency I', value: 'national_proficiency_1' },
  { label: 'National Proficiency II', value: 'national_proficiency_2' },
  { label: 'National Cerificate I', value: 'national_certificate_1' },
  { label: 'National Cerificate II', value: 'national_certificate_2' },
  { label: 'HND', value: 'hnd' },
  { label: 'Bachelor of Technology (B.Tech)', value: 'b_tech' },
  { label: 'Others', value: 'other_previous_vocational_certificate_check' },
];

export const BUSINESS_SUPPORT = [
  { label: 'Equipment/Machinery', value: 'equipment_machinery' },
  {
    label: 'Registration & Compliance Certification',
    value: 'registration_compliance_certification',
  },
  { label: 'Technology', value: 'technology' },
  { label: 'Working capital', value: 'working_capital' },
];

export const VOCATIONAL_SKILLS = [
  {
    label: 'Food Preparation/Processing and Beverage',
    value: 'Food Preparation/Processing and Beverage',
  },
  {
    label: 'Plumbing and Gas Technology',
    value: 'Plumbing and Gas Technology',
  },
  { label: 'Block Laying and Tiling', value: 'Block Laying and Tiling' },
  { label: 'Furniture Works', value: 'Furniture Works' },
  { label: 'Welding and Fabrication', value: 'Welding and Fabrication' },
  { label: 'Electrical Installation', value: 'Electrical Installation' },
  { label: 'Electronics', value: 'Electronics' },
  { label: 'Mechanical', value: 'Mechanical' },
  { label: 'Hydraulics Technology', value: 'Hydraulics Technology' },
  { label: 'Cosmetology (Beautician, Hairdressing)', value: 'Cosmetology' },
  { label: 'Make-up Artistry', value: 'Make-up Artistry' },
  {
    label: 'Garment Making (Tie & Dye, Fashion Designing)',
    value: 'Garment Making',
  },
  { label: 'Automotive Mechanic', value: 'Automotive Mechanic' },
  { label: 'ICT', value: 'ICT' },
  { label: 'Others', value: 'other' },
];

/**
 *  "basic_attendance_score": 0,
      "basic_participation_score": 0,
      "basic_class_exercise_score": 0,
      "basic_business_model_canvas_score": 0,
      "intermediate_attendance_score": 0,
      "intermediate_participation_score": 0,
      "intermediate_class_exercise_score": 0,
      "intermediate_business_plan_score": 0,
      "advanced_attendance_score": 0,
      "advanced_participation_score": 0,
      "advanced_class_exercise_score": 0,
      "advanced_business_plan_presentation_score": 0,
      "advanced_pitch_practice_score": 0
 * 
 */

export const basic_score_keys = [
  'basic_attendance_score',
  'basic_participation_score',
  'basic_class_exercise_score',
  'basic_business_model_canvas_score',
];
export const intermediate_score_keys = [
  'intermediate_attendance_score',
  'intermediate_participation_score',
  'intermediate_class_exercise_score',
  'intermediate_business_plan_score',
];
export const advanced_score_keys = [
  'advanced_attendance_score',
  'advanced_participation_score',
  'advanced_class_exercise_score',
  'advanced_business_plan_presentation_score',
  'advanced_pitch_practice_score',
];

export const grant_score_keys = [
				"enterprise_overview_score",
				"challenges_score",
				"technical_implementation_score",
				"technical_capacity_score",
				"has_benefited_from_grant_programme_score",
				"fiscal_expenditure_score",
				"grant_expenditure_score",
				"performance_indicator_score",
				"sustainability_plan_score",
				"operational_risk_score",
			
]



export const PASS_THRESHOLD = 30;

export const defaultActiveColor = '#2a3b8f';
