/*eslint-disable*/
import React from 'react';
import { Menu, Box, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

interface IAssessmentMenuProps {
  menuAnchor: Element | null;
  setMenuAnchor: React.Dispatch<React.SetStateAction<Element | null>>;
}

const AssessmentMenu = ({ menuAnchor, setMenuAnchor }: Partial<IAssessmentMenuProps>) => {
  const navigate = useNavigate();
  const menuOpen = Boolean(menuAnchor);

  return (
    <Menu
      id="basic-menu"
      anchorEl={menuAnchor}
      open={menuOpen}
      onClose={() => {
        setMenuAnchor(null);
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPaper-root': {
          color: '#2A3B8F',
          backgroundColor: 'white',
          paddingLeft: '1rem',
          boxShadow: 1,
        },
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem
        sx={{ fontFamily: 'inherit!important' }}
        onClick={() => {
          navigate('/grantassessment');
        }}
      >
        Single Assessment
      </MenuItem>
      <MenuItem
        sx={{ fontFamily: 'inherit!important' }}
        onClick={() => {
          navigate('/grantassessment/batch');
        }}
      >
        Batch Assessment
      </MenuItem>
      <MenuItem
        sx={{
          fontFamily: 'inherit!important',
          color: 'red',
          display: 'flex',
        }}
        onClick={() => {
          setMenuAnchor(null);
        }}
      >
        <Box
          sx={{
            fontFamily: 'inherit!important',
            color: 'red',
            display: 'flex',
            fontSize: '1rem',
          }}
        >
          Close Menu
        </Box>
        <Box sx={{ order: '1', marginLeft: 'auto' }}>
          <CloseIcon sx={{ color: 'red', marginLeft: '1rem' }} />
        </Box>
      </MenuItem>
    </Menu>
  );
};

export default AssessmentMenu;
