/*eslint-disable*/
import React from 'react';
import { Box } from '@mui/material';
import {
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  LabelSeries,
  FlexibleWidthXYPlot,
  Hint,
} from 'react-vis';
import propTypes from 'prop-types';
import NumberWrapper from '../NumberWrapper';
import { DashboardContext } from '../DashboardContent';

export const educationalLevelLabelObj = {
  degree: { label: 'Degree' },
  diploma: { label: 'Diploma' },
  junior_high_school: { label: 'Junior High' },
  masters: { label: 'Masters' },
  no_formal_education: { label: 'No Education' },
  senior_high_school: { label: 'Senior High' },
  vocational: { label: 'Vocational' },
  hnd: { label: 'HND' },
  middle_school: { label: 'Middle School' },
  teacher_training_nursing: { label: 'Teacher Training' },
  postgraduate: { label: 'Postgrad' },
  phd: { label: 'Phd' },
};

const ApplicantChart = ({ data }) => {
  const [useCanvas] = React.useState(false);
  const [hintValue, setHintValue] = React.useState(0);
  const BarSeries = VerticalBarSeries;
  const [_chartData, setChartData] = React.useState([
    { x: 'degree', label: 'Degree', y: 0 },
    { x: 'diploma', label: 'Diploma', y: 0 },
    { x: 'junior_high_schoo', label: 'Junior High School', y: 0 },
    { x: 'masters', label: 'Masters Degree', y: 0 },
    { x: 'no_formal_education', label: 'No Formal Education', y: 0 },
    { x: 'senior_high_school', label: 'Senior High School', y: 0 },
    { x: 'vocational', label: 'Vocational', y: 0 },
    { x: 'hnd', label: 'HND', y: 0 },
    { x: 'middle_school', label: 'Middle School', y: 0 },
    { x: 'teacher_training_nursing', label: 'Teacher Training Nursing', y: 0 },
    { x: 'postgraduate', label: 'Post Graduate', y: 0 },
    { x: 'phd', label: 'Phd', y: 0 },
  ]);

  const labelObj = {
    degree: { label: 'Degree' },
    diploma: { label: 'Diploma' },
    junior_high_school: { label: 'Junior High' },
    masters: { label: 'Masters' },
    no_formal_education: { label: 'No Education' },
    senior_high_school: { label: 'Senior High' },
    vocational: { label: 'Vocational' },
    hnd: { label: 'HND' },
    middle_school: { label: 'Middle School' },
    teacher_training_nursing: { label: 'Teacher Training' },
    postgraduate: { label: 'Postgrad' },
    phd: { label: 'Phd' },
  };

  const toolTipStyle = {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '15px',
    fontWeight: '600',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
  };

  React.useEffect(() => {
    if (data) {

      let { educational_levels } = data;
      let keys = Object.keys(educational_levels);


      let chartDataArray = [];
      keys.forEach((key) => {
        let { label } = labelObj?.[key];

        let values = educational_levels[key];
        let chartObj = {
          x: label,
          y: values?.total_male_applicants + values?.total_female_applicants,
        };
        chartDataArray.push(chartObj);
      });
      setChartData(chartDataArray);
    }
  }, [data]);



  return (
    <div className="custom-line-series">
      <FlexibleWidthXYPlot xType="ordinal" height={300} xDistance={100}>
        <Hint value={hintValue} align={{ vertical: 'top', horizontal: 'left' }}>
          <Box style={toolTipStyle}>
            {hintValue?.x}: {<NumberWrapper value={hintValue?.y} size={'0.9rem'} />}
            <span
              style={{
                fontSize: '0.8rem',
                display: 'inline',
                marginLeft: '0.4rem',
                color: 'gray',
              }}
            >
              (%
              {((hintValue?.y / data?.total_completed_applications) * 100)?.toFixed(1)})
            </span>
          </Box>
        </Hint>
        {/* <VerticalGridLines /> */}
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        {/* <BarSeries className="vertical-bar-series-example" data={greenData} /> */}
        <BarSeries
          onNearestX={(value, { event, innerX, index }) => {
            setHintValue(value);
          }}
          className="vertical-bar-series-example"
          data={_chartData}
          color={'rgb(0 201 64)'}
          barWidth={0.3}
          animation={true}
        ></BarSeries>
      </FlexibleWidthXYPlot>
    </div>
  );
};

ApplicantChart.propTypes = {
  totalApplications: propTypes.number,
  data: propTypes.object,
};

const EducationalLevelChart = () => {
  const { totalApplications, userData } = React.useContext(DashboardContext);
  const style = {
    display: 'flex',
    padding: '1.2rem',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '1rem',
    marginTop: '1.5rem',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    width: '100%',
    // marginRight:'1rem'
  };
  return (
    <div className="applicants-over-time" style={style}>
      <div className="top card-title">Applications by Educational Level</div>
      <div className="bottom">
        <ApplicantChart
          // type = {params?.type}
          totalApplications={totalApplications}
          data={userData}
        />
      </div>
    </div>
  );
};

export default EducationalLevelChart;
