/*eslint-disable*/
import { IApplicantDataObject } from '../interfaces/applicantDetails.model';

export const useLoadBusinessActiviites = () => {};

interface OptionType {
  label: string;
  value: string;
}

export const monthsArray: OptionType[] = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const yearsArray: OptionType[] = [
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
  { label: '2028', value: '2028' },
  { label: '2029', value: '2029' },
  { label: '2030', value: '2030' },
  { label: '2031', value: '2031' },
  { label: '2032', value: '2032' },
  { label: '2033', value: '2033' },
  { label: '2034', value: '2034' },
  { label: '2035', value: '2035' },
];
export const useLoadDisplayTableRows = (
  rows: IApplicantDataObject['profile'][0]['grant_information']['activities'],
) => {
  try {
    if (rows) {
      let rowData: string[][] = [];
      rows?.forEach((row) => {
        if (row) {
          rowData.push([
            row.activity_name || '',
            row.detailed_activity_description || '',
            monthsArray.find((value) => {
              return value.value == row?.start_month?.toString();
            })?.label || '',
            monthsArray.find((value) => {
              return value.value == row?.end_month?.toString();
            })?.label || '',
            row?.year?.toString() || '',
          ]);
        }
      });
      return { rowData: rowData };
    }
    throw new Error('undefined rows');
  } catch (e: any) {
    if (e?.message == 'undefined rows') {
      // console.log('this happened');
    }
    return { rowData: [] };
  }
};
