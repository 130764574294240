/*eslint-disable*/

import { AxiosResponse } from 'axios';
import moment from 'moment';
import { returnDefaultValue } from '../constants';

export const useGetGrantAssessmentTableData = (data: AxiosResponse<IApprovalsList, any>) => {
  const tableData: any[][] = [];
  let count = 0;

  data?.data?.data?.forEach((applicant) => {
    let { project_application_code, user, profile, business_profile, application_process_status } = applicant;
    let tableRow = [
      `${returnDefaultValue(user?.first_name)} ${returnDefaultValue(user?.last_name)}`,
      `${returnDefaultValue(project_application_code)}`,
      moment(user?.created).format('YYYY-MM-DD'),
      `${user?.first_name} ${user?.last_name}`,
      user?.phone_number,
      profile?.id_number,
      user?.id,
    ];
    tableData.push(tableRow);
  });
  count = data?.data?.count;
  return { tableData, count };
};
