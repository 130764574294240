import { styled, Box } from '@mui/material';

export const ModalTitle = styled(Box)({
  fontSize: '18px',
  fontWeight: '700',
});

export const ModalSubtitle = styled(Box)({
  fontSize: '15px!important',
  marginTop: '20px',
  marginBottom: '20px',
});
