import React from "react";
import ApplicantSidebar from "@components/ApplicantSidebar/ApplicantSidebar";
import ApplicantMain from "@components/ApplicantMain/ApplicantMain";
import { useParams } from "react-router-dom";

const Applicants = () => {
  const { id, type, category } = useParams();
  const style = {
    width: "100%",
    height: "100vh",
    overflowY: "hidden",
  };

  return (
    <div className="flex" style={style}>
      <ApplicantSidebar />
      <ApplicantMain id={id} type={type} category={category} />
    </div>
  );
};

export default Applicants;
