/*eslint-disable*/
import { defaultActiveColor } from '@/utils/constants';
import React from 'react';
import {
  TrainingStageSwitcherRoot,
  LeftTrainingStageButton,
  RightTrainingStageButton,
} from './styled/trainingdashboard.styledcomponents';

interface IGrantStageSwitcher {
  currentStage: boolean;
  setStage: (stage: boolean) => void;
}

const GrantStageSwitcher = ({ setStage, currentStage }: IGrantStageSwitcher) => {
  const getStyles = (isActive: boolean): React.CSSProperties => {
    return {
      backgroundColor: isActive ? defaultActiveColor : '#E4EBFD',
      color: isActive ? 'white' : 'black',
    };
  };
  return (
    <TrainingStageSwitcherRoot>
      <LeftTrainingStageButton
        onClick={() => {
          setStage?.(true);
        }}
        sx={getStyles(currentStage == true)}
      >
        Skipped Advanced Training
      </LeftTrainingStageButton>
      <RightTrainingStageButton
        onClick={() => {
          setStage?.(false);
        }}
        sx={getStyles(currentStage == false)}
      >
        Advanced Training to Grants
      </RightTrainingStageButton>
    </TrainingStageSwitcherRoot>
  );
};

export default GrantStageSwitcher;
