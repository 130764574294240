import apiConfig from "./apiConfig";

const getSelf = () => {
  const url = `staff/auth/profile/self`;
  return apiConfig.getRequest(url);
};

const getToken = () => {
  const url = `staff/auth/account/refresh`;
  return apiConfig.getRequest(url);
};

export default {
  getSelf,
  getToken,
};
