/*eslint-disable*/
import { Box } from '@mui/material';
import React from 'react';
import { FormTypeButton } from '../GrantAssessment/components/GrantDetails/GrantDetails.components';
import { disabled_gray, light_gray } from '@/utils/colors';
import { defaultActiveColor } from '@/utils/constants';

interface IMultiSelectButton {
  selected: string | number | boolean;
  disabled?: boolean;
  labels: { value: string | number | boolean; label: string }[];
  onChange: (e: string | number | boolean) => void;
}

const getSelectedStyles = (isSelected: boolean) => {
  if (isSelected) {
    return {
      backgroundColor: defaultActiveColor,
      fontFamily: 'inherit',
      color: 'white',
      '&:hover': {
        color: 'white',
        backgroundColor: defaultActiveColor,
      },
    };
  }

  return {
    backgroundColor: disabled_gray,
    fontFamily: 'inherit',
    color: light_gray,
  };
};

const MultiSelectButton = ({ selected, labels, disabled, onChange }: IMultiSelectButton) => {
  return (
    <Box flexDirection={'row'} columnGap={2}>
      {labels.map((label, key) => {
        return (
          <>
            {label.value === selected && (
              <FormTypeButton
                disabled={disabled == true}
                style={getSelectedStyles(true)}
                key={Math.random()}
                onClick={() => {
                  onChange(label.value);
                }}
              >
                {label.label}
              </FormTypeButton>
            )}
            {label.value !== selected && (
              <FormTypeButton
                disabled={disabled == true}
                style={getSelectedStyles(false)}
                key={Math.random()}
                onClick={() => {
                  onChange(label.value);
                }}
              >
                {label.label}
              </FormTypeButton>
            )}
          </>
        );
      })}
    </Box>
  );
};

export default MultiSelectButton;
