import React from "react";
import TableLoaders from "@components/Loaders/TableLoaders";
import MUIDataTable from "mui-datatables";
import { returnCapitalizedInitial } from "@utils/helpers/functions";
import { Box } from "@mui/material";
import propTypes from "prop-types";

const PRIMARY_COLOR = "#2a3b8f";

const AuditTable = ({ setAuditParams, auditParams, loading, searchString }) => {
  const responsive = "vertical";
  const tableBodyMaxHeight = "";
  const tableOptions = {
    fixedHeader: true,
    fixedFooter: true,
    print: false,
    search: false,
    viewColumns: false,
    download: false,
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight: `${window.innerHeight - window.innerHeight * 0.05}px`,
    tableBodyMaxHeight,
    searchText: searchString,
    page: auditParams?.page,
    serverSide: true,
    count: auditParams?.count,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],

    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setAuditParams({ ...auditParams, page: tableState?.page });
      }
    },
    customSearchRender: () => null,
  };

  const auditColumns = [
    "Audit Type",
    {
      name: "Created By",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              px={"0.9rem"}
              py={"0.6rem"}
              sx={{
                backgroundColor: PRIMARY_COLOR,
                color: "white",
                borderRadius: "50%",
              }}
            >
              {returnCapitalizedInitial(value)}
            </Box>
            <Box sx={{ marginLeft: "1rem" }}>{value}</Box>
          </Box>
        ),
      },
    },
    "Created On",
    "Description",
  ];

  return (
    <Box
      className="custom-data-table settings-table"
      my={"2rem"}
      sx={{ width: "100%" }}
    >
      {loading == true ? (
        <TableLoaders />
      ) : (
        <MUIDataTable
          options={tableOptions}
          columns={auditColumns}
          data={auditParams?.data}
        />
      )}
    </Box>
  );
};

AuditTable.propTypes = {
  setAuditParams: propTypes.func,
  auditParams: propTypes.object,
  loading: propTypes.bool,
  searchString: propTypes.string,
};

export default AuditTable;
