/*eslint-disable*/
import { getRegions } from '@/service/CommonApi';
import { IGrantFormField } from '../interfaces/GrantOverview.interface';
import { IApplicantDataObject } from '../interfaces/applicantDetails.model';
import { useGetRegions } from '@/utils/hooks/getRegionDistricts';
import { useContext } from 'react';
import { GrantAssessmentContext } from '@/utils/context/GrantAssementContext';

export const BusinessFormFields: IGrantFormField[] = [{}];
interface IUseGetBusinessFormFields {
  response: Partial<IApplicantDataObject>;
  regions: any;
}

export const useGetBusinessFormFields = ({ response, regions }: IUseGetBusinessFormFields) => {
  const { selectedApplicantResponseObject } = useContext(GrantAssessmentContext);
  const isSkipped = selectedApplicantResponseObject?.client_details?.[0]?.skipped_advanced_training == true;
  const getBusinessRegistrationStatus = (value: string) => {
    switch (value) {
      case 'Y':
        return 'Yes';
      case 'N':
        return 'No';
      case 'IN_THE_PROCESS':
        return 'In the Process';
    }
    return 'No';
  };

  const getBusinessStageStatus = (value: string) => {
    switch (value) {
      case 'basic_training':
        return 'Basic Training';
      case 'intermediate_training':
        return 'Intermediate Training';
      case 'advanced_training':
        return 'Advanced Training';
    }
  };
  const application_summary_fields: IGrantFormField[] = [
    {
      label: 'Applicant Reference Number',
      type: 'default',
      value: response?.client_details?.[0]?.project_application_code,
    },
    {
      label: 'Business Plan Title',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.business_plan?.business_plan_title,
    },
    {
      label: 'Business Plan Summary',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.business_plan?.business_plan_summary,
    },
    {
      label: 'Expected Start Date',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.business_plan?.expected_start_date,
    },
    {
      label: 'Expected Duration in months',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.business_plan?.expected_duration_in_months,
    },
    {
      label: 'Total Funding Requested',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.business_plan?.total_funding_requested,
    },
  ];

  const applicant_information_fields: IGrantFormField[] = [
    {
      label: 'First Name',
      type: 'default',
      value: response?.overview?.first_name,
    },
    {
      label: 'Last Name',
      type: 'default',
      value: response?.overview?.last_name,
    },
    {
      label: 'Other Name',
      type: 'default',
      value: response?.overview?.middle_name,
    },
    {
      label: 'Phone Number',
      type: 'default',
      value: response?.overview?.phone_number,
    },

    {
      label: 'Enterprise Name',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.enterprise_name,
    },
    {
      label: 'ID Number',
      type: 'default',
      value: response?.profile?.[0]?.id_number,
    },
    {
      label: 'Region',
      type: 'default',
      value: regions?.filter((region: any) => {
        return region?.id == response?.profile?.[0]?.grant_information?.applicant_information?.region;
      })?.[0]?.name,
    },
    {
      label: 'City',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.city,
    },
    {
      label: 'Address 1',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.address_line_1,
    },
    {
      label: 'Address 2',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.address_line_2,
    },
    {
      label: 'Digital Address',
      type: 'default',
      value: response?.profile?.[0]?.digital_address,
    },
    {
      label: 'Contact Person Name',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.contact_person_name,
    },
    {
      label: 'Contact Person Telephone',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.contact_person_telephone,
    },
    {
      label: 'Contact Person Email',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.contact_person_email,
    },
  ];

  const applicant_information_2_fields: IGrantFormField[] = [
    {
      label: 'Gender of owner',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.gender_of_owner,
    },
    {
      label: 'Is the owner a person with a disability?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.owner_is_disabled == true ? 'Y' : 'N',
    },
    {
      label: 'What level of training have you reached under the program?',
      type: 'default',
      value: getBusinessStageStatus(
        response?.profile?.[0]?.grant_information?.applicant_information?.level_of_training_under_this_program || '',
      ),
    },
    {
      label: 'Provide an overview of your enterprise, your product/service',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.product_overview,
    },
    {
      label: 'Next, tell us about how the opportunity in your area of business or interest was identified.',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.how_did_you_identify_opportunity,
    },
  ];

  const applicant_information_3_fields: IGrantFormField[] = [
    {
      label:
        'Tell us about the stage of your product(s) or services. Are you at the ideation stage, or the testing stage, or perhaps the stage where you can scale your startup (that is, the commercialization stage)?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.business_stage,
    },
    {
      label:
        'Tell us if your product(s) has received FDA certification or whether you are now applying for or pursuing these certifications.',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.product_has_fda_approval_or_now_applying,
    },
    {
      label:
        'Who are the customers of the products/services? Mention some. For new products/services yet to enter the market, tell us about your immediate target market and how your plan to access those markets identified.',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.who_are_your_customers,
    },
  ];

  const applicant_information_4_fields: IGrantFormField[] = [
    {
      label:
        'Tell us about your key competitors. What will you do differently to set you apart from or separate your product/service from your key competitors?',
      type: 'default',
      value:
        response?.profile?.[0]?.grant_information?.applicant_information
          ?.who_is_your_competition_and_how_unique_are_you_from_them,
    },
    {
      label:
        'Tell us if your product(s) has received FDA certification or whether you are now applying for or pursuing these certifications.',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.product_has_fda_approval_or_now_applying,
    },
    {
      label: 'Provide details of the challenges your enterprise faces or your startup anticipates',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.details_of_entreprise_challenges,
    },
  ];

  const project_information_fields: IGrantFormField[] = [
    {
      label: 'Business Activities',
      type: 'activity',
      value: response?.profile?.[0]?.grant_information?.activities,
    },
    {
      label: 'Additional information about your marketing plan',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.additional_marketing_information,
    },
  ];

  const project_information_2_fields: IGrantFormField[] = [
    {
      label: 'Technical Capacity',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.technical_capability,
      shouldShow: isSkipped == true ? false : true,
    },

    {
      label:
        'Have you registered your business or are you in the process of registering your business with the Office of Registrar of Companies? ',
      type: 'default',
      value: getBusinessRegistrationStatus(
        response?.profile?.[0]?.grant_information?.applicant_information?.challenges_during_business_registration || '',
      ),
    },
  ];

  const project_information_3_fields: IGrantFormField[] = [
    {
      label: 'Has your business benefitted from any grant programme?',
      type: 'default',
      value:
        response?.profile?.[0]?.grant_information?.applicant_information?.has_business_benefited_from_any_grant == true
          ? 'Y'
          : 'N',
    },
    {
      label:
        'If ‘Yes’, state name of the grant awarding organization, amount received, purpose of grant and year of grant receipt',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.applicant_information?.grant_details,
    },
  ];

  const project_information_4_fields: IGrantFormField[] = [
    {
      label: 'Fiscal Projections',
      type: 'fiscal_projection',
      value: response?.profile?.[0]?.grant_information?.financial_projections,
    },
  ];

  const grant_request_fields: IGrantFormField[] = [
    {
      label: 'Expenditures',
      type: 'grants_expenditure',
      value: response?.profile?.[0]?.grant_information?.business_expenditures,
    },
    {
      label: 'Total expected expenditure',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.business_plan?.total_funding_requested,
    },
  ];

  const grant_request_fields_2: IGrantFormField[] = [
    {
      label: 'Bank Name',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.bank?.bank_name,
    },
    {
      label: 'Bank Branch',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.bank?.bank_branch,
    },
    {
      label: 'Account Number',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.bank?.bank_account_number,
    },
    {
      label: 'Account Name',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.bank?.bank_account_name,
    },
  ];

  const expected_results: IGrantFormField[] = [
    {
      label: 'Performance Indicators',
      type: 'performance_indicator',
      value: response?.profile?.[0]?.grant_information?.performance_indicators,
    },
  ];

  const sustainability_fields: IGrantFormField[] = [
    {
      label: 'What are your plans to ensure operations are sustained after grant disbursement from GEA?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.sustainability_plan,
      shouldShow: isSkipped == true ? false : true,
    },
    {
      label:
        'Are there any issues/risks that might affect the successful execution of your proposed project? E.g., an ongoing litigation that may affect the project, etc. Please detail if applicable and indicate how these would be mitigated.',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.expected_issues_that_may_affect_execution,
      shouldShow: isSkipped == true ? false : true,
    },
    /**userData?.data?.profile?.grant_information
                ?.received_support_from_bac == true */
    {
      label:
        'Did you receive support from a Business Advisory Centre (BAC)/ Business Resource Centre (BRC) or anyone else to complete this template?',
      type: 'default',
      value: response?.profile?.[0]?.grant_information?.received_support_from_bac == true ? 'Yes' : 'No',
    },
  ];

  return {
    application_summary_fields,
    applicant_information_fields,
    applicant_information_2_fields,
    applicant_information_3_fields,
    applicant_information_4_fields,
    project_information_fields,
    project_information_2_fields,
    project_information_3_fields,
    project_information_4_fields,
    grant_request_fields,
    grant_request_fields_2,
    expected_results,
    sustainability_fields,
  };
};
