/*eslint-disable*/
import React from 'react';
import { Box, Slider } from '@mui/material';
import { XAxis, YAxis, HorizontalGridLines, VerticalBarSeries, LabelSeries, FlexibleWidthXYPlot } from 'react-vis';
import { IChart, IChartDataObject } from './interfaces/chart.interfaces';
import {
  ChartWrapper,
  ChartTitle,
  HorizontalChartRow,
  HorizontalChartWrapper,
  HorizontalRowLabel,
  HorizontalSliderWrapper,
  HorizontalSliderValue,
  HorizontalSliderPercentageText,
  BarChartSlider,
} from './styled/chart.styledcomponents';
import NumberWrapper from '../NumberWrapper';

const HorizontalBarChart = ({ data, title }: IChart<IChartDataObject[]>) => {
  const [total, setTotal] = React.useState(0);
  React.useEffect(() => {
    const sum = data.reduce((accumulator, item) => {
      return (accumulator = accumulator + item.y);
    }, 0);
    setTotal(sum);
  }, [data]);

  return (
    <ChartWrapper style={{ height: '400px' }}>
      <ChartTitle>{title}</ChartTitle>
      <HorizontalChartWrapper>
        {data?.map((item, index) => {
          const CustomSlider = BarChartSlider({ color: item.color });
          return (
            <HorizontalChartRow key={`${item.x}-${item.y}`}>
              <HorizontalRowLabel>{item.x}</HorizontalRowLabel>
              <HorizontalSliderWrapper>
                <CustomSlider max={total} min={0} defaultValue={item?.y} disabled={true} />
              </HorizontalSliderWrapper>
              <HorizontalSliderValue>
                <NumberWrapper value={item?.y} size={'1rem'} />
                <HorizontalSliderPercentageText as={'span'}>
                  {` (%${((item.y / total) * 100).toFixed(2)})`}
                </HorizontalSliderPercentageText>
              </HorizontalSliderValue>
            </HorizontalChartRow>
          );
        })}
      </HorizontalChartWrapper>
    </ChartWrapper>
  );
};

export default HorizontalBarChart;
