/*eslint-disable*/

import React from 'react'
import { DisbursementContainer, DisbursementMainLayout, DisbursementMainRight, DisbursementNavigationWrapper, DisbursementRootComponent, DisbursementRootTop } from '../Disbursement/components/styledcomponents/DisbursementBaseLayout.components'
import ApplicantSidebar from '../ApplicantSidebar/ApplicantSidebar'
import { Box, Button, Tab, Tabs } from '@mui/material'
import SwitchComponent from '../Switch/SwitchComponent'
import ApplicantNavbar from '../ApplicantNavbar/ApplicantNavbar'
import { grantTabs, monitoringTabs } from '../GrantAssessmentBatch/constants'
import useGetMonitoringEvaluation from './hooks/useGetMonitoring'
import MonitoringBatchFileUpload from './components/MonitoringBatchFileUpload'
import ConfirmModalBase from '../Modal/ConfirmModalBase'
import MonitoringEvaluationTable from './components/MonitoringEvaluationTable'
import { grantBeneficiariesColumns, nonGrantBeneficiariesColumns } from './constants'

const MonitoringEvalutation = () => {
    const {
        tabValue,
        isLoading,
        previewList,
        batchGrantAssessmentColumns,
        batchGrantAssessmentPreviewColumns,
        tableData,
        batchGrantAssessmentTableOptions,
        batchGrantAssessmentTablePreviewOptions,
        downloadCSVTemplate,
        downloadCSV,
        templateFile,
        handleFileChange,
        uploadCSV,
        setTabValue
    } = useGetMonitoringEvaluation()
  return (
    <DisbursementMainLayout>
        <ApplicantSidebar selectedView={8}/>
        <DisbursementMainRight>
              <ApplicantNavbar type={''} navBack="/applicants" />
             <DisbursementContainer container as="section">
            <DisbursementRootComponent item xs={12} sm={12}>
              <DisbursementRootTop display={'flex'} justifyContent={'space-between'}>
                {/* <ExpandButton /> */}
                Monitoring/Evaluation Module
                
              </DisbursementRootTop>
              <DisbursementNavigationWrapper>
                  <Tabs
                    className="custom-tabs"
                    value={tabValue}
                    onChange={(e, value) => {
                      setTabValue(value);
                    }}
                  >
                    {monitoringTabs?.map((tabTitle, index) => (
                      <Tab label={tabTitle.label} value={index} key={`${tabTitle.label}-${index}`} />
                    ))}
                  </Tabs>
                </DisbursementNavigationWrapper>
                <MonitoringBatchFileUpload handleFileChange={handleFileChange} templateFile={templateFile}
                
                  prompt={'Upload a file of applicants for monitoring and evaluation purposes'}/>
                 <Box sx={{ width: '100%' }}>
                  <ConfirmModalBase onConfirm={uploadCSV} confirmText="Yes">
                    <Button
                      sx={{
                        '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
                        fontWeight: '400',
                        fontFamily: 'inherit !important',
                        fontSize: '0.85rem',
                        color: 'white',
                        backgroundColor: '#2a3b8f',
                        textTransform: 'initial !important',
                        padding: '0.7rem',
                        paddingLeft: '2rem',
                        paddingRight: '2rem',
                        ':disabled': {
                          backgroundColor: '#e0e0e0',
                        },
                        width: '100%',
                      }}
                      disabled={isLoading || previewList.length == 0}
                    >
                      Upload CSV
                    </Button>
                  </ConfirmModalBase>
                </Box>
                <MonitoringEvaluationTable  
                columns={tabValue == 0 ? grantBeneficiariesColumns : nonGrantBeneficiariesColumns}
                data={previewList}
                tableOptions={
                  batchGrantAssessmentTablePreviewOptions
                }
                isLoading={isLoading}
                showDownloadCSVButton={true}
                title={tabValue == 0 ? 'Grant Beneficiaries':'Non-Grant Beneficiaries'}
                onActionButtonPress={downloadCSV}
                downloadTemplate={() => {
                  downloadCSVTemplate(tabValue==0?true:false)
                }}/>
              </DisbursementRootComponent>
        </DisbursementContainer>
        </DisbursementMainRight>
        

    </DisbursementMainLayout>
  )
}

export default MonitoringEvalutation