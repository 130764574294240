import { defaultActiveColor } from '@/utils/constants';
import { styled, FormControlLabel, Radio, RadioGroup } from '@mui/material';

export const CustomFormControlLabel = styled(FormControlLabel)({
  fontFamily: 'inherit!important',
  fontSize: '14px',
});

export const CustomRadioComponent = styled(Radio)({
  fontFamily: 'inherit!important',
  '&.Mui-checked': {
    color: defaultActiveColor,
  },
  '&.MuiFormControlLabel-root': {
    fontFamily: 'inherit!important',
    fontWeight: '500!important',
    fontSize: '5px!important',
  },
});

export const ConfirmRadioGroup = styled(RadioGroup)({
  //@ts-ignore
  marginTop: '20px',
  marginBottom: '20px',
  flexDirection: 'row!important',
  fontFamily: 'inherit!important',
});
