/*eslint-disable*/
import { IGenerateCSV } from '@/components/Disbursement/interfaces/DisbursementTable.interface';
import { parseIdFromProjectId } from '@/utils/helpers/functions';
import { useGetGrantAssessmentTableData } from './useGetGrantAssessmentTableData';

export const useGenerateAssessmentCSV = ({ rows, columns, data }: Partial<IGenerateCSV>) => {
  try {
    const { tableData } = useGetGrantAssessmentTableData(data);
    console.log(rows, tableData, 99988);
    let header = columns;
    let csvArray = [];
    csvArray.push(header);
    rows.forEach((id) => {
      const selectedApplicant = tableData.filter((applicant) => {
        if (applicant[1]) {
          return parseIdFromProjectId(applicant[1]) == parseIdFromProjectId(id);
        }
      })[0];
      csvArray.push([
        selectedApplicant[1],
        ...Array.from({ length: 10 }, (_, i) => {
          return 0;
        }),
      ]);
    });
    console.log(csvArray);
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvArray.forEach((row: any[]) => {
      let rowString = row.join(',');
      csvContent += rowString + '\r\n';
    });
    const uri = encodeURI(csvContent);
    var element = document.createElement('a');
    element.href = uri;
    element.target = '_blank';
    element.download = 'export.csv';
    element.click();
    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};
