/*eslint-disable*/
import React from 'react';
import { Box } from '@mui/material';
import MultiSelectButton from '../Buttons/MultiSelectButton';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { basicColumns, intermediateColumns, advancedColumns } from '@/utils/tableColumns';
import { basic_score_keys, intermediate_score_keys, advanced_score_keys } from '@/utils/constants';

interface ITrainingScores {
  user: any;
}

const tableOptions: Partial<MUIDataTableOptions> = {
  print: true,
  search: false,
  viewColumns: false,
  download: true,
  filter: false,
  filterType: 'dropdown',
  rowsPerPage: 100,
  rowsPerPageOptions: [100],
  sortOrder: {
    name: 'Role',
    direction: 'asc',
  },
};

const TrainingScores = ({ user }: ITrainingScores) => {
  const [stage, setStage] = React.useState<'Basic' | 'Intermediate' | 'Advanced'>('Basic');
  const handleChange = (e: typeof stage) => {
    setStage(e);
  };
  const labels = [
    { label: 'Basic', value: 'Basic' },
    { label: 'Intermediate', value: 'Intermediate' },
    { label: 'Advanced', value: 'Advanced' },
  ];
  const returnDefaultValue = (value) => {
    if (value == undefined || value == null || value == '') {
      return `N/A`;
    }
    return value;
  };

  const returnTableColumns = (stage: string) => {
    switch (stage) {
      case 'Basic':
        return basicColumns;
      case 'Intermediate':
        return intermediateColumns;
      case 'Advanced':
        return advancedColumns;
      default:
        return basicColumns;
    }
    //6,7,3
  };

  const loadScores = (scoreObject: any, stage: 'basic' | 'intermediate' | 'advanced') => {
    let scores = [];
    switch (stage) {
      case 'basic':
        basic_score_keys.forEach((key) => {
          scores.push(scoreObject[key] !== null?scoreObject[key]: 'N/A');
        });
        break;
      case 'intermediate':
        intermediate_score_keys.forEach((key) => {
   scores.push(scoreObject[key] !== null?scoreObject[key]: 'N/A');        });
        break;
      case 'advanced':
        advanced_score_keys.forEach((key) => {
   scores.push(scoreObject[key] !== null?scoreObject[key]: 'N/A');        });
        break;
    }
    return scores;
  };

  const getData = (user: any, stage: string) => {
    const { client_details, overview } = user.data;
    console.log(
      [
        `${returnDefaultValue(client_details?.[0].project_application_code)}`,
        `${returnDefaultValue(overview?.first_name)} ${returnDefaultValue(overview?.last_name)}`,
        ...loadScores(
          client_details?.[0]?.training_score,
          stage.toLowerCase() as 'basic' | 'intermediate' | 'advanced',
        ),
        overview?.id,
      ],
      8000,
    );
    return [
      `${returnDefaultValue(client_details?.[0].project_application_code)}`,
      `${returnDefaultValue(overview?.first_name)} ${returnDefaultValue(overview?.last_name)}`,
      ...loadScores(client_details?.[0]?.training_score, stage.toLowerCase() as 'basic' | 'intermediate' | 'advanced'),
      overview?.id,
    ];
  };

  console.log(user, 4666);

  return (
    <Box flexDirection={'column'} py={2}>
      <MultiSelectButton selected={stage} labels={labels} onChange={handleChange} />
      <Box className="custom-data-table reports-table" mb={'6rem'}>
        <MUIDataTable
          title={''}
          options={tableOptions}
          columns={returnTableColumns(stage)}
          data={[getData(user, stage)]}
        />
      </Box>
    </Box>
  );
};

export default TrainingScores;
