import UserProfile from "@service/UserProfile";
import {
  storeLocalStorage,
  handleErrorMessage,
} from "@utils/helpers/functions";

export default async function useRefreshToken() {
  try {
    let response = await UserProfile.getToken();
    const asscessToken = response?.headers?.["set-auth-token"];
    if (asscessToken) {
      storeLocalStorage("accessToken", asscessToken);
    }
  } catch (error) {
    handleErrorMessage(error.response);
  }
}
