/*eslint-disable*/

import { Grid, Box, styled } from '@mui/material';

export const DisbursementMainLayout = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  overflowY: 'hidden',
  display: 'flex',
}));

export const DisbursementMainRight = styled(Grid)(({ theme }) => ({
  display: 'flex',
  width: '98%',
  height: 'calc(100% - 35px)',
  flexDirection: 'column',
  overflowX: 'auto',
}));

export const DisbursementContainer = styled(Grid)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

export const DisbursementRootComponent = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '2.5rem',
}));

export const DisbursementRootTop = styled(Box)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: '600',
  marginBottom: '0.1rem',
}));

export const DisbursementNavigationWrapper = styled(Box)(({ theme }) => ({
  borderTop: '1px solid #e4e4e4',
  marginTop: '30px',
  marginBottom: '10px',
}));
