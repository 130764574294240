import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserSignUp from '@service/UserSignUp';
import { useNavigate } from 'react-router-dom';
import Logo from '@utils/Logo';
import { handleErrorMessage, messageAlert, storeLocalStorage } from '@utils/helpers/functions';
import { useMutation } from 'react-query';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import backgroundImage from '../assets/hero.jpg';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{ fontFamily: 'inherit' }}>
      {'Copyright © '}
      <Link color="inherit" href="https://client-jas.web.app/">
        Job and Skills
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const mutation = useMutation(UserSignUp.adminLogin);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    mutation.mutate(
      {
        email: data?.email,
        password: data?.password,
      },
      {
        onSuccess: (response) => {
          const accessToken = response.headers?.['set-auth-token'];
          window.localStorage.setItem('user', JSON.stringify(response?.data));

          if (response?.data?.user?.must_reset_password == true) {
            window.localStorage.setItem('defaultPassword', data?.password);
          }
          axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          storeLocalStorage('accessToken', accessToken);
          navigate('/dashboard');
        },
        onError: (error) => {
          var errorMessage = handleErrorMessage(error);
          messageAlert('error', errorMessage);
        },
      },
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={{ height: '100vh' }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Grid p={3}>
            <Logo type="dark" size="medium" />
          </Grid>
          <Container component="main" maxWidth="s" style={{ paddingLeft: '24px', paddingRight: '24px' }}>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '1.5rem',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'inherit',
                  fontSize: '24px',
                  textAlign: 'left',
                  width: '100%',
                  fontWeight: '700',
                }}
              >
                Login to your account
              </Typography>
              <Typography
                my={'1rem'}
                sx={{
                  fontFamily: 'inherit',
                  fontSize: '15px',
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                Enter your email and phone number to access your account
              </Typography>
              <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, width: '100%' }}>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors?.email?.type ? true : false}
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      type={'email'}
                      autoComplete="email"
                      autoFocus
                      sx={{ fontFamily: 'inherit!important' }}
                      helperText={errors?.email?.type}
                    />
                  )}
                />

                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors?.password?.type ? true : false}
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      sx={{ fontFamily: 'inherit!important' }}
                      autoComplete="current-password"
                      helperText={errors?.password?.type}
                    />
                  )}
                />
                <FormControlLabel
                  sx={{ fontFamily: 'inherit!important' }}
                  control={<Checkbox style={{ fontFamily: 'inherit' }} value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  disabled={mutation.isLoading}
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    textTransform: 'inherit',
                    fontFamily: 'inherit',
                    fontSize: '1.2rem',
                    backgroundColor: '#2A3B8F',
                    '&:hover': {
                      backgroundColor: '#2A3B8F',
                    },
                  }}
                >
                  {mutation.isLoading ? 'Loading...' : 'Login'}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2" sx={{ textDecoration: 'none', fontFamily: 'inherit' }}>
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Container>
        </Grid>
        <Grid
          item
          md={6}
          // hidden={{ xs: true, sm: true }}
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </ThemeProvider>
  );
}
