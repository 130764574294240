import React from "react";
import { Grid, TextField, Box } from "@mui/material";
// import { DefaultButton } from '@components/Buttons/ButtonFullWidth';
// import { MdEditNote } from 'react-icons/md';
import propTypes from "prop-types";

const Remarks = ({ user }) => {
  const [remarks, setRemarks] = React.useState("");

  const getRemarks = (user) => {
    let approvalsArray = user?.data?.client_details?.[0]?.approvals;
    approvalsArray.forEach((approval) => {
      if (approval?.approval_type == "interview_approval") {
        let _remark = approval?.remarks;
        if (_remark !== null) {
          setRemarks(_remark);
        }
      }
    });
  };

  React.useEffect(() => {
    getRemarks(user);
  }, [user]);
  return (
    <div style={{ marginTop: "2rem" }}>
      <Grid container direction="row" mb={5}>
        <Grid item xs={6}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              className="card-title no-bottom text-default-color"
              sx={{ borderLeft: "0.2em solid #2A3B8F", paddingLeft: "0.5em" }}
            >
              Remarks
            </Box>
            {/* <Box sx={{order:'1',marginLeft:'auto'}}>
            <Typography><DefaultButton className="custom-default-btn" icon={<MdEditNote size={20}/>} text='Edit Remarks'/></Typography>
          </Box>  */}
          </Box>
        </Grid>

        <Grid container rowSpacing={1}>
          <Grid item xs={6} style={{ marginBottom: "1.5rem" }}>
            <div className="bottom" style={{ marginTop: "1rem" }}>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                placeholder="Message"
                sx={{ width: "100%" }}
                value={remarks}
                disabled={true}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Remarks.propTypes = {
  user: propTypes.object,
};

export default Remarks;
