/*eslint-disable */
import React, { useEffect, useState } from 'react';
import ApprovalsNav from './ApprovalsNav';
import { Box, Button, CircularProgress, Grid, Stack, Typography, FormLabel } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import SearchInput from '@components/SearchInput';
import { useNavigate } from 'react-router-dom';
import { AvatarInitial } from '@utils/Logo';
import { getInterviewApprovals, approveApplicant } from '@service/Applicants';
import AlertDialog from '@components/Modals';
import { messageAlert } from '@utils/helpers/functions';
import { useGetRegions, useGetDistricts } from '@utils/hooks/getRegionDistricts';
import { checkPermissions } from '@service/RequireAuth';
import useGetUserData from '@utils/hooks/useGetUserData';
import { DefaultButton } from '@components/Buttons/ButtonFullWidth';
import TableLoaders from '@components/Loaders/TableLoaders';
import { handleErrorMessage } from '@utils/helpers/functions';
import FilterComponent from './FilterComponent';
import ApprovalsBaseLayout from './ApprovalsBaseLayout';
import ExpandButton from '../Buttons/ExpandButton';

const Interview = () => {
  const type = 'interview';
  const userData = JSON.parse(window.localStorage.getItem('user'));
  const [passMark, setPassMark] = useState('above_pass_mark');
  const [searchQuery, setSearchQuery] = useState('');
  let [applicantData, setApplicantData] = useState({
    page: 0,
    count: 1,
    data: [],
  });
  const [params, setParams] = React.useState({
    region: '',
    district: '',
    gender: '',
    disability: '',
    score_upper_limit: '',
    score_lower_limit: '',
    educational_level: '',
    vocational_skill: '',
    age_upper_limit: '',
    age_lower_limit: '',
    q: '',
  });
  const [_isLoading, setIsLoading] = React.useState(false);
  const [selectedRegion, setSelectedregion] = React.useState('');
  const { regions } = useGetRegions();
  const { districts } = useGetDistricts(regions, selectedRegion);
  const navigate = useNavigate();
  const [openDisq, setOpenDisq] = React.useState(false);

  const handleChange = (event) => {

  };

  React.useEffect(() => {
    setParams({ ...params, q: searchQuery });
  }, [searchQuery]);

  function setRegion(e) {
    if (e.target.value === '') {
      e.target.value = '';

      setParams({ ...params, region: '' });
      setSelectedregion('');
      return;
    }

    setParams({ ...params, region: parseInt(e.target.value) });
    setSelectedregion(parseInt(e.target.value));
  }

  const fetchApplicants = async () => {
    setIsLoading(true);
    try {
      const response = await getInterviewApprovals(
        applicantData?.page + 1,
        10,
        params?.region == '' ? userData?.profile?.region?.id : params?.region,
        params?.district == '' ? userData?.profile?.district?.id : params?.district,
        params.gender,
        params.disability,
        params.score_upper_limit,
        params.score_lower_limit,
        params?.educational_level,
        params?.vocational_skill,
        params?.age_lower_limit,
        params?.age_upper_limit,
        params.q,
      );
      if (response?.data) {
        getTableData(response?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      let message = handleErrorMessage(error);
      messageAlert('error', message);
    }
  };

  useEffect(() => {
    if (!_isLoading) {
      fetchApplicants();
    }
  }, [applicantData?.page]);

  useEffect(() => {
    let status = checkPermissions('interview approvals', userData);


    if (status == false) {
      navigate('/dashboard');
    }
    try {

      let region = userData?.profile?.region?.id;
      let district = userData?.profile?.district?.id;


      setSelectedregion(region);
      setParams({ ...params, region: region, district: district });
      // fetchApplicants();
    } catch (e) {
      handleErrorMessage(e);

    }
  }, []);

  const getTableData = (data) => {
    let tableData = [];
    data?.data?.forEach((applicant) => {

      let { applicant_score, user } = applicant;
      let tableRow = [`${user?.first_name} ${user?.last_name}`, user?.phone_number, applicant_score, user?.id];
      tableData.push(tableRow);
    });
    setApplicantData({ ...applicantData, count: data?.count, data: tableData });
  };

  const getDefaultValue = (value) => {
    if (value) {
      return value;
    }
    return 'N/A';
  };

  const [tableData, setTableData] = React.useState([]);
  const responsive = 'vertical';
  const tableBodyHeight = `${window.innerHeight * 0.5}px`;
  const tableBodyMaxHeight = '';

  const tableOptions = {
    print: false,
    search: false,
    viewColumns: false,
    download: false,
    filter: false,
    filterType: 'checkbox',
    selectableRows: 'multiple',
    page: applicantData?.page,
    responsive,
    serverSide: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    count: applicantData?.count,
    tableBodyHeight,
    tableBodyMaxHeight,
    searchText: searchQuery,
    sortOrder: {
      name: 'Role',
      direction: 'asc',
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setApplicantData({ ...applicantData, page: tableState?.page });
      }
    },
    customSearchRender: () => null,
    customToolbarSelect: () => null,

    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const dataToState = rowsSelected.map((item) => {
        return item;
      });
      setTableData(getSelectedRows(dataToState));
    },
  };

  const getSelectedRows = (rowArray) => {
    try {
      let rowDataArray = [];
      rowArray.forEach((rowIndex) => {
        let _applicantData = applicantData?.data[rowIndex];
        rowDataArray.push(_applicantData[3]); //this index is where the id is stored
      });
      return rowDataArray;
    } catch (e) {

      return [];
    }
  };

  const columns = [
    {
      name: 'Name',
      options: {
        filter: true,
        customBodyRender: (value) => <AvatarInitial text={value || ''} />,
      },
    },
    'Phone Number',
    {
      name: 'Applicant Score (%)',
      options: {
        filter: true,
        customBodyRender: (value) => (
          <div style={{ color: '#1D9649', fontWeight: 'bold' }}>{getDefaultValue(value)}%</div>
        ),
      },
    },
    {
      name: 'Action',
      options: {
        filter: true,
        customBodyRender: (value) => (
          <Button
            sx={{ textTransform: 'initial' }}
            onClick={() => {
              navigate(`/applicants/${value}/${type}`);
            }}
          >
            View
          </Button>
        ),
      },
    },
  ];

  const changePassMark = (e) => {
    setPassMark(e.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenDisqModal = () => {
    setOpenDisq(true);
  };

  const handleCloseDisqModal = () => {
    setOpenDisq(false);
  };

  const submitApproval = async (status, type, success) => {
    let approvalData = {
      id_list: tableData,
      approval_status: status,
      approval_type: type,
    };
    setApplicantData({ ...applicantData, isLoading: true });

    try {
      await approveApplicant(approvalData);
      messageAlert(status == 'approved' ? 'success' : 'error', success);
      await fetchApplicants();
    } catch (e) {
      //
      handleErrorMessage(e);
      messageAlert('error', 'Error fulfilling request');
      setApplicantData({ ...applicantData, isLoading: false });
    }
  };

  const checkSelected = (id) => {
    if (params.district == '') {
      return id == userData?.profile?.district?.id;
    } else {
      return id == params?.district;
    }
  };

  //   useEffect(()=>{
  //     try
  //     {
  //         if (_isLoading == false )
  //         {
  //             //
  //             fetchApplicants();
  //             //
  //         }
  //     }
  //     catch(e)
  //     {
  //
  //     }
  // },[]);

  const remarksComponent = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box>Are you sure you want to approve Applicant(s) for interview ?</Box>
        {/* <Box mt={'1rem'}>
            <textarea placeholder={'Remarks'} style={{width:'100%'}} onChange={(e)=>{setRemarks(e.target.value)}}>
            </textarea>
        </Box>   */}
      </Box>
    );
  };

  const ApproveButton = () => {
    return (
      <Button
        sx={{
          '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
          color: 'white',
          backgroundColor: '#2a3b8f',
          textTransform: 'initial !important',
          border: '1px solid #2a3b8f',
        }}
        onClick={() => {
          setOpen(false);
          submitApproval('approved', 'interview_approval', 'Interview Approved');
        }}
      >
        Approve
      </Button>
    );
  };

  const DisqButton = () => {
    return (
      <Button
        sx={{
          '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
          color: 'white',
          backgroundColor: '#2a3b8f',
          textTransform: 'initial !important',
          border: '1px solid #2a3b8f',
          fontFamily: 'inherit',
        }}
        onClick={() => {
          submitApproval('disqualified', 'interview_approval', 'Applicant Disqualified');
          setOpenDisq(false);
        }}
      >
        Reject
      </Button>
    );
  };

  document.body.style.cssText = 'overflow-y:hidden!important';

  return (
    <ApprovalsBaseLayout selectedView={1}>
      <Box
        sx={{
          fontSize: '1.2rem',
          fontWeight: '600',
          marginBottom: '2rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ExpandButton />
        <Box sx={{ fontSize: 'inherit' }}>Interview/Screening Approvals</Box>
        <Box mx={'2rem'}>
          {/* {_isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )} */}
        </Box>
        {/* <Box sx={{order:'2',marginLeft:'auto'}}>
                <Box sx={{order:'1',marginLeft:'auto',display:'flex'}}>
                            <Box sx={{ fontSize:'1.2rem',fontWeight:'600', marginBottom:"2rem",marginRight:'1rem'}}>
                                <DefaultButton color="#FF4B55"  text='Reject' onClick={()=>{handleOpenDisqModal()}} disabled={tableData?.length < 1}/>
                            </Box>
                            <Box sx={{ fontSize:'1.2rem',fontWeight:'600', marginBottom:"2rem"}}>
                                <DefaultButton text='Approve For Training' onClick={()=>{handleOpenModal()}} disabled={tableData?.length < 1}/>
                            </Box>
                    </Box> 
                </Box> */}
      </Box>

      <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
        <Grid item xs={4}>
          <SearchInput placeholder={'Enter Name or Phone Number'} setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item xs={8} sx={{ marginBottom: '2rem' }}>
          <Box>
            <Stack direction="row" justifyContent="end">
              <Box mt={1} fontSize={15} mx={2}>
                <Typography sx={{ fontFamily: 'inherit' }}>Score Range:</Typography>
              </Box>

              <input
                placeholder={'Lower Mark'}
                min={0}
                type="number"
                className="range-input"
                style={{
                  width: '20%',
                  padding: '0.8rem',
                  border: '1px solid #EEF1F3',
                }}
                onChange={(e) => {
                  setParams({ ...params, score_lower_limit: e.target.value });
                }}
              />

              <Box mt={2}>
                {' '}
                <hr
                  style={{
                    width: '1rem',
                    marginLeft: '0.5rem',
                    marginRight: '0.5rem',
                  }}
                />
              </Box>
              <input
                placeholder={'Upper Mark'}
                min={0}
                type="number"
                className="range-input"
                style={{
                  width: '20%',
                  padding: '0.8rem',
                  border: '1px solid #EEF1F3',
                }}
                onChange={(e) => {
                  setParams({ ...params, score_upper_limit: e.target.value });
                }}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>

      {/* <Box mt={4} mb={4}><Divider  variant="middle" /></Box> */}

      <FilterComponent
        checkSelected={checkSelected}
        setParams={setParams}
        userData={userData}
        regions={regions}
        districts={districts}
        fetchApplicants={fetchApplicants}
        params={params}
        setRegion={setRegion}
        selectedRegion={selectedRegion}
      />

      <Box className="custom-data-table settings-table" my={'2rem'} sx={{ width: '100%' }}>
        {_isLoading == true && <TableLoaders />}
        {_isLoading == false && (
          <>
            <MUIDataTable options={tableOptions} columns={columns} data={applicantData?.data} />
            {open && (
              <AlertDialog
                open={open}
                handleCloseModal={() => handleCloseModal(false)}
                title="Confirm Approval"
                body={remarksComponent()}
                actionButton={<ApproveButton />}
              />
            )}

            {openDisq && (
              <AlertDialog
                open={openDisq}
                handleCloseModal={() => handleCloseDisqModal(false)}
                title="Confirm Disqualification"
                body={`Are you sure you want to reject Applicant(s) for interview ?`}
                actionButton={<DisqButton />}
              />
            )}
          </>
        )}
      </Box>
    </ApprovalsBaseLayout>
  );
};

export default Interview;
