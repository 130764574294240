/*eslint-disable*/
import React from "react";
import { Box, Grid } from "@mui/material";

const Documents = ({ userData }) => {
  return (
    <Box sx={{ padding: "1rem" }}>
      {userData?.data?.documents.map((document, index) => {
        return <Box>{`Document ${index}`}</Box>;
      })}
    </Box>
  );
};

export default Documents;
