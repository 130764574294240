/*eslint-disable*/
import React, { ReactNode } from 'react'
import NumberWrapper from '../NumberWrapper';

interface ITotalCardFinal{
    value?:number;
    title?:string;
    iconComponent?:ReactNode;
    iconWrapperBackgroundColor?:string;
}

const TotalCardFinal = ({ title, value, iconComponent, iconWrapperBackgroundColor}:ITotalCardFinal) => {
  const style = {
    display: "flex",
    borderRadius: "1rem",
    backgroundColor: "white",
    padding: "1.5rem",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    width:'100%',
    marginRight:'0px!important'
  };

  const icon_wrapper_style = {
    borderRadius: "0.5rem",
    padding: "0.3rem",
    backgroundColor: `${iconWrapperBackgroundColor}20`,
  };

  return (
    <div style={style} className='total-card-final' >
      <div className="left">
        <div className="icon-wrapper" style={icon_wrapper_style}>
          {iconComponent}
        </div>
      </div>
      <div className="right flex flex-c">
        <div className="top" style={{ color: "black", fontWeight: "600" }}>
          {title}
        </div>
        <div className="bottom">
          <NumberWrapper size={"1.5rem"} value={value} />
        </div>
      </div>
    </div>
  );
};


export default TotalCardFinal