import React from "react";
import { Box, Slider } from "@mui/material";
import propTypes from "prop-types";
import NumberWrapper from "../NumberWrapper";
import { DashboardContext } from "../DashboardContent";

const returnDefaultValue = (value) => {
  if (value == undefined || value == null) {
    return 0;
  }
  return value;
};

const ApplicationAgeDistribution = () => {
  const { userData } = React.useContext(DashboardContext);
  const [dataArray, setDataArray] = React.useState([]);

  React.useEffect(() => {
    if (userData != null) {
      let keyList = Object.keys(userData?.age_groups);
      let dataList = [];
      keyList.forEach((key) => {
        let object = userData?.age_groups[key];
        dataList.push({
          label: key,
          total_male: object?.total_male_applicants,
          total_female: object?.total_female_applicants,
          total_value_male: object?.total_amount_requested,
        });
      });
      setDataArray(dataList);
    }
  }, [userData]);

  const style = {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "1rem",
    padding: "1.2rem",
    marginTop: "1.5rem",
    // marginLeft:'1rem',
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  };

  const keyText = {
    fontSize: "15px",
    fontWeight: "600",
  };
  return (
    <Box style={style}>
      <Box
        className="title"
        style={{ fontSize: "1rem", marginBottom: "0.5rem", fontWeight: "600" }}
      >
        Applications By Age Distribution
      </Box>

      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="distribution-key flex"
          style={{
            display: "flex",
            position: "relative",
            marginBottom: "1.5rem",
          }}
        >
          <div style={keyText}>
            <span
              className="key-color"
              style={{ backgroundColor: "rgb(255 50 21)" }}
            ></span>
            Female Applicants (
            {returnDefaultValue(userData?.genders?.F?.total_applicants)})
          </div>
          <div style={keyText}>
            <span
              className="key-color"
              style={{ backgroundColor: "#407BFF" }}
            ></span>
            Male Applicants (
            {returnDefaultValue(userData?.genders?.M?.total_applicants)})
          </div>
        </div>
        {dataArray.map((item, index) => {
          return (
            <Box
              my={"0.8rem"}
              style={{ display: "flex", alignItems: "center" }}
              key={`${index}-${item.label}`}
            >
              <Box sx={{ width: "10%", fontWeight: "600", textAlign: "left" }}>
                <NumberWrapper value={item?.total_female} size={"0.9rem"} />
                <span style={{ width: "100px", display: "inline" }}>
                  {" "}
                  (%
                  {(
                    (item?.total_female /
                      userData?.total_completed_applications) *
                    100
                  ).toFixed(1)}
                  )
                </span>
              </Box>
              <Box className="slider-reverse-wrapper" style={{ width: "30%" }}>
                <Slider
                  className=" slider-reverse sector-slider"
                  max={returnDefaultValue(
                    userData?.total_completed_applications
                  )}
                  min={0}
                  defaultValue={item?.total_female}
                  disabled={true}
                />
              </Box>

              <Box
                style={{
                  width: "20%",
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "600",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
              >
                {item.label}
              </Box>
              <Box className="custom-slider-wrapper" style={{ width: "30%" }}>
                <Slider
                  className="custom-slider sector-slider"
                  max={returnDefaultValue(
                    userData?.total_completed_applications
                  )}
                  min={0}
                  defaultValue={item.total_male}
                  disabled={true}
                />
              </Box>
              <Box sx={{ width: "10%", fontWeight: "600", textAlign: "right" }}>
                <NumberWrapper value={item?.total_male} size={"0.9rem"} />
                <span style={{ width: "100px", display: "inline" }}>
                  {" "}
                  (%
                  {(
                    (item?.total_male /
                      userData?.total_completed_applications) *
                    100
                  ).toFixed(1)}
                  )
                </span>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

ApplicationAgeDistribution.propTypes = {
  userData: propTypes.object,
};

export default ApplicationAgeDistribution;
