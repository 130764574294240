/*eslint-disable*/
import React, { ReactNode } from 'react'

export interface IShowPermissions {
    shouldShow:boolean;
    children:ReactNode;
}

const ShowPermissions = ({children,shouldShow}:IShowPermissions) => {
  return (
    <>
    {shouldShow && <>{children}</>}</>
  )
}

export default ShowPermissions