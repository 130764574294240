/*eslint-disable*/
import React, { SetStateAction } from 'react';

export type AppContextType = {
  currentViewState?: 'expanded' | 'closed';
  setCurrentViewState?: React.Dispatch<SetStateAction<'expanded' | 'closed'>>;
};

export const AppContext = React.createContext<AppContextType>({
  currentViewState: 'expanded',
  setCurrentViewState: () => {},
});
