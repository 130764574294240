import React from "react";
import propTypes from "prop-types";
// import '../style/DashboardStyles.css';

const TitleArea = ({ username }) => {
  const style = {
    paddingBottom: "1.5rem",
    borderBottom: "1px solid #E8EEF8",
    fontSize: "2rem",
  };
  return (
    <div className="title-area" style={style}>
      <span>Welcome, </span>
      <span>{username}</span>
    </div>
  );
};

TitleArea.propTypes = {
  username: propTypes.string,
};

export default TitleArea;
