/*eslint-disable*/
import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { getApplicant } from "@service/Applicants";
import { messageAlert } from "@utils/helpers/functions";
import AvatarIcon from "./AvatarIcon";
import MenuComponent from "./MenuComponent";
import ApplicationOverview from "@components/ApplicantMain/ApplicationOverview";
import ReportsMain from "../BaseLayout/ReportsMain";
import { handleErrorMessage } from "@utils/helpers/functions";

const UserDetails = () => {
  const testId = 1;
  const [isLoading, setIsLoading] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [currentView, setCurrentView] = React.useState("details");
  const [userData, setUserData] = React.useState(null);
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    // height:'100vh',
    overflowY: "auto",
    padding: "2rem",
  };

  const topAreaStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      let response = await getApplicant(testId);
      setUserData(response.data);
    } catch (e) {
      let message = handleErrorMessage(e);
      messageAlert("error", "Error occured while fetching data.");
    }
    setIsLoading(false);
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  React.useState(() => {
    getData();
  }, []);

  return (
    <ReportsMain selectedView={1}>
      <Box sx={containerStyle}>
        <Box sx={topAreaStyle}>
          <AvatarIcon
            firstName={userData?.data?.overview?.first_name}
            lastName={userData?.data?.overview?.last_name}
            rating={userData?.data?.application_score?.[0]?.score_rating}
            score={userData?.data?.application_score?.[0]?.percentage_score}
          />
          <Box>
            <MenuComponent />
          </Box>
        </Box>
        <Box>
          <Tabs
            className="custom-tabs"
            value={tabValue}
            onChange={handleTabChange}
            style={{ borderBottom: "0.5px solid #EBEFF2" }}
            aria-label="basic tabs example"
          >
            <Tab label="Application Details" value={0} />
          </Tabs>
        </Box>
        {currentView == "details" && (
          <ApplicationOverview userData={userData} />
        )}
      </Box>
    </ReportsMain>
  );
};

export default UserDetails;
