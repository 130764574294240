/*eslint-disable*/
import React from 'react';
import { IGrantFormField } from '../../interfaces/GrantOverview.interface';
import {
  ActivityTableField,
  DefaultGrantFormField,
  ExpectedResultsTableField,
  FiscalProjectionTableField,
  GrantExpenditureTableField,
} from '../../components/GrantDetails/GrantOverviewComponents';
import { defaultActiveColor } from '@/utils/constants';
import { disabled_gray, light_gray } from '@/utils/colors';
import { useQuery } from 'react-query';
import { getGrantBusinessExpenditures } from '@/service/Applicants';
import { GrantAssessmentContext } from '@/utils/context/GrantAssementContext';

export const useGetGrantOverview = () => {
  const { selectedApplicantResponseObject, isPrinting, expenditureList } = React.useContext(GrantAssessmentContext);
  const [currentFormStage, setCurrentFormStage] = React.useState<'grant_form' | 'business_plan_form'>(
    'business_plan_form',
  );
  // const {
  //   data: expenditureList,
  //   isLoading: expenditureLoading,
  //   isRefetching: expenditureRefetching,
  // } = useQuery([], () => {
  //   return getGrantBusinessExpenditures(selectedApplicantResponseObject?.user?.id);
  // });

  const returnDefaultValue = (value: any) => {
    if (value == null || value == undefined) {
      return 'N/A';
    }
    return value;
  };

  const returnFieldComponent = (props: IGrantFormField[]) => {
    return props?.map((field, index) => {
      if (field.shouldShow !== false) {
        if (field.type == 'default' || field.type == 'boolean') {
          return DefaultGrantFormField(isPrinting, returnDefaultValue(field.value), field?.label);
        }
        if (field.type == 'activity') {
          return ActivityTableField(field.value, field.label);
        }
        if (field.type == 'fiscal_projection') {
          return FiscalProjectionTableField(field.label, field.value);
        }
        if (field.type == 'performance_indicator') {
          return ExpectedResultsTableField(field.label, field.value);
        }
        if (field.type == 'grants_expenditure') {
          return GrantExpenditureTableField(field.label, expenditureList);
        }
      }
    });
  };

  const onStageChange = () => {
    setCurrentFormStage(currentFormStage == 'grant_form' ? 'business_plan_form' : 'grant_form');
  };

  const getSelectedStyles = (isSelected: boolean) => {
    if (isSelected) {
      return {
        backgroundColor: defaultActiveColor,
        color: 'white',
        '&:hover': {
          color: 'white',
          backgroundColor: defaultActiveColor,
        },
      };
    }

    return {
      backgroundColor: disabled_gray,
      color: light_gray,
    };
  };

  return {
    currentFormStage,
    // expenditureList,
    // expenditureLoading,
    // expenditureRefetching,
    getSelectedStyles,
    setCurrentFormStage,
    returnDefaultValue,
    returnFieldComponent,
    onStageChange,
  };
};
