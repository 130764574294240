/*eslint-disable*/
import React from 'react'
import backgroundImage from '../assets/hero.jpg'
import ApplicantSidebar from '@/components/ApplicantSidebar/ApplicantSidebar'
import { logout } from '@/service/RequireAuth';
import UserSignUp from '@/service/UserSignUp';
import { messageAlert, handleErrorMessage } from '@/utils/helpers/functions';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ResetPasswordModal from '@/components/Users/ResetPasswordModal';

const NotFoundDashboard = () => {
    const userData = JSON.parse(window.localStorage.getItem('user'));
  const [resetModal, setResetModal] = React.useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const onSubmit = (data:any) => {
   
    if (data.new_password == data.retyped_password){
       //@ts-ignore
      mutation.mutate({
        old_password: window.localStorage.getItem('defaultPassword'),
        new_password:data.new_password
      },{
        onSuccess:(data)=>{
          messageAlert('success','Password Successfully Reset');
          logout()
          navigate('/')
          // setResetModal(false)
        },
        onError:(e)=>{
          let message = handleErrorMessage(e)
          messageAlert('error',message)
        }
        
      })
      return
    }
    messageAlert('error','Both Passwords should match.')

  };
  const mutation = useMutation({
    mutationFn: (data) => {
      return UserSignUp.changePassword(data);
    },
  });
  const navigate = useNavigate();
  const style = {
    height: '100vh',
    display: 'flex',
  };

  React.useEffect(() => {
    // document.body.style.cssText = "overflow-y:auto!important;";

    setResetModal(userData?.user?.must_reset_password == true)
  }, [userData]);
  return (
    <div style={{width:'100%',height:'100vh',display:'flex'}}>
         <ResetPasswordModal
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
        isLoading={mutation.isLoading}
        modalShowing={resetModal}
        setModalState={setResetModal}
      />
           <ApplicantSidebar selectedView={0} />
            <div style={{backgroundImage:`url(${backgroundImage})`,backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat',height:'100%',width:'98%',position:'relative'}}>
                <div style={{position:'absolute',bottom:'100px',left:'10px',fontSize:'3rem',fontWeight:'700',color:'white',lineHeight:'3.5rem',maxWidth:'500px'}}>
                    Youstart Ghana Jobs and Skills Project
                </div>
            </div>
    </div>
  )
}

export default NotFoundDashboard