/*eslint-disable*/
import Table from '@/components/Table/Table';
import { OverviewDefaultField, OverviewDefaultLabel, OverviewDefaultValue } from './GrantDetails.components';
import React from 'react';
import { FormTableInputOptions } from '../../utils/constants';
import { useLoadDisplayTableRows } from '../../hooks/useLoadBusinessActivities';
import {
  returnActivityDataTableComponent,
  returnExpectedResultTableComponent,
  returnFiscalProjectionTableComponent,
  returnGrantExpenditureTableComponent,
} from '../../services/GrantDetails/ViewUserSubmission.service';

export const DefaultGrantFormField = (isPrinting: boolean, value?: string, label?: string) => {
  return (
    <OverviewDefaultField item md={isPrinting ? 12 : 6} xs={12}>
      <OverviewDefaultLabel>{label}</OverviewDefaultLabel>
      <OverviewDefaultValue mx={isPrinting ? 'unset' : 'initial'} style={{ maxHeight: isPrinting ? 'unset' : '150px' }}>
        {value}
      </OverviewDefaultValue>
    </OverviewDefaultField>
  );
};

export const ActivityTableField = (value?: any, label?: string) => {
  return (
    <OverviewDefaultField item md={12} xs={12}>
      <OverviewDefaultLabel>{label}</OverviewDefaultLabel>
      <OverviewDefaultValue style={{ maxHeight: 'unset' }}>
        {returnActivityDataTableComponent(value)}
      </OverviewDefaultValue>
    </OverviewDefaultField>
  );
};

export const ExpectedResultsTableField = (label?: string, value?: any) => {
  return (
    <OverviewDefaultField item md={12} xs={12}>
      <OverviewDefaultLabel>{label}</OverviewDefaultLabel>
      <OverviewDefaultValue style={{ maxHeight: 'unset' }}>
        {' '}
        {returnExpectedResultTableComponent(value)}
      </OverviewDefaultValue>
    </OverviewDefaultField>
  );
};

export const FiscalProjectionTableField = (label?: string, value?: any) => {
  return (
    <OverviewDefaultField item md={12} xs={12}>
      <OverviewDefaultLabel>{label}</OverviewDefaultLabel>
      <OverviewDefaultValue style={{ maxHeight: 'unset' }}>
        {returnFiscalProjectionTableComponent(value)}
      </OverviewDefaultValue>
    </OverviewDefaultField>
  );
};

export const GrantExpenditureTableField = (label?: string, value?: any) => {
  return (
    <OverviewDefaultField item md={12} xs={12}>
      <OverviewDefaultLabel>{label}</OverviewDefaultLabel>
      <OverviewDefaultValue style={{ maxHeight: 'unset' }}>
        {returnGrantExpenditureTableComponent(value)}
      </OverviewDefaultValue>
    </OverviewDefaultField>
  );
};
