/*eslint-disable*/
import React from 'react';
import MUIDataTable, { MUIDataTableProps, MUIDataTableColumnDef } from 'mui-datatables';

interface ITableProps {
  columns: MUIDataTableColumnDef[];
  title: string;
  options: MUIDataTableProps['options'];
  data: any;
}

const Table = ({ columns, options, title, data }: ITableProps) => {
  return <MUIDataTable options={options} columns={columns} data={data} title={title} />;
};

export default Table;
