import apiConfig from './apiConfig';
import axios from 'axios';

export function getApplicants() {
  return apiConfig.getRequest('staff/dashboard/applicants');
}

export function getApplicant(id) {
  return apiConfig.getRequest(`staff/dashboard/applicants/${id}`);
}

export function getPaginatedApplicants(page, per_page = 10, type = '', query = '', region, district) {
  region = region ? region : '';
  district = district ? district : '';
  switch (type) {
    case 'applicants':
      return apiConfig.getRequest(
        `staff/approvals/applicant_approvals?page=${page}&per_page=10&q=${query}&region=${region}&district=${district}`,
      );

    case 'interview':
      return apiConfig.getRequest(
        `staff/approvals/interview_approvals?page=${page}&per_page=10&q=${query}&region=${region}&district=${district}`,
      );

    default:
      return apiConfig.getRequest(`staff/dashboard/applicants_search?query=${query}&per_page=${per_page}&page=${page}`);
  }
}

export function setAccessToken() {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }
  return;
}

export function listDataWrapper(type = '', page = 1, q = '') {
  if (type == '') {
    return apiConfig.getRequest(`staff/dashboard/applicants?page=${page}&per_page=${5}`);
  } else if (type == 'applicant_approvals') {
    return apiConfig.getRequest(`staff/approvals/applicant_approvals?page=${page}&per_page=${5}&q=${q}`);
  } else if (type == 'interview_approvals') {
    return apiConfig.getRequest(`staff/approvals/interview_approvals?page=${page}&per_page=${5}&q=${q}`);
  } else if (type == 'grants') {
    return apiConfig.getRequest(`staff/approvals/grant_approvals?page=${page}&per_page=${5}&q=${q}`);
  }
}

export function getInterviewApprovals(
  page,
  per_page = 5,
  region = '',
  district = '',
  gender = '',
  disability = '',
  score_upper_limit = '',
  score_lower_limit = '',
  educational_level = '',
  vocational_skill = '',
  age_lower_limit = '',
  age_upper_limit = '',
  q = '',
) {
  let queryString = '';
  let keyArray = Object.keys({
    region: region,
    district: district,
    gender: gender,
    disability: disability,
    score_upper_limit: score_upper_limit,
    score_lower_limit: score_lower_limit,
    educational_level: educational_level,
    vocational_skill: vocational_skill,
    age_lower_limit: age_lower_limit,
    age_upper_limit: age_upper_limit,
    q: q,
  });

  [
    region,
    district,
    gender,
    disability,
    score_upper_limit,
    score_lower_limit,
    educational_level,
    vocational_skill,
    age_lower_limit,
    age_upper_limit,
    q,
  ].forEach((item, index) => {
    if (item !== '' && item !== undefined && item !== null) {
      let keyName = keyArray[index];
      queryString = queryString + `&${keyName}=${item}`;
    }
  });
  return apiConfig.getRequest(`staff/approvals/interview_approvals?page=${page}&per_page=${per_page}${queryString}`);
}

export function approveApplicant(data) {
  return apiConfig.postRequest(`staff/approvals/`, data);
}

export function getGrantApprovals(
  page,
  per_page = 5,
  region = '',
  district = '',
  gender = '',
  disability = '',
  score_upper_limit = '',
  score_lower_limit = '',
  educational_level = '',
  vocational_skill = '',
  age_lower_limit = '',
  age_upper_limit = '',
  q = '',
  skipped_advanced_training = false,
) {
  let queryString = '';
  let keyArray = Object.keys({
    region: region,
    district: district,
    gender: gender,
    disability: disability,
    score_upper_limit: score_upper_limit,
    score_lower_limit: score_lower_limit,
    educational_level: educational_level,
    vocational_skill: vocational_skill,
    age_lower_limit: age_lower_limit,
    age_upper_limit: age_upper_limit,
    q: q,
    skipped_advanced_training: skipped_advanced_training,
  });

  [
    region,
    district,
    gender,
    disability,
    score_upper_limit,
    score_lower_limit,
    educational_level,
    vocational_skill,
    age_lower_limit,
    age_upper_limit,
    q,
    skipped_advanced_training,
  ].forEach((item, index) => {
    if (item !== '' && item !== undefined && item !== null) {
      let keyName = keyArray[index];
      queryString = queryString + `&${keyName}=${item}`;
    }
  });
  return apiConfig.getRequest(`staff/approvals/grant_approvals?page=${page}&per_page=${per_page}${queryString}`);
}

export function getTrainingApprovals(
  page,
  per_page = 5,
  region = '',
  district = '',
  gender = '',
  disability = '',
  score_upper_limit = '',
  score_lower_limit = '',
  educational_level = '',
  vocational_skill = '',
  q = '',
  stage = '',
) {
  let queryString = '';
  let keyArray = Object.keys({
    region: region,
    district: district,
    gender: gender,
    disability: disability,
    score_upper_limit: score_upper_limit,
    score_lower_limit: score_lower_limit,
    educational_level: educational_level,
    vocational_skill: vocational_skill,
    q: q,
  });

  [
    region,
    district,
    gender,
    disability,
    score_upper_limit,
    score_lower_limit,
    educational_level,
    vocational_skill,
    q,
  ].forEach((item, index) => {
    if (item !== '' && item !== undefined && item !== null) {
      let keyName = keyArray[index];
      queryString = queryString + `&${keyName}=${item}`;
    }
  });

  const getRouteName = (stage) => {
    switch (stage) {
      case 'basic':
        return 'basic_training_approvals';
      case 'intermediate':
        return 'intermediate_training_approvals';
      case 'advanced':
        return 'advanced_training_approvals';
      default:
        return 'basic_training_approvals';
    }
  };

  return apiConfig.getRequest(`staff/approvals/${getRouteName(stage)}?page=${page}&per_page=${per_page}${queryString}`);
}

export function getApplicantApprovals(
  page,
  per_page = 5,
  region = '',
  district = '',
  gender = '',
  disability = '',
  score_upper_limit = '',
  score_lower_limit = '',
  educational_level = '',
  vocational_skill = '',
  age_lower_limit = '',
  age_upper_limit = '',
  q = '',
) {
  let queryString = '';
  let keyArray = Object.keys({
    region: region,
    district: district,
    gender: gender,
    disability: disability,
    score_upper_limit: score_upper_limit,
    score_lower_limit: score_lower_limit,
    educational_level: educational_level,
    vocational_skill: vocational_skill,
    age_lower_limit: age_lower_limit,
    age_upper_limit: age_upper_limit,
    q: q,
  });

  [
    region,
    district,
    gender,
    disability,
    score_upper_limit,
    score_lower_limit,
    educational_level,
    vocational_skill,
    age_lower_limit,
    age_upper_limit,
    q,
  ].forEach((item, index) => {
    if (item !== '' && item !== undefined && item !== null) {
      let keyName = keyArray[index];
      queryString = queryString + `&${keyName}=${item}`;
    }
  });
  return apiConfig.getRequest(`staff/approvals/applicant_approvals?page=${page}&per_page=${per_page}${queryString}`);
}

export function uploadFile(data) {
  setAccessToken();
  return apiConfig.postRequest(`common/files`, data);
}

export function searchApplicants(query, type, region, district, page) {
  region = region ? region : '';
  district = district ? district : '';
  switch (type) {
    case 'applicants':
      return apiConfig.getRequest(
        `staff/approvals/applicant_approvals?page=1&per_page=10&q=${query}&region=${region}&district=${district}&page=${page}`,
      );

    case 'interview':
      return apiConfig.getRequest(
        `staff/approvals/interview_approvals?page=1&per_page=10&q=${query}&region=${region}&district=${district}&page=${page}`,
      );

    default:
      if (query !== '') {
        return apiConfig.getRequest(`staff/dashboard/applicants_search?query=${query}&per_page=10&page=${page}`);
      }
  }
}

export const makeEditable = (id) => {
  return apiConfig.postRequest(`staff/dashboard/applicants/${id}/make-editable`);
};

export const scoreApplicant = (params) => {
  return apiConfig.postRequest(`staff/grants/user-grant-score`, params);
};
export function getGrantBusinessExpenditures(id) {
  return apiConfig.getRequest(`staff/grants/user-business-expenditure?client=${id}`);
}

export function makeApplicantEditable(id) {
  return apiConfig.postRequest(`staff/dashboard/applicants/${id}/make-bp-editable`);
}
