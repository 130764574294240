/*eslint-disable*/
import React from 'react';
import { IFilterParams } from '@/utils/interfaces/approvals.interface';
import { useQuery } from 'react-query';
import { getTrainingApprovals } from '@/service/Applicants';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { AxiosResponse } from 'axios';
import { useGenerateDisbursementCSV, useGetPostdisbursementTableData } from '../hooks/disbursementTable.hooks';
import { handleErrorMessage, messageAlert, parseIdFromProjectId } from '@/utils/helpers/functions';
import { IPostdisbursementParams } from '../interfaces/DisbursementBaseLayout.interface';
import apiConfig from '@/service/apiConfig';
import { useGetDistricts, useGetRegions } from '@/utils/hooks/getRegionDistricts';
import { IApprovalsData } from '@/utils/interfaces/api.approvals.interface';
import useGetUserData from '@/utils/hooks/useGetUserData';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { validateBankAccountNumber, validateDate, validateDisbursedAmount } from '../utils/helper';

const tableBodyHeight = `${window.innerHeight * 0.95}px`;
const tableBodyMaxHeight = '';

const getPostdisbursementassessmentlist = async (props: IPostdisbursementParams) => {
  const keys: Array<keyof IPostdisbursementParams> = Object.keys({
    page: 0,
    region: undefined,
    district: undefined,
    q: undefined,
  } as IPostdisbursementParams) as Array<keyof IPostdisbursementParams>;
  let queryString = '';
  keys.forEach((key) => {
    if (props[key] && key !== 'page') {
      queryString = queryString + `&${key}=${props[key]}`;
    }
  });
  return apiConfig.getRequest(
    `staff/approvals/post_disbursement_list?page=${(props?.page || 0) + 1}&per_page=10${queryString}`,
  );
};

export const useGetPostdisbursement = () => {
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = React.useState(false);
  const { userData, isLoading: userLoading } = useGetUserData();
  const [tabValue, setTabValue] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [previewTableData, setPreviewTableData] = React.useState<any[][]>([]);
  const [templateFile, setTemplateFile] = React.useState<File | null>();
  const [params, setParams] = React.useState<IFilterParams>({
    tableData: [],
    age_lower_limit: '',
    age_upper_limit: '',
    disability: '',
    district: '',
    educational_level: '',
    gender: '',
    q: '',
    region: '',
    score_lower_limit: '',
    score_upper_limit: '',
    vocational_skill: '',
    pass_threshold: 0,
  });
  const { data, isLoading, isRefetching, error, refetch } = useQuery(
    'training-list',
    () => {
      return getPostdisbursementassessmentlist({
        district: params.district,
        region: params.region,
        page: currentPage,
        q: params.q,
      });
    },
    { refetchOnWindowFocus: false },
  );

  const { regions, error: regionsError } = useGetRegions();
  const { districts, error: districtsError } = useGetDistricts(regions, parseInt(params?.region || '1'));

  const _data = data as unknown as AxiosResponse<IApprovalsList>;

  const getTableData = (data: any) => {
    try {
      let { tableData, count } = useGetPostdisbursementTableData(data);
      setCount(count);
      setTableData(tableData);
    } catch (e) {}

    // props?.setBaseLayoutParams({ ...params, tableData: tableData });
  };

  const getSelectedRows = (rowArray: any[]) => {
    try {
      let rowDataArray: any = [];
      rowArray.forEach((rowIndex: string | number | any) => {
        let _applicantData = tableData[rowIndex];
        rowDataArray.push(_applicantData[0]); //this index is where the id is stored
      });
      return rowDataArray;
    } catch (e) {
      return [];
    }
  };

  const validateRow = (row: string[]): boolean => {
    let isValid = true;
    row.forEach((value, index) => {
      if (value.trim().length == 0) {
        isValid = false;
      }
      if (index == 1) {
        if (validateBankAccountNumber(value) == false) {
          isValid = false;
        }
      }
      if (index == 4) {
        if (validateDate(value) == false) {
          isValid = false;
        }
      }
      if (index == 5) {
        if (validateDisbursedAmount(value) == false) {
          isValid = false;
        }
      }
    });
    return isValid;
  };

  const getPostDisbursementScoreFields = (row: any[]) => {
    let keyList = ['account_number', 'bank_name', 'branch_name', 'date_of_payment', 'disbursed_amount'];
    let scores = {};
    console.log(row, 122);

    keyList.forEach((key, index) => {
      if (index == 3) {
        scores[key] = moment(row[index], 'DD-MM-YYYY').format();
      } else {
        scores[key] = row[index];
      }
    });
    return scores;
  };

  const previewTable = (file: File) => {
    setPreviewTableData([]);
    let hasError: boolean = false;

    try {
      let reader = new FileReader();

      reader.addEventListener('loadend', () => {
        let temp = typeof reader?.result === 'string' ? reader?.result.split('\r\n') : [];
        //
        let tableArray = [];
        for (let i = 1; i < temp.length; i++) {
          //
          let row = temp[i].replace(/['"]+/g, '').split(',');

          if (validateRow(row) == true) {
            tableArray.push(row);
          } else {
            hasError = true;
          }
        }
        if (hasError) {
          messageAlert(
            'error',
            'Some rows contained invalid values, those rows have been removed from the uploaded csv.',
          );
        }
        setPreviewTableData(tableArray);
      });
      reader.readAsText(file);
    } catch (e) {
      messageAlert('error', 'Preview Failed, make sure file is in the right format');
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj: File | undefined = e?.target?.files?.[0];
    if (fileObj) {
      setTemplateFile(fileObj);
      previewTable(fileObj);
    }
  };

  const filter = () => {
    setCurrentPage(0);
    refetch();
  };

  const previewTableOptions: MUIDataTableOptions = {
    print: false,
    search: true,
    viewColumns: false,
    download: false,
    filter: false,
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 10,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'Role',
      direction: 'asc',
    },
  };

  const previewTableColumns: MUIDataTableColumnDef[] = [
    {
      name: 'Application ID',
      options: {
        filter: false,
        customBodyRender: (value: any) => <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{value}</div>,
      },
    },
    {
      name: 'Account Number',
    },
    {
      name: 'Bank Name',
    },
    { name: 'Branch Name' },
    { name: 'Date of Payment' },
    { name: 'Disbursed Amount' },
  ];

  const postdisbursementTableOptions: MUIDataTableOptions = {
    print: false,
    search: false,
    viewColumns: false,
    download: true,
    filter: false,
    filterType: 'dropdown',
    responsive: 'vertical',
    serverSide: true,
    page: currentPage,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    count: count,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'Role',
      direction: 'asc',
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setCurrentPage(tableState?.page);
      }
    },

    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const dataToState = rowsSelected?.map((item) => {
        return item;
      });
      setSelectedItems(getSelectedRows(dataToState || []));
    },
  };

  const postdisbursementTableColumns: MUIDataTableColumnDef[] = [
    {
      name: 'Application ID',
      options: {
        filter: false,
        customBodyRender: (value: any) => <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{value}</div>,
      },
    },
    {
      name: 'Account Number',
    },
    {
      name: 'Bank Name',
    },
    { name: 'Branch Name' },
    { name: 'Date of Payment' },
    { name: 'Disbursed Amount' },
    {
      name: 'Action',
      options: {
        customBodyRender: (id: any) => {
          return (
            <div>
              <Button
                onClick={() => {
                  navigate(`/applicants/${parseIdFromProjectId(id)}`);
                }}
              >
                View Details
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const downloadCSV = () => {
    const headerColumns = [
      'Application ID',
      'Account Number',
      'Bank Name',
      'Branch Name',
      'Date of Payment',
      'Disbursed Amount',
    ];
    const { success } = useGenerateDisbursementCSV({
      rows: selectedItems,
      columns: headerColumns,
      data: _data,
      type: 'Post',
    });

    if (success) {
      messageAlert('success', 'CSV Downloaded');
      setSelectedItems([]);
    }
  };

  const clearData = () => {
    setTemplateFile(null);
    setPreviewTableData([]);
    setSelectedItems([]);
    setCurrentPage(0);
  };

  const uploadCSV = async () => {
    setIsUploading(true);
    if (!userLoading) {
      const idList = [];
      previewTableData.forEach((row) => {
        idList.push(row);
      });
      const _params: IApprovalsData = {
        approval_type: 'post_disbursement_approval',
        id_list: idList.map((row: any[]) => {
          return { id: parseIdFromProjectId(row[0]), ...getPostDisbursementScoreFields(row?.slice(1)) };
        }),
        user: userData,
        approval_status: 'approved',
      };
      if (idList.length > 0) {
        try {
          console.log(_params);
          await apiConfig.postRequest(`staff/approvals/`, _params);
          messageAlert('success', 'Approval Successful');
          refetch();
          clearData();
        } catch (e) {
          let message = handleErrorMessage(error);
          messageAlert('error', message);
        }
      }
    }
    setIsUploading(false);
  };

  const downloadCSVTemplate = () => {
    let templateHeader = [
      ['Application ID', 'Account Number', 'Bank Number', 'Branch Name', 'Date of Payment', 'Disbursed Amount'],
    ];

    let csvContent = 'data:text/csv;charset=utf-8,';

    templateHeader.forEach(function (rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });
    const uri = encodeURI(csvContent);
    var element = document.createElement('a');
    element.href = uri;
    element.target = '_blank';
    element.download = 'template.csv';
    element.click();
  };

  React.useEffect(() => {
    refetch();
  }, [currentPage]);

  React.useEffect(() => {
    const _data = data as unknown as AxiosResponse<IApprovalsList>;
    getTableData(_data);
  }, [data]);

  React.useEffect(() => {
    handleErrorMessage(error);
  }, [error]);

  return {
    tabValue,
    params,
    currentPage,
    _data,
    isLoading: isLoading || isUploading,
    isRefetching,
    error,
    postdisbursementTableOptions,
    postdisbursementTableColumns,
    previewTableColumns,
    previewTableOptions,
    tableData,
    previewTableData,
    regions,
    districts,
    selectedItems,
    templateFile,
    downloadCSVTemplate,
    uploadCSV,
    downloadCSV,
    handleFileChange,
    refetch,
    setCurrentPage,
    setTabValue,
    setParams,
    filter,
  };
};
