/*eslint-disable*/
import React from 'react';
import { CircularProgress, Box } from '@mui/material';

interface ILoaderProps {
  text?: string;
}

const Loader = ({ text }: ILoaderProps) => {
  return (
    <Box sx={{ textAlign: 'center', marginTop: '5rem' }}>
      <CircularProgress />
      <Box sx={{ marginTop: '1.5rem' }}>{text}</Box>
    </Box>
  );
};

export default Loader;
