import React, { Fragment } from "react";
import { Grid, Typography } from "@mui/material";
import { DefaultButton } from "@components/Buttons/ButtonFullWidth";
import { MdDone } from "react-icons/md";
import propTypes from "prop-types";

const ScoreGrid = ({ type }) => {
  var inputTitle = "Do you have a business plan";
  var mainTitle = "Business plan";
  var score = "100";
  var score2 = "80";
  var options = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  if (type === "business_plan") {
    mainTitle = "Business plan";
    inputTitle = "Do you have a business plan";
    score = "100";
    options = [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ];
  } else if (type === "credit_history") {
    mainTitle = "Credit reference bureau history";
    inputTitle = "Principal Owner Debt Status";
    score = "40";
    options = [
      { label: "Performing", value: "performing" },
      { label: "Non-Performing", value: "non_performing" },
    ];
  } else if (type === "cashflows") {
    mainTitle = "3 Yr projected cashflows ";
    inputTitle = "Growth in Business T/O";
    score = "85";
    options = [
      { label: "Negative", value: "negative" },
      { label: "Positive", value: "positive" },
    ];
  } else {
    mainTitle = "Source of repayment";
    inputTitle = "Repayment Sources";
    score = "70";
    options = [
      { label: "Multiple", value: "multiple" },
      { label: "Single", value: "single" },
    ];
  }

  return (
    <Grid container direction="row" mb={5}>
      <Grid container rowSpacing={1}>
        <Grid item xs={6}>
          <div
            className="card-title text-default-color"
            style={{ borderLeft: "0.2em solid #2A3B8F", paddingLeft: "0.5em" }}
          >
            {mainTitle}
          </div>
        </Grid>
        {type === "business_plan" && (
          <Grid item xs={6}>
            <Typography className="pull-right" mx={4}>
              <DefaultButton icon={<MdDone size={20} />} text="Save Scores" />
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={4} style={{ marginBottom: "1.5rem" }}>
          <div className="top" style={{ marginBottom: "0.5rem" }}>
            {inputTitle}
          </div>
          <div className="bottom">
            <select
              name={type}
              style={{ width: "calc(100%-1.6rem)" }}
              placeholder="Score"
              className="add-staff-form-input"
            >
              {options.map((option, idx) => (
                <option value={option?.value} key={idx}>
                  {option?.label}
                </option>
              ))}
            </select>
          </div>
        </Grid>
        <Grid item xs={2} style={{ marginBottom: "1.5rem" }}>
          <div className="top" style={{ marginBottom: "0.5rem" }}>
            Score
          </div>
          <div className="bottom">
            <Typography variant="h6" style={{ color: "#1D9649" }}>
              {score}%
            </Typography>
          </div>
        </Grid>
        {type === "credit_history" && (
          <Fragment>
            <Grid item xs={4} style={{ marginBottom: "1.5rem" }}>
              <div className="top" style={{ marginBottom: "0.5rem" }}>
                Business Debt Status
              </div>
              <div className="bottom">
                <select
                  name="debt_status"
                  style={{ width: "calc(100%-1.6rem)" }}
                  placeholder="Score"
                  className="add-staff-form-input"
                >
                  <option>Performing</option>
                  <option>Never Borrowed</option>
                  <option>NPL</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={2} style={{ marginBottom: "1.5rem" }}>
              <div className="top" style={{ marginBottom: "0.5rem" }}>
                Score
              </div>
              <div className="bottom">
                <Typography variant="h6" style={{ color: "#1D9649" }}>
                  {score2}%
                </Typography>
              </div>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
};

ScoreGrid.propTypes = {
  type: propTypes.string,
};

const TrainingScoreForm = () => {
  return (
    <div style={{ marginTop: "2rem" }}>
      <ScoreGrid type="business_plan" />
      <ScoreGrid type="credit_history" />
      <ScoreGrid type="cashflows" />
      <ScoreGrid type="repayment_sources" />
    </div>
  );
};

export default TrainingScoreForm;
