/*eslint-disable*/
import { getDisqualifiedStage } from '@/components/Reports/hooks/useGetDetailedReports';
import apiConfig from './apiConfig';

export const getRegions = () => {
  return apiConfig.getRequest(`locations/regions`);
};

export const getDistricts = (region) => {
  return apiConfig.getRequest(`locations/districts?region=${region}`);
};

export const getDocuments = () => {
  return apiConfig.getRequest(`common/files`);
};

export const getDetailedAnalysis = (_params) => {
  const {receivedGrant,receivedTraining,...params} = _params
  console.log(params,18888)
  // params.page = params?.page==0?1:params?.page;
   if (receivedGrant == true) {
     return apiConfig.getRequest(`staff/reports/m_and_e_grant_beneficiaries`, params);
  }

  if (receivedTraining == true) {
      return apiConfig.getRequest(`staff/reports/m_and_e_training_only_beneficiaries`, params);
  }
  if (params.show_disqualified == false) {
    return apiConfig.getRequest(`staff/reports/detailed_reports`, params);
  }

 

  if(params.show_disqualified == true){

  const { show_disqualified, application_process_status, ...rest } = params;
  rest.approval_stage = getDisqualifiedStage(application_process_status);
  return apiConfig.getRequest(`staff/reports/disqualified_clients`, rest);
  }

};

export const getMonitoringAndEvaluationGrant = (params) => {
    return apiConfig.getRequest(`staff/reports/m_and_e_grant_benficiaries`, params);
}

export const getMonitoringAndEvaluationTraining = (params) => {
    return apiConfig.getRequest(`staff/reports/m_and_e_training_only_benficiaries`, params);
  
}
