/*eslint-disable*/
import { GrantAssessmentContext } from '@/utils/context/GrantAssementContext';
import { IGetManualScoringFields, IManualScoreField } from '../interfaces/ManualScoring.interfaces';
import React from 'react';
import { getSkippedManualScoringFields } from './skippedmanualscoring.constants';

export const getManualScoringFields = ({ data }: IGetManualScoringFields): IManualScoreField[] => {
  const { selectedApplicantResponseObject } = React.useContext(GrantAssessmentContext);
  const isSkipped = selectedApplicantResponseObject?.client_details?.[0]?.skipped_advanced_training == true;

  if (isSkipped) {
    return getSkippedManualScoringFields(data);
  }

  return [
    {
      title: 'Enterprise Overview Score',
      key: 'enterprise_overview_score',
      questionPrompt: 'Provide an overview of your enterprise, your product/service',
      sectionKey: 'application_summary',
      range: {
        low: 0,
        high: 15,
      },
      scoringGuidelines: [
        {
          score: 3,
          description: `Applicant sufficiently presents enterprise including recommended considerations such as 
                    products/service, production capacity, volume of production, target market and market size, 
                    etc`,
        },
        {
          score: 3,
          description: `Applicant can demonstrate an understanding of the opportunities in their area of business, or 
        the business interest identified (in the case of startups)`,
        },
        {
          score: 3,
          description: `When applicant articulates the stage of their products or services. Is the stage of product or 
        service development or growth clearly stated? Eg. are they at the ideation stage, or the testing 
        stage, or perhaps the stage where scaleup is expected. Is it clearly stated the product 
        certification stages such as receipt of FDA certification?`,
        },
        {
          score: 3,
          description: `Does the Business Plan state clearly the customers of the products/services? Is there a clearcut 
        strategy for reaching the customer base? For new products/services yet to enter the market, 
        does the Business Plan mention the immediate target market and how those target markets will
        be accessed? `,
        },
        {
          score: 3,
          description: `Does the BP show clarity on key competitors?  What is the applicant doing differently to set 
        their products/services apart from their key competitors?`,
        },
      ],
      type: 'default',
      submissionData: data?.[0]?.grant_information?.applicant_information?.product_overview,
    },

    {
      title: 'Challenges Score',
      key: 'challenges_score',
      questionPrompt: `Challenges: Provide details of the challenges your enterprise faces or challenges 
    that your startup anticipates`,
      sectionKey: 'applicant_information',
      range: {
        low: 0,
        high: 6,
      },
      scoringGuidelines: [
        {
          score: 2,
          description: `Applicant is able to demonstrate an understanding of the challenges faced by the enterprise 
        from both internal and systemic approach.`,
        },
        {
          score: 1,
          description: `Able to connect linkages across factors.`,
        },
        {
          score: 3,
          description: `Provides tangible solutions to challenges.`,
        },
      ],
      type: 'default',
      submissionData: data?.[0]?.grant_information?.applicant_information?.details_of_entreprise_challenges,
    },

    {
      title: 'Technical Implementation Score',
      key: 'technical_implementation_score',
      questionPrompt: `Please provide details of your project: How will 
    the proposed project activities be viably 
    implemented – technically?`,
      sectionKey: 'project_information',
      range: {
        low: 0,
        high: 15,
      },
      scoringGuidelines: [
        {
          score: 5,
          description: `Applicant demonstrates project viability within 
        the funding timeframe. .`,
        },
        {
          score: 5,
          description: `Applicant can unpack key project outputs and 
        activities.`,
        },
        {
          score: 5,
          description: `Bonus: Applicant provides additional relevant 
        information to support business case. (that is, 
        where the applicant provides additional 
        executable information about their marketing 
        plan).`,
        },
      ],
      submissionData: data?.[0]?.grant_information?.activities,
      type: 'activity',
    },

    {
      title: 'Technical Capacity Score',
      key: 'technical_capacity_score',
      questionPrompt: `Technical Capability:  What technical 
    capacity do you have to implement the 
    business plan?`,
      sectionKey: 'project_information',
      range: {
        low: 0,
        high: 8,
      },
      scoringGuidelines: [
        {
          score: 8,
          description: `Does technical expertise of the proprietor or 
        business owner match the product/service 
        delivery? 
        Alternatively, does the staff hired to implement 
        their projects or currently implementing their 
        business have the requisite knowledge and 
        training to successfully implement the project 
        and ensuring that key staff are experienced 
        and qualified for respective roles.`,
        },
      ],
      submissionData: data?.[0]?.grant_information?.applicant_information?.technical_capability,
      type: 'default',
    },

    {
      title: 'Has Benefited From Grant Score',
      key: 'has_benefited_from_grant_programme_score',
      questionPrompt: `Has your business benefited from any grant 
    programme?  Yes [  ]      No [  ] 
    If ‘Yes’, state name of the grant awarding 
    organisation, amount received, purpose of 
    grant and year of grant receipt`,
      sectionKey: 'project_information',
      range: {
        low: 0,
        high: 1,
      },
      scoringGuidelines: [
        {
          score: 0,
          description: `0 if answer is “No”
       `,
        },
        {
          score: 1,
          description: `
        1 if answer is “Yes” and the applicant states the
        name of the grant awarding organization, 
        amount received, purpose of grant and year 
        when grant fund was awarded`,
        },
      ],
      type: 'default',
      submissionData: `${
        data?.[0]?.grant_information?.applicant_information?.has_business_benefited_from_any_grant == true
          ? 'Yes'
          : 'No'
      } ${data?.[0]?.grant_information?.applicant_information?.grant_details}`,
    },

    {
      title: 'Fiscal Expenditure Score',
      key: 'fiscal_expenditure_score',
      sectionKey: 'project_information',
      questionPrompt: 'Outline the projections for the next three years.',
      range: {
        low: 0,
        high: 4,
      },
      scoringGuidelines: [
        {
          score: 0,
          description: `0 point if no projection is given for sales, 
        operating cost, and gross profit for each of the 
        three years.`,
        },
        {
          score: 4,
          description: `Max of 4 points if projections are given for 
        sales, operating cost, gross profit, and net 
        cashflow for each of the three years `,
        },
      ],
      submissionData: data?.[0]?.grant_information?.financial_projections,
      type: 'fiscal_projection',
    },

    {
      title: 'Expected use of grants Score',
      key: 'grant_expenditure_score',
      sectionKey: 'grant_request',
      range: {
        low: 0,
        high: 10,
      },
      questionPrompt: 'What is the proposed or expected use of the grants?',
      scoringGuidelines: [
        {
          score: 8,
          description: 'Expenditures are correctly detailed.',
        },
        {
          score: 2,
          description: `Support documents are adequate.`,
        },
        {
          score: -2,
          description: `Note: applicant will lose all 2 points for not 
        attaching any of the following if required:
        —At least 3 proforma invoices from separate 
        vendors for all proposed capital expenditure 
        items exceeding GHS10,000 such as 
        machinery and equipment,
        —At least 1 proforma invoice for procurement 
        of raw materials`,
        },
      ],
      submissionData: '',
      type: 'grants_expenditure',
    },
    {
      title: 'Performance Indicator Score',
      key: 'performance_indicator_score',
      sectionKey: 'expected_results',
      range: {
        low: 0,
        high: 20,
      },
      questionPrompt: `How will grant support affect your performance 
    indicators?`,
      scoringGuidelines: [
        {
          score: 10,
          description: `Applicant is able articulate current enterprise 
        status with respect to its sales/turnover, 
        employee size, and production volume.`,
        },
        {
          score: 10,
          description: `Applicant is able to project expected grant 
        impact in terms of any incremental 
        sales/turnover, employee size, and production 
        volume`,
        },
      ],
      submissionData: data?.[0]?.grant_information?.performance_indicators,
      type: 'performance_indicator',
    },
    {
      title: 'Sustainability Plan Score',
      key: 'sustainability_plan_score',
      sectionKey: 'sustainability',
      range: {
        low: 0,
        high: 8,
      },
      questionPrompt: `What are your plans to ensure operations are
    sustained after grant disbursement from 
    GEA?`,
      scoringGuidelines: [
        {
          score: 0,
          description: `0 point if no clear sustainability plan
            to ensure operation continuity when
            grant funds run out is presented in 
            the Business Plan. `,
        },
        {
          score: 8,
          description: `Continuity is 
        expressed clearly after grant fund is
        fully utilized. `,
        },
      ],
      submissionData: data?.[0]?.grant_information?.sustainability_plan,
      type: 'default',
    },

    {
      title: 'Operational Risk Score',
      key: 'operational_risk_score',
      sectionKey: 'sustainability',
      range: {
        low: 0,
        high: 7,
      },
      questionPrompt: `Are there any issues/risks that might affect 
    the successful execution of your proposed 
    project? E.g., an ongoing litigation that may 
    affect the project, etc. Please detail if 
    applicable and indicate how these would be 
    mitigated. `,
      scoringGuidelines: [
        {
          score: 0,
          description: ` No operational risk is 
        captured `,
        },
        {
          score: 7,
          description: `Max of 7 points where risks are 
        clearly captured, and mitigations 
        are assigned for each identified risk`,
        },
      ],
      submissionData: data?.[0]?.grant_information?.expected_issues_that_may_affect_execution,
      type: 'default',
    },
  ];
};
