/*eslint-disable*/
import React from 'react';
import { Grid, Box, Button, FormLabel, Stack, Typography } from '@mui/material';
import { IFilterComponent, IFilterParams } from '@/utils/interfaces/approvals.interface';
import { defaultActiveColor } from '@/utils/constants';

const DisbursementFilter = ({
  checkSelected,
  districts,
  onFilter,
  params,
  setParams,
  regions,
  userData,
}: IFilterComponent) => {
  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          paddingTop: '2rem',
          display: 'flex',
          // alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        {/* <Grid item xs={3} sx={{ display: 'flex,', flexDirection: 'column' }}>
          <FormLabel
            sx={{
              fontFamily: 'inherit',
              marginBottom: '0.1rem',
              fontWeight: '600',
            }}
          >
            Gender
          </FormLabel>
          <select
            className=" filter-select"
            onChange={(e) => {
              setParams({ ...params, gender: e.target.value });
            }}
          >
            <option value={''}>All</option>
            <option value={'M'}>Male</option>
            <option value={'F'}>Female</option>
          </select>
        </Grid> */}
        {/* <Grid item xs={3} sx={{ display: 'flex,', flexDirection: 'column' }}>
          <FormLabel
            sx={{
              fontFamily: 'inherit',
              marginBottom: '0.1rem',
              fontWeight: '600',
            }}
          >
            Disability
          </FormLabel>
          <select
            className="filter-select"
            name="disability"
            onChange={(e) => {
              setParams({ ...params, disability: e.target.value });
            }}
          >
            <option value={''}>All</option>
            <option value={'yes'}>Show disabled Applicants</option>
            <option value={'no'}>Hide disabled Applicants</option>
          </select>
        </Grid> */}
        <Grid item xs={4} sx={{ display: 'flex,', flexDirection: 'column' }}>
          <FormLabel
            sx={{
              fontFamily: 'inherit',
              marginBottom: '0.1rem',
              fontWeight: '600',
            }}
          >
            Region
          </FormLabel>
          <select
            name="region"
            className="filter-select"
            onChange={(e) => {
              setParams?.({ ...params, region: e.target.value } as unknown as IFilterParams);
            }}
            disabled={userData?.staff_type == 'bac' || userData?.staff_type == 'bac_regional_head'}
          >
            <option value={''}>All</option>
            {regions?.map((region, idx) => (
              <option selected={region.id === params?.region} key={`${idx} - ${region.name}`} value={region.id}>
                {region.name}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex,', flexDirection: 'column' }}>
          <FormLabel
            sx={{
              fontFamily: 'inherit',
              marginBottom: '0.1rem',
              fontWeight: '600',
            }}
          >
            District
          </FormLabel>
          <select
            name="district"
            className="filter-select"
            onChange={(e) => {
              setParams?.({ ...params, district: e.target.value } as unknown as IFilterParams);
            }}
            disabled={userData?.staff_type == 'bac'}
          >
            <option value={''}>All</option>
            {districts?.map((district, idx) => (
              <option key={`${idx} - ${district.name}`} value={district.id}>
                {district.name}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item xs={4}>
          <Box style={{}}>
            <Stack direction="column">
              <Box fontSize={15}>
                <Typography sx={{ fontFamily: 'inherit' }}>Pass Score</Typography>
              </Box>

              <input
                placeholder={'Pass Mark'}
                min={0}
                type="number"
                className="range-input"
                defaultValue={params?.pass_threshold}
                style={{ padding: '0.8rem', border: '1px solid #EEF1F3' }}
                onChange={(e) => {
                  setParams?.({ ...params, score_lower_limit: parseInt(e.target.value) } as unknown as IFilterParams);
                }}
              />
              {/* <Box mt={2}>
              {' '}
              <hr style={{ width: '1rem', marginLeft: '0.5rem', marginRight: '0.5rem' }} />
            </Box> */}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{}}>
            <Stack direction="column">
              <Box fontSize={15}>
                <Typography sx={{ fontFamily: 'inherit' }}>Search </Typography>
              </Box>

              <input
                placeholder={'Enter Name or ID Number'}
                min={0}
                type="text"
                className="range-input"
                defaultValue={params?.q}
                style={{ padding: '0.8rem', border: '1px solid #EEF1F3' }}
                onChange={(e) => {
                  setParams?.({ ...params, q: e.target.value } as unknown as IFilterParams);
                }}
              />
              {/* <Box mt={2}>
              {' '}
              <hr style={{ width: '1rem', marginLeft: '0.5rem', marginRight: '0.5rem' }} />
            </Box> */}
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} style={{ marginTop: '10px' }}>
        <Box>
          <Button
            sx={{
              '&:hover': { backgroundColor: '#2a3b8f', color: 'white', border: '2px solid #2a3b8f' },
              fontWeight: '600',
              fontFamily: 'inherit !important',
              fontSize: '1rem',
              color: defaultActiveColor,
              border: `2px dashed ${defaultActiveColor}`,
              textTransform: 'initial !important',
              width: '100%',
              paddingTop: '8px',
              paddingBottom: '8px',
            }}
            onClick={onFilter}
          >
            Filter
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default DisbursementFilter;
