/*eslint-disable*/
import { IViewUserSubmissionModal } from '../../interfaces/ManualScoring.interfaces';
import { useTheme, useMediaQuery } from '@mui/material';
import { IReturnUserFeedback } from '../../interfaces/ManualScoring.interfaces';
import { IApplicantDataObject } from '../../interfaces/applicantDetails.model';
import Table from '@/components/Table/Table';
import {
  ActivityTableColumns,
  ExpectedResultColumns,
  FiscalProjectionTableColumns,
  FormTableInputOptions,
  GrantExpenditureTableColumns,
} from '../../utils/constants';
import React from 'react';
import { useLoadDisplayTableRows } from '../../hooks/useLoadBusinessActivities';
import { loadIndicatorTables } from '../../hooks/useLoadExpectedResults';
import { useLoadFiscalProjections } from '../../hooks/useLoadFiscalProjection';
import { useLoadGrantExpenditures } from '../../hooks/useLoadGrantExpenditures';
export const useGetViewUserSubmission = ({ open, setOpen }: IViewUserSubmissionModal) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return { fullScreen };
};

export const returnGrantExpenditureTableComponent = (data: any) => {
  // console.log(data, 2777);
  let { rowData } = useLoadGrantExpenditures(data);
  return (
    <Table
      columns={GrantExpenditureTableColumns}
      options={FormTableInputOptions}
      title={'Grant Expenditures'}
      data={rowData}
    />
  );
};

export const returnActivityDataTableComponent = (
  data: IApplicantDataObject['profile'][0]['grant_information']['activities'],
) => {
  let { rowData } = useLoadDisplayTableRows(data);
  return (
    <Table columns={ActivityTableColumns} options={FormTableInputOptions} title={'Activity List'} data={rowData} />
  );
};

export const returnExpectedResultTableComponent = (
  data: IApplicantDataObject['profile'][0]['grant_information']['performance_indicators'],
) => {
  let { rowData } = loadIndicatorTables(data);
  return (
    <Table columns={ExpectedResultColumns} options={FormTableInputOptions} title="Expected Results" data={rowData} />
  );
};

export const returnFiscalProjectionTableComponent = (
  data: IApplicantDataObject['profile'][0]['grant_information']['financial_projections'],
) => {
  let { rowData } = useLoadFiscalProjections(data);
  return (
    <Table
      columns={FiscalProjectionTableColumns}
      options={FormTableInputOptions}
      title="Fiscal Projections"
      data={rowData}
    />
  );
};

export const returnUserFeedback = ({ data, type }: IReturnUserFeedback) => {
  switch (type) {
    case 'activity':
      returnActivityDataTableComponent(data);
      break;
  }
  return <>{'user feedback'}</>;
};
