/*eslint-disable*/
import { AvatarInitial } from '@/utils/Logo';
import { Button } from '@mui/material';
import { MUIDataTableOptions, MUIDataTableColumnDef } from 'mui-datatables';
import { useNavigate } from 'react-router-dom';
import { IGetBatchGrantAssementTableOptions } from './types';
import { getTemplateHeaders, getTemplateHeadersWithStatus } from './helpers';

export const BATCH_ASSESSMENT_PASS_THRESHOLD = 50;



export const getBatchGrantAssessmentTableOptions = ({
  currentPage,
  tableBodyHeight,
  tableBodyMaxHeight,
  count,
  setCurrentPage,
  setSelectedItems,
  getSelectedRows,
}: IGetBatchGrantAssementTableOptions): MUIDataTableOptions => {
  return {
    print: false,
    search: false,
    viewColumns: false,
    download: true,
    filter: false,
    filterType: 'dropdown',
    responsive: 'vertical',
    serverSide: true,
    page: currentPage,
    rowsPerPage: 100,
    rowsPerPageOptions: [100],
    count: count,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'Role',
      direction: 'asc',
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setCurrentPage(tableState?.page);
      }
    },

    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const dataToState = rowsSelected?.map((item) => {
        return item;
      });
      setSelectedItems(getSelectedRows(dataToState || []));
    },
  };
};

export const getGrantAssessmentPreviewOptions = ({
  tableBodyHeight,
  tableBodyMaxHeight,
}: Partial<IGetBatchGrantAssementTableOptions>): MUIDataTableOptions => {
  return {
    print: false,
    search: false,
    viewColumns: false,
    download: false,
    filter: false,
    responsive: 'vertical',
    rowsPerPage: 100,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRowsHideCheckboxes: true,
    sortOrder: {
      name: 'Role',
      direction: 'asc',
    },
  };
};

export const getBatchGrantAssessmentColumns = () => {
  const navigate = useNavigate();
  return [
    {
      name: 'Applicant',
      options: {
        filter: true,
        customBodyRender: (value) => <AvatarInitial text={value || ''} />,
      },
    },
    'Client ID',
    'Registration Date',
    'Applicant Name',
    'Phone Number',
    'ID Number',
    {
      name: 'Action',
      options: {
        filter: true,
        customBodyRender: (value) => (
          <Button
            sx={{ textTransform: 'none', fontFamily: 'inherit' }}
            onClick={() => {
              navigate(`/grantassessment/${value}`);
            }}
          >
            View
          </Button>
        ),
      },
    },
  ];
};

export const getBatchGrantAssessmentPreviewColumns = (skipped?: boolean) => {
  return getTemplateHeadersWithStatus(skipped);
};

export const returnDefaultValue = (value) => {
  if (value == undefined || value == null || value == '') {
    return `N/A`;
  }
  return value;
};

export const grantTabs: { label: string; value: number }[] = [
  {
    label: 'Assessment List',
    value: 0,
  },
  { label: 'Batch Upload', value: 1 },
];

export const monitoringTabs: { label: string; value: number }[] = [
  {label:'Grant Beneficiaries',value:1},
  {label:'Non-Grant Beneficiaries',value:2},
]
