import React from 'react';
import { Grid, Box } from '@mui/material';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { checkPermissions } from '@service/RequireAuth';
import { defaultActiveColor } from '@/utils/constants';

const ApprovalsNav = ({ selectedView }) => {
  const navigate = useNavigate();
  const userData = JSON.parse(window.localStorage.getItem('user'));
  const getStyles = (view, selectedView) => {
    if (view === selectedView) {
      return {
        backgroundColor: 'white',
        marginLeft: '.5rem',
        borderLeft: ' 3px solid #2a3b8f',
        fontSize: '0.90rem',
        padding: '1rem',
        fontWeight: '800',
        marginBottom: '0.3rem',
        color: defaultActiveColor,
      };
    } else
      return {
        fontSize: '0.90rem',
        marginLeft: '.5rem',
        padding: '1rem',
        fontWeight: '800',
        marginBottom: '0.3rem',
        color: 'gray',
      };
  };

  // if (isLoading == true) return <>Loading</>;
  return (
    <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#F9FAFD',
          height: '100%',
        }}
      >
        <Box
          className="settings-nav-item fw-bold no-hover"
          mt={'2.5rem'}
          sx={{
            fontSize: '1.2rem',
            paddingLeft: '1.5rem',
            paddingBottom: '1rem',
            fontWeight: '700',
            // borderBottom: `1px solid #00000015`,
          }}
        >
          Approvals
        </Box>
        {checkPermissions('applicant approvals', userData) == true && (
          <Box
            className="settings-nav-item"
            mt={'3rem'}
            mb={'0.1rem'}
            sx={getStyles(0, selectedView)}
            onClick={() => {
              navigate('/applicant/approvals');
            }}
          >
            Application Approvals
          </Box>
        )}
        {checkPermissions('interview approvals', userData) == true && (
          <Box
            className="settings-nav-item"
            mb={'0.1rem'}
            sx={getStyles(1, selectedView)}
            onClick={() => {
              navigate('/interview/approvals');
            }}
          >
            Interview/Screening Approvals
          </Box>
        )}
        {checkPermissions('training approvals', userData) == true && (
          <Box
            className="settings-nav-item no-hover"
            mb={'0.1rem'}
            sx={getStyles(3, selectedView)}
            // onClick={() => {
            //   navigate('/training/approvals');
            // }}
          >
            Training Approvals
            <Box
              className="settings-nav-item"
              mt={'1.5rem'}
              mb={'0.5rem'}
              sx={getStyles(4, selectedView)}
              onClick={() => {
                navigate('/training/advancedTraining/basic');
              }}
            >
              Basic Approvals
            </Box>
            <Box
              className="settings-nav-item"
              my={'0.1rem'}
              sx={getStyles(5, selectedView)}
              onClick={() => {
                navigate('/training/advancedTraining/intermediate');
              }}
            >
              Intermediate Approvals
            </Box>
            <Box
              className="settings-nav-item"
              my={'0.1rem'}
              sx={getStyles(6, selectedView)}
              onClick={() => {
                navigate('/training/advancedTraining/advanced');
              }}
            >
              Advanced Approvals
            </Box>
          </Box>
        )}
        <Box className="settings-nav-item no-hover" mb={'0.1rem'} sx={getStyles(7, selectedView)} onClick={() => {}}>
          Grant Approvals
          <Box
            className="settings-nav-item"
            mt={'1.5rem'}
            mb={'0.5rem'}
            sx={getStyles(8, selectedView)}
            onClick={() => {
              navigate('/grant/approvals/skipped');
            }}
          >
            Intermediate to Grant
          </Box>
          <Box
            className="settings-nav-item"
            my={'0.1rem'}
            sx={getStyles(9, selectedView)}
            onClick={() => {
              navigate('/grant/approvals/regular');
            }}
          >
            Advanced to Grant
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

ApprovalsNav.propTypes = {
  selectedView: propTypes.number,
};

export default ApprovalsNav;
