/*eslint-disable*/
import PieChart from './components/PieChart';
import React from 'react';
import HorizontalBarChart from './components/HorizontalBarChart';
import MapComponent from './components/MapComponent';
import { useGetDisbursementDashboard } from './components/services/useGetDisbursementDashboard';
import { TrainingDashboardRoot, DashboardComponent } from './components/styled/trainingdashboard.styledcomponents';
import DisbursementStageSwitcher from './components/DisbursementStageSwitcher';
import MultiSelectButton from '../Buttons/MultiSelectButton';
import TotalCard from './Cards/TotalCard';
import TotalCardFinal from './Cards/TotalCardFinal';

const DisbursementDashboard = () => {
  const {
    ageDistributionChartData,
    disabilityChartData,
    genderChartData,
    educationalLevelChartData,
    sectorChartData,
    _userData,
    disbursementStage,
    setDisbursementStage,
    disbursementRegional,
    disbursementValueRegional,
    disbursementValueDisability,
    disbursementValueGender,
    regionalDistributionData,
  } = useGetDisbursementDashboard();

  return (
    <TrainingDashboardRoot container rowSpacing={2} columnSpacing={2}>
      <DashboardComponent item xs={12} sm={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
        <TotalCardFinal value={_userData?.total_applicants_disbursed} title="Total Number of Disbursals" />

        {/* <MultiSelectButton labels={[{label:'Post-Disbursement',value:'approved_application_grant_post_disbursement'}]} selected={disbursementStage} onChange={setDisbursementStage}/> */}
        {/* <DisbursementStageSwitcher currentStage={disbursementStage} setStage={setDisbursementStage} /> */}
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
        <TotalCardFinal value={_userData?.total_amount_disbursed} title="Total Value of Disbursals" />
      </DashboardComponent>

      <DashboardComponent item xs={12} sm={12} md={6}>
        <PieChart title={genderChartData.title} data={genderChartData?.data} />
      </DashboardComponent>
      {/* <DashboardComponent item xs={12} sm={12} md={4}>
        <MapComponent data={regionalDistributionData.data} title={regionalDistributionData.title} />
      </DashboardComponent> */}
      <DashboardComponent item xs={12} sm={12} md={6}>
        <PieChart title={disabilityChartData.title} data={disabilityChartData?.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={6}>
        <PieChart item_description="Value" title={disbursementValueGender.title} data={disbursementValueGender.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={6}>
        <PieChart
          item_description="Value"
          title={disbursementValueDisability.title}
          data={disbursementValueDisability.data}
        />
      </DashboardComponent>

      <DashboardComponent item xs={12} sm={12} md={12}>
        <HorizontalBarChart title={disbursementRegional.title} data={disbursementRegional.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={12}>
        <HorizontalBarChart title={disbursementValueRegional.title} data={disbursementValueRegional.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={12}>
        <HorizontalBarChart title={educationalLevelChartData.title} data={educationalLevelChartData.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={4}>
        <PieChart title={ageDistributionChartData.title} data={ageDistributionChartData?.data} />
      </DashboardComponent>
      <DashboardComponent item xs={12} sm={12} md={8}>
        <HorizontalBarChart title={sectorChartData.title} data={sectorChartData?.data} />
      </DashboardComponent>
    </TrainingDashboardRoot>
  );
};

export default DisbursementDashboard;
