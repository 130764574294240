import React from "react";
import propTypes from "prop-types";
import NumberWrapper from "../NumberWrapper";

const TotalCard = ({ title, value, icon, backgroundColor, params }) => {
  const style = {
    display: "flex",
    borderRadius: "1rem",
    backgroundColor: "white",
    padding: "1.5rem",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    width: params?.type == "Registrations" ? "50%" : "33%",
  };

  const icon_wrapper_style = {
    borderRadius: "0.5rem",
    padding: "0.3rem",
    backgroundColor: `${backgroundColor}20`,
    marginRight: "0.6rem",
  };

  return (
    <div style={style} className="total-card">
      <div className="left">
        <div className="icon-wrapper" style={icon_wrapper_style}>
          {icon}
        </div>
      </div>
      <div className="right flex flex-c">
        <div className="top" style={{ color: "black", fontWeight: "600" }}>
          {title}
        </div>
        <div className="bottom">
          <NumberWrapper size={"1.5rem"} value={value} />
        </div>
      </div>
    </div>
  );
};

TotalCard.propTypes = {
  title: propTypes.string,
  value: propTypes.number,
  icon: propTypes.element,
  backgroundColor: propTypes.string,
  params: propTypes.object,
};

export default TotalCard;
