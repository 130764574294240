/*eslint-disable*/
import { defaultActiveColor, light_gray } from '@/utils/colors';
import { styled, Grid, Typography, Box, Button } from '@mui/material';
import React from 'react';

export const GrantDetailsRoot = styled('section')(({ theme }) => ({
  height: 'calc(100vh - 70px)',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '3rem',
  paddingRight: '3rem',
  overflowY: 'auto',
}));
export const GrantDetailsHeader = styled('header')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const GrantDetailsHeaderTop = styled('section')(({ theme }) => ({
  borderBottom: '1px solid #e4e4e4',
  paddingBottom: '2rem',
  alignItems: 'center',
  display: 'flex',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export const GrantDetailsHeaderBottom = styled('section')(({ theme }) => ({}));

export const GrantDetailsBottomWrapper = styled('section')(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'hidden',
}));

export const UserInfoWrapper = styled('article')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
}));

export const UserInitialsWrapper = styled('div')(({ theme }) => ({
  fontSize: '2rem',
  color: 'white',
  borderRadius: '50%',
  backgroundColor: ' #ABB3BF',
  alignItems: 'center',
  justifyContent: 'center',
  width: '5rem',
  height: '5rem',
  fontWeight: '600',
  display: 'flex',
}));

export const UserInfoRight = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: '1rem',
  marginRight: '0.8rem',
  alignItems: 'flex-start',
}));

export const UsernameWrapper = styled('div')(({ theme }) => ({
  fontSize: '19px',
  marginRight: '0.6rem',
  marginTop: '0.2rem',
  display: 'flex',
  marginBottom: '0.5rem',
  fontWeight: '600',
}));

export const RatingsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const PercentageText = styled(Typography)(({ theme }) => ({
  fontFamily: 'inherit',
  marginLeft: 'unset',
  fontSize: '12px',
  color: light_gray,
}));

export const GrantDetailsHeaderContainer = styled(Grid)(({ theme }) => ({}));

export const GrantOverviewSectionTitle = styled(Box)(({ theme }) => ({
  fontSize: '1.0rem',
  color: '#2A3B8F',
  borderLeft: '3px solid',
  fontWeight: '700',
  paddingLeft: '0.5rem',
  marginTop: '1rem',
  marginBottom: '3rem',
}));

export const ManualScoringSectionTitle = styled(Box)(({ theme }) => ({
  fontSize: '1.0rem',
  color: '#2A3B8F',
  borderLeft: '3px solid',
  fontWeight: '700',
  paddingLeft: '0.5rem',
  marginTop: '1rem',
  marginBottom: '3rem',
}));

export const GrantOverviewRoot = styled(Box)(({ theme }) => ({
  padding: '1rem',
  overflowY: 'auto',
}));

export const GrantFormSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
}));
export const BusinessPlanSection = styled(Grid)(({ theme }) => ({}));

export const OverviewDefaultField = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1.5rem',
}));

export const OverviewDefaultLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'inherit',
  marginBottom: '0.5rem',
  color: light_gray,
}));

export const OverviewDefaultValue = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: '600',
  overflow: 'auto',
  maxHeight: '150px',
  // paddingRight: '10px',
  borderRadius: '8px',
  backgroundColor: `${defaultActiveColor}17`,
  border: `1px solid ${defaultActiveColor}17`,
  padding: '8px',
}));

export const FormSwitcher = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '2rem',
  width: '100%',
  backgroundColor: 'white',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
}));

export const FormTypeButton = styled(Button)(({ theme }) => ({
  fonFamily: 'inherit',
  textTransform: 'initial',
  borderRadius: '8px',
  fontWeight: '400',
  marginRight: '10px',
}));

export const ManualScoringRoot = styled('section')(({ theme }) => ({
  marginBottom: '2rem',
}));

export const TotalScoresSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ManualScoringSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  marginLeft: '0px',
  marginRight: '0px',
  // flexDirection: 'column',
}));

export const ManualScoringField = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1rem',
  paddingRight: '12px',
  // backgroundColor: `${defaultActiveColor}10`,
  // border: `1px solid ${defaultActiveColor}10`,
  // borderRadius: '8px',
}));

export const ManualScoringLabel = styled(Grid)(({ theme }) => ({
  fontFamily: 'Nunito',
  fontSize: '14px',
  fontWeight: '800',
  lineHeight: '21px',
  letterSpacing: '0px',
  textAlign: 'left',
  marginBottom: '1rem',
}));

export const TotalScoreText = styled(Grid)(({ theme }) => ({
  color: '#1D9649',
  fontWeight: '700',
  fontSize: '20px',
  marginBottom: '20px',
}));

export const ActionAreaWrapper = styled('section')(({ theme }) => ({
  marginLeft: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginTop: '10px',
  },
}));

export const BaseActionButton = (styles: Partial<React.CSSProperties>) =>
  styled(Button)(({ theme }) => ({
    backgroundColor: defaultActiveColor,
    color: 'white',
    fontFamily: 'inherit',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: 'white',
      color: light_gray,
    },
    ':disabled': {
      backgroundColor: '#e0e0e0',
    },
    ...styles,
  }));

export const SecondaryActionButton = BaseActionButton({
  backgroundColor: '#00000010',
  color: defaultActiveColor,
});

export const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: defaultActiveColor,
  color: 'white',
  fontFamily: 'inherit',
  textTransform: 'initial',
  '&:hover': {
    backgroundColor: 'white',
    color: light_gray,
  },
  ':disabled': {
    backgroundColor: '#e0e0e0',
  },
}));

export const ManualScoringBottom = styled(Box)(({ theme }) => ({
  // padding: '8px',
  // backgroundColor: `${defaultActiveColor}10`,
  // border: `1px solid ${defaultActiveColor}10`,
  // borderRadius: '8px',
  // display: 'flex',
  // flexDirection: 'column',
}));

export const ManualScoringFieldRoot = styled(Box)(({ theme }) => ({
  padding: '8px',

  border: `1px solid ${defaultActiveColor}30`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  ':hover': {
    backgroundColor: `${defaultActiveColor}10`,
    cursor: 'pointer',
  },
}));
