import React from "react";
import { IGrantAssessmentBatchContext } from "./types";

export const GrantAssessmentBatchContext = React.createContext<IGrantAssessmentBatchContext>({
    districts:[],
    params:{},
    regions:[     
    ],
    tabValue:0,
    setParams:()=>{},
    setTabValue:()=>{},

})