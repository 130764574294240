/*eslint-disable*/
import { getApplicant, getGrantBusinessExpenditures } from '@/service/Applicants';
import { useGetRegions } from '@/utils/hooks/getRegionDistricts';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { IApplicantDataObject } from '../interfaces/applicantDetails.model';
import { useGetBusinessFormFields } from './useGetBusinessFormFields';
import { IGrantFormField } from '../interfaces/GrantOverview.interface';
import {
  DefaultGrantFormField,
  ActivityTableField,
  FiscalProjectionTableField,
  ExpectedResultsTableField,
  GrantExpenditureTableField,
} from '../components/GrantDetails/GrantOverviewComponents';
import { BASE_CLIENT } from '@/service/apiConfig';

export const useGetGrantAssessmentPrint = () => {
  const { id } = useParams();
  const [selectedApplicantResponseObject, setSelectedApplicantResponseObject] =
    React.useState<Partial<IApplicantDataObject>>();
  const { regions } = useGetRegions();
  const businessFormFieldProps = useGetBusinessFormFields({
    response: selectedApplicantResponseObject || {},
    regions: regions,
  });

  const printForm = (id: number) => {
    if (document !== null) {
      let a = window.open('', '', 'height=500, width=500');

      a.document.write('<html>');
      a.document.write('<head>');
      a.document.write('<link rel="preconnect" href="https://fonts.googleapis.com">');
      // Include other necessary styles and scripts here
      a.document.write(`<style>
        body{
          width:100%;
          height:100vh;
          overflow-y:auto;
        }
      </style>`);
      a.document.write('</head>');

      a.document.write('<body>');

      // Create an iframe and set its source URL
      a.document.write(
        `<iframe src="${BASE_CLIENT}/grantassessment/print/${id}" id="iframe" width="100%" height="100%"></iframe>`,
      );

      a.document.write('</body></html>');

      setTimeout(function () {
        const iframe = a.document.getElementById('iframe') as HTMLIFrameElement;
        iframe?.contentWindow?.print();
        a.document.close();
      }, 5000);
      a.document.close();

      // Wait for the iframe to load before printing
      // a.onload = function () {
      //   const iframe = a.document.getElementById('iframe') as HTMLIFrameElement;
      //   iframe.onload = function () {
      //     // Print the contents of the iframe
      //     iframe?.contentWindow?.print();
      //     a.document.close();
      //   };
      // };
    }
  };

  const isSkipped = selectedApplicantResponseObject?.client_details?.[0]?.skipped_advanced_training == true;

  const { data, isLoading, refetch, isError } = useQuery(
    [id],
    () => {
      if (id) {
        return getApplicant(id);
      }
    },
    { staleTime: Infinity, refetchOnWindowFocus: false },
  );

  const {
    data: expenditureList,
    isLoading: expenditureLoading,
    isRefetching: expenditureRefetching,
  } = useQuery(
    [selectedApplicantResponseObject],
    () => {
      if (selectedApplicantResponseObject) {
        return getGrantBusinessExpenditures(id);
      }
    },
    { refetchOnWindowFocus: false, refetchOnMount: false, refetchOnReconnect: false, staleTime: Infinity },
  );

  const returnDefaultValue = (value: any) => {
    if (value == null || value == undefined) {
      return 'N/A';
    }
    return value;
  };

  const returnFieldComponent = (props: IGrantFormField[]) => {
    return props?.map((field, index) => {
      if (field.shouldShow !== false) {
        if (field.type == 'default' || field.type == 'boolean') {
          return DefaultGrantFormField(true, returnDefaultValue(field.value), field?.label);
        }
        if (field.type == 'activity') {
          return ActivityTableField(field.value, field.label);
        }
        if (field.type == 'fiscal_projection') {
          return FiscalProjectionTableField(field.label, field.value);
        }
        if (field.type == 'performance_indicator') {
          return ExpectedResultsTableField(field.label, field.value);
        }
        if (field.type == 'grants_expenditure') {
          return GrantExpenditureTableField(field.label, expenditureList?.data);
        }
      }
    });
  };

  React.useEffect(() => {
    if (data) {
      setSelectedApplicantResponseObject(data.data.data);
    }
  }, [data]);

  React.useEffect(() => {
    if (isLoading && expenditureLoading) {
      // printForm();
    }
  }, [isLoading, expenditureLoading]);

  return {
    printForm,
    id,
    selectedApplicantResponseObject,
    businessFormFieldProps,
    regions,
    isSkipped,
    isLoading: expenditureLoading || isLoading,
    returnFieldComponent,
  };
};
