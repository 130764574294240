/*eslint-disable*/
import React from 'react';
import { Box } from '@mui/material';
import { RadialChart } from 'react-vis/dist';
import propTypes from 'prop-types';
import NumberWrapper from '../NumberWrapper';
import { DashboardContext } from '../DashboardContent';

const returnDefaultValue = (value) => {
  if (value == undefined || value == null) {
    return 0;
  }
  return value;
};

const Disability = ({ totalApplications, data }) => {
  const [disabledObj, setDisabledObj] = React.useState({});
  const [totalApplicants, setTotalApplicants] = React.useState(0);
  const [isHovering, setIsHovering] = React.useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  React.useEffect(() => {
    if (data) {
      const { disabled, total_completed_applications } = data;
      setDisabledObj(disabled);
      setTotalApplicants(total_completed_applications);
    }
  }, [data]);

  console.log(
    (
      (returnDefaultValue(disabledObj?.total_male_applicants) +
        returnDefaultValue(disabledObj?.total_female_applicants) / totalApplicants) *
      100
    ).toString(2),
  );

  const getAngle = (total, categorySize) => {
    let angle = Math.floor((categorySize / total) * 360);
    return angle;
  };

  const dummyData = [
    {
      angle: getAngle(
        totalApplicants,
        returnDefaultValue(disabledObj?.total_male_applicants) +
          returnDefaultValue(disabledObj?.total_female_applicants),
      ),
      label: `Disabled %${(
        (returnDefaultValue(disabledObj?.total_male_applicants) +
          returnDefaultValue(disabledObj?.total_female_applicants) / totalApplicants) *
        100
      ).toString(2)}`,
      style: { stroke: 'rgb(64, 61, 160)', fill: 'rgb(64, 61, 160)' },
    },
    {
      angle: getAngle(
        totalApplicants,
        returnDefaultValue(totalApplicants) -
          (returnDefaultValue(disabledObj?.total_male_applicants) +
            returnDefaultValue(disabledObj?.total_female_applicants)),
      ),
      label: `Not Disabled: %${(
        ((returnDefaultValue(totalApplicants) -
          (returnDefaultValue(disabledObj?.total_male_applicants) +
            returnDefaultValue(disabledObj?.total_female_applicants))) /
          totalApplicants) *
        100
      )?.toFixed(2)}`,
      style: { stroke: 'rgb(255 50 21)', fill: 'rgb(255 50 21)' },
    },
  ];
  return (
    <Box onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <RadialChart
        data={dummyData}
        width={300}
        height={300}
        innerRadius={130}
        radius={80}
        // showLabels={isHovering}
        labelsStyle={{
          fill: 'red',
          dominantBaseline: 'middle',
          textAnchor: 'middle',
        }}
      ></RadialChart>
    </Box>
  );
};

const DisabilityChart = () => {
  const [isHovering, setIsHovering] = React.useState(false);
  const { userData } = React.useContext(DashboardContext);
  const style = {
    display: 'flex',
    padding: '1.2rem',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '1rem',
    marginTop: '1.5rem',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    width: '50%',
    marginLeft: '1rem',
  };

  const keyText = {
    fontSize: '13px',
    fontWeight: '600',
  };

  const percentText = {
    fontSize: '13px',
    fontWeight: '600',
  };

  const handleOnEnter = () => {
    setIsHovering(true);
  };

  const handleOnLeave = () => {
    setIsHovering(false);
  };

  return (
    <div className="gender-distribution" style={style}>
      <div className="card-title">Applicants By Disability Status</div>
      <div className="bottom flex w-100" onMouseEnter={handleOnEnter} onMouseLeave={handleOnLeave}>
        <Disability totalApplications={100} data={userData} />
        <div className="total-description flex flex-c">
          <div className="top">Total Applicants</div>
          <div className="bottom">{returnDefaultValue(userData?.total_completed_applications)}</div>
        </div>
        {isHovering && (
          <div className="flex flex-c" style={{ position: 'absolute', left: '10px', top: '50%' }}>
            <div>
              <span style={percentText}>Disabled: %</span>{' '}
              {(
                ((returnDefaultValue(userData?.disabled?.total_male_applicants) +
                  returnDefaultValue(userData?.disabled?.total_female_applicants)) /
                  userData?.total_completed_applications) *
                100
              ).toFixed(2)}
            </div>
            <div>
              <span style={percentText}>Not Disabled: %</span>{' '}
              {(
                ((returnDefaultValue(userData?.total_completed_applications) -
                  (returnDefaultValue(userData?.disabled?.total_male_applicants) +
                    returnDefaultValue(userData?.disabled?.total_female_applicants))) /
                  userData?.total_completed_applications) *
                100
              ).toFixed(2)}
            </div>
          </div>
        )}
        <div className="distribution-key flex">
          <div style={keyText}>
            <span className="key-color" style={{ backgroundColor: 'rgb(64, 61, 160)' }}></span>
            Disabled (
            <NumberWrapper
              value={
                returnDefaultValue(userData?.disabled?.total_male_applicants) +
                returnDefaultValue(userData?.disabled?.total_female_applicants)
              }
              size={'0.8rem'}
            />
            )
          </div>
          <div style={keyText}>
            <span className="key-color" style={{ backgroundColor: 'rgb(255 50 21)' }}></span>
            Non Disabled (
            <NumberWrapper
              value={
                returnDefaultValue(userData?.total_completed_applications) -
                (returnDefaultValue(userData?.disabled?.total_male_applicants) +
                  returnDefaultValue(userData?.disabled?.total_female_applicants))
              }
              size={'0.8rem'}
            />
            )
          </div>
        </div>
      </div>
    </div>
  );
};

DisabilityChart.propTypes = {
  userData: propTypes.object,
};

Disability.propTypes = {
  totalApplications: propTypes.number,
  data: propTypes.object,
};

export default DisabilityChart;
