import React from 'react';
import { useGetGrantAssessmentPrint } from './hooks/useGetGrantAssessmentPrint';
import { defaultActiveColor } from '@/utils/constants';
import { Box, Grid } from '@mui/material';
import { BusinessPlanSection, GrantOverviewSectionTitle } from './components/GrantDetails/GrantDetails.components';
import { CircularProgress } from '@mui/material';
import { DefaultButton } from '../Buttons/ButtonFullWidth';

const GrantAssessmentPrint = () => {
  const {
    id,
    businessFormFieldProps,
    isSkipped,
    isLoading,
    selectedApplicantResponseObject,
    printForm,
    returnFieldComponent,
  } = useGetGrantAssessmentPrint();

  return (
    <BusinessPlanSection id="business_plan_form" container style={{ overflowY: 'auto', height: '100%' }}>
      {isLoading && (
        <Box width={'100%'}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <DefaultButton
              text="Print Form"
              onClick={() => {
                printForm(parseInt(id));
              }}
            />
            <Box sx={{ textAlign: 'center', width: '100%', marginBottom: '20px', marginTop: '15px' }}>
              <Box sx={{ fontSize: '18px', marginBottom: '10px', fontWeight: '700', color: defaultActiveColor }}>
                Ghana Jobs and Skills Project
              </Box>
              <Box sx={{ fontSize: '15px', marginBottom: '10px' }}>
                <span style={{ fontWeight: '700' }}>Name</span>:{' '}
                {`${selectedApplicantResponseObject?.overview?.first_name || 'No Applicant Selected'} ${
                  selectedApplicantResponseObject?.overview?.last_name || ''
                }`}
              </Box>
              <Box sx={{ fontSize: '15px' }}>
                <span style={{ fontWeight: '700' }}>GJSP Application Code</span>:{' '}
                {`${selectedApplicantResponseObject?.client_details?.[0]?.project_application_code}`}
              </Box>
            </Box>

            <GrantOverviewSectionTitle as="h1">Application Summary</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.application_summary_fields)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Applicant Information</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.applicant_information_fields)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Applicant Information II</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.applicant_information_2_fields)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Applicant Information III</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.applicant_information_3_fields)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Applicant Information IV</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.applicant_information_4_fields)}
            </Grid>
          </Box>
          {!isSkipped && (
            <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
              <GrantOverviewSectionTitle>Project Information</GrantOverviewSectionTitle>
              <Grid container columnSpacing={1}>
                {returnFieldComponent(businessFormFieldProps?.project_information_fields)}
              </Grid>
            </Box>
          )}

          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Project Information II</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.project_information_2_fields)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Project Information III</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.project_information_3_fields)}
            </Grid>
          </Box>
          {!isSkipped && (
            <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
              <GrantOverviewSectionTitle>Project Information IV</GrantOverviewSectionTitle>
              <Grid container columnSpacing={1}>
                {returnFieldComponent(businessFormFieldProps?.project_information_4_fields)}
              </Grid>
            </Box>
          )}

          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Grant Request</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.grant_request_fields)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Grant Request II</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.grant_request_fields_2)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>Expected Results</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.expected_results)}
            </Grid>
          </Box>
          <Box className="html2pdf__page-break" sx={{ width: '100%' }}>
            <GrantOverviewSectionTitle>{!isSkipped ? 'Sustainability' : 'Other Issues'}</GrantOverviewSectionTitle>
            <Grid container columnSpacing={1}>
              {returnFieldComponent(businessFormFieldProps?.sustainability_fields)}
            </Grid>
          </Box>
        </>
      )}
    </BusinessPlanSection>
  );
};

export default GrantAssessmentPrint;
