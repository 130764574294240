/*eslint-disable */
import React, { useEffect, useState } from 'react';
import ApprovalsNav from './ApprovalsNav';
import { Box, Button, CircularProgress, Grid, Typography, Stack, Divider, FormLabel,Tab,Tabs } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import SearchInput from '@components/SearchInput';
import { useNavigate } from 'react-router-dom';
import { AvatarInitial } from '@utils/Logo';
import { getApplicantApprovals, approveApplicant } from '@service/Applicants';
import AlertDialog from '@components/Modals';
import { messageAlert } from '@utils/helpers/functions';
import { DefaultButton } from '@components/Buttons/ButtonFullWidth';
import { useGetRegions, useGetDistricts } from '@utils/hooks/getRegionDistricts';
import FilterListIcon from '@mui/icons-material/FilterList';
import useGetUserData from '@utils/hooks/useGetUserData';
import { checkPermissions } from '@service/RequireAuth';
import TableLoaders from '@components/Loaders/TableLoaders';
import { handleErrorMessage } from '@utils/helpers/functions';
import FilterComponent from './FilterComponent';
import ExpandButton from '../Buttons/ExpandButton';
import ApprovalsBaseLayout from './ApprovalsBaseLayout';

const ApplicatsApproval = () => {
  // const { data,isLoading } = useQuery(['page',1], () => getPaginatedApplicants(1, 10));
  const type = 'applicants';
    const [tabValue, setTabValue] = React.useState(0);
  const userData = JSON.parse(window.localStorage.getItem('user'));
  const [searchQuery, setSearchQuery] = useState('');
  let [applicantData, setApplicantData] = useState({
    page: 0,
    count: 1,
    data: [],
  });
  const [_isLoading, setIsLoading] = useState(false);
  const [params, setParams] = React.useState({
    region: '',
    district: '',
    gender: '',
    disability: '',
    score_upper_limit: '',
    score_lower_limit: '',
    educational_level: '',
    vocational_skill: '',
    age_upper_limit: '',
    age_lower_limit: '',
    q: '',
  });
  const navigate = useNavigate();

  const [selectedRegion, setSelectedregion] = React.useState('');
  const [tableData, setTableData] = React.useState([]);

  const { regions } = useGetRegions();
  const { districts } = useGetDistricts(regions, selectedRegion);
  const [remarks, setRemarks] = useState('');

  const fetchApplicants = async () => {
    //
    setIsLoading(true);
    try {
      if (_isLoading == false) {
        //
        const response = await getApplicantApprovals(
          applicantData?.page + 1,
          10,
          params?.region == '' ? userData?.profile?.region?.id : params?.region,
          params?.district == '' ? userData?.profile?.district?.id : params?.district,
          params.gender,
          params.disability,
          params.score_upper_limit,
          params.score_lower_limit,
          params?.educational_level,
          params?.vocational_skill,
          params?.age_lower_limit,
          params?.age_upper_limit,
          params.q,
        );
        if (response?.data) {
          // setApplicantData({...applicantData, isLoading:false})
          getTableData(response?.data);
        }
      }
    } catch (error) {

    }
    setIsLoading(false);
  };

  const checkSelected = (id) => {
    if (params.district == '') {
      return id == userData?.profile?.district?.id;
    } else {
      return id == params?.district;
    }
  };

  useEffect(() => {
    let status = checkPermissions('applicant approvals', userData);
    if (status == false) {
      navigate('/404');
    }
    try {
      let region = userData?.profile?.region?.id;
      let district = userData?.profile?.district?.id;

      setSelectedregion(region);
      setParams({ ...params, region: region, district: district });
    } catch (e) {

      // let region = '';
      // let district = '';
      // setParams({...params,region:region,district:district});
    }
  }, []);

  useEffect(() => {
    setParams({ ...params, q: searchQuery });
  }, [searchQuery]);

  useEffect(() => {
    fetchApplicants();
  }, [applicantData?.page]);

  useEffect(() => {
    try {
      if (_isLoading == false) {
        //
        fetchApplicants();
        //
      }
    } catch (e) {
      handleErrorMessage(e);
    }
  }, []);

  const getTableData = (data) => {
    //
    //

    let _tableData = [];

    data?.data?.forEach((applicant) => {
      let { applicant_score, user } = applicant;
      let tableRow = [`${user?.first_name} ${user?.last_name}`, user?.phone_number, applicant_score, user?.id];
      _tableData.push(tableRow);
    });
    setApplicantData({
      ...applicantData,
      count: data?.count,
      data: _tableData,
    });
  };

  //Region Districts

  function setRegion(e) {
    if (e.target.value === '') {
      setParams({ ...params, region: '' });
      setSelectedregion('');
      return;
    }

    setParams({ ...params, region: parseInt(e.target.value) });
    setSelectedregion(parseInt(e.target.value));
  }
  //

  const getDefaultValue = (value) => {
    if (value) {
      return value;
    }
    return 'N/A';
  };

  const responsive = 'vertical';
  const tableBodyHeight = `${window.innerHeight}px`;
  const tableBodyMaxHeight = '';

  const tableOptions = {
    fixedHeader: true,
    print: false,
    search: false,
    viewColumns: false,
    download: false,
    filter: false,
    filterType: 'checkbox',
    selectableRows: 'multiple',
    page: applicantData?.page,
    responsive,
    serverSide: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    count: applicantData?.count,
    tableBodyHeight,
    tableBodyMaxHeight,
    searchText: searchQuery,
    fixedFooter: true,
    sortOrder: {
      name: 'Role',
      direction: 'asc',
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setApplicantData({ ...applicantData, page: tableState?.page });
      }
    },
    customSearchRender: () => null,
    customToolbarSelect: () => null,

    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const dataToState = rowsSelected.map((item) => {
        return item;
      });
      setTableData(getSelectedRows(dataToState));
    },
  };

  const getSelectedRows = (rowArray) => {
    try {
      let rowDataArray = [];
      rowArray.forEach((rowIndex) => {
        let _applicantData = applicantData?.data[rowIndex];
        rowDataArray.push(_applicantData[3]); //this index is where the id is stored
      });
      return rowDataArray;
    } catch (e) {

      return [];
    }
  };

  const columns = [
    {
      name: 'Name',
      options: {
        filter: true,
        customBodyRender: (value) => <AvatarInitial text={value || ''} />,
      },
    },
    'Phone Number',
    {
      name: 'Applicant Score (%)',
      options: {
        filter: true,
        customBodyRender: (value) => (
          <div style={{ color: '#1D9649', fontWeight: 'bold' }}>{getDefaultValue(value)}%</div>
        ),
      },
    },
    {
      name: 'Action',
      options: {
        filter: true,
        customBodyRender: (value) => (
          <Button
            sx={{ textTransform: 'initial' }}
            onClick={() => {
              navigate(`/applicants/${value}/${type}`);
            }}
          >
            View
          </Button>
        ),
      },
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [openDisq, setOpenDisq] = React.useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenDisqModal = () => {
    setOpenDisq(true);
  };

  const handleCloseDisqModal = () => {
    setOpenDisq(false);
  };

  const submitApproval = async (status, type, success) => {
    let approvalData = {
      id_list: tableData.map((item)=>{
        return {id:item}
      }),
      approval_status: status,
      approval_type: type,
    };
    setIsLoading(true);

    try {
      await approveApplicant(approvalData);
      messageAlert(status == 'approved' ? 'success' : 'error', success);
      await fetchApplicants();
    } catch (e) {
      let message = handleErrorMessage(e);
      messageAlert('error', message);
      setIsLoading(false);
    }
  };

  const remarksComponent = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>Are you sure you want to approve Applicant(s) for interview ?</Box>
      </Box>
    );
  };

  const ApproveButton = () => {
    return (
      <Button
        sx={{
          '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
          color: 'white',
          backgroundColor: '#2a3b8f',
          textTransform: 'initial !important',
          border: '1px solid #2a3b8f',
          fontFamily: 'inherit',
        }}
        onClick={() => {
          submitApproval('approved', 'applicant_approval', 'Applicant Approved');
          setOpen(false);
        }}
      >
        Approve
      </Button>
    );
  };

  const DisqButton = () => {
    return (
      <Button
        sx={{
          '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
          color: 'white',
          backgroundColor: '#2a3b8f',
          textTransform: 'initial !important',
          border: '1px solid #2a3b8f',
          fontFamily: 'inherit',
        }}
        onClick={() => {
          submitApproval('disqualified', 'applicant_approval', 'Applicant Disqualified');
          setOpenDisq(false);
        }}
      >
        Reject
      </Button>
    );
  };

  document.body.style.cssText = 'overflow-y:hidden!important';

  return (
    <ApprovalsBaseLayout selectedView={0}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box>
          <ExpandButton />
        </Box>

        <Box sx={{ fontSize: '1.2rem', fontWeight: '600' }}>
          Application Approvals
          {/* {_isLoading && (
                                <CircularProgress
                                size={24}
                                style={{ marginLeft: 15, position: "relative", top: 4 }}
                                />
                            )} */}
        </Box>
        <Box sx={{ order: '1', marginLeft: 'auto', display: 'flex' }}>
          <Box
            sx={{
              fontSize: '1.2rem',
              fontWeight: '600',
              marginBottom: '2rem',
              marginRight: '1rem',
            }}
          >
            <DefaultButton
              color="#FF4B55"
              text="Reject"
              onClick={() => {
                handleOpenDisqModal();
              }}
              disabled={tableData?.length < 1}
            />
          </Box>
          <Box
            sx={{
              fontSize: '1.2rem',
              fontWeight: '600',
              marginBottom: '2rem',
            }}
          >
            <DefaultButton
              text="Approve For Interview"
              onClick={() => {
                handleOpenModal();
              }}
              disabled={tableData?.length < 1}
            />
          </Box>
        </Box>
      </Box>

      {/* <Tabs
            className="custom-tabs"
            value={tabValue}
            onChange={(e,value)=>{setTabValue(value)}}
            style={{ borderBottom: '0.5px solid #EBEFF2' }}
            aria-label="basic tabs example"
            sx={{marginBottom:'15px'}}
          >
            <Tab label="Application Approvals" value={0} />
            <Tab label="Batch Backtracking"/>
          </Tabs> */}
      <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
        <Grid item xs={4}>
          <SearchInput placeholder={'Enter Name or Phone Number'} setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item xs={8} sx={{ marginBottom: '2rem' }}>
          <Box>
            <Stack direction="row" justifyContent="end">
              <Box mt={1} fontSize={15} mx={2}>
                <Typography sx={{ fontFamily: 'inherit' }}>Score Range:</Typography>
              </Box>

              <input
                placeholder={'Lower Mark'}
                min={0}
                type="number"
                className="range-input"
                style={{
                  width: '20%',
                  padding: '0.8rem',
                  border: '1px solid #EEF1F3',
                }}
                onChange={(e) => {
                  setParams({ ...params, score_lower_limit: e.target.value });
                }}
              />
              <Box mt={2}>
                {' '}
                <hr
                  style={{
                    width: '1rem',
                    marginLeft: '0.5rem',
                    marginRight: '0.5rem',
                  }}
                />
              </Box>
              <input
                placeholder={'Upper Mark'}
                min={0}
                type="number"
                className="range-input"
                style={{
                  width: '20%',
                  padding: '0.8rem',
                  border: '1px solid #EEF1F3',
                }}
                onChange={(e) => {
                  setParams({ ...params, score_upper_limit: e.target.value });
                }}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>

      {/* <Box mt={4} mb={4}><Divider  variant="middle" /></Box> */}

      {/* <Grid container spacing={1} sx={{paddingTop:'2rem',borderTop:'1px solid #e6e6e6',display:'flex',alignItems:'flex-end',justifyContent:'flex-end'}} >
                <Grid item xs={2} sx={{display:'flex,',flexDirection:'column'}}>
                    <FormLabel sx={{fontFamily:'inherit',marginBottom:'0.1rem',fontWeight:'600'}}>Gender</FormLabel>
                    <select className=" filter-select" onChange={(e)=>{setParams({...params,gender:e.target.value})}}>
                        <option value={''}>All</option>
                        <option value={'M'}>Male</option>
                        <option value={'F'}>Female</option>
                    </select>
                </Grid>
                <Grid item xs={2} sx={{display:'flex,',flexDirection:'column'}}>
                <FormLabel sx={{fontFamily:'inherit',marginBottom:'0.1rem',fontWeight:'600'}}>Disability</FormLabel>
                    <select className="filter-select" name="disability" onChange={(e)=>{setParams({...params,disability:e.target.value})}}>
                        <option value={''}>All</option>
                        <option value={'yes'}>Show disabled Applicants</option>
                        <option value={'no'}>Hide disabled Applicants</option>
                    </select>
                </Grid>
                <Grid item xs={3} sx={{display:'flex,',flexDirection:'column'}}>
                <FormLabel sx={{fontFamily:'inherit',marginBottom:'0.1rem',fontWeight:'600'}}>Region</FormLabel>
                    <select name="region" className="filter-select"  onChange={setRegion}
                     disabled={userData?.staff_type == 'bac' || userData?.staff_type == 'bac_regional_head'}>
                        <option value={''}>All</option>
                        {regions?.map((region, idx) => 
                        (<option
                            selected={region.id === selectedRegion}
                            key={`${idx} - ${region.name}`} 
                            value={region.id}>
                                {region.name}
                        </option>
                        ))}
                    </select>
                </Grid>
                <Grid item xs={3} sx={{display:'flex,',flexDirection:'column'}}>
                <FormLabel sx={{fontFamily:'inherit',marginBottom:'0.1rem',fontWeight:'600'}}>District</FormLabel>
                    <select name="district" className="filter-select" onChange={(e)=>{setParams({...params,district:e.target.value})}} 
                    disabled={userData?.staff_type == 'bac'}
                    >
                        <option value={''}>All</option>
                        {districts?.map((district, idx) => 
                            (<option 
                                selected = {checkSelected(district?.id)}
                                key={`${idx} - ${district.name}`} 
                                value={district.id}>
                                    {district.name}
                                </option>)
                            )}
                    </select>
                </Grid>
                <Grid item xs = {2}>
                    <Box>
                        <Button sx={{'&:hover':{color:'#2a3b8f'},fontWeight:'400',fontFamily:'inherit !important',fontSize:'0.95rem',color:'white',backgroundColor:'#2a3b8f',textTransform:'initial !important',
                        width:'100%'}} onClick={fetchApplicants} >
                            Filter 
                        </Button>
                    </Box>
                </Grid>
                           
            </Grid> */}
      <FilterComponent
        checkSelected={checkSelected}
        setParams={setParams}
        userData={userData}
        regions={regions}
        districts={districts}
        fetchApplicants={fetchApplicants}
        params={params}
        setRegion={setRegion}
        selectedRegion={selectedRegion}
      />

      <Box className="custom-data-table" my={'2rem'} sx={{ width: '100%', height: '100%' }}>
        {_isLoading && <TableLoaders />}

        {_isLoading == false && (
          <>
            <MUIDataTable options={tableOptions} columns={columns} data={applicantData?.data} />
            {open && (
              <AlertDialog
                open={open}
                handleCloseModal={() => handleCloseModal(false)}
                title="Confirm Approval"
                body={remarksComponent()}
                actionButton={<ApproveButton />}
              />
            )}
            {openDisq && (
              <AlertDialog
                open={openDisq}
                handleCloseModal={() => handleCloseDisqModal(false)}
                title="Confirm Disqualification"
                body={`Are you sure you want to reject Applicant(s) for interview ?`}
                actionButton={<DisqButton />}
              />
            )}
          </>
        )}
      </Box>
    </ApprovalsBaseLayout>
  );
};

export default ApplicatsApproval;
