/*eslint-disable */
import React from 'react';
import { Box, Tab, Tabs, Modal, Button, FormLabel, Grid } from '@mui/material';
import ReportsMain from '../BaseLayout/ReportsMain';
import Title from '../Title';
import moment from 'moment';
import addDays from 'date-fns/addDays';
import { DateRangePicker } from 'react-date-range';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SearchInput from '@components/SearchInput';
import MUIDataTable from 'mui-datatables';
import { useNavigate } from 'react-router-dom';
import FilterRow from './FilterRow';
import { useGetDataAnalysisData } from '@utils/hooks/useGetDashboardData';
import { useQueryClient, useQuery } from 'react-query';
import UserSignUp from '@service/UserSignUp';
import { formatNumbers } from '@utils/helpers/functions';
import Table from 'rc-table';
import Logo from '@utils/Logo';
import { handleErrorMessage, messageAlert } from '@utils/helpers/functions';
import TableLoaders from '@components/Loaders/TableLoaders';

const DataAnalysis = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    overflowY: 'auto',
    marginBottom: '4rem',
  };
  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const selectStyle = {
    width: '200px',
    height: '100%',
    color: 'black',
    fontWeight: '600',
    fontFamily: 'inherit',
    padding: '0.5rem',
    backgroundColor: 'white',
  };

  const buttonStyle = {
    '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
    fontWeight: '400',
    fontFamily: 'inherit !important',
    fontSize: '0.85rem',
    color: 'white',
    backgroundColor: '#2a3b8f',
    textTransform: 'initial !important',
    padding: '0.7rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  };
  const dateRangeStyle = {
    padding: '0.7rem',
    color: 'black',
    backgroundColor: 'white',
    border: '1px solid #e1e1e1',
    borderRadius: '0.8rem',
    fontSize: '0.9rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };

  const cellStyle = {
    padding: '1rem',
    fontSize: '1rem',
  };

  const cellHeaderStyle = {
    padding: '1rem',
    fontSize: '1.2rem',
    color: '#2a3b8f',
    backgroundColor: 'white',
    fontWeight: '700',
  };

  const cellHeaderStyleText = {
    padding: '1rem',
    fontSize: '1rem',
    color: 'white',
    backgroundColor: 'white',
  };
  const queryClient = useQueryClient();
  const [dateModalOpen, setDateModalOpen] = React.useState(false);
  const [params, setParams] = React.useState({
    type: 'Registration',
    time_upper_limit: moment().format('YYYY-MM-DD'),
    time_lower_limit: moment().add('7', 'days').format('YYYY-MM-DD'),
  });
  const navigate = useNavigate();
  const [isRefreshing, setIsRefeshing] = React.useState(false);
  const { data, error, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['dataAnalysis'],
    queryFn: () => {
      let gap = Math.abs(moment(params?.time_lower_limit).diff(moment(params?.time_upper_limit), 'days'));
      if (gap > 7) {
        messageAlert('error', 'You cannot select more than 7 days');
        return null;
      }
      return UserSignUp?.getDataAnalysis(params);
    },
    enabled: false,
    refetchOnWindowFocus: false,
  });
  const [_tableData, setTableData] = React.useState([]);
  const [tableSearchQuery, setTableSearchQuery] = React.useState([]);
  const tableColumns = [
    {
      title: 'Metric',
      dataIndex: 'metric',
      key: 'metric',

      render: (value) => {
        if (value == '' || value.includes('Breakdown')) {
          return (
            <Box className="cell-header" style={cellHeaderStyle}>
              {value}
            </Box>
          );
        }
        return <Box style={cellStyle}>{value}</Box>;
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',

      render: (value) => {
        if (value == '') {
          return (
            <Box className="cell-header" style={cellHeaderStyleText}>
              {'.'}
            </Box>
          );
        }
        return <Box style={cellStyle}>{value}</Box>;
      },
    },
    {
      title: 'Percentage (%)',
      dataIndex: 'percentage',
      key: 'value',

      render: (value) => {
        if (value == '') {
          return (
            <Box className="cell-header" style={cellHeaderStyleText}>
              {'.'}
            </Box>
          );
        }
        return <Box style={cellStyle}>{value}</Box>;
      },
    },
  ];

  const changeDateParams = (dateValues) => {
    setDateState(dateValues);
    let _time_upper_limit = moment(dateValues[0].startDate).format('YYYY-MM-DD');
    let _time_lower_limit = moment(dateValues[0].endDate).format('YYYY-MM-DD');
    setParams({
      ...params,
      time_upper_limit: _time_lower_limit,
      time_lower_limit: _time_upper_limit,
    });
  };
  const [dateState, setDateState] = React.useState([
    {
      startDate: new Date(moment().format('YYYY-MM-DDThh:mm:ssZ')),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  const runFetch = async () => {
    // setIsRefeshing(true);

    if (!isFetching) {
      refetch();
    }
    // setIsRefeshing(false);
  };

  React.useEffect(() => {
    document.body.style.cssText = 'overflow-y:hidden!important';
  }, []);

  React.useEffect(() => {
    if (data) {
      let tableData = [];
      let totalApplications = data?.data?.total_completed_applications;
      let totalRegistrations = data?.data?.total_registrations;
      let skillsArray = Object?.keys(data?.data?.skills);
      let genderArray = Object?.keys(data?.data?.genders);
      let regionArray = Object?.keys(data?.data?.regions);
      let sectorArray = Object?.keys(data?.data?.sectors);
      let disabledArray = Object?.keys(data?.data?.disabled);
      let ageGroups = Object?.keys(data?.data?.age_groups);
      let educationalLevelArray = Object?.keys(data?.data?.educational_levels);

      tableData.push({
        metric: 'Total Applications',
        value: `${formatNumbers(totalApplications)}`,
        percentage: `${formatNumbers((totalApplications / totalRegistrations) * 100)}`,
      });

      genderArray?.forEach((genderKey) => {
        let { gender, total_applicants, total_registrants } = data?.data?.genders?.[genderKey];
        tableData.push({
          metric: `Total ${gender == 'M' ? 'Male' : 'Female'} Applicants`,
          value: `${formatNumbers(total_applicants)}`,
          percentage: `${formatNumbers((total_applicants / totalApplications) * 100)}`,
        });
        tableData.push({
          metric: `Total ${gender == 'M' ? 'Male' : 'Female'} Registrants`,
          value: `${formatNumbers(total_registrants)}`,
          percentage: `${formatNumbers((total_registrants / totalRegistrations) * 100)}`,
        });
      });

      tableData.push({
        metric: 'Regional Breakdown',
        value: false,
        percentage: false,
      });

      regionArray?.forEach((region) => {
        let {
          total_amount_requested,
          total_female_applicants,
          total_female_registrants,
          total_male_applicants,
          total_male_registrants,
          region: regionName,
        } = data?.data?.regions?.[region];
        tableData.push({
          metric: `Total Registrants (${regionName})`,
          value: `${formatNumbers(total_male_applicants + total_female_applicants)}`,
          percentage: `${formatNumbers(((total_male_applicants + total_female_applicants) / totalApplications) * 100)}`,
        });
        tableData.push({
          metric: `Total Applicants (${regionName})`,
          value: `${formatNumbers(total_male_registrants + total_female_registrants)}`,
          percentage: `${formatNumbers(
            ((total_male_registrants + total_female_registrants) / totalRegistrations) * 100,
          )}`,
        });
      });

      tableData.push({
        metric: 'Sector Breakdown',
        value: false,
        percentage: false,
      });

      sectorArray?.forEach((sectorKey) => {
        let { total_male_applicants, total_female_applicants } = data?.data?.sectors?.[sectorKey];
        tableData.push({
          metric: `Total Applicants (${sectorKey})`,
          value: `${formatNumbers(total_male_applicants + total_female_applicants)}`,
          percentage: `${formatNumbers(((total_male_applicants + total_female_applicants) / totalApplications) * 100)}`,
        });
      });

      tableData.push({
        metric: 'Skills Breakdown',
        value: false,
        percentage: false,
      });

      skillsArray?.forEach((skill) => {
        let { vocational_skill: label, total_male_applicants, total_female_applicants } = data?.data?.skills?.[skill];
        tableData.push({
          metric: `Total Male Applicants (${label})`,
          value: `${formatNumbers(total_male_applicants)}`,
          percentage: `${formatNumbers((total_male_applicants / totalApplications) * 100)}`,
        });
        tableData.push({
          metric: `Total Female Applicants (${label})`,
          value: `${formatNumbers(total_female_applicants)}`,
          percentage: `${formatNumbers((total_female_applicants / totalApplications) * 100)}`,
        });
      });

      tableData.push({
        metric: 'Disability Breakdown',
        value: false,
        percentage: false,
      });
      disabledArray?.forEach((key) => {
        let { total_male_applicants, total_female_applicants } = data?.data?.disabled?.[key];
        tableData.push({
          metric: `Total Applicants (${key})`,
          value: `${formatNumbers(total_male_applicants + total_female_applicants)}`,
          percentage: `${formatNumbers(((total_male_applicants + total_female_applicants) / totalApplications) * 100)}`,
        });
      });

      tableData.push({
        metric: 'Age Distribution Breakdown',
        value: false,
        percentage: false,
      });
      ageGroups?.forEach((key) => {
        let { total_male_applicants, total_female_applicants, total_male_registrants, total_female_registrants } =
          data?.data?.age_groups?.[key];
        tableData.push({
          metric: `Total Applicants (${key})`,
          value: `${formatNumbers(total_male_applicants + total_female_applicants)}`,
          percentage: `${formatNumbers(((total_male_applicants + total_female_applicants) / totalApplications) * 100)}`,
        });
        tableData.push({
          metric: `Total Registrants (${key})`,
          value: `${formatNumbers(total_male_registrants + total_female_registrants)}`,
          percentage: `${formatNumbers(
            ((total_male_registrants + total_female_registrants) / totalRegistrations) * 100,
          )}`,
        });
      });

      tableData.push({
        metric: 'Educational Level Breakdown',
        value: false,
        percentage: false,
      });

      educationalLevelArray?.forEach((key) => {
        let { total_male_applicants, total_female_applicants, total_male_registrants, total_female_registrants } =
          data?.data?.educational_levels?.[key];
        tableData.push({
          metric: `Total Applicants (${key})`,
          value: `${formatNumbers(total_male_applicants + total_female_applicants)}`,
          percentage: `${formatNumbers(((total_male_applicants + total_female_applicants) / totalApplications) * 100)}`,
        });
        tableData.push({
          metric: `Total Registrants (${key})`,
          value: `${formatNumbers(total_male_registrants + total_female_registrants)}`,
          percentage: `${formatNumbers(
            ((total_male_registrants + total_female_registrants) / totalRegistrations) * 100,
          )}`,
        });
      });
      tableData = tableData.filter((row) => {
        return row?.metric.includes(tableSearchQuery) == true;
      });
      setTableData(tableData);
    }
  }, [data, tableSearchQuery]);

  React.useEffect(() => {
    if (error) {
      handleErrorMessage(error);
    }
  }, [error]);

  const printTable = () => {
    let divContents = document.getElementById('analysis-table').innerHTML;

    let a = window.open('', '', 'height=500, width=500');
    a.document.write('<html>');
    a.document.write(`<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap" rel="stylesheet">
    <style>

    table{
      border-spacing:0!important;
      width:100%;
    }
    table th 
    {
      color:white;
      background-color:#2a3b8f;
      font-size:1.2rem;
      padding:1rem;
    }

    .cell-header{
      color:white;
      background-color:#2a3b8f;
      font-size:1.2rem;
      padding:1rem;

    }
    
    td
    {
      border-bottom:1px solid rgb(205, 205, 205);
    }

    body
    {
      font-family: "Nunito", serif!important;
    }
    </style>
    </head>`);
    // mywindow.document.write( "<link rel=\"stylesheet\" href=\"style.css\" type=\"text/css\" media=\"print\"/>" );

    a.document.write('<body>');
    a.document.write(divContents);
    a.document.write('</body></html>');
    setTimeout(function () {
      a.print();
    }, 1000);
    a.document.close();
  };

  return (
    <ReportsMain selectedView={0}>
      <Box sx={containerStyle}>
        <Title text="Data Analysis" />
        <Box
          style={{
            marginTop: '2rem',
            padding: '2rem',
            paddingLeft: 'unset',
            paddingRight: 'unset',
            paddingTop: 'unset',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #ebeff2',
            marginLeft: '2rem',
            marginRight: '2rem',
          }}
        >
          <Box sx={{ marginRight: '1rem' }}>
            <button
              style={dateRangeStyle}
              onClick={() => {
                setDateModalOpen(true);
              }}
            >
              <Box>
                <DateRangeIcon style={{ color: 'black' }} />
              </Box>
              <Box
                style={{
                  color: 'black',
                  fontSize: '0.9rem',
                  fontWeight: '600',
                }}
              >
                {`${moment(dateState[0].startDate).format('MMMM Do YYYY')}
                          - ${moment(dateState[0].endDate).format('MMMM Do YYYY')}`}
              </Box>
            </button>
          </Box>
          <Box>
            <Button onClick={runFetch} sx={buttonStyle}>
              Generate Report
            </Button>
          </Box>
          <Modal
            open={dateModalOpen}
            onClose={() => {
              setDateModalOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={boxStyle}>
              <DateRangePicker
                onChange={(item) => changeDateParams([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                ranges={dateState}
                direction="horizontal"
              />
              ;
              <div className="confirm-row w-100" style={{ textAlign: 'center', borderTop: '1px solid #e7e7e7' }}>
                <button
                  style={dateRangeStyle}
                  onClick={() => {
                    setDateModalOpen(false);
                  }}
                >
                  Confirm Time Period
                </button>
              </div>
            </Box>
          </Modal>
        </Box>

        {/* <FilterRow/> */}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: '2rem',
            marginRight: '2rem',
            alignItems: 'center',
            marginTop: '2rem',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Box>
              <Button
                onClick={() => {
                  printTable();
                }}
                sx={buttonStyle}
              >
                Export As PDF
              </Button>
            </Box>
          </Box>
        </Box>
        <Box mx={'2rem'} style={{ width: '50%', marginTop: '1.5rem' }}>
          <SearchInput placeholder="Filter Rows" setSearchQuery={setTableSearchQuery} />
        </Box>

        {isFetching && <TableLoaders />}
        {!isFetching && (
          <Box
            id={'analysis-table'}
            my={'2rem'}
            sx={{
              marginLeft: '2rem',
              marginRight: '2rem',
              height: '400px',
              overflowY: 'auto',
            }}
          >
            <Box
              style={{
                marginBottom: '1.5rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {' '}
              <Logo size="small" />{' '}
              <Box style={{ fontSize: '1.2rem', marginLeft: '1.2rem' }}>JAS Data Analysis Report</Box>
            </Box>
            <Table columns={tableColumns} data={_tableData} />
          </Box>
        )}
      </Box>
    </ReportsMain>
  );
};

export default DataAnalysis;
