/*eslint-disable*/
import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import propTypes from "prop-types";
// import { snakeToCamelCase } from "@utils/helpers/functions";

const categories = [
  { id: 1, name: "Approvals" },
  { id: 2, name: "Automatic Scoring" },
  { id: 3, name: "Dashboard" },
  { id: 4, name: "Grants" },
  { id: 5, name: "Loans" },
  { id: 6, name: "User Management" },
];

//  const filterPermission = (permission,category) =>
// {

//     return permission?.category?.name == category;
// }

const isCategoryEmpty = (permissionArray, category) => {
  try {
    let found = false;
    permissionArray.every((permission) => {
      if (permission?.category?.name == category) {
        found = true;
        return false;
      }
      return true;
    });
    return !found;
  } catch (e) {
    return true;
  }
};

export default function AdminPermissions({ permissions }) {
  return (
    <div style={{ marginTop: "2rem" }}>
      <Grid container direction="row" style={{ marginBottom: "1.5rem" }}>
        <Grid container direction="row" style={{ marginBottom: "1.5rem" }}>
          {categories.map((category, index) => {
            if (isCategoryEmpty(permissions, category?.name) == false) {
              return (
                <>
                  <div
                    className="card-title text-default-color"
                    style={{
                      borderLeft: "0.2em solid #2A3B8F",
                      paddingLeft: "0.5em",
                      marginBottom: "1rem",
                    }}
                  >
                    {category?.name}
                  </div>

                  <Grid container>
                    {permissions?.map((permission, index) => {
                      if (permission?.category?.name == category?.name)
                        return (
                          <Grid
                            item
                            xs={4}
                            key={index}
                            style={{ marginBottom: "1.5rem" }}
                          >
                            <div
                              className="d-flex flex"
                              style={{ alignItems: "center" }}
                            >
                              <div>
                                <i
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    color: "#02B242",
                                  }}
                                  className={`fa fa-check-circle`}
                                ></i>
                              </div>
                              <div className="bottom">
                                <Typography
                                  sx={{
                                    fontFamily: "inherit !important",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  {permission.name}{" "}
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                        );
                    })}
                  </Grid>
                </>
              );
            }
          })}
        </Grid>
      </Grid>
    </div>
  );
}

AdminPermissions.propTypes = {
  permissions: propTypes.array,
};
